import React from 'react'
import PropTypes from 'prop-types'
import { useStyles } from './styles'
import { Modal as MuiModal, Container, Typography } from '@material-ui/core'
import Paper from '../Paper'
import Section from '../Section'
import Form from '../Form'
import ProviderView from '../Layout/AdminLayouts/ProvidersLayout/ProviderView'
import FormAdmin from '../FormAdmin'
import FormExport from '../FormExport'
import OrganizationView from '../Layout/AdminLayouts/OrganizationLayout/OrganizationView'
import BankView from '../Layout/AdminLayouts/BanksLayout/BankView'

const Modal = (props) => {
    const classes = useStyles();
    let purpose = props.purpose
    // let amount = props.amount ? props.amount : null;
    // getModalStyle is not a pure function, we roll the style only on the first render

    return (
        <MuiModal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={props.openModal}
        onClose={props.handleClose}
        disableBackdropClick={props.disableBackdropClick}
        >
            <Container className={classes.container} >
                <Section paddingTop="8rem">
                    <Paper padding="3rem 4rem">
                        { props.view === "addBank" && 
                            (
                                <>
                                <Typography variant="h6" align="center" gutterBottom>
                                    Add Bank Account
                                </Typography>
                                <Form view="addBank" />
                                </>
                            )                            
                        }
                        { props.view === "otp" &&
                            (
                                <>
                                <Typography variant="h6" align="center" gutterBottom>
                                    Verify OTP
                                </Typography>
                                <Form view="otp" purpose={purpose}/>
                                </>
                            )
                        }

                        {/* //admin  */}
                        { props.view === "viewProvider" &&
                            (
                                <ProviderView/>
                            )
                        }
                        { props.view === "provider" &&
                            (
                                <>
                                    <FormAdmin view="provider" purpose={purpose} closeModal={props.handleClose}/>
                                </>
                            )
                        }
                        { props.view === "viewOrganization" &&
                            (
                                <OrganizationView/>
                            )
                        }
                        { props.view === "organization" &&
                            (
                                <>
                                    <FormAdmin view="organization" purpose={purpose} closeModal={props.handleClose}/>
                                </>
                            )
                        }
                        { props.view === "viewBank" &&
                            (
                                <BankView/>
                            )
                        }
                        { props.view === "bank" &&
                            (
                                <>
                                    <FormAdmin view="bank" purpose={purpose} closeModal={props.handleClose}/>
                                </>
                            )
                        }
                        { props.view === "viewInstitution" &&
                            (
                                ""
                            )
                        }
                        { props.view === "institution" &&
                            (
                                <>
                                    <FormAdmin view="institution" purpose={purpose} closeModal={props.handleClose}/>
                                </>
                            )
                        }
                        {/* { props.view === "viewUser" &&
                            (
                                <UserView/>
                            )
                        } */}
                        { props.view === "user" &&
                            (
                                <>
                                    <FormAdmin view="user" purpose={purpose} closeModal={props.handleClose}/>
                                </>
                            )
                        }
                        { props.view === "export" &&
                            (
                                <>
                                    <FormExport/>
                                </>
                            )
                        }
                        
                    </Paper>
                </Section>
            </Container>
        </MuiModal>
    )
}

Modal.propTypes = {
    handleClose : PropTypes.func,
    openModal : PropTypes.bool,
    view : PropTypes.string,
    purpose : PropTypes.string,
    disableBackdropClick : PropTypes.bool,
    amount : PropTypes.string
}

export default Modal
