import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
    },
    card: {
        height: `100%`,
        minHeight: '8.5rem'
    }
}))

export { useStyles }