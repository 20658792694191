import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useRef,
    Fragment,
  } from "react";
  import {
    Box,
    Typography,
    Grid,
    TextField,
    Select,
    MenuItem,
    IconButton,
    FormControl,
    Switch,
    FormControlLabel,
    Chip,
    Input,
  } from "@material-ui/core";
  import { DropzoneArea } from "material-ui-dropzone";
  import CloseIcon from "@material-ui/icons/Close";
  import Menu from "@material-ui/core/Menu";
  import MoreVertIcon from "@material-ui/icons/MoreVert";
  
  import debounce from "lodash.debounce";
  import * as Yup from "yup";
  import { Formik, Form, ErrorMessage, Field } from "formik";
  
  import Paper from "../../../Paper";
  import Dialog from "../../../Reusables/Dialog";
  import Button from "../../../Button";
  import Table from "../../../Reusables/Table";
  import Pagination from "../../../Reusables/Pagination";
  import TableHead from "../../../Reusables/TableHead";
  
  import alertContext from "../../../../Contexts/alert/alertContext";
  import loanContext from "../../../../Contexts/loan/loanContext";
  
  import FormErrors from "../../../FormErrors";
  import {
    nameValidation,
  } from "../../../../Actions/formActions";
  
  import { useStyles } from "./style";
  import { formatDateTime, moneyFormat, validateDates } from "../../../../Actions/helpers";
import AxiosRequest from "../../../../Services/AxiosRequests";
  
  const ProductsLayout = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
  
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIssubmitting] = useState(false);
    const [product, setProduct] = useState(null);
    const [products, setProducts] = useState({});
    const [dialogContentIndex, setDialogContentIndex] = useState(3);
    const [documents, setDocuments] = useState({PHOTO : ""});
    const [brands, setBrands] = useState([]);
  
    const { setAlert } = useContext(alertContext);
    const { makeFilterRequest, addOrUpdateProduct, deleteProductDetails} = useContext(
      loanContext
    );

	  const [curColorValue, setCurColorValue] = useState("");
	  const [curSizeValue, setCurSizeValue] = useState("");
    const [curFeatureValue, setCurFeatureValue] = useState("");

	  const handleKeyUp = (type, e, setFieldValue, values) => {
  		if (e.keyCode === 13 && e.target?.value) {
        e.persist();

        let newValue;
        console.log(e.keyCode, e.target.value);
        
        switch (type) {
          case "color":
            newValue = [...values.colors, {color : e.target.value}]
            setFieldValue("colors", newValue);
            setCurColorValue("");
            break;
        
          case "size":
            newValue = [...values.sizes, {size : e.target.value}]
            setFieldValue("sizes", newValue);
            setCurSizeValue("");
            break;

          case "features":
            newValue = [...values.keyFeatures, {feature : e.target.value}]
            setFieldValue("keyFeatures", newValue);
            setCurFeatureValue("");
            break;

          default:
            break;
        }
  		}
	  };

  	const handleChange = (type, e) => {
	  	switch (type) {
        case "color":
          setCurColorValue(e.target.value);
          break;

        case "size":
          setCurSizeValue(e.target.value);
          break;

        case "features":
          setCurFeatureValue(e.target.value);
          break;
      
        default:
          break;
      }
    };
  
    const handleDelete = (type, index, setFieldValue, values) => {
      let arr;

      switch (type) {
        case "color":
          arr = [...values?.colors]
          arr.splice(index, 1);
          setFieldValue("colors", arr)
          break;

        case "size":
          arr = [...values?.sizes]
          arr.splice(index, 1)
          setFieldValue("sizes", arr)
          break;
      
        case "features":
          arr = [...values?.keyFeatures]
          arr.splice(index, 1)
          setFieldValue("keyFeatures", arr)
          break;

        default:
          break;
      }
    }

    const getBrands = async () => {
      try {
        const res = await AxiosRequest("get", "brands");
        if(res.data.requestSuccessful) {
          const brands = res.data.responseData.items;
          setBrands(brands);
          console.log(brands);
        } else {
          setAlert(res.data?.message || "brands fetching error", "error")
        }
      } catch (error) {
        setAlert(error.response?.data?.message || "brands fetching error", "error")
      }
    }
  
    const initialState = {
      productName: "",
      startDate: "",
      endDate: "",
      pageSize: 10,
      pageNumber: 1,
    };
  
    const [payload, setPayload] = useState({ ...initialState });
    const { pageSize, pageNumber, startDate, endDate, productName } = payload;
  
    const _isMounted = useRef(true);
  
    useEffect(() => {
      if (_isMounted.current === false) {
        _isMounted.current = true;
      }
  
      getProducts();
      getBrands();
      return () => (_isMounted.current = false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, pageNumber]);
  
  
    ////////////////////////**********MODAL ACTIONS**********///////////////////
    const triggerModalOpen = (index) => {
      if(index < 1 || index === 0) {
        if (window.confirm("Are you sure you want to DELETE this product?")) {
          deleteProduct();
        }
      } else {
        setDialogContentIndex(index);
        setOpen(true);
      }
    };
  
    const handleClose = () => {
      setOpen(false);
      //To fix an issue of having data being set to null first before closing modal
      if (open === false) {
        product !== null && setProduct(null);
      }
    };
    ////////////////////////**********MODAL ACTIONS**********///////////////////
  
    ///////////////////////***********product CREATE ACTIONS***********//////////////
  
    const [anchorEl, setAnchorEl] = useState(null);
    const anchorOpen = Boolean(anchorEl);
  
    const handleMenuOpen = (e, product) => {
      setAnchorEl(e.currentTarget);
      console.log("The selected product is: ", product);
      setProduct(product);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
      // if(dialogContentIndex !==3){
      //   product !== null && setProduct(null);
      // }
    };
  
    const createProduct = async (values) => {
      try {
        setIssubmitting(true);
        const res = await addOrUpdateProduct(values);
        if (res.data.requestSuccessful) {
          let action = product !== null ? "Updated" : "Created";
          setAlert(`Product ${action} successfully`, "success");
          getProducts();
          handleClose();
        } else {
          setAlert(res.data.message || "Error occured while performing request", "error");
        }
        setIssubmitting(false);
      } catch (err) {
        setAlert(err.response?.data?.message || "Error occured while performing request", "error");
        setIssubmitting(false);
      }
    };
  
    const deleteProduct = async () => {
      try {
        setIssubmitting(true);
        const res = await deleteProductDetails(product.id);
        if (res.data.requestSuccessful) {
          let action = "deleted";
          setAlert(`Product ${action} successfully`, "success");
          getProducts();
          handleClose();
        } else {
          setAlert(res.data.message, "error");
        }
      } catch (err) {
        console.log(err);
        setAlert(err.response?.data?.message || "Error deleting product", "error");
      }
    }
  
    const initialValues = {
      brand : product?.brand ? product.brand : "",
      model : product?.model ? product.model : "",
      name : product?.name ? product.name : "",
      price : product?.price ? product.price : "",
      productDescription : product?.productDescription ? product.productDescription : "",
      quantity : product?.quantity ? product.quantity : "",
      sku : product?.sku ? product.sku : "",
      warrantyPeriod : product?.warrantyPeriod ? product.warrantyPeriod : "",
      weight : product?.weight ? product.weight : "",
      colors : product?.colors ? [...product.colors] : [],
      sizes : product?.sizes ? [...product.sizes] : [],
      keyFeatures : product?.keyFeatures ? [...product.keyFeatures] : [],
    };
  
    const validationSchema = Yup.object({
      name: nameValidation(
        "Name",
        "name",
        true
      ),
      brand : Yup.string().required("Brand name is required"),
      model : Yup.string().required("Model is required"),
      price : Yup.number().required("Price is required"),
      productDescription : Yup.string().min(3, "not less than 3 characters").required("Description is required"),
      quantity : Yup.string().required("Quantity is required"),
      sku : Yup.string().required(" SKU is required"),
      warrantyPeriod : Yup.string().required(" Warranty Period is required"),
      weight : Yup.string().required("weight is required"),
    });
  
    const onSubmit = async (values) => {
      let submitValues;
      
      const brandId = brands.find((brand) => brand.brandName === values.brand)?.id || "";
      submitValues = {...values, brandId};
      delete submitValues.brand;

      console.log("The submitted values is: ", values, submitValues);
  
      //If its an update action
      if (product !== null) {
        submitValues = {...submitValues, id : product.id};
        createProduct(submitValues);

        console.log("The updated submit values are: ", submitValues);
      } else {
        createProduct(submitValues);

        console.log("The product sign-up values are: ", submitValues);
      }
    };
  
    /////////////////**********VENDORS CREATE *************////////////////
  
    /////////////////**********TABLE ACTIONS *************////////////////
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      ///always reset pageNumber if pageSize or name changes to avoid wrong data fetch
      if (name === "pageSize" || name === "productName") {
        payload.pageNumber = 1;
      }
      setPayload({ ...payload, [name]: value });
    };
  
    const handlePageClick = (page) => {
      setPayload({ ...payload, pageNumber: page });
    };
  
    const handleVendorNameSearch = (e) => {
      setPayload({ ...payload, productName: e.target.value });
      loadWithDebounce(e.target.value);
    };
  
    let url = `products?pageSize=${pageSize}&pageNo=${pageNumber}`;
    let filter = false;
  
    const clearFilters = async () => {
      //make the request without filters, taking into consideration the state of the productName field
      setPayload({ ...initialState, productName });
      filter = true;
      if (productName !== "") {
        url += `&productName=${productName}`;
      }
      await getProducts(url, filter);
    };
  
    const getProductsFromFirstPage = () => {
      if (pageNumber !== 1) {
        setPayload({ ...payload, pageNumber: 1 });
      }
      pageNumber === 1 && getProducts();
    };
  
    const getProducts = async (
      url = `products?pageSize=${pageSize}&page=${pageNumber}`,
      clearFilter
    ) => {
      if (!clearFilter) {
        if (productName !== "") {
          url += `&productName=${productName}`;
        }
  
        if (!validateDates(startDate, endDate)) {
          setAlert("Please enter valid dates", "error");
          return;
        } else if (startDate !== "" && endDate !== "") {
          url += `&startDate=${startDate}&endDate=${endDate}`;
        }
      }
  
      setLoading(true);
      try {
        const res = await makeFilterRequest(url);
        if (_isMounted.current) {
          setProducts(res.data.responseData);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
      open && handleClose();
      filter = false;
    };
  
    const loadWithDebounce = useCallback(
      debounce((productName) => {
        if (productName === "") {
          getProducts(url);
        } else {
          getProducts(`${url}&productName=${productName}`);
        }
      }, 1000),
      []
    );

    const updateDocuments = (doc, name) => {
      console.log(`The ${name} document is: `, doc);
      setDocuments({...documents, [name] : doc});
    }

    const uploadDocuments = async () => { 
      try {
        if(!documents?.PHOTO) {
          return setAlert(`Product image is required`, "error");
        }

        setIssubmitting(true);
        const formData = new FormData();
        formData.append("PHOTO", documents["PHOTO"]);

        console.log("The form data is: ", formData)

        const res = await AxiosRequest("post", `Products/upload_product_photo/${product?.id}`, formData)
        if (res.data.requestSuccessful) {
          setAlert(`Product image uploaded successfully`, "success");
          handleClose();
        } else {
          setAlert(res.data.message, "error");
        }
        setIssubmitting(false);
      } catch (err) {
        console.log("Update logo error", err)
        setAlert(err.response?.data?.message || "Error occured while uploading image", "error");
        setIssubmitting(false);
      }
    }
  
    const columns = [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Brand",
        accessor: "brand",
      },
      {
        Header: "Vendor Name",
        accessor: "vendorName",
      },
      {
        Header: "Store Name",
        accessor: "storeName",
      },
      {
        Header: "Date Created",
        accessor: "dateCreated",
        Cell: (props) => <span>{new Date(props.value).toDateString()}</span>,
      },
      {
        Header: "Model",
        accessor: "model",
      },
      {
        Header: "Warranty",
        accessor: "warrantyPeriod",
      },
      {
        Header: "SKU",
        accessor: "sku",
      },
      {
        Header: " ",
        Cell: (props) => {
          return (
            <>
              <IconButton
                aria-label="more"
                aria-haspopup="true"
                aria-controls={props.row.original.id}
                onClick={(e) => handleMenuOpen(e, props.row.original)}
              >
                <MoreVertIcon color="primary" style={{ cursor: "pointer" }} />
              </IconButton>
            </>
          );
        },
      },
    ];
  
    /////////////////**********TABLE ACTIONS *****************////////////////
  
    /////////////////**********MODAL VIEWS *****************////////////////
    const filterForm = () => (
      <>
        <Grid container spacing={2} style={{ marginTop: "1rem" }}>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <label htmlFor="startDate" className={classes.label}>
                Start Date
              </label>
              <TextField
                name="startDate"
                variant="outlined"
                size="small"
                type="date"
                value={startDate}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <label htmlFor="endDate" className={classes.label}>
                End Date
              </label>
              <TextField
                name="endDate"
                variant="outlined"
                size="small"
                type="date"
                value={endDate}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
        </Grid>
  
        <Box className={classes.filterActions}>
          <Typography
            style={{ color: "red", cursor: "pointer" }}
            onClick={clearFilters}
          >
            Clear all filters
          </Typography>
          <Button
            variant="contained"
            size="medium"
            disabled={loading || (startDate === "" && endDate === "")}
            loading={loading}
            // type="submit"
            color="primary"
            onClick={() => getProductsFromFirstPage()}
          >
            Apply
          </Button>
        </Box>
      </>
    );
  
    const viewProduct = () => {
      if (product !== null) {
        const {
          name,
          model,
          price,
          brand,
          quantity,
          sku,
          storeName,
          vendorName,
          warrantyPeriod,
          weight,
          dateCreated,
          colors,
          sizes,
          keyFeatures,
          productDescription,
        } = product;
  
        return (
          <Box className={classes.vendorView}>
            <Box className={classes.vendorItem}>
              <small variant="body1">Product Name</small>
              <Typography>{name}</Typography>
            </Box>

            <Box className={classes.vendorItem}>
              <small variant="body1">Product Model</small>
              <Typography>{model}</Typography>
            </Box>

            <Box className={classes.vendorItem}>
              <small>Product Description</small>
              <Typography>{productDescription}</Typography>
            </Box>
  
            <Box className={classes.vendorItem}>
              <small>Price</small>
              <Typography>{moneyFormat(price)}</Typography>
            </Box>
  
            <Box className={classes.vendorItem}>
              <small>Brand</small>
              <Typography>{brand}</Typography>
            </Box>
  
            <Box className={classes.vendorItem}>
              <small>Quantity</small>
              <Typography>{quantity}</Typography>
            </Box>
  
            <Box className={classes.vendorItem}>
              <small>SKU</small>
              <Typography>{sku}</Typography>
            </Box>

            <Box className={classes.vendorItem}>
              <small>Store Name</small>
              <Typography>{storeName}</Typography>
            </Box>

            <Box className={classes.vendorItem}>
              <small>Vendor Name</small>
              <Typography>{vendorName}</Typography>
            </Box>

            <Box className={classes.vendorItem}>
              <small>Warranty Period</small>
              <Typography>{warrantyPeriod}</Typography>
            </Box>

            <Box className={classes.vendorItem}>
              <small>Weight</small>
              <Typography>{weight}</Typography>
            </Box>

            <Box className={classes.vendorItem}>
              <small>Date Created</small>
              <Typography>{new Date(dateCreated).toDateString()}</Typography>
            </Box>

            <Box className={classes.vendorItem}>
              <small>Colors</small>
              <Typography>{colors?.length && colors.map((colorEntry) => (colorEntry.color)).join(" | ")}</Typography>
            </Box>

            <Box className={classes.vendorItem}>
              <small>Sizes</small>
              <Typography>{sizes?.length && sizes.map((sizeEntry) => (sizeEntry.size)).join(" | ")}</Typography>
            </Box>

            <Box className={classes.vendorItem}>
              <small>Key Features</small>
              <Typography>{keyFeatures?.length && keyFeatures.map((featureEntry) => (featureEntry.feature)).join(" | ")}</Typography>
            </Box>
          </Box>
        );
      }
    };
  
    const createProductForm = () => (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, getFieldProps, values, setFieldValue, submitForm }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={2} style={{ marginTop: "1rem" }}>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <label htmlFor="Name" className={classes.label}>
                    Product Name
                  </label>
                  <TextField
                    name="Name"
                    placeholder="Enter product's Name"
                    id="Name"
                    variant="outlined"
                    {...getFieldProps("name")}
                    error={
                      errors.name && touched.name
                        ? true
                        : false
                    }
                    size="small"
                  />
                  <ErrorMessage name="name" component={FormErrors} />
                </FormControl>
              </Grid>
  
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <label htmlFor="model" className={classes.label}>
                    Product Model
                  </label>
                  <TextField
                    name="model"
                    placeholder="Enter Product Model"
                    id="model"
                    error={errors.phoneNumber && touched.phoneNumber ? true : false}
                    variant="outlined"
                    {...getFieldProps("model")}
                    size="small"
                  />
                  <ErrorMessage name="model" component={FormErrors} />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}
                  variant="outlined"
                  size="small"
                >
                  <label htmlFor="brand" className={classes.label}>
                    Brand
                  </label>

                  <Select
                    name="brand"
                    {...getFieldProps("brand")}
                    id="brand"
                    error={errors.brand && touched.brand ? true : false}
                  >
                    {
                      brands.map((brand, index) => (
                        <MenuItem key={index} value={brand.brandName}>{brand.brandName}</MenuItem>
                      ))
                    }
                  </Select>
                  <ErrorMessage name="brand" component={FormErrors} />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <label htmlFor="price" className={classes.label}>
                    Product Price
                  </label>
                  <TextField
                    name="price"
                    placeholder="Enter Product Price"
                    id="price"
                    error={errors.price && touched.price ? true : false}
                    variant="outlined"
                    {...getFieldProps("price")}
                    size="small"
                  />
                  <ErrorMessage name="price" component={FormErrors} />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <label htmlFor="quantity" className={classes.label}>
                    Product Quantity
                  </label>
                  <TextField
                    name="quantity"
                    placeholder="Enter Product quantity"
                    id="price"
                    error={errors.quantity && touched.quantity ? true : false}
                    variant="outlined"
                    {...getFieldProps("quantity")}
                    size="small"
                  />
                  <ErrorMessage name="quantity" component={FormErrors} />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <label htmlFor="sku" className={classes.label}>
                    SKU
                  </label>
                  <TextField
                    name="sku"
                    placeholder="Enter SKU"
                    id="sku"
                    error={errors.sku && touched.sku ? true : false}
                    variant="outlined"
                    {...getFieldProps("sku")}
                    size="small"
                  />
                  <ErrorMessage name="sku" component={FormErrors} />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <label htmlFor="warrantyPeriod" className={classes.label}>
                    Warranty Period
                  </label>
                  <TextField
                    name="warrantyPeriod"
                    placeholder="Enter Warranty Period"
                    id="warrantyPeriod"
                    error={errors.warrantyPeriod && touched.warrantyPeriod ? true : false}
                    variant="outlined"
                    {...getFieldProps("warrantyPeriod")}
                    size="small"
                  />
                  <ErrorMessage name="warrantyPeriod" component={FormErrors} />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <label htmlFor="weight" className={classes.label}>
                    Product Weight
                  </label>
                  <TextField
                    name="weight"
                    placeholder="Enter Product Weight"
                    id="weight"
                    error={errors.weight && touched.weight ? true : false}
                    variant="outlined"
                    {...getFieldProps("weight")}
                    size="small"
                  />
                  <ErrorMessage name="weight" component={FormErrors} />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <label htmlFor="productDescription" className={classes.label}>
                    Product Description
                  </label>
                  <TextField
                    name="productDescription"
                    placeholder="Enter Product Description"
                    id="productDescription"
                    error={errors.productDescription && touched.productDescription ? true : false}
                    variant="outlined"
                    {...getFieldProps("productDescription")}
                    size="small"
                  />
                  <ErrorMessage name="productDescription" component={FormErrors} />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <label htmlFor="colors" className={classes.label}>
                  Colors <small>(multiple entries allowed)</small>
                </label>

                <FormControl classes={{ root: classes.formControlRoot }}>
				          <div className={"container"}>
					          {values && values.colors?.map((item, index) => (
					  	        <Chip key={index} size="small" onDelete={()=>handleDelete("color", index, setFieldValue, values)} label={item.color}/>
				  	        ))}
			  	        </div>
		  		        <Input
          					value={curColorValue}
  				        	onChange={(e) => handleChange("color", e)}
        					  onKeyDown={(e) => handleKeyUp("color", e, setFieldValue, values)}
				          />
			          </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <label htmlFor="colors" className={classes.label}>
                  Sizes <small>(multiple entries allowed)</small>
                </label>

                <FormControl classes={{ root: classes.formControlRoot }}>
				          <div className={"container"}>
					          {values && values.sizes?.map((item, index) => (
					  	        <Chip key={index} size="small" onDelete={() => handleDelete("size", index, setFieldValue, values)} label={item.size}/>
				  	        ))}
			  	        </div>
		  		        <Input
          					value={curSizeValue}
  				        	onChange={(e) => handleChange("size", e)}
        					  onKeyDown={(e) => handleKeyUp("size", e, setFieldValue, values)}
				          />
			          </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <label htmlFor="features" className={classes.label}>
                  Key Features <small>(multiple entries allowed)</small>
                </label>

                <FormControl classes={{ root: classes.formControlRoot }}>
				          <div className={"container"}>
					          {values && values.keyFeatures?.map((item, index) => (
					  	        <Chip key={index} size="small" onDelete={() => handleDelete("features", index, setFieldValue, values)} label={item.feature}/>
				  	        ))}
			  	        </div>
		  		        <Input
          					value={curFeatureValue}
  				        	onChange={(e) => handleChange("features", e)}
        					  onKeyDown={(e) => handleKeyUp("features", e, setFieldValue, values)}
				          />
			          </FormControl>
              </Grid>
            </Grid>
  
            <Box className={classes.createActions}>
              <Typography
                variant="body2"
                color="primary"
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              >
                Cancel
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={submitForm}
                disabled={isSubmitting}
                loading={isSubmitting}
                padding={"0.8rem 1.5rem"}
              >
                {product !== null ? "Update" : "Add"}
              </Button>
            </Box>

            <Grid container spacing={2} style={{ marginTop: "1rem" }}>
              { product !== null &&
                <Fragment>
                  <Grid item xs={12} md={6} sm={6}>
                    <FormControl
                      component="fieldset"
                      style={{ width: "100%" }}
                      className={classes.formControl}
                    >
                      <label htmlFor="Logo" className={classes.label}>
                        Product Photo Upload
                      </label>
                      <DropzoneArea
                        acceptedFiles={["image/*"]}
                        dropzoneText={
                          <Box>
                            <Typography display="inline" variant="body2">
                              Drag and drop or
                            </Typography>
                            <Typography
                              display="inline"
                              color="primary"
                              variant="body2"
                            >
                              Browse
                            </Typography>
                          </Box>
                        }
                        maxFileSize={1000000}
                        showPreviewsInDropzone
                        showFileNamesp
                        showFileNamesInPreview
                        getDropRejectMessage={() =>
                          "File should be the required format and not more than 800KB"
                        }
                        filesLimit={1}
                        onChange={(file) => updateDocuments(file[0], "PHOTO")}
                      />
                      <div className={classes.uploadInfo}>
                        <small style={{ fontSize: "10px", fontWeight: 300 }}>
                          Files Supported: JPEG, PNG, SVG
                        </small>
                        <small style={{ fontSize: "10px", fontWeight: 300 }}>
                          Max File Size: 1 MB
                        </small>
                      </div>
                    </FormControl>
                  </Grid>
                </Fragment>
              }
            </Grid>

            { product !== null &&
              <Box className={classes.createActions}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  padding={"0.8rem 1.5rem"}
                  onClick={uploadDocuments}
                >
                  Submit
                </Button>
              </Box>
            }
          </Form>
        )}
      </Formik>
    );
  
    /////////////////**********MODAL VIEWS *****************////////////////
  
    const getDialogContent = () => {
      switch (dialogContentIndex) {
        case 1:
          return filterForm();
  
        case 2:
          return viewProduct();
  
        case 3:
          return createProductForm();
  
        default:
          return "Nothing";
      }
    };
  
    const dialogContent = () => (
      <Box style={{ width: "100%" }} className={classes.paper}>
        <Box className={classes.dialogTitle}>
          <Typography variant="h4">
            {dialogContentIndex === 1
              ? "Filter"
              : dialogContentIndex === 2
              ? "Product Details"
              : product !== null
              ? "Edit Product"
              : "Add Product"}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
  
        <Box className={classes.dialogContent}>
          <hr />
          <Box marginTop={"1rem"}>{getDialogContent()}</Box>
        </Box>
      </Box>
    );
  
    return (
      <Box>
        <Box className={classes.headerTexts}>
          <Typography variant="h4">Products</Typography>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => {
              setProduct(null);
              triggerModalOpen(3);
            }}
          >
            Add Product
          </Button>
        </Box>
        <Box style={{ border: "1px solid #D7DCE0" }} marginTop={"1rem"}>
          <Paper borderRadius={"0"} padding={"2rem"}>
            <TableHead
              title="Total Products"
              searchItem={productName}
              onChange={handleVendorNameSearch}
              loading={loading}
              placeholder="Search By Product Name"
              totalSize={products.totalSize}
              onClick={() => triggerModalOpen(1)}
              handleInputChange={handleInputChange}
            />
  
            <Box marginTop={"2rem"}>
              {loading && !products.items ? (
                <p>Loading...</p>
              ) : (!loading && products.items) ||
                (loading && products.items) ? (
                <>
                  {/* {console.log(users.items)} */}
                  <Table
                    columns={columns}
                    data={products.items}
                    loading={loading}
                  />
                  <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                    <Grid item xs={12} md={3}>
                      {products.items.length > 0 && (
                        <Typography>
                          Showing page {products.pageNumber} of{" "}
                          {Math.ceil(products?.totalSize / pageSize)}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Pagination
                        dataSize={products?.totalSize}
                        perPage={pageSize}
                        handlePageClick={handlePageClick}
                        forcePage={products.pageNumber}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <p>Loading...</p>
              )}
            </Box>
          </Paper>
        </Box>
        <>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={anchorOpen}
            onClick={handleMenuClose}
            PaperProps={{
              style: {
                width: "10ch",
              },
            }}
          >
            <MenuItem onClick={() => triggerModalOpen(2)}>View</MenuItem>
            <MenuItem onClick={() => triggerModalOpen(3)}>Edit</MenuItem>
            <MenuItem onClick={() => triggerModalOpen(0)}>Delete</MenuItem>
          </Menu>
        </>
        <Dialog handleClose={handleClose} open={open} content={dialogContent()} />
      </Box>
    );
  };
  
  export default ProductsLayout;
  
  