import React, { useState, useEffect, useContext } from "react";
// import StatusConnector from "./StatusConnector";
import Spinner from "../../../../Reusables/Spinner";
import * as Yup from "yup";
import { Box, Typography, IconButton } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { useStyles } from "./style";
import queryString from "query-string";
import moment from "moment";

import LoanContext from "../../../../../Contexts/loan/loanContext";
import CredpalContext from "../../../../../Contexts/credpal/credpalContext";
import AlertContext from "../../../../../Contexts/alert/alertContext";

import StudentInformation from "./StudentInformation";
import SponsorInformation from "./SponsorInformation";
import BankAndGuarantorInformation from "./BankAndGuarantorInformation";
import EmploymentInformation from "./EmploymentInformation";
import LoanDocuments from "./LoanDocuments";
import StatusConnector from "./StatusConnector";

import {
  currencyToNumber,
  moneyFormat,
  envURL,
} from "../../../../../Actions/helpers";

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import {
  addressValidation,
  emailValidation,
  nameValidation,
  phoneNumberValidation,
} from "../../../../../Actions/formActions";
import LoanPolicy from "./LoanPolicy";
import AxiosRequest from "../../../../../Services/AxiosRequests";

const ApplicationInformation = () => {
  const classes = useStyles();
  const [showLoanPolicy, setShowLoanPolicy] = useState(false);

  const {
    activeStep,
    user,
    userEmail,
    setUserEmail,
    verified,
    verifiedData,
    submitApplication,
    getBanks,
    currentLoan,
    banks,
  } = useContext(CredpalContext);
  console.log("The user data is: ", user, currentLoan);

  const { sponsorInfo, employment, bankAccount, guarantors, loanRequest, nextOfKin } =
    currentLoan || {};

  const { setAlert } = useContext(AlertContext);
  const { redirectUrl, states, getCities } = useContext(LoanContext);

  // const {
  //   requestDocument,
  //   bankName: providerName,
  //   borrowerType,
  // } = currentProvider;

  const {
    regNo,
    amount,
    Email,
    FirstName,
    LastName,
    Address1,
    DateOfBirth,
    TelePhone,
    Sex,
  } = verifiedData;

  const {
    institutionCode,
    programDescription,
    institutionName,
    email,
    userType,
    bankAccountName,
    bankAccountNo,
    bankName,
  } = user;

  useEffect(() => {
    getBanks();
    setUserEmail(userId);
  }, []);

  //Child components remount, we need to hold the state above
  const [cities, setCities] = useState({
    studentCities: [],
    sponsorCities: [],
  });

  const getCitiesFromStore = async (stateId, identity) => {
    let citiesFromStore = await getCities(stateId);
    if (identity === "student") {
      setCities({ ...cities, studentCities: citiesFromStore });
    } else {
      setCities({ ...cities, sponsorCities: citiesFromStore });
    }
  };

  const location = useLocation();
  let loanRef = queryString.parse(location.search).loanRef;
  const userId = queryString.parse(location.search).userId;
  const loanTypeId = queryString.parse(location.search).type;

  loanRef = loanRef
    ? loanRef
    : localStorage.loanRef
    ? localStorage.loanRef
    : undefined;

  const link = redirectUrl ? redirectUrl : `${envURL}/dashboard`;

  const getGuarantors = (payload) => {
    let newGuarantors = [];
    for (let i = 0; i < payload.length; i++) {
      if (payload[i].firstName !== "") {
        newGuarantors = [...newGuarantors, payload[i]];

        //send Id together with each when running updates
        if (currentLoan?.guarantors?.length > 0) {
          if (payload[i]) {
            newGuarantors[i].id =
              currentLoan.guarantors[i] && currentLoan.guarantors[i].id;
          }
        }
      }
    }
    return newGuarantors;
  };

  const onSubmit = (values) => {
    const {
      firstName,
      lastName,
      gender,
      phoneNumber,
      email,
      dateOfBirth,
      state,
      city,
      address,
    } = values;
    //format payload to match api expectation
    let payload = {
      // providerCode,
      // providerName,
      link,
      requestData: { ...values },
    };

    //add required values and in case of updates for loanRef
    if (userId !== undefined) {
      payload = { userId, ...payload };
    }
    if (loanRef !== undefined) {
      payload = { loanRef, ...payload };
    }

    // if (borrowerType.length) {
    //   payload = { borrowerType, ...payload };
    // }

    if (payload.requestData.loanRequest.isSelfSponsored) {
      payload.requestData = {
        ...payload.requestData,
        sponsorInfo: {
          firstName,
          lastName,
          dateOfBirth,
          gender,
          phoneNumber,
          email,
          address,
          state,
          city,
          relationship: "self",
          bvn: payload.requestData.bankAccount.bvn
            ? payload.requestData.bankAccount.bvn
            : payload.requestData.sponsorInfo.bvn,
          nin: payload.requestData.nin,
        },
      };
    }

    switch (activeStep) {
      case 1:
        // validation for institution partner and employer name on first page
        const {loanRequest, organizationCode, institutionPartner, employment} = values
        if(activeStep === 1 && loanRequest.borrowerType === 1 && (!organizationCode || !institutionPartner)) {
          return setAlert("You must specify an institution partner to proceed", "error");
        } else if(activeStep === 1 && loanRequest.borrowerType === 2 && (!organizationCode || !employment.employerName)) {
          return setAlert("You must specify a corporate partner to proceed", "error");
        }
        
        // As a fix to have that Nin sent to the api as a sponsor info, we no longer delete
        // sponsorInfo if loan is a student type and btw, sending dummy bvn until corrected by
        // user on the bankInfo section
        !payload.requestData.loanRequest.isSelfSponsored &&
          delete payload.requestData.sponsorInfo;
        delete payload.requestData.guarantors; //
        delete payload.requestData.bankAccount; //
        delete payload.requestData.employment; //
        delete payload.requestData.loanDocuments;
        break;

      case 2:
        delete payload.requestData.guarantors; //
        delete payload.requestData.bankAccount;
        delete payload.requestData.employment;
        delete payload.requestData.loanDocuments;
        break;

      case 3:
        // if (
        //   (payload.requestData.guarantors &&
        //     payload.requestData.guarantors[0].firstName === "" &&
        //     payload.requestData.guarantors[1].firstName === "") ||
        //   (payload.requestData.guarantors[0] === undefined &&
        //     payload.requestData.guarantors[1] === undefined)
        // ) {
        //   setAlert("Please provide at least one guarantor", "error");
        //   return;
        // } else {
        //   payload.requestData.guarantors = getGuarantors(
        //     payload.requestData.guarantors
        //   );
        // }

        //set BVN
        // const {employer : {salaryAmount}, loanRequest : {loanAmount}, availableLoanTypeId} = values;
        
        // if(availableLoanTypeId != "e5558021-c254-492c-aac8-78798054f476" && salaryAmount < 100000 && loanAmount > (3 * salaryAmount)) {
        //   return setAlert("Loan amount cannot be more than 3X your salary amount")
        // } else if (availableLoanTypeId != "e5558021-c254-492c-aac8-78798054f476" && salaryAmount < 500000 && loanAmount > (4 * salaryAmount)) {
        //   return setAlert("Loan amount cannot be more than 4X your salary amount")
        // } else if (availableLoanTypeId != "e5558021-c254-492c-aac8-78798054f476" && salaryAmount < 1500000 && loanAmount > (5 * salaryAmount)) {
        //   return setAlert("Loan amount cannot be more than 5X your salary amount")
        // }

        payload.requestData.sponsorInfo.bvn =
          payload.requestData.bankAccount.bvn;
        //Include bankId as partof payload based on user's selected bank
        payload.requestData.bankAccount.bankId = banks.find(
          (bank) => bank.bankCode === payload.requestData.bankAccount.bankCode
        ).id;
        // delete payload.requestData.employment;
        delete payload.requestData.loanDocuments;
        break;

      case 4:
        payload.requestData.guarantors = [];
        // getGuarantors(
        //   payload.requestData.guarantors
        // );
        // delete payload.requestData.loanDocuments;
        break;

      case 5:
        payload.requestData.guarantors = [];
        // getGuarantors(
        //   payload.requestData.guarantors
        // );
        break;

      default:
        return payload;
    }
    delete payload.requestData.nin;
    if(activeStep === 4 && payload.requestData.loanRequest.borrowerType === 2 && !payload.requestData.employment.termsToDecductFromSalary) {
      setAlert('Please accept the terms and conditions before you proceed', 'error');
    }else {
      submitApplication(payload, activeStep);
      // return console.log(payload)
    }
  };

  const getActiveStepContent = (index, values) => {
    switch (index) {
      case 1:
        return (
          <StudentInformation
            getCities={getCitiesFromStore}
            studentCities={cities.studentCities}
          />
        );

      case 2:
        return (
          <SponsorInformation
            getCities={getCitiesFromStore}
            sponsorCities={cities.sponsorCities}
          />
        );

      case 3:
        return <BankAndGuarantorInformation values={values} />;

      case 4:
        return <EmploymentInformation values={values} setShowLoanPolicy={setShowLoanPolicy} />;

      case 5:
        return <LoanDocuments values={values} />;
      
      default:
        return <Spinner />;
    }
  };

  const initialValues = {
    organizationCode: "OTH",
    regNo: regNo ? regNo : userId,
    firstName: currentLoan ? currentLoan.firstName : verified ? FirstName : "",
    lastName: currentLoan ? currentLoan.lastName : verified ? LastName : "",
    address: currentLoan ? currentLoan.address : verified ? Address1 : "",
    dateOfBirth: currentLoan
      ? currentLoan.dateOfBirth
      : verified
      ? DateOfBirth
      : new Date(),
    gender: currentLoan ? currentLoan.gender : verified ? Sex : "",
    email: currentLoan ? currentLoan.email : userEmail,
    phoneNumber: currentLoan
      ? currentLoan.phoneNumber
      : verified
      ? TelePhone
      : "",
    state: currentLoan ? (currentLoan.state ? currentLoan.state : "") : "",
    city: currentLoan ? (currentLoan.city ? currentLoan.city : "") : "",
    nin: currentLoan ? currentLoan.sponsorInfo?.nin : "",
    identificationType: currentLoan ? currentLoan.identificationType : "",
    idNumber: currentLoan ? currentLoan.idNumber : "",
    isThirdParty: currentLoan ? currentLoan.isThirdParty : false,
    availableLoanTypeId : currentLoan ? currentLoan.availableLoanTypeId : loanTypeId,
    institutionPartner : currentLoan ? currentLoan.institutionPartner : "",

    sponsorInfo: {
      firstName: sponsorInfo ? sponsorInfo.firstName : "",
      lastName: sponsorInfo ? sponsorInfo.lastName : "",
      gender: sponsorInfo ? sponsorInfo.gender : "",
      dateOfBirth: sponsorInfo ? sponsorInfo.dateOfBirth : new Date(),
      phoneNumber: sponsorInfo ? sponsorInfo.phoneNumber : "",
      email: sponsorInfo ? sponsorInfo.email : "",
      relationship: sponsorInfo ? sponsorInfo.relationship : "",
      nin: sponsorInfo ? sponsorInfo.nin : "",
      bvn: sponsorInfo
        ? sponsorInfo.bvn
          ? sponsorInfo.bvn
          : 22289641372
        : 22289641372, //test bvn to get by api validation
      // as it is required to be sent. Would be reset when user sends in bank info
      address: sponsorInfo
        ? sponsorInfo.address
          ? sponsorInfo.address
          : ""
        : "",
      state: sponsorInfo ? (sponsorInfo.state ? sponsorInfo.state : "") : "",
      city: sponsorInfo ? (sponsorInfo.city ? sponsorInfo.city : "") : "",
    },

    loanRequest: {
      loanAmount: loanRequest ? loanRequest.loanAmount : amount ? amount : "",
      tenure: loanRequest ? loanRequest.tenure : "",
      borrowerType: loanRequest ? loanRequest.borrowerType : "",
      isSelfSponsored: userType === "student" ? true : false,
      isAcceptedAgreement: true,
      isExecutedSponsorAgreement: true,
    },

    bankAccount: {
      bankId: bankAccount ? bankAccount.bankId : "",
      accountNumber: bankAccount ? bankAccount.accountNumber : "",
      accountName: bankAccount ? bankAccount.accountName : "",
      bankCode: bankAccount ? bankAccount.bankCode : "",
      bvn: bankAccount ? bankAccount.bvn : "",
    },

    guarantors : [],

    // guarantors: [
    //   {
    //     // firstName: guarantors ? guarantors[0] ? guarantors[0].firstName : "" : "",
    //     firstName: guarantors ? guarantors[0].firstName : "",
    //     lastName: guarantors ? guarantors[0].lastName : "",
    //     phoneNumber: guarantors ? guarantors[0].phoneNumber : "",
    //     relationship: guarantors ? guarantors[0].relationship : "",
    //   },
    //   {
    //     // firstName: guarantors ? guarantors[1].firstName : "",
    //     firstName: guarantors
    //       ? guarantors[1]
    //         ? guarantors[1].firstName
    //         : ""
    //       : "",
    //     lastName: guarantors
    //       ? guarantors[1]
    //         ? guarantors[1].lastName
    //         : ""
    //       : "",
    //     phoneNumber: guarantors
    //       ? guarantors[1]
    //         ? guarantors[1].phoneNumber
    //         : ""
    //       : "",
    //     relationship: guarantors
    //       ? guarantors[1]
    //         ? guarantors[1].relationship
    //         : ""
    //       : "",
    //   },
    // ],

    employment: {
      employmentStatus: employment ? employment.employmentStatus : "",
      employerName: employment ? employment.employerName : "",
      employerAddress: employment ? employment.employerAddress : "",
      workplaceEmail: employment ? employment.workPlaceEmail : "",
      salaryAmount: employment ? employment.salaryAmount : "",
      salaryDueDate: employment ? employment.salaryDueDate : new Date(),
      termsToDecductFromSalary: employment ? employment.termsToDecductFromSalary : false,
      staffOREmploymentId: employment ? employment.staffOREmploymentId : ""
    },

    institutionInfo: {
      name: institutionName,
      programDescription : programDescription || currentLoan?.institutionInfo?.programDescription || "Education", // Temporary fix for users with an application already.
      code: "OTH",
      bankAccountNo: bankAccountNo ? bankAccountNo : null,
      bankAccountName: bankAccountName ? bankAccountName : null,
      bankName: bankName ? bankName : null,
    },

    nextOfKin: {
      firstName: nextOfKin ? nextOfKin.firstName : "",
      lastName: nextOfKin ? nextOfKin.lastName : "",
      phoneNumber: nextOfKin ? nextOfKin.phoneNumber : "",
      address: nextOfKin ? nextOfKin.address : "",
      relationship : nextOfKin ? nextOfKin.relationship : ""
    },

    loanDocuments: {
      id: undefined,
      bankStatement: undefined,
      employeeID: undefined,
      employmentLetter: undefined,
      studentId: undefined,
    },
  };

  const validateSponsorInfo = () => {
    if (userType !== "student" && activeStep === 2) {
      return Yup.object({
        firstName: nameValidation("First Name", "sponsorInfo.firstName"),
        lastName: nameValidation("Last Name", "sponsorInfo.lastName"),
        gender: Yup.string().required("Gender is empty"),
        dateOfBirth: Yup.string().test(
          "dateOfBirth",
          "Applicant must be between 18 to 59 years",
          (value) => {
            return (
              moment().diff(moment(value), "years") >= 18 &&
              moment().diff(moment(value), "years") <= 59
            );
          }
        ),
        phoneNumber: phoneNumberValidation(
          "Phone Number",
          "sponsorInfo.phoneNumber"
        ),
        email: emailValidation(),
        relationship: Yup.string().required("Relationship is empty"),
        nin: Yup.string()
          .matches(/^[0-9]*$/, "Only numbers allowed")
          .min(11, "Has to be eleven digits")
          .max(11, "Has to be eleven digits")
          .required("NIN is empty"),
        address: addressValidation(),
        state: Yup.string().required("Select state"),
        city: Yup.string().required("Select city"),
        // borrowerType: Yup.string().required("Select borrower type"),
      });
    }
  };

  const validationSchema = Yup.object({
    firstName: nameValidation("First Name", "firstName"),
    lastName: nameValidation("Last Name", "lastName"),
    address: addressValidation(),
    dateOfBirth:
      //putting applicant age limit of the selfSPonsored type
      userType !== "student"
        ? Yup.string()
            .required("Date of birth is empty")
            .nullable()
            .test(
              "dateOfBirth",
              "Date of birth must be in the past",
              (value) => {
                var d = new Date();
                d.setDate(d.getDate() - 1);
                return !moment(value).isAfter(d, "day");
              }
            )
        : Yup.string()
            .test(
              "dateOfBirth",
              "Applicant must be between 18 to 59 years",
              (value) => {
                return (
                  moment().diff(moment(value), "years") >= 18 &&
                  moment().diff(moment(value), "years") <= 59
                );
              }
            )
            .nullable(),
    gender: Yup.string().required("Gender is empty"),
    phoneNumber: phoneNumberValidation("Phone Number", "phoneNumber"),
    // identificationType: requestDocument && Yup.string().required("Identification Type is required"),
    nin:
      userType === "student" &&
      Yup.string()
        .matches(/^[0-9]*$/, "Only numbers allowed")
        .min(11, "Has to be eleven digits")
        .max(11, "Has to be eleven digits")
        .required("NIN is empty"),
    state: Yup.string().required("Select state"),
    city: Yup.string().required("Select city"),

    // idNumber: requestDocument && Yup.string().required("ID number is empty"),

    sponsorInfo: validateSponsorInfo(),

    loanRequest:
      activeStep === 1 &&
      Yup.object({
        loanAmount: Yup.string()
          .test({
            name: "validator-custom-name",
            //eslint-disable-next-line object-shorthand
            test: function (value) {
              return currencyToNumber(value) > 6000000 //providerInfo.maximumLoan
                ? this.createError({
                    message: `Maximum loan Amount is ${moneyFormat(
                      6000000 //providerInfo.maximumLoan
                    )}`,
                    path: "loanRequest.loanAmount",
                  })
                : true;
            },
          })
          .test({
            name: "validator-custom-name",
            //eslint-disable-next-line object-shorthand
            test: function (value) {
              return currencyToNumber(value) < 20000 // providerInfo.minimumLoan
                ? this.createError({
                    message: `Minimum loan Amount is ${moneyFormat(
                      20000 //providerInfo.minimumLoan
                    )}`,
                    path: "loanRequest.loanAmount",
                  })
                : true;
            },
          })
          .required("Request loan amount is empty"),
        // loanAmount: Yup.number()
        //   .min(
        //     providerInfo.minimumLoan,
        //     `Minimum loan Amount is ${providerInfo.minimumLoan}`
        //   )
        //   .max(
        //     providerInfo.maximumLoan,
        //     `Maximum loan Amount is ${providerInfo.maximumLoan}`
        //   )
        //   ,
        tenure: Yup.number()
          .min(1, "Minimum Tenure is 1 month")
          .max(
            24, //providerInfo.tenor
            `Maximum Tenure is 24 Months`
          )
          .required("Loan tenure is empty"),
        borrowerType: Yup.string().required("Select borrower type"),
      }),

    nextOfKin: 
      activeStep === 1 &&
      Yup.object({
        firstName: Yup.string()
          .test({
            name: "validator-custom-name",
            //eslint-disable-next-line object-shorthand
            test: function (value) {
              return /\d/.test(value)
                ? this.createError({
                    message: "Invalid name",
                    path: "nextOfKin.firstName",
                  })
                : true;
            },
          })
          .min(3, "Minimum of 3 characters")
          .required("First Name is empty"),
        lastName: Yup.string()
          .test({
            name: "validator-custom-name",
            //eslint-disable-next-line object-shorthand
            test: function (value) {
              return /\d/.test(value)
                ? this.createError({
                    message: "Invalid name",
                    path: "nextOfKin.lastName",
                  })
                : true;
            },
          })
          .min(3, "Minimum of 3 characters")
          .required("Last Name is empty"),
        phoneNumber: phoneNumberValidation("Phone Number", "nextOfKin.phoneNumber"),
        address: addressValidation(),
        relationship: Yup.string()
          .min(3, "Not less than 3 characters")
          .test({
            name: "validator-custom-name",
            //eslint-disable-next-line object-shorthand
            test: function (value) {
              return /\d/.test(value)
                ? this.createError({
                    message: "Invalid relationship",
                    path: "nextOfKin.relationship",
                  })
                : true;
            },
          })
          .required("Relationship is empty"),
      }),

    bankAccount:
      activeStep === 3 &&
      Yup.object({
        accountName: Yup.string()
          .min(3, "Not less than 3 characters")
          .test({
            name: "validator-custom-name",
            //eslint-disable-next-line object-shorthand
            test: function (value) {
              return /\d/.test(value)
                ? this.createError({
                    message: "Invalid name",
                    path: "bankAccount.accountName",
                  })
                : true;
            },
          })
          .required("Account Name is empty"),
        bankCode: Yup.string().required("Choose a bank"),
        accountNumber: Yup.string()
          .matches(/^[0-9]*$/, "Only numbers allowed")
          .min(10, "Has to be ten digits")
          .max(10, "Has to be ten digits")
          .required("Account Number is empty"),
        bvn:
          // userType === "student" &&
          Yup.string()
            .matches(/^[0-9]*$/, "Only numbers allowed")
            .min(11, "Has to be eleven digits")
            .max(11, "Has to be eleven digits")
            .required("BVN is empty"),
      }),

    // guarantors:
    //   activeStep === 3 &&
    //   Yup.array().of(
    //     Yup.lazy((value) => {
    //       if (
    //         value.firstName ||
    //         value.lastName ||
    //         value.phoneNumber !== undefined
    //       ) {
    //         return Yup.object().shape({
    //           firstName: Yup.string()
    //             .test({
    //               name: "validator-custom-name",
    //               //eslint-disable-next-line object-shorthand
    //               test: function (value) {
    //                 return /\d/.test(value)
    //                   ? this.createError({
    //                       message: "Invalid name",
    //                       // path: `${value}.firstName`
    //                       path: this.path,
    //                     })
    //                   : true;
    //               },
    //             })
    //             .min(3, "Minimum of 3 characters")
    //             .required("First name is empty"),
    //           lastName: Yup.string()
    //             .test({
    //               name: "validator-custom-name",
    //               //eslint-disable-next-line object-shorthand
    //               test: function (value) {
    //                 return /\d/.test(value)
    //                   ? this.createError({
    //                       message: "Invalid name",
    //                       path: this.path,
    //                     })
    //                   : true;
    //               },
    //             })
    //             .min(3, "Minimum of 3 characters")
    //             .required("Surname is empty"),
    //           phoneNumber: Yup.string()
    //             .matches(
    //               /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
    //               "Enter a valid phone number"
    //             )
    //             .min(9, "Minimum of 9 characters")
    //             .required("Phone Number is empty"),
    //           relationship: Yup.string()
    //             .min(3, "Not less than 3 characters")
    //             .test({
    //               name: "validator-custom-name",
    //               //eslint-disable-next-line object-shorthand
    //               test: function (value) {
    //                 return /\d/.test(value)
    //                   ? this.createError({
    //                       message: "Invalid relationship",
    //                       path: this.path,
    //                     })
    //                   : true;
    //               },
    //             })
    //             .required("Relationship is empty"),
    //         });
    //       }
    //       return Yup.mixed().notRequired();
    //     })
    //   ),

    employment:
      activeStep === 3 &&
      Yup.object({
        employmentStatus: Yup.string().required("Select Employment status"),
        employerName: Yup.string()
          .min(3, "Not less than 3 characters")
          .test({
            name: "validator-custom-name",
            //eslint-disable-next-line object-shorthand
            test: function (value) {
              return /\d/.test(value)
                ? this.createError({
                    message: "Invalid name",
                    path: "employment.employerName",
                  })
                : true;
            },
          })
          .required("Employer's name is empty"),
        employerAddress: Yup.string()
          .min(5, "Not less than 5 characters")
          .required("Employer's address is empty"),
        workplaceEmail: Yup.string()
          .email("Invalid email format!")
          .required("email is empty"),
        salaryAmount: Yup.string()
          .matches(
            /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
            "Enter a valid Amount"
          )
          .required("How much do you earn ?"),
        // salaryDueDate: Yup.date().min(new Date(), "Date cannot be in the past")
        // .required("Salary due date is empty"),
        salaryDueDate: Yup.string()
          .nullable()
          .test("salaryDueDate", "Cannot be in the past", (value) => {
            var d = new Date();
            d.setDate(d.getDate() - 1);
            return moment(value).isAfter(d, "day");
          })
          // .min(new Date(Date.now() - 86400000), "Date cannot be in the past")
          // .min(new Date(), "Date cannot be in the past")
          .required("Salary due date is empty"),
        // staffOREmploymentId:  user.borrowerType === 2 ? Yup.string()
        // .min(3, "Not less than 5 characters")
        // .max(30, 'Maximum of 30 characters')
        // .required("Employee ID is empty") : Yup.string()
        // .min(3, "Not less than 5 characters")
        // .max(30, 'Maximum of 30 characters'),
      }),

    loanDocuments:
      activeStep === 5 &&
      Yup.object({
        // id: Yup.mixed(),
        // bankStatement: Yup.mixed().required("Upload your bank statement"),
        // employeeID: Yup.mixed().required("Upload your employee id"),
        // employmentLetter: Yup.mixed(),
        // studentId: Yup.mixed().when("organizationCode", {
        //   is: "OTH",
        //   then: Yup.mixed().required("Upload your student Id"),
        // }),
      }),
  });

  return !states.length ? (
    <Spinner />
  ) : (
    <Box>
      <div style={{ marginTop: "2rem", overflow: "hidden" }}>
        <StatusConnector
          // showUploads={requestDocument}
          selfSponsored={initialValues.loanRequest.isSelfSponsored}
        />
      </div>
      <Box className={classes.header}>
        <Typography className={classes.title} gutterBottom>
          Loan Form
        </Typography>

        {showLoanPolicy ? <Box sx={{cursor: 'pointer'}} className={classes.dashboardLink} onClick={() => setShowLoanPolicy(false)}>
          <IconButton aria-label="close" className={classes.backButton}>
              <KeyboardArrowLeftIcon fontSize="small" /> 
            </IconButton>
            <small className={classes.dashboardLink}>Back</small>
        </Box> : <Box>
          <a
            href={link}
            className={classes.dashboardLink}
            rel="noopener noreferrer"
          >
            <IconButton aria-label="close" className={classes.backButton}>
              <KeyboardArrowLeftIcon fontSize="small" />
            </IconButton>
            <small style={{fontWeight : "500", color : "#117AF3", fontSize : "13px"}}>Back Home</small>
          </a>
        </Box>}
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, touched }) => (
          <Form noValidate autoComplete="off" className={classes.form}>
            {showLoanPolicy ?  <LoanPolicy /> : getActiveStepContent(activeStep, values)}
            
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ApplicationInformation;

ApplicationInformation.propTypes = {
  providerInfo: PropTypes.object,
};
