import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    // height: "100vh",
      margin: "0 auto",
      width: "30%",
      paddingBottom: "2rem",
      [theme.breakpoints.down('sm')]: {
        width: "50%"
    },
      [theme.breakpoints.down('xs')]: {
        width: "90%"
    },
   
  }
}));

export { useStyles }

