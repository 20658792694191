import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  //    root: {},

  container: {
    width: "50%",
    margin: "2rem auto 5rem",
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  title: {
    fontWeight: "500",
    fontSize: "18px",
    color: theme.palette.primary.main,
  },
  fieldWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  backButton: {
    padding: "0!important",
  },
  header: {
    width: "50%",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  dropContainer: {
    width: "5rem!important",
    height: "5rem!important",
    padding: "8rem!important",
    backgroundColor: "green!important",
  },
  dropZone: {
    height: "2%!important",
  },
  root: {
    "& .MuiDropzoneArea-root": {
      borderRadius: "100px!important",
      boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75)!important;",
    },
  },

  intro: {
    marginBottom: "1rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  formControl: {
    minWidth: `48%`,
    margin: theme.spacing(0.5, 0),
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  dashboardLink: {
    textDecoration: "none",
    color: "#000000",
    cusor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  form: {
    position: "relative",
    top: "-1rem",
    width: "100%",
  },
  label: {
    fontSize: "13px",
    fontWeight: "400",
    marginBottom: ".1rem",
  },
  select: {
    height: "2.7rem",
  },
}));

export { useStyles };
