import React, {useState} from 'react'
import { useStyles } from './style'
import { useTable, usePagination, useSortBy, useFilters } from 'react-table'
import {Table as MuiTable, TableBody, TableCell, TableHead, TableRow, TableContainer, TablePagination, Button, Toolbar, Tooltip, Typography, Box, TextField} from '@material-ui/core/'
import Paper from '../Paper'
import TablePaginationActions from './TablePaginationActions'
import { DefaultColumnFilter } from '../Layout/AdminLayouts/ReportLayout'
import Modal from '../Modal'

const Table = (
        {
        columns,
        data,
        fetchData,
        loading,
        pageCount: controlledPageCount,
        pageName // the page the app is on
        }
    ) => {

    const classes = useStyles();

    
    const defaultColumn = React.useMemo(
        () => ({
          // Let's set up our default Filter UI
          Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageCount,
        gotoPage,
        setPageSize,
        // Get the state from the instance
        state: { startDate, endDate, pageIndex, pageSize },
        } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, hiddenColumns: ['id'] }, 
            manualPagination: true,
            pageCount: controlledPageCount,
            defaultColumn, // Be sure to pass the defaultColumn option
            // filterTypes,
            autoResetPage: false,
        },
        useFilters,
        useSortBy,
        usePagination,

    )
    // Listen for changes in pagination and use the state to fetch our new data
    React.useEffect(() => {
        fetchData({ startDate, endDate, pageIndex, pageSize })
    }, [fetchData, startDate, endDate, pageIndex, pageSize])


    const handleChangeRowsPerPage = (event) => {
        setPageSize(+event.target.value);
    };


    // Modal controls 
    const [openModal, setOpenModal] = useState(false);

    const handleOpen = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    //date range functions
    const [ dateRange, setDateRange ] = useState({
        startDate: '',
        endDate: ''
    })

    const fetchDateRange = (startDate, endDate, pageIndex, pageSize) => {
        fetchData({ startDate, endDate, pageIndex, pageSize })
    }

    const handleDateRangeChange = e => {
        const { name, value } = e.target 

        setDateRange({ 
            ...dateRange, 
            [name]: value,
        });     
    }

    const onSubmitDate = (event) => {
        event.preventDefault()

        fetchDateRange(dateRange.startDate, dateRange.endDate, 0, 50)

    }


    return (
        <div>
            <Paper padding='0'>
                
                { pageName === 'report' &&
                <Toolbar>
                <Typography className={classes.title} variant="h6" id="tableTitle" component="div" style={{ flex: '1 1 100%' }}>
                    Report
                </Typography>
                <Box width="100%" textAlign="right" margin=".5rem" borderRight="1px solid #eee" paddingRight="1rem">
                    <TextField
                        id="startDate"
                        name="startDate"
                        label="Start Date"
                        type="date"
                        size="small"
                        value={dateRange.startDate}
                        onChange={handleDateRangeChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{width: '17ch', margin: '8px'}}
                        // variant="outlined"
                    />
                    <TextField
                        id="endDate"
                        name="endDate"
                        label="End Date"
                        type="date"
                        size="small"
                        value={dateRange.endDate}
                        onChange={handleDateRangeChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{width: '17ch', margin: '8px'}}
                        // variant="outlined"
                    />
                    <Box margin='8px'>
                        <Button variant="outlined" color="primary" onClick={onSubmitDate}
                        style={{verticalAlign: 'baseline'}}>
                            Filter Date
                        </Button>
                    </Box>
                </Box>
                <Tooltip title="Export">
                    <Button variant='contained' color='secondary' onClick={handleOpen}>Export</Button>
                </Tooltip>
                </Toolbar>
                }
                 <TableContainer className={classes.container}> 
                    <MuiTable stickyHeader aria-label="table" {...getTableProps()}>
                        <TableHead>
                        {headerGroups.map(headerGroup => (
                            <TableRow hover {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <TableCell {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                    {/* <span>
                                        {column.isSorted
                                        ? column.isSortedDesc
                                            ? ' 🔽'
                                            : ' 🔼'
                                        : ''}
                                    </span> */}
                                    {/* Render the columns filter UI */}
                                    {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                                </TableCell>
                            ))}
                            </TableRow>
                        ))}

                        </TableHead>
                        <TableBody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                            <TableRow hover {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                return <TableCell {...cell.getCellProps()} style={{fontSize: "12px"}}>{cell.render('Cell')}</TableCell>
                                })}
                            </TableRow>
                            )
                        })}
                        <TableRow>
                            {loading ? (
                            // Use our custom loading state to show a loading indicator
                            <TableCell colSpan="10000">Loading...</TableCell>
                            ) : (
                            <TableCell colSpan="10000">
                                Showing {page.length} of ~{pageCount}{' '}results
                                {/* Showing {page.length} of ~{controlledPageCount * pageSize}{' '}results */}
                            </TableCell>
                            )}
                        </TableRow>
                        </TableBody>
                    </MuiTable>
                </TableContainer>
                <TablePagination 
                    rowsPerPageOptions={[10, 20, 30, 40, 50, 100, 200]}
                    component="div"
                    count={pageCount}
                    rowsPerPage={pageSize}
                    page={pageIndex}
                    onChangePage={gotoPage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    colSpan={3}
                    SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                    }}
                    ActionsComponent={TablePaginationActions}
                />
            </Paper>

            {/* 
                Pagination can be built however you'd like. 
                This is just a very basic UI implementation:
            */}

            {/* <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
                </button>{' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
                </button>{' '}
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
                </button>{' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                {'>>'}
                </button>{' '}
                <span>
                Page{' '}
                <strong>
                    {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
                </span>
                <span>
                | Go to page:{' '}
                <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(page)
                    }}
                    style={{ width: '100px' }}
                />
                </span>{' '}
                <select
                value={pageSize}
                onChange={e => {
                    setPageSize(Number(e.target.value))
                }}
                >
                {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                    </option>
                ))}
                </select>
            </div> */}


            {/* Load Custom Modal COmponent */}
            <Modal handleClose={handleClose} openModal={openModal} view='export' />
        </div>
    )
}

Table.propTypes = {

}

export default Table
