import React, {useContext, useState} from 'react'
import { Typography, Box, Button } from '@material-ui/core'
import { SelectedEntityContext } from '../../../../../App'
import Modal from '../../../../Modal'

const BankView = props => {
    const [ selectedEntity ] = useContext(SelectedEntityContext)
    console.log('selectedEntity', selectedEntity);

    // Modal controls 
    const [openModal, setOpenModal] = useState(false);

    const handleOpen = (entity) => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };


    return (
        <div>
            <Typography variant="h6" align="center" gutterBottom>
                {selectedEntity.bankName}
            </Typography>
            <Box textAlign='left'>
                <Typography variant="body1" gutterBottom>
                    Bank Code: <strong>{ selectedEntity.bankCode }</strong>
                </Typography>
                <Button color='secondary' variant='contained' onClick={handleOpen}>
                    Edit
                </Button>
            </Box>
            
            {/* Load Custom Modal COmponent */}
            <Modal handleClose={handleClose} openModal={openModal} view='bank' />

        </div>
    )
}

BankView.propTypes = {

}

export default BankView
