import React, { useContext } from "react";
import Button from "../../../../Button";
import { Box } from "@material-ui/core";
import credPalContext from "../../../../../Contexts/credpal/credpalContext";
import { useStyles } from "./style";

const ControlButtons = ({ step }) => {
  const classes = useStyles();
  const { isSubmitting, handlePreviousStep, activeStep } = useContext(credPalContext);

  return (
    <Box align="right" marginTop={6} style={{ width: "100%" }}>
      <Box className={classes.buttons}>
          <Box style={{visibility: activeStep < 2 ? "hidden" : "" }}>
          <Button
          onClick={() => handlePreviousStep(step)}
          disabled={isSubmitting}
          variant="outlined"
          padding={"0.7rem 0.5rem"}
          type="submit"
          color="primary"
        >
          Back
        </Button>
          </Box>
        <Button // when clicked, this button triggers the validation and submission of the parent form on application info page
          variant="contained"
          padding={"0.7rem 2rem"}
          disabled={isSubmitting}
          loading={isSubmitting}
          type="submit"
          color="primary"
        >
         { activeStep < 3 ? "Save & Continue" : "Submit" } 
        </Button>
      </Box>
    </Box>
  );
};

export default ControlButtons;
