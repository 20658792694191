import { makeStyles } from '@material-ui/core/styles'

const backgroundColorPicker = (backgroundColor, theme) => {
    if (backgroundColor === 'primary') {
        return theme.palette.primary.main
    } else if (backgroundColor === 'secondary') {
        return theme.palette.secondary.main
    } else {
        return '#ffffff'
    }
}

const backgroundHoverPicker = (backgroundColor, theme) => {
    if (backgroundColor === 'primary') {
        return theme.palette.primary.dark
    } else if (backgroundColor === 'secondary') {
        return theme.palette.secondary.dark
    } else {
        return ''
    }
}

const useStyles = makeStyles(theme => ({
    root: {
        width: props => props.width ? props.width : `100%`,
        height: props => props.height ? props.height : `auto`,
        // padding: props => props.padding ? props.padding : `3rem`,
        borderRadius: `1rem`,
        boxShadow: `0px 20px 30px rgba(17,122,243,0.05)`,
        backgroundColor: props => backgroundColorPicker(props.backgroundColor, theme),
        color: props => props.backgroundColor ? theme.palette.background.paper : theme.palette.text.main,
        transition: `all .7s cubic-bezier(.2,1,.22,1)`,
        '&:hover' : {
            backgroundColor: props => backgroundHoverPicker(props.backgroundColor, theme),
            boxShadow: `0px 10px 20px rgba(17,122,243,0.30)`,
            transform: `translateY(-3px)`
        },
        '&:active' : {
            boxShadow: `0px 10px 20px rgba(17,122,243,0.60)`
        }
    }
}));

export { useStyles }