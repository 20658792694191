import { Typography, Paper, Grid, Button, FormGroup, FormControlLabel, Checkbox, useTheme, useMediaQuery, Divider } from '@material-ui/core';
import { Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Input from '../../../../Input';
import useStyles from './styles';
import { useHistory, useLocation } from 'react-router';
import userContext from '../../../../../Contexts/users/userContext';
import alertContext from '../../../../../Contexts/alert/alertContext';
import * as Yup from 'yup';

const AddEditRole = () => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [permissions, setPermissions] = useState([]);

    const initialValue = {
        roleName: "",
        description: "",
        selectedPermissions: []
    }
    const roleValues = location.state
    const role = roleValues ? { ...roleValues, selectedPermissions: roleValues.selectedPermissions.map(i => i.permissionId) } : initialValue;
    const { setAlert } = useContext(alertContext);
    const { getModulesPermissions, createAdminRole } = useContext(
        userContext
    );

    useEffect(() => {
        (async () => {
            const permissionData = await getModulesPermissions();
            setPermissions(permissionData)
        })()
    }, []);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={role}
            validationSchema={Yup.object({
                roleName: Yup.string().required('please provide the role name'),
            })}
            onSubmit={async (values, { setSubmitting }) => {
                const formValues = {
                    ...values,
                    selectedPermissions: values.selectedPermissions.map(id => ({ permissionId: id }))
                }
                try {
                    const res = await createAdminRole(formValues);
                    if (res.data.requestSuccessful) {
                        const action = values.id ? "Updated" : "Created";
                        setAlert(`Role ${action} successfully`, "success");
                        history.push('/users/roles');
                    } else {
                        setAlert(res.data.message, "error");
                    }
                    setSubmitting(false);
                } catch (err) {
                    console.log(err);
                }
            }}
        >
            {({ getFieldProps, errors, touched, isSubmitting, handleSubmit, values, setFieldValue }) => {
                console.log(values)
                return (
                    <>
                        <Typography variant="h4">{role.id ? 'Edit Role' : 'Create Role'}</Typography>
                        <Paper elevation={0} className={classes.paper}>
                            <Grid container xs={12} spacing={2}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <Typography component="label" htmlFor="roleName">Role Name</Typography>
                                    <Input
                                        {...getFieldProps("roleName")}
                                        fullWidth
                                        id="roleName"
                                        name="roleName"
                                        placeholder="Role Name"
                                        errors={errors}
                                        touched={touched}
                                    />
                                </Grid>
                                <Divider style={{ width: '100%', margin: '3rem 0' }} />
                                {permissions.map((permission, index) =>
                                    <Grid container xs={12} className={classes.permissionBox}>
                                        <Typography variant="body2">{permission.moduleName}</Typography>
                                        <Grid container xs={12} className={classes.permission}>
                                            {permission.permissions.map((item, index) => {
                                                return (<Grid key={index} item xs={12} sm={6} md={4}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={values.selectedPermissions.includes(item.id)}
                                                                onChange={() => {
                                                                    if (values.selectedPermissions.includes(item.id)) {
                                                                        const productsList = [...values.selectedPermissions]
                                                                        const i = productsList.indexOf(item.id);
                                                                        productsList.splice(i, 1);
                                                                        setFieldValue('selectedPermissions', productsList);
                                                                    } else {
                                                                        const productsList = [...values.selectedPermissions]
                                                                        productsList.push(item.id);
                                                                        setFieldValue('selectedPermissions', productsList);
                                                                    }
                                                                }}
                                                                name="create"
                                                                color="secondary"
                                                            />
                                                        }
                                                        label={<Typography>{item.permission.toLowerCase()}</Typography>}
                                                    />
                                                </Grid>)
                                            })}
                                        </Grid>
                                    </Grid>)}
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '100px' }}>
                                    <Button color="primary" style={{ textTransform: 'none' }} onClick={() => history.goBack()}>Cancel</Button>
                                    <Button variant="contained" color="primary" style={{ textTransform: 'none' }} disabled={isSubmitting} onClick={() => handleSubmit()}>Save</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </>
                )
            }}
        </Formik>

    )
};

export default AddEditRole;