import React from 'react'
import Section from '../../Section'
import { Container, Typography } from '@material-ui/core'
import FailedSVG from '../../../Assets/images/Failed.svg'

const Failed = props => {
    return (
        <Section textAlign="center">
            <Container>
                <img src={FailedSVG} alt="failed"  style={{ height: `8rem` }}/>
                <Typography variant="subtitle2" gutterBottom align="center">
                    {props.message}
                </Typography>
            </Container>
        </Section>
    )
}

export default Failed