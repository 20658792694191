import React, { useReducer, useContext } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import credpalReducer from "./credpalReducer";
import AxiosRequest from "../../Services/AxiosRequests";
import AlertContext from "../alert/alertContext";
import CredpalContext from "./credpalContext";
import { useHistory } from "react-router-dom";
import { currencyToNumber, envURL } from "../../Actions/helpers";

import advanclyWidget from "../../Components/Layout/UsersLayouts/AdvanclyWidget";

import {
  SET_LOADING,
  GET_INSTITUTIONS_SUCCESS,
  GET_BANKS_SUCCESS,
  SET_USER_DATA,
  STUDENT_VERIFICATION_FAIL,
  SET_ISSUBMITTING,
  STUDENT_VERIFICATION_SUCCESS,
  HANDLE_PREVIOUS,
  HANDLE_NEXT,
  APPLICATION_FAIL,
  APPLICATION_SUCCESS,
  GET_LOAN_SUCCESS,
  GET_LOAN,
  GET_LOAN_FAIL,
  FILL_BANK_FORM,
  ACTION_ERROR,
  LOAN_ACTION_SUCCESS,
  LOAN_ACTION_ERROR,
  INFORMATION_MISMATCH,
  SET_USER_EMAIL,
  SET_ISCOMPLETED
} from "../types";

const CredpalState = (props) => {
  const initialState = {
    activeStep: 0,
    institutions: [],
    user: {},
    userEmail : "",
    verifiedData: {},
    banks: [],
    verified: false,
    removeBankInformation: false, //to track if user's info did not match bvn, we can empty the bank fields
    currentLoan: null,
    gettingLoan: false, //to track if we're making fetchLoan request
    loading: false,
    isSubmitting: false,
    isLoanCompleted : false,
  };

  const [state, dispatch] = useReducer(credpalReducer, initialState);

  const { setAlert } = useContext(AlertContext);

  const errMsg = "An error occured, please try again";
  const history = useHistory();

  const location = useLocation();

  const {loanRef} = queryString.parse(location.search);

  // const populateBankDetails = async (bankId) => {
  //   try {
  //     const res = await AxiosRequest("get", "Bank");

  //     if(res.data.requestSuccessful) {
  //       const banks = res.data.responseData;

  //       const bankCode = banks.find((bank) => bank.id === bankId).bankCode;
  //       console.log("The bank code is: ", bankCode);
  //     }
  //   } catch (error) {
  //     console.log("Populate bank code error: ", error);
  //   }
  // }

  //get loanRef from storage
  const getStorageLoanRef = () => {
    const loanRef = localStorage.getItem("loanRef");
    
    return loanRef;
  }

  //get Institutions
  const getInstitutions = async () => {
    setLoading();

    try {
      const res = await AxiosRequest("get", "institution");
      // const res = await axios.get("https://gateway-api.educollectfinance.com/api/institution")
      if (res.data.requestSuccessful) {
        const activeInstitutions = res.data.responseData.items.filter(
          (institution) => institution.isActive === true
        );
        await dispatch({
          type: GET_INSTITUTIONS_SUCCESS,
          payload: [
            ...activeInstitutions,
            {
              id: "OTH",
              name: "OTHER INSTITUTIONS",
              code: "OTH",
            },
          ],
        });
      } else {
        dispatch({
          type: ACTION_ERROR,
        });
      }
    } catch (err) {
      //  console.log(err)
      dispatch({
        type: ACTION_ERROR,
      });
    }
  };

  // Get Banks
  const getBanks = async () => {
    let response = [];
    setLoading();
    try {
      const res = await AxiosRequest("get", "Bank");
      if (res.data.requestSuccessful) {
        dispatch({
          type: GET_BANKS_SUCCESS,
          payload: res.data.responseData,
        });
        response = res.data.responseData;
      } else {
        dispatch({
          type: ACTION_ERROR,
        });
      }
    } catch (err) {
      //console.log(err)
      dispatch({
        type: ACTION_ERROR,
      });
    }

    return console.log("Banks operation done.", response);
  };

  //set user's information for use where necessary
  const setUserData = (values) => {
    dispatch({
      type: SET_USER_DATA,
      payload: values,
    });
    handleNextStep(1);
  };

  //set user's email from their Id to auto-populate the loan form.
  const setUserEmail = (userId) => {
    try {
      const fetchUser = async () => {
        if (userId) {
          const res = await AxiosRequest(
            "get",
            `User/GetUserBySSOUserId/${userId}`
          );
    
          if(res.data.requestSuccessful) {
            const {email} = res.data.responseData;
            
            dispatch({
              type: SET_USER_EMAIL,
              payload: email,
            });
          } else {
            setAlert("Fetching user data failed, please reload page", "error");
          }
        }
      }

      fetchUser();
    } catch (error) {
      setAlert("Fetching user data failed, please reload page", "error");
    }
  };

  //verify a student against school db if he/she exists
  const verifyStudent = async (values) => {
    setIsSubmitting();

    try {
      const institutionObj = state.institutions.find(
        (el) => el.code === values.institutionCode
      );
      const { accountName, accountNo, bankName, isIntegrated } = institutionObj;

      values = {
        ...values,
        bankName,
        bankAccountNo: accountNo,
        bankAccountName: accountName,
      };
      //Get student's information if Bursery is integrated with the institution
      //Else, just populate the bankInfo of the institution in our db
      if (isIntegrated) {
        const payload = {
          regNo: values.regNo,
          email: values.email,
          institutionCode: values.institutionCode,
        };
        const res = await AxiosRequest(
          "post",
          "BeneficiaryDetail/verify",
          payload
        );

        if (res.data.requestSuccessful) {
          dispatch({
            type: STUDENT_VERIFICATION_SUCCESS,
            payload: {
              user: values,
              verifiedData: res.data.responseData,
            },
          });
          handleNextStep(1);
        } else {
          dispatch({
            type: STUDENT_VERIFICATION_FAIL,
          });
          setAlert(res.data.message, "error");
        }
      } else {
        dispatch({
          type: STUDENT_VERIFICATION_SUCCESS,
          payload: {
            user: values,
          },
        });
        handleNextStep(1);
      }
    } catch (err) {
      actionError(err);
    }
  };

  const getLoanByRef = async (loanRef, userId) => {
    dispatch({
      type: GET_LOAN,
    });
    try {
      const res = await AxiosRequest(
        "get",
        `LoanTransaction/loan/${loanRef}?userId=${userId}`
      );
      if (res.data.requestSuccessful) {
        let data = res.data.responseData;
        //set the bankCode property as the api returns only ID
        if (data.hasOwnProperty("bankAccount")) {
          const getBank = await AxiosRequest(
            "get",
            `Bank/${data.bankAccount.bankId}`
          );
          data.bankAccount.bankCode = getBank.data.responseData.bankCode;
        }
        dispatch({
          type: GET_LOAN_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: GET_LOAN_FAIL,
        });
      }
    } catch (err) {
      // console.log(err)
      actionError(err);
    }
  };

  const updateLoanProvider = async (payload) => {
    try {
      const res = await AxiosRequest(
        "post",
        `LoanTransaction/update_loan_request_provider_id`,
        payload
      );
      if(res.data.requestSuccessful) {
        setAlert("Loan Provider Updated Successfully", "success");
      }

      return res.data;
    } catch (err) {
      // console.log(err)
      actionError(err);
      return {error : "Update Provider Error. Try Again"};
    }
  }

  const initiateAdvanclyWidget = async (banks, values) => {
    try {
      const {
        providerCode,
        loanRef,
        link,
        sponsorInfo: {
          phoneNumber,
          city,
          state: applicantState,
          firstName,
          lastName,
          email,
          address,
          gender,
        },
        loanRequest: { tenure, loanAmount },
        bankAccount: { bvn, bankId, accountNumber},
        // institutionInfo: { bankAccountNo, bankName },
      } = values;
      const { data } = await AxiosRequest(
        "get",
        `LoanTransaction/GetAggregatorInfo?sortCode=${providerCode}`
      );

      const { product_id, product_code, aggregator_id, public_key } =
        data.responseData;

      const payload = {
        aggregator_id,
        public_key,
        // customer_type: borrowerType[0].typeId.toString(),
        customer_type: "1",
        product_id,
        product_code,
        bvn_number: bvn,
        aggregator_loan_ref: loanRef,
        borrower_phone: phoneNumber,
        city,
        state: applicantState.replace(" State", ""),
        first_name: firstName,
        last_name: lastName,
        email,
        residence_address: address,
        gender: gender === "M" ? "Male" : "Female",
        tenure: tenure * 30,
        bank_code: banks.find((bank) => bank.id === bankId).bankCode,
        bank_account_number: accountNumber,
        amount: currencyToNumber(loanAmount),
        redirect: link
          ? `/application/success?redirect=${link}`
          : "/application/success",
      };

      advanclyWidget(payload);
      dispatch({
        type: APPLICATION_SUCCESS,
      });
    } catch (err) {
      console.log("Error occured with advancly widget population", err);
      actionError(err);
    }
  };

  //submit advancly application.
  const submitAdvanclyApplication = async (values) => {
    console.log("Advancly submit is called", values, state.banks);

    try {
      const res = await AxiosRequest("get", "Bank");

      if(res.data.requestSuccessful) {
        const banks = res.data.responseData;

        console.log("The banks fetched are: ", banks);
        initiateAdvanclyWidget(banks, values);
      } else {
        throw res.data;
      }
    } catch (error) {
      console.log("Populate bank code error: ", error);
    }
  }

  //Submit loan requests
  const submitApplication = async (values, activeStep) => {
    setIsSubmitting();
    try {
      if (activeStep === 5) {
        const { id, bankStatement, employeeID, employmentLetter, studentId } =
          values.requestData.loanDocuments;
        let loanDocData = new FormData();
        loanDocData.append("id", id);
        loanDocData.append("bankStatement", bankStatement);
        loanDocData.append("employeeID", employeeID);
        loanDocData.append("employmentLetter", employmentLetter);
        loanDocData.append("studentId", studentId);

        const uploadDocs = await AxiosRequest(
          "post",
          `Upload?loanRef=${values.loanRef}`,
          loanDocData
        );
        if (uploadDocs.data.requestSuccessful) {
          dispatch({
            type: APPLICATION_SUCCESS,
          });
          handleNextStep(1);
        } else {
          setAlert("Uploads failed, please try again", "error");
          dispatch({
            type: APPLICATION_FAIL,
          });
        }
      } else {
        // remove data not needed by api here
        const { borrowerType, link, providerName, ...rest } = values;

        rest.requestData.loanRequest.loanAmount = currencyToNumber(
          rest.requestData.loanRequest.loanAmount
        );
        const res = await AxiosRequest(
          "post",
          "LoanTransaction/loanRequest",
          rest
        );
        if (res.data.requestSuccessful) {
          console.log("The request was successful", res.data.responseData);

          if (activeStep === 4 && values.providerName === "Advancly") {
            return initiateAdvanclyWidget(values);
          } else if(activeStep === 3) {
            const res = await AxiosRequest(
              "post",
              "LoanTransaction/update_loan_request_to_completed",
              {loanRef : localStorage.getItem("loanRef") || loanRef}
            );

            
            
            // go back to the dashboard
            // window.location.href = `${envURL}/dashboard`

            // set loan completed to true
            return res.data.requestSuccessful && setIsCompleted();
          }

          dispatch({
            type: APPLICATION_SUCCESS,
          });

          //This makes sure when a user reloads the page, they are able to continue the loan process and not restart
          if (activeStep === 1 && !(getStorageLoanRef())) {
            window.history.replaceState(
              null,
              "Educollect Loan",
              `apply?loanRef=${res.data.responseData.data.loanRef}&userId=${values.userId}`
            );
          }

          if (typeof window !== "undefined") {
            !localStorage.getItem("loanRef") &&
              localStorage.setItem(
                "loanRef",
                res.data.responseData?.data?.loanRef
              );
          }

          ///Handle steps (check if user is selfsponsored, skip sponsor page)/////
          if (
            activeStep === 1 &&
            values.requestData.loanRequest.isSelfSponsored
          ) {
            handleNextStep(2);
          } else {
            handleNextStep(1);
          }
          ////////////
        } else {
          if (
            res.data.message === "Loan Request failed. Information mismatch"
          ) {
            informationMismatch();
          }
          //This should cater for a scenario where a user had submitted wrong bank details and
          // goes back to previous form, user should be allowed to go correct data on the bankInfo page
          let possibleBankErrors = [
            "Could not resolve account name. Check parameters or try again.",
            "Unable to resolve BVN",
          ];
          if (
            activeStep !== 3 &&
            possibleBankErrors.includes(res.data.message)
          ) {
            dispatch({
              type: FILL_BANK_FORM,
            });
          }
          //allow user pass to the bank info page if he is not selfsponsored but with wrong phone number
          if (
            !values.requestData.loanRequest.isSelfSponsored &&
            res.data.message === "Loan Request failed. Information mismatch" &&
            activeStep < 3
          ) {
            dispatch({
              type: APPLICATION_SUCCESS,
            });
            return handleNextStep(1);
          }
          console.log("The error received is: ", res.data.message);
          setAlert(res.data.message, "error");
          dispatch({
            type: APPLICATION_FAIL,
            // payload: res.data.message
          });
        }
      }
    } catch (err) {
      dispatch({
        type: APPLICATION_FAIL,
        // payload: res.data.message
      });
      console.log("The return error is: ", err.response?.data || err);
      setAlert(err.response?.data?.message || "Error occured. Cannot submit application.", "error");
    }
  };

  const informationMismatch = () =>
    dispatch({
      type: INFORMATION_MISMATCH,
    });

  //This is for admins
  const updateLoanRequest = async (payload) => {
    setIsSubmitting();
    try {
      //remove the loanDocuments from payload
      const { loanDocuments, ...rest } = payload.requestData;
      payload.requestData = rest;

      const res = await AxiosRequest(
        "post",
        "LoanTransaction/loanRequest",
        payload
      );

      if (res.data.requestSuccessful) {
        //if loan updates successfully, send documents
        //checck if any doc was uploaded
        if (JSON.stringify(loanDocuments) !== "{}") {
          // const { id, bankStatement, employeeID, employmentLetter, studentId } = loanDocuments;
          let loanDocData = new FormData();
          
          for (const key in loanDocuments) {
            if (loanDocuments[key]) {
              const element = loanDocuments[key];
              
              loanDocData.append(key, element);
            }
          }

          const uploadDocs = await AxiosRequest(
            "post",
            `Upload?loanRef=${payload.loanRef}`,
            loanDocData
          );
          if (!uploadDocs.data.requestSuccessful) {
            dispatch({
              type: LOAN_ACTION_ERROR,
            });
            return setAlert(uploadDocs.data?.message || "Uploads failed, please try again", "error");
          }
        }
        history.push({
          pathname: "/dashboard",
        });
        setAlert("Loan updated successfully", "success");
        dispatch({
          type: LOAN_ACTION_SUCCESS,
        });
      } else {
        dispatch({
          type: LOAN_ACTION_ERROR,
        });
        setAlert(res.data.message, "error");
      }
    } catch (err) {
      actionError(err);
    }
  };

  const updateLoanByRef = async (loanRef, userId) => {
    dispatch({
      type: GET_LOAN,
    });
    try {
      const res = await AxiosRequest(
        "get",
        `LoanTransaction/loan/`
      );
      if (res.data.requestSuccessful) {
        let data = res.data.responseData;
        //set the bankCode property as the api returns only ID
        if (data.hasOwnProperty("bankAccount")) {
          const getBank = await AxiosRequest(
            "get",
            `Bank/${data.bankAccount.bankId}`
          );
          data.bankAccount.bankCode = getBank.data.responseData.bankCode;
        }
        dispatch({
          type: GET_LOAN_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: GET_LOAN_FAIL,
        });
      }
    } catch (err) {
      // console.log(err)
      actionError(err);
    }
  };

  const actionError = (err) => {
    console.log(err);
    setAlert(err.response?.data?.message || errMsg, "error");
    dispatch({ type: ACTION_ERROR });
  };

  const handleNextStep = (step) =>
    dispatch({
      type: HANDLE_NEXT,
      payload: step,
    });

  const handlePreviousStep = (step) =>
    dispatch({
      type: HANDLE_PREVIOUS,
      payload: step,
    });

  //set form submission status
  const setIsSubmitting = () => dispatch({ type: SET_ISSUBMITTING });

  //set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  //set loan completed status
  const setIsCompleted = (payload = true) => dispatch({ type: SET_ISCOMPLETED, payload });

  return (
    <CredpalContext.Provider
      value={{
        ...state,
        getInstitutions,
        setUserData,
        setUserEmail,
        verifyStudent,
        getLoanByRef,
        submitApplication,
        getBanks,
        handleNextStep,
        handlePreviousStep,
        updateLoanRequest,
        informationMismatch,
        submitAdvanclyApplication,
        setIsCompleted,
        updateLoanProvider
      }}
    >
      <>{props.children}</>
    </CredpalContext.Provider>
  );
};

export default CredpalState;
