import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  Fragment,
} from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  IconButton,
  FormControl,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import debounce from "lodash.debounce";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";

import Paper from "../../../Paper";
import Dialog from "../../../Reusables/Dialog";
import Button from "../../../Button";
import Table from "../../../Reusables/Table";
import Pagination from "../../../Reusables/Pagination";
import StatusIndicator from "../../../Reusables/StatusIndicator";
import TableHead from "../../../Reusables/TableHead";

import alertContext from "../../../../Contexts/alert/alertContext";
import loanContext from "../../../../Contexts/loan/loanContext";
import credpalContext from "../../../../Contexts/credpal/credpalContext";

import FormErrors from "../../../FormErrors";
import {
  nameValidation,
  phoneNumberValidation,
  emailValidation,
  addressValidation
} from "../../../../Actions/formActions";

import { useStyles } from "./style";
import { formatDateTime, validateDates } from "../../../../Actions/helpers";

const CustomersLayout = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIssubmitting] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [customers, setCustomers] = useState({});
  const [dialogContentIndex, setDialogContentIndex] = useState(3);

  const { setAlert } = useContext(alertContext);
  const { makeFilterRequest, addOrUpdateCustomers, deleteCustomerDetails } = useContext(
    loanContext
  );
  const { getBanks, banks } = useContext(credpalContext);

  const initialState = {
    customerName: "",
    startDate: "",
    endDate: "",
    pageSize: 10,
    pageNumber: 1,
  };

  const [payload, setPayload] = useState({ ...initialState });
  const { pageSize, pageNumber, startDate, endDate, customerName } = payload;

  const _isMounted = useRef(true);

  useEffect(() => {
    if (_isMounted.current === false) {
      _isMounted.current = true;
    }

    getCustomers();
    return () => (_isMounted.current = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageNumber]);

  useEffect(() => {
    getBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ////////////////////////**********MODAL ACTIONS**********///////////////////
  const triggerModalOpen = (index) => {
    setDialogContentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    //To fix an issue of having data being set to null first before closing modal
    if (open === false) {
      customer !== null && setCustomer(null);
    }
  };
  ////////////////////////**********MODAL ACTIONS**********///////////////////

  ///////////////////////***********customer CREATE ACTIONS***********//////////////

  const [anchorEl, setAnchorEl] = useState(null);
  const anchorOpen = Boolean(anchorEl);

  const handleMenuOpen = (e, customer) => {
    setAnchorEl(e.currentTarget);
    console.log("The selected customer is: ", customer);
    setCustomer(customer);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // if(dialogContentIndex !==3){
    //   customer !== null && setcustomer(null);
    // }
  };

  const createCustomers = async (values) => {
    try {
      setIssubmitting(true);
      const res = await addOrUpdateCustomers(values);
      if (res.data.requestSuccessful) {
        let action = customer !== null ? "Updated" : "Created";
        setAlert(`Customer ${action} successfully`, "success");
        getCustomers();
        handleClose();
      } else {
        setAlert(res.data.message, "error");
      }
      setIssubmitting(false);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteCustomer = async () => {
    try {
      setIssubmitting(true);
      const res = await deleteCustomerDetails(customer.id);
      if (res.data.requestSuccessful) {
        let action = "deleted";
        setAlert(`Customer ${action} successfully`, "success");
        getCustomers();
        handleClose();
      } else {
        setAlert(res.data.message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  }

  const initialValues = {
    customerName: customer?.name ? customer.name : "",
    contactEmail: customer?.contactPersonEmail ? customer.contactPersonEmail : "",
    contactNumber: customer?.contactPersonPhoneNumber ? customer.contactPersonPhoneNumber : "",
    address: customer?.address ? customer.address : "",
    type: customer?.type ? customer.type : "",
    bankCode: customer?.bankCode ? customer.bankCode : "",
    bankName: customer?.bankName ? customer.bankName: "",
    bankAccountName: customer?.accountName ? customer.accountName : "",
    bankAccountNo: customer?.accountNo ? customer.accountNo : "",
  };

  const validationSchema = Yup.object({
    customerName: nameValidation(
      "Customer Name",
      "customerName",
      true
    ),
    contactEmail: emailValidation(),
    contactNumber: phoneNumberValidation("Phone Number", "contactNumber"),
    address : addressValidation(),
    type : Yup.number().required("Select a customer type"),
    bankCode: Yup.string().required("Bank code is required"),
    bankAccountName: nameValidation(
      "Customer Account Name",
      "bankAccountName",
      true
    ),
    bankAccountNo: Yup.string()
      .matches(/^[0-9]*$/, "Only numbers allowed")
      .min(10, "Has to be ten digits")
      .max(10, "Has to be ten digits")
      .required("Account Number is empty"),
  });

  const onSubmit = async (values) => {
    const {
      customerName,
      contactEmail,
      contactNumber,
      address,
      type,
      bankCode,
      bankName,
      bankAccountName,
      bankAccountNo,
    } = values;
    const bank = banks.find((bank) => bank.bankCode === bankCode);
    values = {
      name: customerName,
      contactPersonPhoneNumber : contactNumber,
      contactPersonEmail : contactEmail,
      address : address,
      type : type,
      bankName: bank?.bankName || bankName,
      bankCode,
      accountNo: bankAccountNo,
      accountName: bankAccountName,
    };

    //If its an update action
    if (customer !== null) {
      values = { ...values, id: customer.id };
    }

    createCustomers(values);
  };

  /////////////////**********CUSTOMERS CREATE *************////////////////

  /////////////////**********TABLE ACTIONS *************////////////////
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    ///always reset pageNumber if pageSize or name changes to avoid wrong data fetch
    if (name === "pageSize" || name === "customerName") {
      payload.pageNumber = 1;
    }
    setPayload({ ...payload, [name]: value });
  };

  const handlePageClick = (page) => {
    setPayload({ ...payload, pageNumber: page });
  };

  const handleCustomerNameSearch = (e) => {
    setPayload({ ...payload, customerName: e.target.value });
    loadWithDebounce(e.target.value);
  };

  let url = `partner?pageSize=${pageSize}&pageNo=${pageNumber}`;
  let filter = false;

  const clearFilters = async () => {
    //make the request without filters, taking into consideration the state of the customerName field
    setPayload({ ...initialState, customerName });
    filter = true;
    if (customerName !== "") {
      url += `&name=${customerName}`;
    }
    await getCustomers(url, filter);
  };

  const getcustomersFromFirstPage = () => {
    if (pageNumber !== 1) {
      setPayload({ ...payload, pageNumber: 1 });
    }
    pageNumber === 1 && getCustomers();
  };

  const getCustomers = async (
    url = `partner?pageSize=${pageSize}&page=${pageNumber}`,
    clearFilter
  ) => {
    if (!clearFilter) {
      if (customerName !== "") {
        url += `&name=${customerName}`;
      }

      if (!validateDates(startDate, endDate)) {
        setAlert("Please enter valid dates", "error");
        return;
      } else if (startDate !== "" && endDate !== "") {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }
    }

    setLoading(true);
    try {
      const res = await makeFilterRequest(url);
      if (_isMounted.current) {
        res.data.responseData.items.forEach(element => {
          if (element.type === "INSTITUTION") element.type = 1;
          else element.type = 2;
        });
        setCustomers(res.data.responseData);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
    open && handleClose();
    filter = false;
  };

  const loadWithDebounce = useCallback(
    debounce((customerName) => {
      if (customerName === "") {
        getCustomers(url);
      } else {
        getCustomers(`${url}&Name=${customerName}`);
      }
    }, 1000),
    []
  );

  const columns = [
    {
      Header: "Customer Name",
      accessor: "name",
    },
    {
      Header: "Contact Person Email",
      accessor: "contactPersonEmail",
    },
    {
      Header: "Contact Person Number",
      accessor: "contactPersonPhoneNumber",
    },
    {
      Header: "Customer Type",
      accessor: "type",
      Cell: (props) => <span>{props.value === 1 ?  "Institution" : "Corporate"}</span>,
    },
    {
      Header: " ",
      Cell: (props) => {
        return (
          <>
            <IconButton
              aria-label="more"
              aria-haspopup="true"
              aria-controls={props.row.original.id}
              onClick={(e) => handleMenuOpen(e, props.row.original)}
            >
              <MoreVertIcon color="primary" style={{ cursor: "pointer" }} />
            </IconButton>
          </>
        );
      },
    },
  ];

  /////////////////**********TABLE ACTIONS *****************////////////////

  /////////////////**********MODAL VIEWS *****************////////////////
  const filterForm = () => (
    <>
      <Grid container spacing={2} style={{ marginTop: "1rem" }}>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <label htmlFor="startDate" className={classes.label}>
              Start Date
            </label>
            <TextField
              name="startDate"
              variant="outlined"
              size="small"
              type="date"
              value={startDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <label htmlFor="endDate" className={classes.label}>
              End Date
            </label>
            <TextField
              name="endDate"
              variant="outlined"
              size="small"
              type="date"
              value={endDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Box className={classes.filterActions}>
        <Typography
          style={{ color: "red", cursor: "pointer" }}
          onClick={clearFilters}
        >
          Clear all filters
        </Typography>
        <Button
          variant="contained"
          size="medium"
          disabled={loading || (startDate === "" && endDate === "")}
          loading={loading}
          // type="submit"
          color="primary"
          onClick={() => getcustomersFromFirstPage()}
        >
          Apply
        </Button>
      </Box>
    </>
  );

  const viewcustomer = () => {
    if (customer !== null) {
      const {
        name,
        contactPersonEmail,
        contactPersonPhoneNumber,
        address,
        type,
        bankName,
        accountName,
        accountNo,
      } = customer;

      return (
        <Box className={classes.customerView}>
          <Box className={classes.customerItem}>
            <small variant="body1">Customer Name</small>
            <Typography>{name}</Typography>
          </Box>

          <Box className={classes.customerItem}>
            <small>Contact Person Email</small>
            <Typography>{contactPersonEmail}</Typography>
          </Box>

          <Box className={classes.customerItem}>
            <small>Contact Person Number</small>
            <Typography>{contactPersonPhoneNumber}</Typography>
          </Box>

          <Box className={classes.customerItem}>
            <small>Address</small>
            <Typography>{address}</Typography>
          </Box>

          <Box className={classes.customerItem}>
            <small>Customer Type</small>
            <Typography>{type === 1 ? "INSTITUTION" : "CORPORATE"}</Typography>
          </Box>

          <Box className={classes.customerItem}>
            <small>Bank Name</small>
            <Typography>{bankName}</Typography>
          </Box>

          <Box className={classes.customerItem}>
            <small>Account Name</small>
            <Typography>{accountName}</Typography>
          </Box>

          <Box className={classes.customerItem}>
            <small>Account Number</small>
            <Typography>{accountNo}</Typography>
          </Box>
        </Box>
      );
    }
  };

  const createcustomerForm = () => (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, getFieldProps, values, setFieldValue }) => (
        <Form noValidate autoComplete="off">
          <Grid container spacing={2} style={{ marginTop: "1rem" }}>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <label htmlFor="customerName" className={classes.label}>
                  Customer Name
                </label>
                <TextField
                  name="customerName"
                  placeholder="Enter customer name"
                  id="customerName"
                  variant="outlined"
                  {...getFieldProps("customerName")}
                  error={
                    errors.customerName && touched.customerName
                      ? true
                      : false
                  }
                  size="small"
                />
                <ErrorMessage name="customerName" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <label htmlFor="contactEmail" className={classes.label}>
                  Contact Person's Email
                </label>
                <TextField
                  name="contactEmail"
                  placeholder="okay@fine.com"
                  id="contactEmail"
                  error={errors.contactEmail && touched.contactEmail ? true : false}
                  variant="outlined"
                  {...getFieldProps("contactEmail")}
                  size="small"
                />
                <ErrorMessage name="contactEmail" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <label htmlFor="contactNumber" className={classes.label}>
                  Contact Person's Number
                </label>
                <TextField
                  name="contactNumber"
                  placeholder="07012345678"
                  id="contactNumber"
                  error={errors.contactNumber && touched.contactNumber ? true : false}
                  variant="outlined"
                  {...getFieldProps("contactNumber")}
                  size="small"
                />
                <ErrorMessage name="contactNumber" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <label htmlFor="address" className={classes.label}>
                  Customer's address
                </label>
                <TextField
                  name="address"
                  placeholder="No 1, Ozumba Mbadiwe Avenue"
                  id="address"
                  error={errors.address && touched.address ? true : false}
                  variant="outlined"
                  {...getFieldProps("address")}
                  size="small"
                />
                <ErrorMessage name="address" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl
                size="small"
                variant="outlined"
                className={classes.formControl}
              >
                <label htmlFor="type" className={classes.label}>
                  Customer Type
                </label>
                <Select
                  defaultValue=""
                  placeholder="Select"
                  displayEmpty
                  id="type"
                  name="type"
                  {...getFieldProps("type")}
                  error={errors.bankCode && touched.bankCode ? true : false}
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  <MenuItem value={1}>Institution</MenuItem>
                  <MenuItem value={2}>Corporate</MenuItem>
                </Select>

                <ErrorMessage name="type" component={FormErrors} />
              </FormControl>
            </Grid>
            
            { values.type === 1 &&
              <Fragment>
              <Grid item xs={12} md={6}>
              <FormControl
                size="small"
                variant="outlined"
                className={classes.formControl}
              >
                <label htmlFor="bankName" className={classes.label}>
                  Bank Name
                </label>
                <Select
                  defaultValue=""
                  placeholder="Select"
                  displayEmpty
                  id="bankCode"
                  name="bankCode"
                  {...getFieldProps("bankCode")}
                  error={errors.bankCode && touched.bankCode ? true : false}
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  {banks.map((bank) => (
                    <MenuItem key={bank.id} value={bank.bankCode}>
                      {bank.bankName}
                    </MenuItem>
                  ))}
                </Select>

                <ErrorMessage name="bankName" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <label htmlFor="bankAccountName" className={classes.label}>
                  Customer's Account Name
                </label>
                <TextField
                  name="bankAccountName"
                  id="bankAccountName"
                  placeholder="Jane Doe"
                  {...getFieldProps("bankAccountName")}
                  variant="outlined"
                  error={
                    errors.bankAccountName && touched.bankAccountName
                      ? true
                      : false
                  }
                  size="small"
                />
                <ErrorMessage name="bankAccountName" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <label htmlFor="bankAccountNo" className={classes.label}>
                  Bank Account Number
                </label>
                <TextField
                  name="bankAccountNo"
                  id="bankAccountNo"
                  placeholder="0122449274"
                  className={classes.numberInput}
                  {...getFieldProps("bankAccountNo")}
                  variant="outlined"
                  error={
                    errors.bankAccountNo && touched.bankAccountNo ? true : false
                  }
                  size="small"
                />
                <ErrorMessage name="bankAccountNo" component={FormErrors} />
              </FormControl>
            </Grid>
              </Fragment>
            }
          </Grid>

          <Box className={classes.createActions}>
            <Typography
              variant="body2"
              color="primary"
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            >
              Cancel
            </Typography>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              padding={"0.8rem 1.5rem"}
            >
              {customer !== null ? "Update" : "Add"}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );

  /////////////////**********MODAL VIEWS *****************////////////////

  const getDialogContent = () => {
    switch (dialogContentIndex) {
      case 1:
        return filterForm();

      case 2:
        return viewcustomer();

      case 3:
        return createcustomerForm();

      default:
        return "Nothing";
    }
  };

  const dialogContent = () => (
    <Box style={{ width: "100%" }} className={classes.paper}>
      <Box className={classes.dialogTitle}>
        <Typography variant="h4">
          {dialogContentIndex === 1
            ? "Filter"
            : dialogContentIndex === 2
            ? "Customer Details"
            : customer !== null
            ? "Edit Customer"
            : "Add Customer"}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className={classes.dialogContent}>
        <hr />
        <Box marginTop={"1rem"}>{getDialogContent()}</Box>
      </Box>
    </Box>
  );

  return (
    <Box>
      <Box className={classes.headerTexts}>
        <Typography variant="h4">Customer Management</Typography>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => {
            setCustomer(null);
            triggerModalOpen(3);
          }}
        >
          Add Customer
        </Button>
      </Box>
      <Box style={{ border: "1px solid #D7DCE0" }} marginTop={"1rem"}>
        <Paper borderRadius={"0"} padding={"2rem"}>
          <TableHead
            title="Total Customers"
            searchItem={customerName}
            onChange={handleCustomerNameSearch}
            loading={loading}
            placeholder="Search By customer Name"
            totalSize={customers.totalSize}
            onClick={() => triggerModalOpen(1)}
            handleInputChange={handleInputChange}
          />

          <Box marginTop={"2rem"}>
            {loading && !customers.items ? (
              <p>Loading...</p>
            ) : (!loading && customers.items) ||
              (loading && customers.items) ? (
              <>
                {/* {console.log(users.items)} */}
                <Table
                  columns={columns}
                  data={customers.items}
                  loading={loading}
                />
                <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                  <Grid item xs={12} md={3}>
                    {customers.items.length > 0 && (
                      <Typography>
                        Showing page {customers.pageNumber} of{" "}
                        {Math.ceil(customers?.totalSize / pageSize)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Pagination
                      dataSize={customers?.totalSize}
                      perPage={pageSize}
                      handlePageClick={handlePageClick}
                      forcePage={customers.pageNumber}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <p>Loading...</p>
            )}
          </Box>
        </Paper>
      </Box>
      <>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={anchorOpen}
          onClick={handleMenuClose}
          PaperProps={{
            style: {
              width: "10ch",
            },
          }}
        >
          <MenuItem onClick={() => triggerModalOpen(2)}>View</MenuItem>
          <MenuItem onClick={() => triggerModalOpen(3)}>Edit</MenuItem>
          <MenuItem onClick={deleteCustomer}>Delete</MenuItem>
        </Menu>
      </>
      <Dialog handleClose={handleClose} open={open} content={dialogContent()} />
    </Box>
  );
};

export default CustomersLayout;

