import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
    },
    countItem: {
        borderRadius: "0.4rem",
         padding: "1.2rem 1rem 2.3rem",
         [theme.breakpoints.down('xs')]: {
            minHeight: '12rem'
        }
    }
}))

export { useStyles }