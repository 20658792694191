import React, { useState, useEffect, useContext } from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { useStyles } from "./style";
import loanContext from "../../../../../Contexts/loan/loanContext"
import Skeleton from '@material-ui/lab/Skeleton'
import Paper from "../../../../Paper";


const LoanCounts = () => {
  const classes = useStyles();
  const { getLoanCounts } = useContext(loanContext)

  const [loading, setLoading] = useState(false)
  const [loanCounts, setLoanCounts] = useState({})

  const { total, approved, declined } = loanCounts

  let isMounted = true
  useEffect(() => {
    getLoanCountsData()
    return (() => isMounted = false)
  },[])

  const getLoanCountsData = async () => {
    setLoading(true)
    try {
      const res = await getLoanCounts()
      if(isMounted){
        setLoanCounts(res.data.payload)
      setLoading(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={4} md={12}>
          <Paper padding={"0rem"} borderRadius={"0rem"}>
            <Box
              style={{ borderTop: "3px solid #5E4BCE" }}
              className={classes.countItem}
            >
              <Typography
                variant="body2"
                gutterBottom
                style={{ color: "#81909D" }}
              >
                {" "}
                {loading ? <Skeleton variant="text" width='100%' height={30} /> : "TOTAL LOAN REQUESTS"}
              </Typography>
              <Typography variant="h4" style={{ marginTop: "1rem" }}>
              {loading ? <Skeleton variant="text" width='50%' height={15} /> : total}
              </Typography>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={4} md={12}>
          <Paper padding={"0rem"} borderRadius={"0rem"}>
            <Box
              style={{ borderTop: "3px solid #1B9098" }}
              className={classes.countItem}
            >
              <Typography
                variant="body2"
                gutterBottom
                style={{ color: "#81909D" }}
              >
                {" "}
                {loading ? <Skeleton variant="text" width='100%' height={30} /> : "TOTAL DISBURSED"}
              </Typography>
              <Typography variant="h4" style={{ marginTop: "1rem" }}>
              {loading ? <Skeleton variant="text" width='50%' height={15} /> : approved}
              </Typography>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={4} md={12}>
          <Paper padding={"0rem"} borderRadius={"0rem"}>
            <Box
              style={{ borderTop: "3px solid #B38400" }}
              className={classes.countItem}
            >
              <Typography
                variant="body2"
                gutterBottom
                style={{ color: "#81909D" }}
              >
                {" "}
                {loading ? <Skeleton variant="text" width='100%' height={30} /> : "TOTAL DECLINED"}
              </Typography>
              <Typography variant="h4" style={{ marginTop: "1rem" }}>
              {loading ? <Skeleton variant="text" width='50%' height={15} /> : declined}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoanCounts;
