import React from "react";
import ForgotPasswordLayout from "../../Components/Layout/AdminLayouts/AuthLayout/ForgotPasswordLayout"
import AuthLayout from "../../Components/Layout/AdminLayouts/AuthLayout"

const AdminForgotPassword = () => {
  return (
        <>
            <AuthLayout>
                <ForgotPasswordLayout />
            </AuthLayout>

        </>
        
  );
};

export default AdminForgotPassword;
