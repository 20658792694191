import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  IconButton,
  FormControl,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CSVLink } from "react-csv";
import debounce from "lodash.debounce";

import Paper from "../../../Paper";
import Dialog from "../../../Reusables/Dialog";
import Button from "../../../Button";
import Table from "../../../Reusables/Table";
import Pagination from "../../../Reusables/Pagination";
import StatusIndicator from "../../../Reusables/StatusIndicator";
import TableHead from "../../../Reusables/TableHead";

import alertContext from "../../../../Contexts/alert/alertContext";
import userContext from "../../../../Contexts/users/userContext";

import { useStyles } from "./style";
import {
  capitalizeFirstLetter,
  formatDateTime,
  validateDates,
} from "../../../../Actions/helpers";

const ApplicantsTable = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState({});

  const { setAlert } = useContext(alertContext);
  const { getUsersData, resendConfrimationLink } = useContext(userContext);


  const initialState = {
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    status: "",
    startDate: "",
    endDate: "",
    pageSize: 10,
    pageNumber: 1,
  };

  const [payload, setPayload] = useState(initialState);
  const {
    email,
    firstName,
    lastName,
    phoneNumber,
    status,
    startDate,
    endDate,
    pageSize,
    pageNumber,
  } = payload;

  const _isMounted = useRef(true);

  useEffect(() => {
    if(_isMounted.current === false){
      _isMounted.current = true
    }
    getUsers();

    return () => _isMounted.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageNumber]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    ///always reset pageNumber if pageSize or Name changes to avoid wrong data fetch
    if (name === "pageSize" || name === "email") {
      payload.pageNumber = 1;
    }
    setPayload({ ...payload, [name]: value });
  };

  const handlePageClick = (page) => {
    setPayload({ ...payload, pageNumber: page });
  };

  const handleEmailSearch = (e) => {
    setPayload({ ...payload, email: e.target.value });
    loadWithDebounce(e.target.value);
  };

  let url = `User/activeusers/?pageSize=${pageSize}&page=${pageNumber}`;
  let filter = false;

  const clearFilters = async () => {
    //make the request without filters, taking into consideration the state of the email field
    setPayload({ ...initialState, email });
    filter = true;
    if (email !== "") {
      url += `&email=${email}`;
    }
    await getUsers(url, filter);
  };

  const getUsersFromFirstPage = () => {
    if (pageNumber !== 1) {
      setPayload({ ...payload, pageNumber: 1 });
    }
    pageNumber === 1 && getUsers();
  };

  const getUsers = async (
    url = `User/activeusers?pageSize=${pageSize}&page=${pageNumber}`,
    clearFilter
  ) => {
    if (!clearFilter) {
      if (email !== "") {
        url += `&email=${email}`;
      }
      if (!validateDates(startDate, endDate)) {
        setAlert("Please enter valid dates", "error");
        return;
      } else if (startDate !== "" && endDate !== "") {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }

      if (firstName !== "") {
        url += `&firstName=${firstName}`;
      }

      if (lastName !== "") {
        url += `&lastName=${lastName}`;
      }

      if (phoneNumber !== "") {
        url += `&phoneNumber=${phoneNumber}`;
      }

      if (status !== "") {
        url += `&status=${status}`;
      }
    }

    setLoading(true);
    try {
      const res = await getUsersData(url);
      if (_isMounted.current) {
        setUsers(res.data.responseData);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
    open && handleClose();
    filter = false;
  };


  const loadWithDebounce = useCallback(
    debounce((email) => {
      if (email === "") {
        getUsers(url);
      } else {
        getUsers(`${url}&email=${email}`);
      }
    }, 1000),
    []
  );


  const columns = [
    {
      Header: "First Name",
      accessor: "firstName",
      Cell: (props) => capitalizeFirstLetter(props.value),
    },
    {
      Header: "last Name",
      accessor: "lastName",
      Cell: (props) => capitalizeFirstLetter(props.value),
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone",
      accessor: "phoneNumber",
    },
    {
      Header: "Date Registered",
      accessor: "dateCreated",
      Cell: (props) => formatDateTime(props.value),
    },
    {
      Header: "Status",
      accessor: "emailConfirmed",
      Cell: (props) => {
        let status = props.value === "Active" ? true : false;
        return <StatusIndicator status={status} />;
      },
    },

    {
      Header: " ",
      Cell: (props) => {
        const [disabled, setDisabled] = useState(false);
        if (props.row.original.emailConfirmed === "Active") {
          return "";
        } else {
          return (
            <Button
              color="primary"
              variant="outlined"
              size="small"
              disabled={disabled}
              loading={disabled}
              onClick={async () => {
                try {
                  setDisabled(true);
                  const res = await resendConfrimationLink(
                    props.row.original.id
                  );
                  if (res.data.requestSuccessful) {
                    setAlert(
                      "Confirmation Message sent successfully",
                      "success"
                    );
                  } else {
                    setAlert(res.data.message, "error");
                  }
                  setDisabled(false);
                } catch (error) {
                  console.log(error);
                  setDisabled(false);
                }
              }}
            >
              Resend
            </Button>
          );
        }
      },
    },
  ];

  const dialogContent = () => (
    <Box style={{ width: "100%" }} className={classes.paper}>
      <Box className={classes.dialogTitle}>
        <Typography variant="h4">Filter</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className={classes.dialogContent}>
        <hr />
        <Grid container spacing={2} style={{ marginTop: "1rem" }}>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <Typography variant="body2" gutterBottom>
                Start Date
              </Typography>
              <TextField
                name="startDate"
                variant="outlined"
                size="small"
                type="date"
                value={startDate}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <Typography variant="body2" gutterBottom>
                End Date
              </Typography>
              <TextField
                name="endDate"
                variant="outlined"
                size="small"
                type="date"
                value={endDate}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <Typography variant="body2" gutterBottom>
                First Name
              </Typography>
              <TextField
                name="firstName"
                variant="outlined"
                size="small"
                value={firstName}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <Typography variant="body2" gutterBottom>
                Last Name
              </Typography>
              <TextField
                name="lastName"
                variant="outlined"
                size="small"
                value={lastName}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <Typography variant="body2" gutterBottom>
                Phone Number
              </Typography>
              <TextField
                name="phoneNumber"
                type="number"
                className={classes.numberInput}
                variant="outlined"
                size="small"
                value={phoneNumber}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* <FormControl className={classes.formControl}>
              <Typography variant="body2" gutterBottom>
                Status
              </Typography>
              <Select
                id="status"
                name="status"
                defaultValue=""
                value={status}
                variant="outlined"
                style={{ height: "2.7rem" }}
                onChange={handleInputChange}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl> */}
          </Grid>
        </Grid>
        <Box className={classes.filterActions}>
          <Typography
            style={{ color: "red", cursor: "pointer" }}
            onClick={clearFilters}
          >
            Clear all filters
          </Typography>
          <Button
            variant="contained"
            size="medium"
            disabled={
              loading ||
              (lastName === "" &&
                firstName === "" &&
                phoneNumber === "" &&
                status === "" &&
                startDate === "" &&
                endDate === "")
            }
            loading={loading}
            // type="submit"
            color="primary"
            onClick={() => getUsersFromFirstPage()}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Box>
  );

  let headers = [];
  columns.map((column) => {
    let newColumn = {};
    newColumn.label = column.Header;
    newColumn.key = column.accessor;
    if (column.Header !== " ") {
      headers = [...headers, newColumn];
    }
    return headers;
  });

  const exportData = users?.items?.length > 0 ? users.items : [];
  exportData.length > 0 &&
    exportData.map((item) => {
      if (item.emailConfirmed === true) {
        item.emailConfirmed = "Active";
      } else if (item.emailConfirmed === false) {
        item.emailConfirmed = "Inactive";
      }
      // item.dateCreated = formatDateTime(item.dateCreated)
    });

  return (
    <Box>
      <Box className={classes.headerTexts}>
        <Typography variant="h4">Registered Users</Typography>
        <Button
          variant="outlined"
          size="medium"
          color="primary"
          disabled={!Array.isArray(users?.items) || !users?.items.length}
        >
          {Array.isArray(users?.items) && users?.items?.length > 0 ? (
            <CSVLink
              filename={"users.csv"}
              data={exportData}
              headers={headers}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              Export
            </CSVLink>
          ) : (
            "Export"
          )}
        </Button>
      </Box>
      <Box style={{ border: "1px solid #D7DCE0" }} marginTop={"1rem"}>
        <Paper borderRadius={"0"} padding={"2rem"}>
          <TableHead
            title="Total Users"
            searchItem={email}
            onChange={handleEmailSearch}
            loading={loading}
            placeholder="Search By Email"
            totalSize={users?.totalSize}
            onClick={setOpen}
            handleInputChange={handleInputChange}
          />
          <Box marginTop={"2rem"}>
            {loading && !users.items ? (
              <p>Loading...</p>
            ) : (!loading && users.items) || (loading && users.items) ? (
              <>
                <Table columns={columns} data={users.items} loading={loading} />
                <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                  <Grid item xs={12} md={3}>
                    {users.items.length > 0 && (
                      <Typography>
                        Showing page {users.pageNumber} of{" "}
                        {Math.ceil(users?.totalSize / pageSize)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Pagination
                      dataSize={users?.totalSize}
                      perPage={pageSize}
                      handlePageClick={handlePageClick}
                      forcePage={users.pageNumber}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <p>Loading...</p>
            )}
          </Box>
        </Paper>
      </Box>
      <Dialog handleClose={handleClose} open={open} content={dialogContent()} />
    </Box>
  );
};

export default ApplicantsTable;
