import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
     formControl: {
        minWidth : `100%`,
        marginBottom: '.8rem'
    },
    loginCTA: {
        margin: "0 auto",
        color: theme.palette.primary.main,
        cursor: "pointer",
        textDecoration: "none"
    },
    label: {
        marginBottom: "0.35em",
        fontSize: "1rem",
        fontWeight: "400",
        lineHeight: 1.5
      },
      numberInput: {
        '& ::-webkit-outer-spin-button, & ::-webkit-inner-spin-button' : {
            '-webkit-appearance': 'none',
            'margin': 0
        },
        '& ::-webkit-inner-spin-button' : {
            '-webkit-appearance': 'none',
            'margin': 0
        },
        '& [type=number]' : {
            '-moz-appearance': 'textfield'
        },
    },
}));

export { useStyles }

