import React, {useContext, useState, useEffect} from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { Typography, Box, Button } from '@material-ui/core'
import { SelectedEntityContext } from '../../../../../App'
import api from '../../../../../Services/axiosAdmin';
import Modal from '../../../../Modal'

const OrganizationView = props => {
    const [ selectedEntity, setSelectedEntity ] = useContext(SelectedEntityContext)
    console.log('selectedEntity', selectedEntity);

    const [providers, setProviders] = useState()

    // Get each providers under the organization
    const getProviders = async () => {
        try {
            const res = await api.get(`/Organization/${selectedEntity.id}`)
            const provRes = res.data.responseData
            console.log(provRes);
            if(res.data.requestSuccessful === true) {
                setProviders(provRes.providers)
                setSelectedEntity(provRes)
            }
        } catch (error) {

        }
    }


    // Modal controls 
    const [openModal, setOpenModal] = useState(false);

    const handleOpen = (entity) => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };


    useEffect(() => {
        getProviders()
        //eslint-disable-next-line
    }, [])

    return (
        <div>
            <Typography variant="h6" align="center" gutterBottom>
                {selectedEntity.orgName}
            </Typography>
            <Box textAlign='left'>
                <Typography variant="body1" gutterBottom>
                    Code: <strong>{ selectedEntity.orgCode }</strong>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Hash Key: <strong>{ selectedEntity.hashKey }</strong>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Url: <strong>{ selectedEntity.redirectUrl ? selectedEntity.redirectUrl : 'not set' }</strong>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Currency: <strong>{ selectedEntity.currency }</strong>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Initial: <strong>{ selectedEntity.initial ? selectedEntity.initial : 'not set' }</strong>
                </Typography>

                { !providers ? (
                    <Skeleton variant="text"height={100} />
                ) : providers.length === 0 ? (
                    <div> </div>
                ) : (
                    <Box marginTop='1.5rem'>
                    <Typography variant="body1" gutterBottom>
                        <strong>Providers</strong>
                    </Typography>
                    { providers.map((provider, key) => (
                        <Typography key={key} variant="body1" gutterBottom>
                            {provider.bankName}
                        </Typography>
                    ))}
                    </Box>
                )}


                {providers && (

                    <Button color='secondary' variant='contained' onClick={handleOpen}>
                        Edit
                    </Button>
                )}
            </Box>
            
            {/* Load Custom Modal COmponent */}
            <Modal handleClose={handleClose} openModal={openModal} view='organization' />

        </div>
    )
}

OrganizationView.propTypes = {

}

export default OrganizationView
