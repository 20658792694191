import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Box,
  FormControl,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";

import FormErrors from "../../../../FormErrors";
import Button from "../../../../Button";
import Paper from "../../../../Paper";
import PartnerConfigLayout from "../PartnerConfigLayout";

import { useStyles } from "./style";
import LoanContext from "../../../../../Contexts/loan/loanContext";
import AlertContext from "../../../../../Contexts/alert/alertContext";
import {
  nameValidation,
  phoneNumberValidation,
  emailValidation,
} from "../../../../../Actions/formActions";

const CreatePartnerLayout = (props) => {
  const classes = useStyles();

  const [submitting, setIssubmitting] = useState(false);

  const { addOrUpdatePartner, providers, getProviders } =
    useContext(LoanContext);
  const { setAlert } = useContext(AlertContext);
  const partner = props.location?.state?.data && props.location?.state?.data;

  useEffect(() => {
    !providers.length && getProviders();
  }, []);

  const getPartnerProviders = () => {
    let orgProviders = [];
    partner.organizationProviders.map(
      (provider) => (orgProviders = [...orgProviders, provider.providerId])
    );
    return orgProviders;
  };

  const initialValues = {
    orgName: partner ? partner.orgName : "",
    orgCode: partner ? partner.orgCode : "",
    providers: partner ? getPartnerProviders() : [],
    baseUrl: partner ? partner.baseUrl : "",
    redirectUrl: partner ? partner.redirectUrl : "",
    hashKey: partner ? partner.hashKey : "",
    apiKey: partner ? partner.apiKey : "",
    initial: partner ? partner.initial : "",
    currency: partner ? partner.currency : "",
    minLoanAmount: partner ? partner.minLoanAmount : 0,
    contactPersonName: partner ? partner.contactPersonName : "",
    email: partner ? partner.email : "",
    phoneNumber: partner ? partner.phoneNumber : "",
    Logo: undefined,
    apiKey: partner ? partner.apiKey : "",
    hashRequired: partner ? partner.hashRequired : false,
    status: partner ? partner.status : false,
  };

  const validationSchema = Yup.object({
    orgName: nameValidation("Partner's Name", "orgName", true),
    orgCode: Yup.string().required("Partner's Code is Required"),
    // providers: Yup.array()
    //   .required("Select Providers")
    //   .min(1, "Select at least one provider"),
    baseUrl: Yup.string()
      .required("Base Url is required")
      .url("Invalid url format e.g (https://www.abc.com)"),
    redirectUrl: Yup.string()
      .required("Redirect Url is Required")
      .url("Invalid url format e.g (https://www.abc.com)"),
    // hashKey: Yup.string().required("Hash Key is Required"),
    initial: Yup.string().required("Partner's Inital is Required"),
    currency: Yup.string().required("select a currency"),
    minLoanAmount: Yup.string().required("Minimum Loan Amount is Required"),
    contactPersonName: nameValidation(
      "Contact Person Name",
      "contactName",
      true
    ),
    email: emailValidation(),
    phoneNumber: phoneNumberValidation("Phone Number", "phoneNumber"),
    Logo: !partner && Yup.mixed().required("Upload Partner Logo"),
  });

  const onSubmit = async (values) => {
    let newValues = {};
    let newOrgProviders = [];
    values.providers.map(
      (provider) =>
        (newOrgProviders = [
          ...newOrgProviders,
          {
            id: provider,
            sortCode: providers.find((p) => p.id === provider).sortCode,
          },
        ])
    );
    Object.assign(newValues, values);
    newValues.providers = newOrgProviders;
    newValues.hashRequired = newValues.hashKey !== "" ? true : false;
    // values.hashRequired = values.hashKey !== "" ? true : false

    try {
      setIssubmitting(true);
      const res = await addOrUpdatePartner(newValues, partner?.id);
      console.log(partner?.id);
      if (res.data.requestSuccessful) {
        let action = partner ? "Updated" : "Created";
        setAlert(`Partner ${action} successfully`, "success");
        props.history.push("/partners");
      } else {
        setAlert(res.data.message, "error");
      }
      setIssubmitting(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Box>
        <Typography variant="h4" gutterBottom>
          {partner ? "Update" : "Add"} Partner
        </Typography>
        <Paper borderRadius="0" padding="1.5rem 2rem 2rem">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ errors, touched, getFieldProps, setFieldValue, values }) => (
              <Form noValidate autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="orgName" className={classes.label}>
                        Partner Name
                      </label>
                      <TextField
                        name="orgName"
                        placeholder="Schoolable"
                        id="orgName"
                        variant="outlined"
                        {...getFieldProps("orgName")}
                        error={errors.orgName && touched.orgName ? true : false}
                        size="small"
                      />
                      <ErrorMessage name="orgName" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="orgCode" className={classes.label}>
                        Partner's Code
                      </label>
                      <TextField
                        name="orgCode"
                        placeholder="SBE"
                        id="orgCode"
                        variant="outlined"
                        {...getFieldProps("orgCode")}
                        error={errors.orgCode && touched.orgCode ? true : false}
                        size="small"
                      />
                      <ErrorMessage name="orgCode" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      size="small"
                    >
                      <label htmlFor="providers" className={classes.label}>
                        Select Providers (Multiple)
                      </label>
                      <Select
                        multiple
                        displayEmpty
                        error={
                          errors.providers && touched.providers ? true : false
                        }
                        {...getFieldProps("providers")}
                        MenuProps={{
                          getContentAnchorEl: () => null,
                        }}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return "Select";
                          }
                          let newSelected = [];
                          selected.map((item) => {
                            let selectedProvider = providers.find(
                              (provider) => item === provider.id
                            );
                            if (selectedProvider) {
                              newSelected = [
                                ...newSelected,
                                selectedProvider.bankName,
                              ];
                            }
                          });
                          return newSelected.join(", ");
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem disabled value="">
                          Select
                        </MenuItem>
                        {providers?.map(({ id, bankName, sortCode }) => (
                          <MenuItem key={id} value={id}>
                            {bankName}
                          </MenuItem>
                        ))}
                      </Select>
                      <ErrorMessage name="providers" component={FormErrors} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="baseUrl" className={classes.label}>
                        Base Url
                      </label>
                      <TextField
                        name="baseUrl"
                        placeholder="https://www.schoolabe.com"
                        id="baseUrl"
                        variant="outlined"
                        {...getFieldProps("baseUrl")}
                        error={errors.baseUrl && touched.baseUrl ? true : false}
                        size="small"
                      />
                      <ErrorMessage name="baseUrl" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="redirectUrl" className={classes.label}>
                        Redirect Url
                      </label>
                      <TextField
                        name="redirectUrl"
                        placeholder="https://www.schoolabe.com"
                        id="redirectUrl"
                        variant="outlined"
                        {...getFieldProps("redirectUrl")}
                        error={
                          errors.redirectUrl && touched.redirectUrl
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage name="redirectUrl" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="hashKey" className={classes.label}>
                        Hash Key
                      </label>
                      <TextField
                        name="hashKey"
                        placeholder="kwdf245"
                        id="hashKey"
                        variant="outlined"
                        {...getFieldProps("hashKey")}
                        error={errors.hashKey && touched.hashKey ? true : false}
                        size="small"
                      />
                      <ErrorMessage name="hashKey" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="apiKey" className={classes.label}>
                        Api Key
                      </label>
                      <TextField
                        name="apiKey"
                        placeholder="792a0aae8c9114be4a3c1f22cd3f5597c"
                        id="apiKey"
                        variant="outlined"
                        {...getFieldProps("apiKey")}
                        size="small"
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="initial" className={classes.label}>
                        Initial
                      </label>
                      <TextField
                        name="initial"
                        placeholder="S.B"
                        id="initial"
                        variant="outlined"
                        {...getFieldProps("initial")}
                        error={errors.initial && touched.initial ? true : false}
                        size="small"
                      />
                      <ErrorMessage name="initial" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl
                      className={classes.formControl}
                      size="small"
                      variant="outlined"
                    >
                      <label htmlFor="currency" className={classes.label}>
                        Currency
                      </label>
                      <Select
                        displayEmpty
                        id="currency"
                        name="currency"
                        error={errors.inital && touched.inital ? true : false}
                        {...getFieldProps("currency")}
                        inputProps={{ "aria-label": "Without label" }}
                        variant="outlined"
                      >
                        <MenuItem disabled value="">
                          Select
                        </MenuItem>
                        <MenuItem value="NGN">NGN</MenuItem>
                        <MenuItem value="USD">USD</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="minLoanAmount" className={classes.label}>
                        Minimum Loan Amount
                      </label>
                      <TextField
                        name="minLoanAMount"
                        placeholder="100000"
                        id="minLoanAmount"
                        variant="outlined"
                        {...getFieldProps("minLoanAmount")}
                        error={
                          errors.minLoanAmount && touched.minLoanAmount
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage
                        name="minLoanAmount"
                        component={FormErrors}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label
                        htmlFor="contactPersonName"
                        className={classes.label}
                      >
                        Contact Person's Name
                      </label>
                      <TextField
                        name="contactPersonName"
                        placeholder="Mr. Ajayi"
                        id="contactPersonName"
                        variant="outlined"
                        {...getFieldProps("contactPersonName")}
                        error={
                          errors.contactPersonName && touched.contactPersonName
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage
                        name="contactPersonName"
                        component={FormErrors}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="email" className={classes.label}>
                        Email
                      </label>
                      <TextField
                        name="email"
                        placeholder="ajayi@schoolable.com"
                        id="email"
                        variant="outlined"
                        {...getFieldProps("email")}
                        error={errors.email && touched.email ? true : false}
                        size="small"
                      />
                      <ErrorMessage name="email" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="minLoanAmount" className={classes.label}>
                        Phone Number
                      </label>
                      <TextField
                        name="phoneNumber"
                        placeholder="08039483938"
                        className={classes.numberInput}
                        id="phoneNumber"
                        variant="outlined"
                        {...getFieldProps("phoneNumber")}
                        error={
                          errors.phoneNumber && touched.phoneNumber
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage name="phoneNumber" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl className={classes.formControl}>
                      <Typography className={classes.label}>
                        Toggle to display on the website
                      </Typography>
                      <Box className={classes.enableCTA}>
                        <label htmlFor="status" className={classes.enable}>
                          Enable
                        </label>
                        <FormControlLabel
                          control={
                            <Field
                              component={Switch}
                              id="status"
                              type="checkbox"
                              checked={values.status}
                              name="status"
                              {...getFieldProps("status")}
                              color="primary"
                            />
                          }
                        />
                      </Box>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl
                      component="fieldset"
                      style={{ width: "100%" }}
                      className={classes.formControl}
                    >
                      <label htmlFor="Logo" className={classes.label}>
                        Partner Logo
                      </label>
                      <DropzoneArea
                        acceptedFiles={["image/*"]}
                        dropzoneText={
                          <Box>
                            <Typography display="inline" variant="body2">
                              Drag and drop or
                            </Typography>
                            <Typography
                              display="inline"
                              color="primary"
                              variant="body2"
                            >
                              Browse
                            </Typography>
                          </Box>
                        }
                        maxFileSize={800000}
                        showPreviewsInDropzone
                        showFileNames
                        showFileNamesInPreview
                        getDropRejectMessage={() =>
                          "File should be the required format and not more than 800KB"
                        }
                        filesLimit={1}
                        onChange={(file) => setFieldValue("Logo", file[0])}
                      />
                      <div className={classes.uploadInfo}>
                        <small style={{ fontSize: "10px", fontWeight: 300 }}>
                          Files Supported: JPEG, PNG, SVG
                        </small>
                        <small style={{ fontSize: "10px", fontWeight: 300 }}>
                          Max File Size: 800KB
                        </small>
                      </div>
                      <ErrorMessage name="Logo" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={12} sm={6} md={4} >
                  <FormControl
                    className={classes.formControl}
                    style={{ marginTop: "1.2rem" }}
                  >
                    <Typography className={classes.label}>
                      Toggle if Hash is Required
                    </Typography>
                    <Box className={classes.enableCTA}>
                      <label htmlFor="hashRequired" className={classes.enable}>
                        Enable
                      </label>
                      <FormControlLabel
                        control={
                          <Field
                            component={Switch}
                            id="hashRequired"
                            type="checkbox"
                            disabled
                            checked={values.hashKey !== ""}
                            name="hashRequired"
                            {...getFieldProps("hashRequierd")}
                            color="primary"
                          />
                        }
                      />
                    </Box>
                  </FormControl>
                </Grid> */}
                </Grid>

                <Box className={classes.createActions}>
                  <Link
                    to="/partners"
                    style={{ cursor: "pointer", textDecoration: "none" }}
                  >
                    <Typography variant="body2" color="primary">
                      Cancel
                    </Typography>
                  </Link>

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                    loading={submitting}
                    size="medium"
                  >
                    {partner ? "Update" : "Add"}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Paper>
      </Box>

      {partner && (
        <Paper
          borderRadius="0"
          style={{
            marginTop: "4rem",
            border: "1px dotted #000000",
            padding: "2rem 0",
          }}
        >
          <PartnerConfigLayout partner={partner} />
        </Paper>
      )}
    </>
  );
};

export default CreatePartnerLayout;
