import Axios from "axios";
import { EnvVariables } from "../env/Env";

// separating urls for environments
// let urls = {
//     test: `http://localhost:3334`,
//     development: 'http://localhost:3333/',
//     production: 'https://your-production-url.com/'
// }

//const { REACT_APP_API_URL } = process.env
const { API_URL } = EnvVariables;

const api = Axios.create({
  baseURL: API_URL,
  // baseURL: urls[process.env.NODE_ENV], // selecting environment
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default api;
