import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  IconButton,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import TuneIcon from "@material-ui/icons/Tune";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import LoadingOverlay from "react-loading-overlay";

import Paper from "../../../Paper";
import Dialog from "../../../Reusables/Dialog";
import Button from "../../../Button";
import Table from "../../../Reusables/Table";
import Pagination from "../../../Reusables/Pagination";

import alertContext from "../../../../Contexts/alert/alertContext";
import loanContext from "../../../../Contexts/loan/loanContext";

import { useStyles } from "./style";
import { formatDataId, formatDateTime, validateDates } from "../../../../Actions/helpers";
import { CSVLink } from "react-csv";

const AuditLayout = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [dialogContentIndex, setDialogContentIndex] = useState(null);

  const [loading, setLoading] = useState(false);
  const [auditLogs, setAuditLogs] = useState({});
  const [auditLog, setAuditLog] = useState({});

  const { setAlert } = useContext(alertContext);
  const { makeFilterRequest } = useContext(loanContext);

  const rowsPerPageOptions = [10, 20, 50, 100, 200, 500];

  const initialState = {
    startDate: "",
    endDate: "",
    userId: "",
    action: "",
    pageSize: 10,
    pageNumber: 1,
  };

  const [payload, setPayload] = useState(initialState);
  const { startDate, endDate, userId, action, pageSize, pageNumber } = payload;

  let isMounted = true;
  useEffect(() => {
    getAuditLogs();

    return () => (isMounted = false);
  }, [pageSize, pageNumber]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    //always reset pageNumber if pageSize changes to avoid wrong data fetch
    if (name === "pageSize") {
      payload.pageNumber = 1;
    }
    setPayload({ ...payload, [name]: value });
  };

  const handlePageClick = (page) => {
    setPayload({ ...payload, pageNumber: page });
  };

  let url = `AuditLog?pageSize=${pageSize}&page=${pageNumber}`;
  let filter = false;

  const clearFilters = async () => {
    //make the request without filters, taking into consideration the state of the name field
    setPayload({ ...initialState });
    filter = true;

    await getAuditLogs(url, filter);
  };

  const getLogsFromFirstpage = () => {
    if (pageNumber !== 1) {
      setPayload({ ...payload, pageNumber: 1 });
    }
    pageNumber === 1 && getAuditLogs();
  };

  const getAuditLogs = async (
    url = `AuditLog?pageSize=${pageSize}&page=${pageNumber}`,
    clearFilter
  ) => {
    if (!clearFilter) {
      if (!validateDates(startDate, endDate)) {
        setAlert("Please enter valid dates", "error");
        return;
      } else if (startDate !== "" && endDate !== "") {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }
      if (action !== "") {
        url += `&action=${action}`;
      }

      if (userId !== "") {
        url += `&userId=${userId}`;
      }
    }

    setLoading(true);
    try {
      const res = await makeFilterRequest(url);
      if (isMounted) {
        setAuditLogs(res.data.responseData);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
    open && handleClose();
    filter = false;
  };

  const getDataID = (arr, data) => {
    console.log('array', arr, 'data', data, 'index', arr.indexOf(data))
    return (arr.indexOf(data) + 1).toString().padStart(6, '0')
  }

  const columns = React.useMemo(() => {return([
    // {
    //   Header: "ID Number",
    //   // accessor: "index",
    //   Cell: (props) => getDataID(auditLogs.items, props.row.original)
    // },
    {
      Header: "Module",
      accessor: "module",
    },
    {
      Header: "Action",
      accessor: "action",
    },
    {
      Header: "Date Created",
      accessor: "createdDate",
      Cell: (props) => formatDateTime(props.value),
    },
    {
      Header: "Created By",
      accessor: "createdBy",
      // Cell: (props) => formatDataId(props.value),
    },
    {
        id: 'details',
        Header: () => null,
        Cell: (props) => <Typography color="primary" style={{ cursor: 'pointer' }} onClick={() => {
          setAuditLog(props.row.original)
          triggerModalOpen(2)
        }}>View Details</Typography>
    },
  ])}, [auditLogs]);

  const getDialogContent = () => {
    switch (dialogContentIndex) {
      case 1:
        return filterForm();

      case 2:
        return viewAudit();

      default:
        return "Nothing";
    }
  };

  const triggerModalOpen = (index) => {
    setDialogContentIndex(index);
    setOpen(true);
  };

  const filterForm = () => (
    <>
      <Grid container spacing={2} style={{ marginTop: "1rem" }}>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <Typography variant="body2" gutterBottom>
                Start Date
              </Typography>
              <TextField
                name="startDate"
                variant="outlined"
                size="small"
                type="date"
                value={startDate}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <Typography variant="body2" gutterBottom>
                End Date
              </Typography>
              <TextField
                name="endDate"
                variant="outlined"
                size="small"
                type="date"
                value={endDate}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>

        

          <Grid item xs={12} md={6}>
            <FormControl
              size="small"
              variant="outlined"
              className={classes.formControl}
            >
              <Typography variant="body2" gutterBottom>
                Action
              </Typography>
              <Select
                displayEmpty
                id="action"
                name="action"
                value={action}
                onChange={handleInputChange}
              >
                <MenuItem disabled value="">
                  Select
                </MenuItem>
                <MenuItem value={"create"}>Create</MenuItem>
                <MenuItem value={"edit"}>Edit</MenuItem>
                <MenuItem value={"delete"}>Delete</MenuItem>
                {/* <MenuItem value={"delete"}>
              Delete
              </MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl} style={{visibility: "hidden"}}>
              <Typography variant="body2" gutterBottom>
                User Id
              </Typography>
              <TextField
                name="userId"
                variant="outlined"
                size="small"
                value={userId}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Box className={classes.filterActions} marginTop={"2rem"}>
          <Typography
            style={{ color: "red", cursor: "pointer" }}
            onClick={clearFilters}
          >
            Clear all filters
          </Typography>
          <Button
            variant="contained"
            size="medium"
            disabled={
              loading ||
              (startDate === "" &&
                endDate === "" &&
                action === "" &&
                userId === "")
            }
            loading={loading}
            // type="submit"
            color="primary"
            onClick={() => getLogsFromFirstpage()}
          >
            Apply
          </Button>
        </Box>
    </>
  );

  const viewAudit = () => {
    if (auditLog !== null) {
      const {
        createdBy,
        newValue,
        oldValue,
        module,
        action,
        ipAddress,
        createdDate,
      } = auditLog;

      return (
        <Grid container spacing={2} style={{ marginTop: "1rem" }}>
          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom color="textSecondary">Module</Typography>
            <Typography style={{wordBreak: 'break-all'}}>{module}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom color="textSecondary">Date Created</Typography>
            <Typography style={{wordBreak: 'break-all'}}>{formatDateTime(createdDate)}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom color="textSecondary">Action</Typography>
            <Typography style={{wordBreak: 'break-all'}}>{action}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom color="textSecondary">IP Address</Typography>
            <Typography style={{wordBreak: 'break-all'}}>{formatDataId(ipAddress)}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom color="textSecondary">Details Of Transaction</Typography>
            <Typography style={{wordBreak: 'break-all'}}>{newValue}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom color="textSecondary">User Email</Typography>
            <Typography style={{wordBreak: 'break-all'}}>{createdBy}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Typography gutterBottom color="textSecondary">Old Value</Typography>
            <Typography style={{wordBreak: 'break-all'}}>{oldValue}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography gutterBottom color="textSecondary">New Value</Typography>
            <Typography style={{wordBreak: 'break-all'}}>{newValue}</Typography>
          </Grid>
        </Grid>
      );
    }
  };

  const dialogContent = () => (
    <Box style={{ width: "100%" }} className={classes.paper}>
      <Box className={classes.dialogTitle}>
        <Typography variant="h4">Filter</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className={classes.dialogContent}>
        <hr />
        <Box marginTop={"1rem"}>{getDialogContent()}</Box>
      </Box>
    </Box>
  );

  return (
    <Box>
      <Box className={classes.headerTexts}>
        <Typography variant="h4">Audit Logs</Typography>
        <Button
          variant="outlined"
          disabled={
            !Array.isArray(auditLogs?.items) || !auditLogs?.items?.length
          }
          size="medium"
          color="primary"
        >
          {Array.isArray(auditLogs?.items) && auditLogs?.items?.length > 0 ? (
            <CSVLink
              filename={"auditLogs.csv"}
              data={auditLogs?.items}
              // headers={headers}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              Export
            </CSVLink>
          ) : (
            "Export"
          )}
        </Button>
      </Box>
      <Box style={{ border: "1px solid #D7DCE0" }} marginTop={"1rem"}>
        <Paper borderRadius={"0"} padding={"2rem"}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} style={{alignSelf: "center"}}>
              <Typography display="inline" gutterBottom variant="h5">
                Recent Activities
              </Typography>{" "}
              &nbsp;
              <Typography display="inline" style={{ color: "#81909D" }}>
                {auditLogs?.totalSize}
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box className={classes.tableCTAs}>
                {/* No search yet. Make visible when we can search */}
                <TextField
                  placeholder="Search By User"
                  name="user"
                  variant="outlined"
                  size="small"
                  style={{ width: "100%", visibility: "hidden" }}
                  InputProps={{
                    endAdornment: <SearchIcon />,
                  }}
                />
                <Box
                  className={classes.filterTrigger}
                  onClick={() => triggerModalOpen(1)}
                >
                  <Typography>Filter By</Typography>
                  <TuneIcon />
                </Box>
                <Box className={classes.numberToShow}>
                  <Typography>Showing:</Typography>
                  <FormControl variant="outlined" size="small">
                    <Select
                      name="pageSize"
                      defaultValue="10"
                      className={classes.select}
                      onChange={handleInputChange}
                    >
                      {rowsPerPageOptions.map((val, key) => (
                        <MenuItem key={key} value={val}>
                          {val} Entries
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box marginTop={"2rem"}>
            {loading && !auditLogs?.items ? (
              <p>Loading...</p>
            ) : (!loading && auditLogs.items) ||
              (loading && auditLogs.items) ? (
                <LoadingOverlay
                active={loading}
                spinner={<CircularProgress color="primary" /> }
               >
                 <Table
                   columns={columns}
                   data={auditLogs.items}
                   loading={loading}
                   showId
                   size="large"
                 />
                 <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                   <Grid item xs={12} md={3}>
                     {auditLogs?.items.length > 0 && (
                       <Typography>
                         Showing page {pageNumber} of{" "}
                         {Math.ceil(auditLogs?.totalSize / pageSize)}
                       </Typography>
                     )}
                   </Grid>
                   <Grid item xs={12} md={9}>
                     <Pagination
                       dataSize={auditLogs?.totalSize}
                       perPage={pageSize}
                       handlePageClick={handlePageClick}
                       forcePage={auditLogs.pageNumber}
                     />
                   </Grid>
                 </Grid>
               </LoadingOverlay>
            ) : (
              <p>Loading...</p>
            )}
          </Box>
        </Paper>
      </Box>
      <Dialog handleClose={handleClose} open={open} content={dialogContent()} />
    </Box>
  );
};

export default AuditLayout;
