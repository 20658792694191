import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        margin: "2rem auto 5rem",
    
    },
    header: {
        display: "flex",
        alignItems: "center",
        gap: ".5rem",
        marginBottom:"1rem"
      },
      goBack: {
        cursor: "pointer",
        marginLeft: "-.3rem",
      },
    fieldWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: "1.5rem",
        '& > * ' : {
            minWidth: '100%',
            margin: theme.spacing(1,1.9,2,1.9),

            [theme.breakpoints.down('lg')]: {
                margin: theme.spacing(1,1.5,2,1.5),
            },

            [theme.breakpoints.down('md')]: {
                margin: theme.spacing(1.2,1,2,1.2),
            },

            [theme.breakpoints.up('sm')]: {
                minWidth: '30%',
            },
            [theme.breakpoints.down('xs')]: {
                margin: theme.spacing(1,0,2,0),
            },

        }
    },
    grouping: {
        flexDirection: 'row'
    },
    numberInput: {
        '& ::-webkit-outer-spin-button, & ::-webkit-inner-spin-button' : {
            '-webkit-appearance': 'none',
            'margin': 0
        },
        '& ::-webkit-inner-spin-button' : {
            '-webkit-appearance': 'none',
            'margin': 0
        },
        '& [type=number]' : {
            '-moz-appearance': 'textfield'
        },
    },
    label: {
        marginBottom: "0.35em",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "16px",
      },
    actionButtons:{
        // border: '1px solid green',
        width: '50%',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            // margin: theme.spacing(0,2,2,0),
        }
    },
    formControl: {
        width: "100%"
    },
    loanDocuments: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridRowGap: "2rem",
        gridColumnGap: "2rem",
        [theme.breakpoints.down("xs")]: {
          gridTemplateColumns: "repeat(1, 1fr)",
        },
    },

    loanDoc: {
        height: "4.2rem",
        border: "1px solid #F08829",
        backgroundColor: "#FDF2E7",
        borderRadius: "3px",
        width: "100%",
        padding: "0.2rem",
        color: "#000000"
    },
    uploadInfo: {
        display: "flex",
        justifyContent: "space-between",
      },
      section: {
       margin: "3rem 0 1rem"
      }
}));

export { useStyles }