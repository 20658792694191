import React, {useContext } from "react";
import {
  Typography,
  Box,
  FormControl,
  Grid,
  TextField,
} from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import { Formik, Form, FieldArray } from "formik";
import Button from "../../../../Button";
import StatusIndicator from "../../../../Reusables/StatusIndicator";

import { useStyles } from "./style";

import LoanContext from "../../../../../Contexts/loan/loanContext";
import AlertContext from "../../../../../Contexts/alert/alertContext";

const ProviderSettingsLayout = ({ providerId, pSetting }) => {
  const classes = useStyles();

  const { createProviderSetting } = useContext(LoanContext);
  const { setAlert } = useContext(AlertContext);

  //formating values (pSetting) passed down as a prop to conform with initial values format
  let newSettings = [];
  pSetting &&
    pSetting.settings.map((setting) => {
      let newSetting = {};
      for (const [key, value] of Object.entries(setting)) {
        newSetting.title = key;
        newSetting.values = value.map((val) => {
          let newValue = {};

          newValue = { value: val };
          return newValue;
        });
      }

      newSettings = [...newSettings, newSetting];
    });

  const edit = pSetting ? true : false;

  //This is either an empty array or filled, depending on the above function
  const initialValues = {
    settings: newSettings,
  };

  //validating form fields before API integration
  const onSubmit = async (values) => {
    let passed = true;
    values.settings.map((setting) => {
      if (setting.title === "") {
        setAlert("please fill all fields", "error");
        passed = false;
      }
      setting.values.map((val) => {
        if (val === "") {
          setAlert("please fill all fields", "error");
          passed = false;
        }
      });
    });

    if (passed) {
      // console.log(JSON.stringify(values, null, 1));
      //if validation passes, append form fields as formData(required by the API)
      let data = new FormData();
      for (let i = 0; i < values.settings.length; i++) {
        let value = values.settings[i];
        let field = "";
        let finalField = "";
        for (let j = 0; j < value.values.length; j++) {
          let fieldValue = value.values[j].value;

          if (j + 1 !== value.values.length) {
            finalField += `${fieldValue}::`;
          } else {
            finalField += fieldValue;
          }
        }
        data.append(value.title, finalField);
      }

      //adding an extra arg(edit) to decide
      await createProviderSetting(data, providerId, edit);
    }
  };

  return (
    <Box className={classes.root} marginTop={4}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, getFieldProps, isSubmitting }) => (
          <Form noValidate autoComplete="off">
            {/* OTHER FIELDS */}
            <FieldArray
              name="settings"
              render={(arrayHelpers) => (
                <Box marginTop={1}>
                  <Grid spacing={2} container>
                    {values.settings.map((setting, index) => (
                      <Box
                        marginBottom={4}
                        key={index}
                        style={{ width: "100%" }}
                      >
                        <Grid item container xs={12}>
                          <Grid item xs={12} sm={4}>
                            <FormControl className={classes.formControl}>
                              <Box className={classes.detailAction}>
                                <Typography className={classes.label}>
                                  Category Title
                                </Typography>
                                <Typography
                                  display="inline"
                                  variant="body2"
                                  color="primary"
                                  style={{
                                    fontSize: "12px",
                                    marginLeft: "1rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <Close
                                    style={{
                                      fontSize: "0.8rem",
                                      verticalAlign: "bottom",
                                    }}
                                  />{" "}
                                  Remove Detail
                                </Typography>
                              </Box>
                              <TextField
                                variant="outlined"
                                name={`settings[${index}].title`}
                                {...getFieldProps(`settings[${index}].title`)}
                                size="small"
                              />
                            </FormControl>
                          </Grid>

                          {/* VALUES FOR EACH TITLE IN SETTING OBJECT */}

                          <FieldArray
                            name={`settings[${index}].values`}
                            render={(arrayHelpers) => (
                              <Box marginTop={2} style={{ width: "100%" }}>
                                <Grid spacing={2} container>
                                  {values.settings[index].values.map(
                                    (value, valueIndex) => (
                                      <Grid
                                        item
                                        container
                                        spacing={1}
                                        xs={12}
                                        sm={6}
                                        key={valueIndex}
                                        style={{ alignItems: "flex-end" }}
                                      >
                                        <Grid item xs={12} sm={9}>
                                          <FormControl
                                            className={classes.formControl}
                                          >
                                            <TextField
                                              variant="outlined"
                                              name={`settings[${index}].values[${valueIndex}].value`}
                                              {...getFieldProps(
                                                `settings[${index}].values[${valueIndex}].value`
                                              )}
                                              size="small"
                                            />
                                          </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={3}>
                                          <StatusIndicator
                                            text={"Delete"}
                                            onClick={() =>
                                              arrayHelpers.remove(valueIndex)
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                    )
                                  )}
                                </Grid>
                                <Box marginTop={2}>
                                  <Typography
                                    display="inline"
                                    variant="body2"
                                    color="primary"
                                    style={{
                                      fontSize: "11px",
                                      marginTop: "0.5rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      arrayHelpers.push("");
                                    }}
                                  >
                                    <Add style={{ fontSize: "0.8rem" }} /> Add
                                    Category option
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                          />
                        </Grid>
                      </Box>
                    ))}
                  </Grid>

                  <Box marginTop={2}>
                    <Typography
                      display="inline"
                      variant="body2"
                      color="primary"
                      style={{
                        fontWeight: "700",
                        fontSize: "12px",
                        marginTop: "0.5rem",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        arrayHelpers.push({ title: "", values: [] })
                      }
                    >
                      <Add style={{ fontSize: "0.8rem" }} /> 
                      Add another Category
                    </Typography>
                  </Box>
                </Box>
              )}
            />
            {values.settings.length > 0 && (
              <Box marginTop={7} align="right">
                <Button
                  padding="1"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  Submit
                </Button>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ProviderSettingsLayout;
