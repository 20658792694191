import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Box,
  FormControl,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import FormErrors from "../../../../FormErrors";
import Button from "../../../../Button";
import Paper from "../../../../Paper";

import { useStyles } from "./style";
import LoanContext from "../../../../../Contexts/loan/loanContext";
import AlertContext from "../../../../../Contexts/alert/alertContext";
import {
  nameValidation,
  phoneNumberValidation,
  emailValidation,
} from "../../../../../Actions/formActions";
import { values } from "lodash";
import AxiosRequest from "../../../../../Services/AxiosRequests";

const CreateStoreLayout = (props) => {
  const classes = useStyles();

  const [submitting, setIssubmitting] = useState(false);
  const [logoUpdate, setLogoUpdate] = useState(0)
  const [userCities, setUserCities] = useState([])

  const { addOrUpdateStore, states, getStates, getCities } = useContext(LoanContext);
  const { setAlert } = useContext(AlertContext);
  const store = props.location?.state?.data && props.location?.state?.data;

  useEffect(() => {
    getStates();
  }, [])

  useEffect(() => {
    (() => {
      if (states.length) {
        if (store?.city) {
          getUserCities(store.state);
        }
      }
    })();
  }, [states]);

  const initialValues = {
    storeName: store ? store.storeName : "",
    storeType: store ? (store.storeType === "INDIVIDUAL" ? 1 : 2): "",
    companyRegisteredName: store ? store.companyRegisteredName : "",
    contactPersonName: store ? store.contactPersonName : "",
    contactPersonPhoneNumber: store ? store.contactPersonPhoneNumber : "",
    legalRepresentativeFirstName : store ? store.legalRepresentativeFirstName : "",
    legalRepresentativeLastName : store ? store.legalRepresentativeLastName : "",
    legalRepresentativeDateOfBirth : store ? store.legalRepresentativeDateOfBirth : "",
    legalRepresentativeIdType : store ? store.legalRepresentativeIdType : "",
    noOfEmployee : store ? store.noOfEmployee : "",
    country: "3ba3cfaa-df45-4664-bcda-59b10c5f56e6",
    state : store ? store.state : "",
    city: store ? store.city : "",
    accountName: store ? store.accountName : "",
    accountNo: store ? store.accountNo : "",
    address: store ? store.address : "",
    bankName: store ? store.bankName : "",
    cac: store ? store.cac : "",
    tin: store ? store.tin : "",
    vat: store ? store.vat : "",
    shipInState : store ? store.shipInState : "",
    isAgreement : store ? store.isAgreement : "",
  };

  const validationSchema = Yup.object({
    storeName: nameValidation("Store Name", "storeName", true),
    storeType: Yup.string().required("Store Type is Required"),
    companyRegisteredName : Yup.string()
      .min(3, "minimum of three characters").
      required("Company Name is required"),
    contactPersonName: nameValidation(
      "Contact Person Name",
      "contactName",
      true
    ),
    contactPersonPhoneNumber: phoneNumberValidation("Contact Phone Number", "contactPersonPhoneNumber"),
    legalRepresentativeFirstName : nameValidation("Legal Rep First Name", "legalRepresentativeFirstName"),
    legalRepresentativeLastName : nameValidation("Legal Rep Last Name", "legalRepresentativeLastName"),
    legalRepresentativeDateOfBirth : Yup.date().required("Legal Rep D.O.B is required"),
    legalRepresentativeIdType : Yup.string().required("Legal rep Id type is required"),
    noOfEmployee : Yup.string()
      .matches(/^[0-9]*$/, "Only numbers allowed")
      .required("Number of Employees is required"),
    state : Yup.string()
      .required("State is required"),
    city : Yup.string()
      .required("City is required"),
    accountName : nameValidation("Account Name", "accountName", true),
    bankName : nameValidation("Bank Name", "bankName", true),
    address: Yup.string().required("Address is Required"),
    accountNo : Yup.string()
      .min(10, "Has to be ten digits")
      .max(10, "Has to be ten digits")
      .required("Account Number is empty"),
    cac: Yup.string().required("CAC number is Required"),
    tin: Yup.string().required("TIN number is Required"),
    vat: Yup.string().required("VAT number is Required"),
    shipInState: Yup.string().required("Ship in State number is Required"),
    isAgreement: Yup.boolean().required("Accept agreement to proceed"),
  });

  const onSubmit = async (values) => {
    const {
      storeName, storeType, companyRegisteredName, contactPersonName, 
      contactPersonPhoneNumber, legalRepresentativeFirstName, legalRepresentativeLastName,
      legalRepresentativeDateOfBirth, legalRepresentativeIdType, noOfEmployee, country, 
      state, city, accountName,accountNo, address, bankName, cac, tin, vat,
      shipInState, isAgreement
    } = values;

    const stateId = states.find((stateData) => stateData.name === state)?.id
    const cityId = userCities.find((cityData) => cityData.name === city)?.id

    const newValues = {
      storeName, storeType, companyRegisteredName, contactPersonName, contactPersonPhoneNumber,
      legalRepresentativeFirstName, legalRepresentativeLastName, legalRepresentativeDateOfBirth,
      legalRepresentativeIdType, noOfEmployee, country, state : stateId, city : cityId,
      vendorInfo : {
        accountName, accountNo, address, bankName, cac, tin, vat, shipInState, isAgreement
      }
    }

    console.log("The new values are: ", newValues);

    try {
      setIssubmitting(true);
      const res = await addOrUpdateStore(newValues, store?.id);
      console.log(store?.id);
      if (res.data.requestSuccessful) {
        let action = store ? "Updated" : "Created";
        setAlert(`Store ${action} successfully`, "success");
        setIssubmitting(false);
        props.history.push("/stores");
      } else {
        setAlert(res.data.message, "error");
      }
    } catch (err) {
      setAlert(err.response?.data?.message || "Error occured while performing request", "error");
      console.log(err);
      setIssubmitting(false);
    }
  };

  const updateStoreLogo = async (Logo) => {
    console.log("The parameters are: ",store?.id, Logo);
    if(logoUpdate) {
      try {
        setIssubmitting(true);
        const formData = new FormData();
        Logo !== undefined && formData.append("Logo", Logo);
        console.log("The updated logo is: ", formData);
        const res = await AxiosRequest("put", `Stores/upload_store_logo/${store.id}`, formData)
        if (res.data.requestSuccessful) {
          setAlert(`Store Logo updated successfully`, "success");
        } else {
          setAlert(res.data.message, "error");
        }
        setIssubmitting(false);
      } catch (err) {
        console.log("Update logo error", err)
        setAlert(err.response?.data?.message || "Error occured while updating logo", "error");
        setIssubmitting(false);
      }
    } else setLogoUpdate(logoUpdate+1)
  }

  const getUserCities = async (stateName) => {
    let cities = await getCities(states.find((state) => state.name === stateName)?.id);
    setUserCities(cities);
  };

  return (
    <>
      <Box>
        <Typography variant="h4" gutterBottom>
          {store ? "Update" : "Add"} Store
        </Typography>
        <Paper borderRadius="0" padding="1.5rem 2rem 2rem">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ errors, touched, getFieldProps, setFieldValue, values }) => (
              <Form noValidate autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="storeName" className={classes.label}>
                        Store Name
                      </label>
                      <TextField
                        name="storeName"
                        placeholder="Shoprite"
                        id="storeName"
                        variant="outlined"
                        {...getFieldProps("storeName")}
                        error={errors.storeName && touched.storeName ? true : false}
                        size="small"
                      />
                      <ErrorMessage name="storeName" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}
                      variant="outlined"
                      size="small"
                    >
                      <label htmlFor="storeType" className={classes.label}>
                        Store Type
                      </label>
                      <Select
                        displayEmpty
                        id="storeType"
                        name="storeType"
                        error={errors.storeType && touched.storeType ? true : false}
                        {...getFieldProps("storeType")}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem disabled value="">
                          Select
                        </MenuItem>
                        <MenuItem value={1}>INDIVIDUAL</MenuItem>
                        <MenuItem value={2}>COMPANY</MenuItem>
                      </Select>
                      <ErrorMessage name="storeType" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="baseUrl" className={classes.label}>
                        Company Registered Name
                      </label>
                      <TextField
                        name="companyRegisteredName"
                        placeholder="Tesla Electronics"
                        id="companyRegisteredName"
                        variant="outlined"
                        {...getFieldProps("companyRegisteredName")}
                        error={errors.companyRegisteredName && touched.companyRegisteredName ? true : false}
                        size="small"
                      />
                      <ErrorMessage name="companyRegisteredName" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="redirectUrl" className={classes.label}>
                        Contact Person Name
                      </label>
                      <TextField
                        name="contactPersonName"
                        placeholder="Peter Pan"
                        id="contactPersonName"
                        variant="outlined"
                        {...getFieldProps("contactPersonName")}
                        error={
                          errors.contactPersonName && touched.contactPersonName
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage name="contactPersonName" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="contactPersonPhoneNumber" className={classes.label}>
                        Contact Person Phone Number
                      </label>
                      <TextField
                        name="contactPersonPhoneNumber"
                        placeholder="08039483938"
                        className={classes.numberInput}
                        id="contactPersonPhoneNumber"
                        variant="outlined"
                        {...getFieldProps("contactPersonPhoneNumber")}
                        error={
                          errors.contactPersonPhoneNumber && touched.contactPersonPhoneNumber
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage name="contactPersonPhoneNumber" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="legalRepresentativeFirstName" className={classes.label}>
                        Legal Rep First Name
                      </label>
                      <TextField
                        name="legalRepresentativeFirstName"
                        placeholder="Peter"
                        id="legalRepresentativeFirstName"
                        variant="outlined"
                        {...getFieldProps("legalRepresentativeFirstName")}
                        error={errors.legalRepresentativeFirstName && touched.legalRepresentativeFirstName ? true : false}
                        size="small"
                      />
                      <ErrorMessage name="legalRepresentativeFirstName" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="legalRepresentativeLastName" className={classes.label}>
                        Legal Rep Last Name
                      </label>
                      <TextField
                        name="legalRepresentativeLastName"
                        placeholder="Peter"
                        id="legalRepresentativeLastName"
                        variant="outlined"
                        {...getFieldProps("legalRepresentativeLastName")}
                        error={errors.legalRepresentativeLastName && touched.legalRepresentativeLastName ? true : false}
                        size="small"
                      />
                      <ErrorMessage name="legalRepresentativeLastName" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="apiKey" className={classes.label}>
                        Legal Rep Date of Birth
                      </label>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          // disableToolbar
                          inputVariant="outlined"
                          format="DD/MM/yyyy"
                          variant="outlined"
                          id="dateOfBirth"
                          name="legalRepresentativeDateOfBirth"
                          size="small"
                          value={values.legalRepresentativeDateOfBirth}
                          onChange={(value) => {
                            setFieldValue("legalRepresentativeDateOfBirth", value);
                          }}
                          helperText={""}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <ErrorMessage name="legalRepresentativeDateOfBirth" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}
                      variant="outlined"
                      size="small"
                    >
                      <label htmlFor="legalRepresentativeIdType" className={classes.label}>
                        Legal Rep ID Number
                      </label>
                      <TextField
                        name="legalRepresentativeIdType"
                        placeholder="12345"
                        id="legalRepresentativeIdType"
                        variant="outlined"
                        {...getFieldProps("legalRepresentativeIdType")}
                        error={errors.legalRepresentativeIdType && touched.legalRepresentativeIdType ? true : false}
                        size="small"
                      />
                      <ErrorMessage name="legalRepresentativeIdType" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="noOfEmployee" className={classes.label}>
                        Number of Employees
                      </label>
                      <TextField
                        name="noOfEmployee"
                        placeholder="3"
                        id="noOfEmployee"
                        variant="outlined"
                        {...getFieldProps("noOfEmployee")}
                        error={errors.noOfEmployee && touched.noOfEmployee ? true : false}
                        size="small"
                      />
                      <ErrorMessage name="noOfEmployee" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="country" className={classes.label}>
                        Country
                      </label>
                      <TextField
                        name="country"
                        placeholder="Nigeria"
                        id="country"
                        variant="outlined"
                        {...getFieldProps("country")}
                        error={
                          errors.country && touched.country
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage
                        name="country"
                        component={FormErrors}
                      />
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl 
                      variant="outlined" size="small" 
                      className={classes.formControl}
                    >
                      <label htmlFor="state" className={classes.label}>
                        State
                      </label>
                      <Select
                        id="state"
                        name="state"
                        error={errors.state && touched.state ? true : false}
                        value={values.state}
                        onChange={(e) => {
                          setFieldValue("city", "")
                          const stateName = e.target.value
                          if(stateName) {
                            setFieldValue("state", stateName)
                            getUserCities(stateName)
                          }
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem disabled value="">
                          Select
                        </MenuItem>
                        { !states.length ? (
                        <MenuItem value={""} defaultValue>
                          Loading States...
                        </MenuItem> ) :
                        (states.map((state, key) => (
                          <MenuItem key={key} value={state.name}>
                            {state.name}
                          </MenuItem>)
                        ))}
                      </Select>

                      <ErrorMessage
                        name="state"
                        component={FormErrors}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl 
                      variant="outlined" size="small" 
                      className={classes.formControl}
                    >
                      <label htmlFor="city" className={classes.label}>
                        City
                      </label>
                      <Select
                        id="city"
                        name="city"
                        error={errors.city && touched.city ? true : false}
                        value={values.city}
                        onChange={(e) => {
                          const cityName = e.target.value
                          setFieldValue("city", cityName)
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        { !userCities.length ? (
                          values.state ?
                          <MenuItem value={""} defaultValue>
                            Loading Cities...
                          </MenuItem> : 
                          <MenuItem value={""} defaultValue>
                            Select a state first
                          </MenuItem>
                        ) :
                        (userCities.map((city, key) => (
                          <MenuItem key={key} value={city.name}>
                            {city.name}
                          </MenuItem>)
                        ))}
                      </Select>
                      
                      <ErrorMessage
                        name="city"
                        component={FormErrors}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12} sm={12}>
                    <Box>
                      <Typography variant="h5">
                        Vendor Information
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label
                        htmlFor="accountName"
                        className={classes.label}
                      >
                        Account Name
                      </label>
                      <TextField
                        name="accountName"
                        placeholder="Tesla Plc"
                        id="accountName"
                        variant="outlined"
                        {...getFieldProps("accountName")}
                        error={
                          errors.accountName && touched.accountName
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage
                        name="accountName"
                        component={FormErrors}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label
                        htmlFor="accountNo"
                        className={classes.label}
                      >
                        Account Number
                      </label>
                      <TextField
                        name="accountNo"
                        placeholder="0012345678"
                        id="accountNo"
                        variant="outlined"
                        {...getFieldProps("accountNo")}
                        error={
                          errors.accountNo && touched.accountNo
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage
                        name="accountNo"
                        component={FormErrors}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="address" className={classes.label}>
                        Address
                      </label>
                      <TextField
                        name="address"
                        placeholder="Airport Road"
                        id="address"
                        variant="outlined"
                        {...getFieldProps("address")}
                        error={errors.address && touched.address ? true : false}
                        size="small"
                      />
                      <ErrorMessage name="address" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="bankName" className={classes.label}>
                        Bank Name
                      </label>
                      <TextField
                        name="bankName"
                        placeholder="Zenith Bank"
                        className={classes.numberInput}
                        id="bankName"
                        variant="outlined"
                        {...getFieldProps("bankName")}
                        error={
                          errors.bankName && touched.bankName
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage name="bankName" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="cac" className={classes.label}>
                        CAC Number
                      </label>
                      <TextField
                        name="cac"
                        placeholder="12345"
                        className={classes.numberInput}
                        id="cac"
                        variant="outlined"
                        {...getFieldProps("cac")}
                        error={
                          errors.cac && touched.cac
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage name="cac" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="tin" className={classes.label}>
                        TIN Number
                      </label>
                      <TextField
                        name="tin"
                        placeholder="12345"
                        className={classes.numberInput}
                        id="tin"
                        variant="outlined"
                        {...getFieldProps("tin")}
                        error={
                          errors.tin && touched.tin
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage name="tin" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="shipInState" className={classes.label}>
                        Ship In State Number
                      </label>
                      <TextField
                        name="shipInState"
                        placeholder="12345"
                        className={classes.numberInput}
                        id="shipInState"
                        variant="outlined"
                        {...getFieldProps("shipInState")}
                        error={
                          errors.shipInState && touched.shipInState
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage name="shipInState" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="vat" className={classes.label}>
                        VAT
                      </label>
                      <Box className={classes.enableCTA}>
                        <label htmlFor="vat" className={classes.enable}>
                          Enable/Disable
                        </label>
                        <FormControlLabel
                          control={
                            <Field
                              component={Switch}
                              id="vat"
                              type="checkbox"
                              checked={values.vat}
                              name="vat"
                              {...getFieldProps("vat")}
                              color="primary"
                            />
                          }
                        />
                      </Box>
                      <ErrorMessage name="vat" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl className={classes.formControl}>
                      <Typography className={classes.label}>
                        Toggle to Enable Agreement
                      </Typography>
                      <Box className={classes.enableCTA}>
                        <label htmlFor="isAgreement" className={classes.enable}>
                          Enable
                        </label>
                        <FormControlLabel
                          control={
                            <Field
                              component={Switch}
                              id="isAgreement"
                              type="checkbox"
                              checked={values.isAgreement}
                              name="isAgreement"
                              {...getFieldProps("isAgreement")}
                              color="primary"
                            />
                          }
                        />
                      </Box>
                    </FormControl>
                  </Grid>

                  { store?.id &&
                    <Grid item xs={12} md={4} sm={6}>
                    <FormControl
                      component="fieldset"
                      style={{ width: "100%" }}
                      className={classes.formControl}
                    >
                      <label htmlFor="Logo" className={classes.label}>
                        Store Logo <span style={{fontSize : "10px"}}>(updates immediately after change)</span>
                      </label>
                      <DropzoneArea
                        acceptedFiles={["image/*"]}
                        dropzoneText={
                          <Box>
                            <Typography display="inline" variant="body2">
                              Drag and drop or
                            </Typography>
                            <Typography
                              display="inline"
                              color="primary"
                              variant="body2"
                            >
                              Browse
                            </Typography>
                          </Box>
                        }
                        maxFileSize={800000}
                        showPreviewsInDropzone
                        showFileNamesp
                        showFileNamesInPreview
                        getDropRejectMessage={() =>
                          "File should be the required format and not more than 800KB"
                        }
                        filesLimit={1}
                        onChange={(file) => updateStoreLogo(file[0])}
                      />
                      <div className={classes.uploadInfo}>
                        <small style={{ fontSize: "10px", fontWeight: 300 }}>
                          Files Supported: JPEG, PNG, SVG
                        </small>
                        <small style={{ fontSize: "10px", fontWeight: 300 }}>
                          Max File Size: 800KB
                        </small>
                      </div>
                    </FormControl>
                  </Grid>
                  }

                  
                </Grid>

                <Box className={classes.createActions}>
                  <Link
                    to="/stores"
                    style={{ cursor: "pointer", textDecoration: "none" }}
                  >
                    <Typography variant="body2" color="primary">
                      Cancel
                    </Typography>
                  </Link>

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                    loading={submitting}
                    size="medium"
                  >
                    {store ? "Update" : "Add"}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Paper>
      </Box>

      {/* {store && (
        <Paper
          borderRadius="0"
          style={{
            marginTop: "4rem",
            border: "1px dotted #000000",
            padding: "2rem 0",
          }}
        >
          <PartnerConfigLayout store={store} />
        </Paper>
      )} */}
    </>
  );
};

export default CreateStoreLayout;
