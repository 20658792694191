import React, { useMemo } from "react";
import { useTable } from "react-table";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@material-ui/core/";
import PropTypes from "prop-types";
import { useStyles } from "./style";

const Table = ({ columns, data, loading, size, showId }) => {
  //   data = useMemo(() => (loading ? [] : data), [loading]);
  // columns = useMemo(
  //   () => [
  //     // {
  //     //   Header: " ",
  //     //   columns: columns,
  //     // },
  //     columns
  //   ],
  //   []
  // );

  columns = useMemo(() => columns, []);
  // data = useMemo(() => data, [loading])
  const classes = useStyles();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0, hiddenColumns: [!showId && "id"] },
  });

  return (
    <TableContainer className={classes.container}>
      <MuiTable aria-label="table" {...getTableProps()}>
        <TableHead style={{backgroundColor: "#F1F4F8"}}>
          {headerGroups.map((headerGroup) => (
            <TableRow hover {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <TableCell {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {!loading && data.length < 1 ? (
            <TableRow>
              <TableCell colSpan="10000">No Results found</TableCell>
            </TableRow>
          ) : (
            rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow hover {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        size={size ? size : "medium"}
                        {...cell.getCellProps()}
                        // className={cell.column.sticky === true ? classes.sticky : ""}

                        style={{
                          fontSize: "12px",
                          minWidth: cell.column.Header === "Email" || cell.column.Header === "Created By" ? 200 : 130,
                          maxWidth: 200,
                          wordBreak:
                            cell.column.Header === "Email" || cell.column.Header === "Created By"  ? "break-all" : "",
                        }}
                        
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          )}
          {/* {} */}
          {/* <TableRow>
            {loading && <TableCell colSpan="10000">Loading...</TableCell>}
          </TableRow> */}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};

Table.propTypes = {
  loading: PropTypes.bool.isRequired,
  size: PropTypes.string,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

export default Table;
