import React from 'react'
import PropTypes from 'prop-types'
import { Card as MuiCard } from '@material-ui/core'
import { useStyles } from './styles'

const Card = props => {
    const classes = useStyles(props);

    return (
        <MuiCard className={classes.root}>
             { props.children }
        </MuiCard>
    )
}

Card.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    // padding: PropTypes.string
}

export default Card