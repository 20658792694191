import { makeStyles } from '@material-ui/core/styles'

// sizes 
const sizePicker = (size) => {
    switch (size) {
        case 'large':
            return {
                padding: '.75rem 2.25rem',
                fontSize: '1rem'
            }
        case 'medium':
            return {
                padding: '0.45rem .75rem',
                fontSize: '1rem'
            }
        case 'small':
            return {
                padding: '0.53em 1.33em',
                fontSize: '0.75rem'
            }
        case 'tiny':
            return{
                padding: '0.62em 1.3em',
                fontSize: '0.65rem',
                fontWeight: '100'                
            }
        default:
            return {
                padding: '0.45rem .75rem',
                fontSize: '1rem'
            }
    }
}

const useStyles = makeStyles(theme => ({
    button: {
        position: 'relative',
        
        // padding: props => props.padding ? props.padding : `1rem 2.5rem`,
        padding: props => {const size = sizePicker(props.size); return size.padding},
        fontSize: props => {const size = sizePicker(props.size); return size.fontSize},
        fontWeight: props => {const size = sizePicker(props.size); return size.fontWeight},
        // boxShadow: `0px 10px 20px rgba(17,122,243,0.20)`,
        textTransform: `none`,
        transition: `all .7s cubic-bezier(.2,1,.22,1)`,
        '&:hover' : {
            boxShadow: `0px 10px 20px rgba(17,122,243,0.30)`,
            transform: `translateY(-3px)`
        },
        '&:active' : {
            boxShadow: `0px 10px 20px rgba(17,122,243,0.60)`
        },
    },
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export { useStyles }