import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { TextField, FormControl, FormHelperText, InputLabel, Select, MenuItem, Typography, Button as MuiButton } from '@material-ui/core'
import { useStyles } from './styles'
import Button from '../Button'
import { ProvidersContext } from '../../Contexts/Store'
import { ActiveStepContext } from '../../Contexts/Store'
import { TransactionContext } from '../../Contexts/Store'
import { AccountDataContext } from '../../Contexts/Store'
import { LoanRequestContext } from '../../Contexts/Store'
import { LoanRequestResponseContext } from '../../Contexts/Store'
import Modal from '../Modal'
import Success from './Success'
import Failed from './Failed'
import { formIsValid } from './Validation'
import AxiosRequest from '../../Services/AxiosRequests'

const Form = props => {
    const classes = useStyles();

    // statuses are normal, success, failed
    const [formStatus, setformStatus] = React.useState({
        status: 'normal',
        message: ''
    });

    //Button loading state
    const [buttonLoading, setButtonLoading] = React.useState(false);

    //Button disabled state
    const [disableSubmit, setDisableSubmit] = React.useState(true);

    // get provider data for select input
    const [ providers ] = useContext(ProvidersContext)

    //chosen account data
    const [ , setAccountData ] = useContext(AccountDataContext) 

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);

    React.useEffect(() => {
        if(props.view === "addBank") {
            setLabelWidth(inputLabel.current.offsetWidth);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [ , setActiveStep ] = useContext(ActiveStepContext)

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1)        
    }

    const [openModal, setOpenModal] = React.useState(false);

    // const handleOpen = () => {
    //     setOpenModal(true);
    // };

    const handleClose = (next) => {
        setOpenModal(false);
        
        if(next === 'next') {
            handleNext()
        }
    };

    const [ userInfo ] = useContext(TransactionContext)

    // Add Bank form functions
    const [bankDetails, setBankDetails] = React.useState({
        accountNo: '',
        providerId: '',
        phoneNumber: '',
        beneficiaryId: userInfo.beneficiaryID,
        transactionReference: userInfo.transactionReference,
        formErrors : {
            accountNo : '',
            providerId : '',
            phoneNumber : ''
        }
    });

    const handleAddBankChange = e => {
        const { name, value } = e.target 

        let formErrors = bankDetails.formErrors
        switch (name) {
            case 'accountNo':
                formErrors.accountNo = value.length < 10 || value.length > 12 ? '10 characters required' : ''
                break;

            case 'providerId':
                formErrors.providerId = value.length > 0 ? '' : 'Select a Bank'
                break;
        
            case 'phoneNumber':
                formErrors.phoneNumber =  value.length < 8 || value.length > 15 ? 'Add a valid phone Number' : '' 
                break;
        
            default:
                break;
        }

        setBankDetails({ 
            ...bankDetails, 
            formErrors,
            [name]: value,
        });     
        // enable submit button 
        Object.values(formErrors).forEach(val => {
            val.length === 0 && (setDisableSubmit(false))
        })
    }

    // Add OTP form functions
    const [otpDetails, setOtpDetails] = React.useState({
        otp: '',
        formErrors : {
            otp : ''
        }
    });

    const handleOtpChange = e => {
        const { name, value } = e.target 

        let formErrors = otpDetails.formErrors
        switch (name) {
            case 'otp':
                formErrors.otp = value.length < 4 || value.length > 6 ? 'Minimum of 4 characters required' : ''
                break;
        
            default:
                break;
        }

        setOtpDetails({ 
            ...otpDetails, 
            formErrors,
            [name]: value,
        });     

        // enable submit button 
        Object.values(formErrors).forEach(val => {
            val.length === 0 && (setDisableSubmit(false))
        })
    }

    // Axios http request function 
    const postForm = (appendUrl, payLoad) => {
        AxiosRequest('post', appendUrl, payLoad)
        .then(res => {
            if (res.data.requestSuccessful === true) {
                console.log(res.data.responseData);
                
                setformStatus({
                    status: 'success',
                    message: 'Account Added Successfully!'
                })

                // set account data globally for eligibility
                setAccountData({
                    providerId : res.data.responseData.providerId, 
                    accountNo : res.data.responseData.accountNo,
                    id: res.data.responseData.id 
                })

                setButtonLoading(false)

                setTimeout(() => {
                    handleClose('next')
                }, 2000)
                
            } else {
                setformStatus({
                    status: 'failed',
                    message: res.data.message
                })

                setButtonLoading(false)

                setTimeout(() => {
                    setformStatus({
                        status: 'normal',
                        message: ''
                    })
                }, 2000)
            }
        })
        .catch(error => {
            setformStatus({
                status: 'failed',
                message: 'An error occured'
            })
            setButtonLoading(false)
            setTimeout(() => {
                setformStatus({
                    status: 'normal',
                    message: ''
                })
            }, 2000)
        })
    }

    const [ loanRequest ] = useContext(LoanRequestContext)


    // Send and Resend OTP  
    const sendOTP = (requestType, appendUrl) => {
        const transactionReference = userInfo.transactionReference;

        AxiosRequest(requestType, `${appendUrl}${transactionReference}`)
        .then(res => {
            if (res.data.requestSuccessful === false) {
                setformStatus({
                    status: 'failed',
                    message: 'An error occured. Please Try again'
                })
            } 
        })
        .catch(error => {
            setformStatus({
                status: 'failed',
                message: 'An error occured. Please Try again'
            })
            setTimeout(() => {
                handleClose()
            }, 2000)
        })
    }


    const [ , setLoanRequestResponse ] = useContext(LoanRequestResponseContext)
    
    const requestLoan = (appendUrl, payload) => {
        AxiosRequest('post', appendUrl, payload)
        .then(res => {
            if (res.data.requestSuccessful === true) {
                setLoanRequestResponse(res.data)
                setformStatus({
                    status: 'success',
                    message: 'OTP Correct'
                })

                setButtonLoading(false)

                setTimeout(() => {
                    handleClose('next')
                }, 2000)
                
            }  else {
                setformStatus({
                    status: 'failed',
                    message: res.data.message
                })

                setButtonLoading(false)

                setTimeout(() => {
                    setformStatus({
                        status: 'normal',
                        message: ''
                    })
                }, 2000)
                
            }
        })
        .catch(error => {
            
            setformStatus({
                status: 'failed',
                message: 'An error occurred '
            })

            setButtonLoading(false)

            setTimeout(() => {
                setformStatus({
                    status: 'normal',
                    message: ''
                })
            }, 2000)
        })
    }


    // Submitting the form
    const onSubmit = event => {
        event.preventDefault()

        switch (props.view) {
            case 'addBank':
                if(formIsValid(bankDetails)) {
                    const { formErrors, ...rest } = bankDetails
                    // setAccountData({
                    //     providerId : bankDetails.providerId, 
                    //     accountNo : bankDetails.accountNo
                    // })

                    setButtonLoading(true)

                    //Call endpoint function
                    postForm('BeneficiaryAccount/create', rest)            
                } else {
                    console.log('invalid form');
                }
                break;

            case 'otp':
                if(formIsValid(otpDetails)) {
                    const { formErrors, ...rest } = otpDetails

                    const payload = ({
                        ...loanRequest, 
                        otp : rest.otp
                    })

                    setButtonLoading(true)
                    
                    requestLoan('LoanTransaction/requestloan', payload)

                } else {
                    console.log('invalid form');
                    setOtpDetails({
                        ...otpDetails,
                        formErrors : {
                            otp : 'Input an OTP'
                        }
                    })
                }
                break;
        
            default:
                break;
        }
    }


    useEffect(() => {
        if(props.purpose === "verifyAccount"){
            sendOTP('post', 'LoanTransaction/otp/')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
        <form noValidate autoComplete="off" onSubmit={onSubmit}>
            { props.view === "addBank" &&
                (
                    <>
                    {formStatus.status === "success" ? (
                        <Success purpose="addAccount" message={formStatus.message} /> 
                    ) : formStatus.status === "failed" ? 
                        (
                            <Failed purpose="addAccount" message={formStatus.message} />
                    ) : ( 
                        <>
                    <FormControl variant="outlined" className={classes.formControl} error={bankDetails.formErrors.providerId.length > 0 ? true : false}>
                        <InputLabel  ref={inputLabel} id="demo-simple-select-outlined-label">
                        Select Bank
                        </InputLabel>
                        <Select
                        labelId="demo-simple-select-outlined-label"
                        id="bankName"
                        name="providerId"
                        value={bankDetails.providerId}
                        onChange={handleAddBankChange}
                        labelWidth={labelWidth}
                        
                        >
                            {
                                !providers ? (
                                    <MenuItem value={0}>No Bank</MenuItem>
                                ) : (
                                    providers.map((provider, key) => (
                                    <MenuItem value={provider.id} key={key}>
                                        {provider.bankName}
                                    </MenuItem>
                                    ))
                                )
                            }
                            

                        </Select>
                        <FormHelperText>{bankDetails.formErrors.providerId}</FormHelperText>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                        id="accountNo"
                        name="accountNo"
                        label="Account Number"
                        type="number"
                        value={bankDetails.accountNo}
                        onChange={handleAddBankChange}
                        variant="outlined"
                        error={bankDetails.formErrors.accountNo.length > 0 ? true : false}
                        helperText={bankDetails.formErrors.accountNo}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Phone Number"
                        type="number"
                        value={bankDetails.phoneNumber}
                        onChange={handleAddBankChange}
                        variant="outlined"
                        error={bankDetails.formErrors.phoneNumber.length > 0 ? true : false}
                        helperText={bankDetails.formErrors.phoneNumber}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <Button variant="contained" color="primary" type="submit" disabled={disableSubmit} loading={buttonLoading}>
                            Confirm Details
                        </Button>
                    </FormControl>
                    </>
                )}
                </>
                )
            }

            { props.view === "otp" &&
                (
                    <>
                    {formStatus.status === "success" ? (
                        <Success purpose={props.purpose} message={formStatus.message} /> 
                    ) : formStatus.status === "failed" ? 
                        (
                            <Failed purpose={props.purpose} message={formStatus.message} />
                    ) : ( 
                        <>
                    <FormControl className={classes.formControl}>
                        <TextField
                        required
                        id="otp"
                        name="otp"
                        label="OTP"
                        type="number"
                        variant="outlined"
                        value={otpDetails.otp}
                        onChange={handleOtpChange}
                        error={otpDetails.formErrors.otp.length > 0 ? true : false}
                        helperText={otpDetails.formErrors.otp}
                        />
                    </FormControl>
                    <Typography variant="body2" className={classes.formControl}>
                        A One Time Password has been sent to the phone number attached to your bank account. Didn't get an OTP? <MuiButton color="primary" onClick={() => sendOTP('post', 'LoanTransaction/otp/')}>Resend OTP</MuiButton>
                    </Typography>
                    <FormControl className={classes.formControl}>
                        <Button variant="contained" color="primary" type="submit"
                        disabled={disableSubmit} loading={buttonLoading}>
                            Proceed
                        </Button>
                    </FormControl>
                    </>
                    )}
                    </>
                )
            }

            
        </form>

        {/* Load Custom Modal COmponent */}
        {openModal === true &&
            (
                <Modal 
                handleClose={handleClose} 
                openModal={openModal} 
                view='otp' 
                purpose="verifyAccount" 
                disableBackdropClick={true} 
                accountData={{
                    providerId : bankDetails.providerId, 
                    accountNo : bankDetails.accountNo
                }} 
                />
            )
            // disableBackdropClick implementation not working
            //accountData prop is to help OTP send it's request automatically
        }
        </>
        
    )
}

Form.propTypes = {
    view : PropTypes.string,
    purpose : PropTypes.string
}

export default Form
