import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root:{ 

    },
    container: {
        marginTop: "0",
        display: "flex",
        justifyContent: "flex-end",
        
        width: "100%",
        gap: "1rem",
        listStyle: "none",
        cursor: "pointer",
    },
    active: {
        backgroundColor: "#EAFBF1",
       padding: "0 0.5rem",
       borderRadius: "0.3px",
     
    },
    paginationLink: {
        border: "none",
    },
    disabled: {color: "#A4AAAE"}
}));

export { useStyles };