import React, { useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Stepper, Step, StepLabel, StepConnector } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import CredpalContext from "../../../../../../Contexts/credpal/credpalContext";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#D7DCE0",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#27BE63",
    },
  },
  line: {
    borderColor: "#D7DCE0",
    borderTopWidth: 3,
    borderRadius: 3,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    flexWrap: "wrap",
    boxSizing: "border-box",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#27BE63",
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#27BE63",
    zIndex: 1,
    fontSize: 24,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    left: "-1rem",
  },
}));

export default function StatusConnector({ selfSponsored, showUploads }) {
  const { activeStep } = useContext(CredpalContext);
  const classes = useStyles();

  const getSteps = () => {
    let actions = ["Personal", "Sponsor", "Income & Additional"];
    if(showUploads){
      actions = [ ...actions, "uploads"]
    }
    selfSponsored && actions.splice(1, 1);
    return actions;
  };

  const steps = getSteps();

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={
          selfSponsored
            ? activeStep === 1
              ? activeStep - 1
              : activeStep - 2
            : activeStep - 1
        }
        connector={<QontoConnector />}
        style={{ backgroundColor: "transparent" }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
