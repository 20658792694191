import React, { useState, useEffect, useContext } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import FormErrors from "../../../../FormErrors";
import * as Yup from "yup";
import { envURL } from "../../../../../Actions/helpers";
import {
  FormControl,
  Box,
  TextField,
  Typography,
  Paper,
  Grid,
  Select,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import Button from "../../../../Button";
import CredpalContext from "../../../../../Contexts/credpal/credpalContext";
import { useStyles } from "./style";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import loanContext from "../../../../../Contexts/loan/loanContext";

const VerifyStudent = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const userId = queryString.parse(location.search).userId;
  const typeId = queryString.parse(location.search).type;

  const { isSubmitting, setUserData, setUserEmail } =
    useContext(CredpalContext);

  const { availableLoanTypes, getLoanTypes } = useContext(loanContext);

  const isStudyLoan = () => {
    let res = false;

    if (typeId && availableLoanTypes?.length) {
      const typeData = availableLoanTypes.find((type) => type.id === typeId);
      console.log("The loan types are: ", availableLoanTypes);

      if (typeData && typeData.name?.match(/Study Loan/i)) res = true;
    }

    return res;
  };

  // const urlQuery = queryString.parse(location.search)

  // const [verification, setVerification] = useState(0);
  // const [othStatus, setOthStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setUserEmail(userId);
    getLoanTypes();
  }, []);

  const initialValues = {
    userType: "",
    institutionName: "",
    programDescription: "",
  };

  const validationSchema = Yup.object({
    userType: Yup.string().required("Select a user type"),

    institutionName: Yup.string()
      .test({
        name: "validator-custom-name",
        //eslint-disable-next-line object-shorthand
        test: function (value) {
          // return !(/^[a-zA-Z ]*$/.test(value)) ?
          return /\d/.test(value)
            ? this.createError({
                message: "Invalid name",
                path: "institutionName",
              })
            : true;
        },
      })
      .min(3, "Minimum of 3 Characters")
      .required("Institution Name is empty"),

    programDescription: Yup.string()
      .min(3, "Minimum of 3 Characters")
      .required("Program Name is empty"),
  });

  const onSubmit = (values, { setTouched }) => {
    setTouched({});
    setUserData(values);

    // verifyStudent({ ...values, institutionName: institutionObj.name });
  };

  return (
    <div className={classes.container}>
      <div>
        <Button
          onClick={() => {
            window.location.href = `${envURL}/dashboard`;
          }}
        >
          &#60; BACK HOME
        </Button>
      </div>
      <Grid container style={{ height: "470px" }}>
        <Grid item xs={false} sm={6} md={6} className={classes.image}></Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            {/* <h1>Lets get to know you</h1> */}
            <Box marginBottom={"2rem"}>
              <Typography className={classes.title}>
                Let's get to know you
              </Typography>

              {/* {verification === 1 && (
                <Box
                  style={{ display: "flex", cursor: "pointer" }}
                  onClick={() => setVerification(0)}
                >
                  <IconButton aria-label="close" className={classes.backButton}>
                    <KeyboardArrowLeftIcon fontSize="small" />
                  </IconButton>
                  <Typography variant="body2">Back</Typography>
                </Box>
              )} */}
            </Box>

            <Box justifyContent="center">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, getFieldProps, handleBlur, setFieldValue }) => (
                  <Form noValidate autoComplete="off" className={classes.form}>
                    <Box>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                        size="small"
                      >
                        <Typography className={classes.label}>
                          I am applying as a
                        </Typography>
                        <Select
                          className={classes.select}
                          defaultValue=""
                          labelId="userType"
                          id="userType"
                          name="userType"
                          // label="userType"
                          {...getFieldProps("userType")}
                          value={values.userType}
                          onChange={(e) => {
                            const { value } = e.target;
                            if (value === "student" && isStudyLoan())
                              setOpen(true);
                            setFieldValue("userType", value);
                          }}
                        >
                          <MenuItem value="student">Student</MenuItem>
                          <MenuItem value="sponsor">Sponsor</MenuItem>
                        </Select>
                        <ErrorMessage name="userType" component={FormErrors} />
                      </FormControl>

                      <FormControl className={classes.formControl}>
                        <Typography className={classes.label}>
                          Name of Institution
                        </Typography>
                        <TextField
                          name="institutionName"
                          {...getFieldProps("institutionName")}
                          variant="outlined"
                          size="small"
                        />
                        <ErrorMessage
                          name="institutionName"
                          component={FormErrors}
                        />
                      </FormControl>

                      <FormControl className={classes.formControl}>
                        <Typography className={classes.label}>
                          Program
                        </Typography>
                        <TextField
                          name="programDescription"
                          {...getFieldProps("programDescription")}
                          variant="outlined"
                          size="small"
                        />
                        <ErrorMessage
                          name="programDescription"
                          component={FormErrors}
                        />
                      </FormControl>
                    </Box>
                    <Box marginTop={4}>
                      <FormControl className={classes.formControl}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                          loading={isSubmitting}
                        >
                          Proceed
                        </Button>
                      </FormControl>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Notice!</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}
            style={{ fontSize: "14px" }}
          >
            This facility is currently available to <b>local</b> students as
            well as international students on <b>distance learning</b>. However,
            on-site international students are allowed to get a <b>sponsor</b>{" "}
            to apply on their behalf.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            padding={"0.4rem 1rem"}
            color="primary"
            onClick={handleClose}
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

VerifyStudent.propTypes = {};

export default VerifyStudent;
