import React, { useEffect, useContext } from "react";
import {
  Typography,
  Box,
  FormControl,
  Grid,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Formik, Form, FieldArray } from "formik";
import Button from "../../../../Button";

import StatusIndicator from "../../../../Reusables/StatusIndicator";
import LoanContext from "../../../../../Contexts/loan/loanContext";

const ProviderConfigLayout = ({ provider, providerConfig }) => {
  const { createProviderConfig, updateProviderConfig } =
    useContext(LoanContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const initialValues = { settings: providerConfig?.settings || [] };

  const onSubmit = async (values) => {
    if (providerConfig) {
      await updateProviderConfig(values, provider.id);
    } else {
      await createProviderConfig(values, provider.sortCode);
    }
  };


  return (
    <>
      <Typography variant="h5">Provider Configuration</Typography>
      <Box marginTop={4}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, isSubmitting, getFieldProps }) => (
            <Form autoComplete="off">
              <FieldArray
                name="settings"
                render={(arrayHelpers) => (
                  <>
                    <Grid container spacing={isMobile ? 1 : 3}>
                      {values.settings.map((value, index) => (
                        <Grid
                          container
                          spacing={isMobile ? 1 : 2}
                          item
                          xs={12}
                          key={index}
                          style={{ alignItems: "flex-end" }}
                        >
                          <Grid item xs={12} sm={2}>
                            <FormControl style={{ width: "100%" }}>
                              <Typography>Parameter Name</Typography>
                              <TextField
                                variant="outlined"
                                required
                                name={`settings[${index}].parameter`}
                                {...getFieldProps(
                                  `settings[${index}].parameter`
                                )}
                                size="small"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <FormControl style={{ width: "100%" }}>
                              <Typography htmlFor="value">Value</Typography>
                              <TextField
                                variant="outlined"
                                required
                                name={`settings.${index}.value`}
                                {...getFieldProps(`settings.${index}.value`)}
                                size="small"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <StatusIndicator
                              text={"Delete"}
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                    <Box marginTop={3}>
                      <Typography
                        color="primary"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          arrayHelpers.push({ parameter: "", value: "" })
                        }
                      >
                        <AddCircleOutlineIcon
                          style={{ verticalAlign: "bottom" }}
                        />{" "}
                        Add Configuration
                      </Typography>
                    </Box>
                    <Box marginTop={7} align="right">
                      <Button
                        padding="1"
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting || (!values.settings.length && !providerConfig)}
                        loading={isSubmitting}
                      >
                        Submit
                      </Button>
                    </Box>
                  </>
                )}
              />
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default ProviderConfigLayout;
