import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
     formControl: {
        minWidth : `100%`,
        marginBottom: '1rem'
    },
    loginCTA: {
        margin: "0 auto",
        color: theme.palette.primary.main,
        cursor: "pointer",
        textDecoration: "none"
    }
}));

export { useStyles }

