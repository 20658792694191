import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth : `100%`,
    },
    subFormControl: {
        display: "flex",
        minWidth : `100%`,
        justifyContent: "space-between"
    },
    detailAction: {
        display: "flex",
        
        justifyContent:"space-between"
    },
    otherDetails: {
        marginTop: "2rem"
    }
}));

export { useStyles }
