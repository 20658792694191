import { createTheme  } from '@material-ui/core/styles';

// Colour Profiles
const eCBlue = {
    main : '#117AF3',
    contrastText : '#F3F5F9',
    lighter : '#E3EAF9'
}
const eCGreen = {
    main : '#11CC93',
    contrastText : '#F3F5F9'
}
const eCPink = {
    main : '#FD2D55',
    contrastText : '#F3F5F9'
}
const eCWhite = {
    main : '#FFFFFF',
    contrastText : '#1A1B33'
}
const eCLight = {
    main : '#F3F5F9',
    contrastText : '#1A1B33'
}
const eCDark = {
    main : '#1A1B33',
    contrastText : '#F3F5F9'
}
  
// Theme
const eCTheme = createTheme ({
    palette : {
        primary : eCBlue,
        secondary : eCGreen,
        error: eCPink,
        text: eCDark,
        background: {
          paper: eCWhite.main
        },
        type: 'light'
    },
    shape : {
      borderRadius : 4
    },
    typography : {
        fontFamily : 'Poppins, Helvetica, Arial, sans-serif',
        h2 : {
            fontWeight: 500,
            fontSize: '1.75rem'
        },
        h3 : {
            fontWeight: 500,
            fontSize: '2.87rem' //change this
        },
        h4 : {
            fontWeight: 500,
            fontSize: '18px'
        },
        h5 : {
            fontWeight: 500,
            fontSize: '1.2rem'
        },
        h6 : {
            fontWeight: 500,
            fontSize: `1.87rem`
        },
        subtitle1 : {
            fontWeight: 400,
            fontSize: `1.87rem`
        },
        subtitle2 : {
            fontWeight: 400,
            fontSize: `0.75rem`,
            color: '#81909D' //e.g, For those small headings

        },
         body1 : {
            fontWeight: 400,
            fontSize: `1rem` //16
        },
        body2 : {
            fontWeight: 500,
            fontSize: `1rem`
        },
      
    }

})
// console.log(eCTheme);
eCTheme.overrides.MuiDropzoneArea = {
    root: {
        minHeight: "4rem",
        padding: "0 1rem",
        '& p': {
            margin: "0.2rem",
            fontSize: "0.8rem"
        },
    },
    icon: {
        width: "20px",
        height: "20px"
    },
}

eCTheme.overrides.MuiDropzonePreviewList = {
    image: {
        height: "25px"
    }
}
export { eCBlue, eCGreen, eCPink, eCWhite, eCLight, eCDark, eCTheme }