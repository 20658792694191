import React from 'react'
import { Box, Typography } from '@material-ui/core'
import OrganizationLayout from '../../Components/Layout/AdminLayouts/OrganizationLayout'

const AdminOrganizations = () => {
    return (
        <div>
            <Box marginBottom="2rem">
                <Typography variant="h3">Organizations</Typography>
            </Box>
            <OrganizationLayout/>
        </div>
    )
}

export default AdminOrganizations
