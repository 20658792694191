import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth : `100%`,
        margin : theme.spacing(1),
    },
    hidden: {
        display: 'none'
    }
}));

export { useStyles }