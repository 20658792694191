import React, { useContext } from "react";
import {
  Typography,
  Box,
  FormControl,
  Grid,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import { Formik, Form, ErrorMessage } from "formik";
import { useTheme } from "@material-ui/styles";
import FormErrors from "../../../FormErrors";
import * as Yup from "yup";
import Button from "../../../Button";
import Paper from "../../../Paper";
import { useStyles } from "./style";
import UserContext from "../../../../Contexts/users/userContext";

const ProfileLayout = () => {
  const theme = useTheme();
  const classes = useStyles();

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { isSubmitting, updateAdminUser } = useContext(UserContext);


  const {
    user: { id, firstName, lastName, email, phoneNumber },
  } = JSON.parse(localStorage.auth);

  const initialValues = {
    firstName,
    lastName,
    email,
    phoneNumber,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
    .test({
      name: 'validator-custom-name',
      test: function(value) {
        return /\d/.test(value) ?
        this.createError({
          message: "Invalid name",
          path: 'firstName'
        }) : true
      }
    })
    .min(3, 'Not less than 3 characters')
    .required("First name is empty"),
    lastName: Yup.string()
    .test({
      name: 'validator-custom-name',
      test: function(value) {
        return /\d/.test(value) ?
        this.createError({
          message: "Invalid name",
          path: 'lastName'
        }) : true
      }
    })
  });

  const onSubmit = (values) => {
    const { firstName, lastName, email, phoneNumber } = values
    values = {
      id: id,
      firstName: firstName.charAt(0).toUpperCase() + firstName.slice(1),
      lastName: lastName.charAt(0).toUpperCase() + lastName.slice(1),
      userName: email,
      email,
      phoneNumber,
    }
    updateAdminUser(values)
  };

  return (
    <Box className={classes.profileForm}>
      <Typography variant="h3" gutterBottom>
        Profile
      </Typography>
      <Paper padding={"2rem"} borderRadius={"1.5rem"}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({ getFieldProps }) => (
            <Form noValidate autoComplete="off">
              <Grid container spacing={isMobile ? 1 : 3}>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <Typography>First Name</Typography>
                    <TextField
                      name="firsName"
                      id="firstName"
                      {...getFieldProps("firstName")}
                      variant="outlined"
                      size="small"
                    />
                    <ErrorMessage name="firstName" component={FormErrors} />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <Typography>Surname</Typography>
                    <TextField
                      name="lastName"
                      id="lastName"
                      {...getFieldProps("lastName")}
                      variant="outlined"
                      size="small"
                    />
                    <ErrorMessage name="lastName" component={FormErrors} />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <Typography>Email</Typography>
                    <TextField
                      name="email"
                      id="email"
                      disabled
                      {...getFieldProps("email")}
                      variant="outlined"
                      size="small"
                    />
                    <ErrorMessage name="email" component={FormErrors} />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <Typography>Phone Number</Typography>
                    <TextField
                      name="phoneNumber"
                      disabled
                      id="phoneNumber"
                      {...getFieldProps("phoneNumber")}
                      variant="outlined"
                      size="small"
                    />
                    <ErrorMessage name="phoneNumber" component={FormErrors} />
                  </FormControl>
                </Grid>
              </Grid>

              <Box marginTop={5} align="right">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  padding={"0.7rem 1.5rem"}
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default ProfileLayout;
