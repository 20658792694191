import React from "react";
import { Box } from "@material-ui/core"
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";
import { useStyles } from "./style"

const Pagination = ({ dataSize, perPage, handlePageClick, forcePage }) => {

    const classes = useStyles()

  const onPageChange = prop => {
     handlePageClick(prop.selected + 1)
  }


  const pageCount = Math.ceil(dataSize / perPage);

  return (
    <Box align="right" className={classes.root}>
        <>
      <ReactPaginate
        previousLabel={"Prev"}
        // pageRangeDisplayed={6}
        nextLabel={"Next"}
        pageCount={pageCount}
        onPageChange={onPageChange}
        containerClassName={classes.container}
        previousLinkClassName={classes.paginationLink}
        nextLinkClassName={classes.paginationLink}
        disabledClassName={classes.disabled}
        activeClassName={classes.active}
        forcePage={forcePage-1}
      />
      </>
    </Box>
  );
}

Pagination.propTypes = {
    dataSize: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    handlePageClick: PropTypes.func.isRequired,
    forcePage: PropTypes.number.isRequired,
}

export default Pagination
