import React from 'react'
import PropTypes from 'prop-types'
import { Paper as MuiPaper } from '@material-ui/core'
import { useStyles } from './styles'

const Paper = props => {
    const classes = useStyles(props);

    return (
        <MuiPaper className={classes.root}>
            { props.children }
        </MuiPaper>
    )
}

Paper.propTypes = {
    width: PropTypes.string,
    padding: PropTypes.string
}

export default Paper