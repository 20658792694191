import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: props => props.marginBottom ? props.marginBottom : `5rem`,
    },
    logo: {
        width: `100%`,
    },
}));

export { useStyles }