import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { useStyles } from './style'
import Sidebar from './Sidebar'
import { useMediaQuery, Hidden } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import Topbar from './Topbar';



const AppLayout = (props) => {
    const { children } = props
    const classes = useStyles(props)

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true
    });

    const [openSidebar, setOpenSidebar] = useState(false);

    const handleSidebarOpen = () => {
      setOpenSidebar(true);
    };
  
    const handleSidebarClose = () => {
      setOpenSidebar(false);
    };
  
    const shouldOpenSidebar = isDesktop ? true : openSidebar;

    
    return (
        <div>

            <Hidden >
                <Topbar 
                onSidebarOpen={handleSidebarOpen} 
                />
            </Hidden>

            <Sidebar 
            onClose={handleSidebarClose}
            open={shouldOpenSidebar}
            variant={isDesktop ? 'persistent' : 'temporary'}
            />

            <div className={classes.root}>
                {/* <Grid spacing={4}>
                    <Grid 
                      item 
                      lg={11} 
                      md={12} 
                      xl={11} 
                      xs={12}
                      > */}

                        {children}

                    {/* </Grid>
                </Grid> */}
            </div> 
        </div>
    )
}

AppLayout.propTypes = {
    children: PropTypes.node,
};
export default AppLayout
