import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  MenuItem,
  IconButton,
  FormControl,
  Select,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";

import Paper from "../../../Paper";
import Dialog from "../../../Reusables/Dialog";
import Button from "../../../Button";
import Table from "../../../Reusables/Table";
import Pagination from "../../../Reusables/Pagination";
import StatusIndicator from "../../../Reusables/StatusIndicator";
import TableHead from "../../../Reusables/TableHead";

import alertContext from "../../../../Contexts/alert/alertContext";
import loanContext from "../../../../Contexts/loan/loanContext";

import { useStyles } from "./style";
import { formatDateTime, validateDates } from "../../../../Actions/helpers";
import AxiosRequest from "../../../../Services/AxiosRequests";

const StoresLayout = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [store, setStore] = useState(null);
  const [stores, setStores] = useState({});

  const { setAlert } = useContext(alertContext);
  const { makeFilterRequest } = useContext(loanContext);

  const initialState = {
    StoreName: "",
    Type: "",
    startDate: "",
    endDate: "",
    pageSize: 10,
    pageNumber: 1,
  };

  const [payload, setPayload] = useState({ ...initialState });
  const {
    pageSize,
    pageNumber,
    startDate,
    endDate,
    StoreName,
    Type,
  } = payload;

  const _isMounted = useRef(true)

  useEffect(() => {
    if(_isMounted.current === false){
      _isMounted.current = true
    }

    getStores();

    return () => _isMounted.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageNumber]);

  ////////////////////////**********MODAL ACTIONS**********///////////////////

  const handleClose = () => {
    setOpen(false);
    store !== null && setStore(null);
  };
  ////////////////////////**********MODAL ACTIONS**********///////////////////

  ///////////////////////***********PARTNER CREATE ACTIONS***********//////////////

  const [anchorEl, setAnchorEl] = useState(null);
  const anchorOpen = Boolean(anchorEl);

  const handleMenuOpen = (e, store) => {
    setAnchorEl(e.currentTarget);
    console.log(store);
    setStore(store);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /////////////////**********TABLE ACTIONS *************////////////////
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    ///always reset pageNumber if pageSize or name changes to avoid wrong data fetch
    if (name === "pageSize" || name === "StoreName") {
      payload.pageNumber = 1;
    }
    setPayload({ ...payload, [name]: value });
  };

  const handlePageClick = (page) => {
    setPayload({ ...payload, pageNumber: page });
  };

  const handleStoreNameSearch = (e) => {
    setPayload({ ...payload, StoreName: e.target.value });
    loadWithDebounce(e.target.value);
  };

  let url = `Stores/?pageSize=${pageSize}&page=${pageNumber}`;
  let filter = false;

  const clearFilters = async () => {
    //make the request without filters, taking into consideration the state of the name field
    setPayload({ ...initialState, StoreName });
    filter = true;
    if (StoreName !== "") {
      url += `&StoreName=${StoreName}`;
    }
    await getStores(url, filter);
  };

  const getStoresFromFirstPage = () => {
    if (pageNumber !== 1) {
      setPayload({ ...payload, pageNumber: 1 });
    }
    pageNumber === 1 && getStores();
  };

  const getStores = async (
    url = `Stores?pageSize=${pageSize}&page=${pageNumber}`,
    clearFilter
  ) => {
    if (!clearFilter) {
      if (StoreName !== "") {
        url += `&StoreName=${StoreName}`;
      }

      if (!validateDates(startDate, endDate)) {
        setAlert("Please enter valid dates", "error");
        return;
      } else if (startDate !== "" && endDate !== "") {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }

      if (Type !== "") {
        url += `&Type=${Type}`;
      }
    }

    setLoading(true);
    try {
      const res = await makeFilterRequest(url);
      console.log("Stores response: ", res.data.responseData);
      if (_isMounted.current) {
        setStores(res.data.responseData);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
    open && handleClose();
    filter = false;
  };

  const loadWithDebounce = useCallback(
    debounce((StoreName) => {
      if (StoreName === "") {
        getStores(url);
      } else {
        getStores(`${url}&StoreName=${StoreName}`);
      }
    }, 1000),
    []
  );

  const deleteStore = async () => {
    if(window.confirm("Are you sure you want to delete this store?")) {
      try {
        setLoading(true);
        const res = await AxiosRequest( "delete", `Stores/delete_store/${store.id}`,);
        if (res.data.requestSuccessful) {
          let action = "deleted";
          setAlert(`Store ${action} successfully`, "success");
          getStores()
          handleClose();
        } else {
          setAlert(res.data.message, "error");
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  const columns = [
    {
      Header: "Store Name",
      accessor: "storeName",
    },
    {
      Header: "Store Type",
      accessor: "storeType",
    },
    {
      Header: "First Name",
      accessor: "firstName",
    },
    {
      Header: "Last Name",
      accessor: "lastName",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Company Name",
      accessor: "companyRegisteredName",
    },
    {
      Header: "Contact Number",
      accessor: "contactPersonPhoneNumber",
    },
    {
      Header: "Status",
      accessor: "isActive",
      Cell: (props) => <span>{props.value === true ? "Active" : "Not Active"}</span>,
    },

    {
      Header: " ",
      Cell: (props) => {
        return (
          <>
            <IconButton
              aria-label="more"
              aria-haspopup="true"
              aria-controls={props.row.original.id}
              onClick={(e) => handleMenuOpen(e, props.row.original)}
            >
              <MoreVertIcon color="primary" style={{ cursor: "pointer" }} />
            </IconButton>
          </>
        );
      },
    },
  ];

  /////////////////**********TABLE ACTIONS *****************////////////////

  /////////////////**********MODAL VIEWS *****************////////////////
  const filterForm = () => (
    <>
      <Grid container spacing={2} style={{ marginTop: "1rem" }}>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <label htmlFor="startDate" className={classes.label}>
              Start Date
            </label>
            <TextField
              name="startDate"
              variant="outlined"
              size="small"
              type="date"
              value={startDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <label htmlFor="endDate" className={classes.label}>
              End Date
            </label>
            <TextField
              name="endDate"
              variant="outlined"
              size="small"
              type="date"
              value={endDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}
            variant="outlined"
            size="small"
          >
            <label htmlFor="Type" className={classes.label}>
              Store Type
            </label>
            <Select
              name="Type"
              value={Type}
              onChange={handleInputChange}
            >
              <MenuItem value={"INDIVIDUAL"}>INDIVIDUAL</MenuItem>
              <MenuItem value={"COMPANY"}>COMPANY</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box className={classes.filterActions}>
        <Typography
          style={{ color: "red", cursor: "pointer" }}
          onClick={clearFilters}
        >
          Clear all filters
        </Typography>
        <Button
          variant="contained"
          size="medium"
          disabled={
            loading || (startDate === "" && endDate === "" && Type === "")
          }
          loading={loading}
          // type="submit"
          color="primary"
          onClick={() => getStoresFromFirstPage()}
        >
          Apply
        </Button>
      </Box>
    </>
  );

  const dialogContent = () => (
    <Box style={{ width: "100%" }} className={classes.paper}>
      <Box className={classes.dialogTitle}>
        <Typography variant="h4">Filter</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className={classes.dialogContent}>
        <hr />
        <Box marginTop={"1rem"}>{filterForm()}</Box>
      </Box>
    </Box>
  );

  return (
    <Box>
      <Box className={classes.headerTexts}>
        <Typography variant="h4">Store Management</Typography>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          linkPage="/store/create"
          to="/store/create"
        >
          Add store
        </Button>
      </Box>
      <Box style={{ border: "1px solid #D7DCE0" }} marginTop={"1rem"}>
        <Paper borderRadius={"0"} padding={"2rem"}>
          <TableHead
            title="Total Stores"
            searchItem={StoreName}
            onChange={handleStoreNameSearch}
            loading={loading}
            placeholder="Search By Store name"
            totalSize={stores?.totalSize}
            onClick={setOpen}
            handleInputChange={handleInputChange}
          />
          <Box marginTop={"2rem"}>
            {loading && !stores.items ? (
              <p>Loading...</p>
            ) : (!loading && stores.items) || (loading && stores.items) ? (
              <>
                {/* {console.log(users.items)} */}
                <Table
                  columns={columns}
                  data={stores.items}
                  loading={loading}
                />
                <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                  <Grid item xs={12} md={3}>
                    {stores.items.length > 0 && (
                      <Typography>
                        Showing page {stores.pageNumber} of{" "}
                        {Math.ceil(stores?.totalSize / pageSize)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Pagination
                      dataSize={stores?.totalSize}
                      perPage={pageSize}
                      handlePageClick={handlePageClick}
                      forcePage={stores.pageNumber}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <p>Loading...</p>
            )}
          </Box>
        </Paper>
      </Box>
      <>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={anchorOpen}
          onClick={handleMenuClose}
          PaperProps={{
            style: {
              width: "10ch",
            },
          }}
        >
          <Link
            style={{ color: "#000000DE", textDecoration: "none" }}
            to={{
              pathname: "/store/details",
              state: {
                data: store,
              },
            }}
          >
            <MenuItem>View</MenuItem>
          </Link>
          <Link
            style={{ color: "#000000DE", textDecoration: "none" }}
            to={{
              pathname: "/store/create",
              state: {
                data: store,
              },
            }}
          >
            <MenuItem>Edit</MenuItem>
          </Link>
          <MenuItem onClick={deleteStore}>Delete</MenuItem>
        </Menu>
      </>
      <Dialog handleClose={handleClose} open={open} content={dialogContent()} />
    </Box>
  );
};

export default StoresLayout;
