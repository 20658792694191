import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        
    },
    container: {
        maxHeight: '60rem',
        [theme.breakpoints.down('md')]: {
            maxHeight: '42rem',
        }
    },
}));

export { useStyles }