import React, { useReducer, useContext } from "react";
import AlertContext from "../alert/alertContext";
import UserContext from "./userContext";
import userReducer from "./userReducer";
import Cookies from "js-cookie";
import AxiosRequest from "../../Services/AxiosRequests";
import axiosInstance from "../../Services/axiosInstance";
import { useHistory } from "react-router-dom";

import {
  SET_LOADING,
  SET_SUBMITTING,
  LOGIN_SUCCESS,
  AUTH_SUCCESS,
  AUTHENTICATING_USER,
  GET_ROLES_SUCCESS,
  AUTH_ERROR,
  ACTION_ERROR,
} from "../types";

const UserState = (props) => {
  const initialState = {
    user: null,
    roles: {},
    isAuthenticated: false,
    isSubmitting: false,
    authenticating: false,
    loading: false,
  };

  const { setAlert } = useContext(AlertContext);

  const [state, dispatch] = useReducer(userReducer, initialState);
  const errMsg = "An error occured, please try again";

  const history = useHistory();

  const Login = async (values) => {
    try {
      if(axiosInstance.defaults.headers.Authorization){
        axiosInstance.defaults.headers.Authorization = undefined //delete the token
      }
      const res = await AxiosRequest("post", "Account/login", values);
      console.log("The response is: ", res);
      if (res.data.requestSuccessful === true) {
        console.log("The request was successful");
        const { user, token, expires, isUser } = res.data.responseData;
        if(isUser === false){
          //5 minutes allowance
          const expiryTime = new Date(
            new Date().getTime() + (Number(expires) - 300) * 1000
          );

          Cookies.set("accessToken", token, {
            sameSite: "strict",
            // expires: expiryTime,
          });

          let valid = new Date(); //token issue time

          // 5 minutes allowance
          const validMinutes = Number(expires / 60 - 5);
          // const validMinutes = 1
          valid.setMinutes(valid.getMinutes() + validMinutes); //expiry time
        
          axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
          localStorage.setItem("email", user.email);
          localStorage.setItem("valid", valid);

          dispatch({
            type: LOGIN_SUCCESS,
            payload: user,
          });

          history.push("/dashboard");
        } else {
          setAlert("Login Unsuccessful", "error")
        }
      } else {
        setAlert(res.data.message, "error");
      }
    } catch (error) {
      setAlert( error.response?.data?.message || errMsg, "error");
      console.log("Error occured", error);
    }
  };

  //load a user
  const loadUser = async () => {
    //fix to prevent the spinner when the user is alreaady logged out
    if(history.location.pathname !== "/adminlogin"){
      dispatch({
        type: AUTHENTICATING_USER,
      });
    }
    try {
      const res = await AxiosRequest(
        "get",
        `User/GetUserByEmail/${localStorage.email}`
      );
      if (res?.data?.requestSuccessful) {
        dispatch({
          type: AUTH_SUCCESS,
          payload: res.data.responseData,
        });
      } else {
        dispatch({ type: AUTH_ERROR });
      }
    } catch (err) {
      //consider logging the user out here
      throw err;
    }
  };


  const getRolePermissions = async () => {
    try {
      const rolePermissions = await AxiosRequest("get", "ModulePermission/loadpermission")
      return rolePermissions
    } catch (err) {
      throw err
    }
  }


  const getUsersData = async (url) => {
    try {
      const usersData = await AxiosRequest("get", url);
      return usersData;
    } catch (error) {
      throw error;
    }
  };

  const resendConfrimationLink = async (userId) => {
    try {
      const resendLink = await AxiosRequest(
        "get",
        `User/ResendEmailConfirmation/${userId}`
      );
      return resendLink;
    } catch (error) {
      throw error;
    }
  };

  // const getAdminUsersData = async (url) => {
  //   try {
  //     const usersData = await AxiosRequest("get", url);
  //     return usersData;
  //   } catch (error) {
  //     throw error;
  //   }
  // };

  const getAdminRoles = async (url) => {
    setLoading()
    const methodUrl = url ? url : "Roles"
    try {
      const res = await AxiosRequest("get", methodUrl);
      if (res.data.responseCode === "Ok") {
        dispatch({
          type: GET_ROLES_SUCCESS,
          payload: res.data.responseData,
        });
        return res.data.responseData;
      } else {
        dispatch({
          type: ACTION_ERROR,
        });
      }
    } catch (err) {
    throw err
    }
  };
  
  //create or update role
  const createAdminRole = async payload => {
    try {
      let method = "post";
      let url = "Roles/create";
      if(payload.id){
        method = "post";
        url = `Roles/update`;
      }
      const res = await AxiosRequest(method, url, payload);
      return res
    } catch (err) {
      throw err
    }
  }


  //create or update an admin user
  const createAdmin = async payload => {
    try {
      let method = "post";
      let url = "User/Create";
      if(payload.id){
        method = "put";
        url = "User/Update";
      }
      const res = await AxiosRequest(method, url, payload);
      return res
    } catch (err) {
      throw err
    }
  }

  //delete admin role
  const deleteRole = async payload => {
    console.log(payload)
    try {
      let method = "delete";
      let url = `Roles/${payload.id}`;
      const res = await AxiosRequest(method, url, payload);
      return res
    } catch (err) {
      throw err
    }
  }

  //create or update faq
  const createFaq = async payload => {
    try {
      let method = "post";
      let url = "FAQ/create";
      if(payload.id){
        method = "put";
        url = `FAQ/${payload.id}`;
      }
      const res = await AxiosRequest(method, url, payload);
      if(payload.enable) {
       const approveUrl = `FAQ/approve_faq/${res.data.responseData.id}`
       await AxiosRequest("post", approveUrl);
      } else {
        const disapproveUrl = `FAQ/disapprove_faq/${res.data.responseData.id}`
       await AxiosRequest("post", disapproveUrl);
      }
      return res
    } catch (err) {
      throw err
    }
  }

  //delete faq
  const deleteFaq = async payload => {
    console.log(payload)
    try {
      let method = "delete";
      let url = `FAQ/${payload.id}`;
      const res = await AxiosRequest(method, url, payload);
      return res
    } catch (err) {
      throw err
    }
  }

  //get modules permissions
  const getModulesPermissions = async () => {
    try {
      const modulesData = await AxiosRequest("get", "ModulePermission/loadpermission");
      return modulesData.data.responseData;
    } catch (error) {
      throw error;
    }
  };


  //get faqs
  const getFaqs = async (url) => {
    try {
      const faqsData = await AxiosRequest("get", url);
      return faqsData;
    } catch (error) {
      throw error;
    }
  };


    //Activate user's email
    const activateUserAccount = async payload => {
      try {
        const res = await AxiosRequest("post", "Account/verifyaccount", payload)
        if (res.data.requestSuccessful) {
          history.push("/adminlogin")
          setAlert("Email confirmed successfully, login to continue", "success")
        } else {
          setAlert(res.data.message, "error")
        }
      } catch (err) {
        actionError(err)
      }
    }

    //Request to reset a user's password
  const forgotPassword = async ({ email }, resetForm, setSubmitting) => {
    try {
      const res = await AxiosRequest(
        "get",
        `User/Admin/ForgotPassword?email=${email}`,
      )
      if (res.data.requestSuccessful) {
        setAlert("Please follow the link sent to your email to reset your password", "success")
        resetForm()
      }else {
        setAlert(res.data.message, "error")
        setSubmitting(false)
      } 
    } catch (err) {
      actionError(err)
  }
}


  //Reset a user's passwprd
  const resetPassword = async (payload, setSubmitting) => {
    try {
      const res = await AxiosRequest(
        "post",
        "User/Admin/ResetPassword",
        payload
      )
      if (res.data.requestSuccessful) {
        history.push("/adminlogin")
        setAlert("Password reset successful, Login to continue", "success")
      } else {
        setAlert(res.data.message, "error")
      }
    } catch (err) {
      console.log(err.response.status)
      if (err.response.status === 400){
        setAlert("Reset password request failed", "error")
      }
      setSubmitting(false)
      console.log(err)
  }
}



  const actionError = (err) => {
    // setAlert(errMsg, "error");
    console.log(err);
    dispatch({ type: ACTION_ERROR });
  };

  // set submitting
  const setIsSubmitting = () => dispatch({ type: SET_SUBMITTING });

  //set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <UserContext.Provider
      value={{
        ...state,
        Login,
        loadUser,
        getRolePermissions,
        resendConfrimationLink,
        getUsersData,
        getAdminRoles,
        createAdmin,
        deleteRole,
        activateUserAccount,
        forgotPassword,
        resetPassword,
        getFaqs,
        createFaq,
        deleteFaq,
        createAdminRole,
        getModulesPermissions,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;