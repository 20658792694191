import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Spinner from "../../../Reusables/Spinner";
import VerifyStudent from "./VerifyStudent";
import ApplicationInformation from "./ApplicationInformation";
import {
  Box,
} from "@material-ui/core";
import PropTypes from "prop-types";

import LoanContext from "../../../../Contexts/loan/loanContext";
import CredpalContext from "../../../../Contexts/credpal/credpalContext";

import { Redirect } from "react-router-dom";

const CredPalForm = ({ providerInfo }) => {
  const location = useLocation();
  const { activeStep, getLoanByRef, gettingLoan } = useContext(CredpalContext);
  const { redirectUrl, getStates } = useContext(LoanContext)

  const link = redirectUrl ? `/application/success?redirect=${redirectUrl}` : '/application/success'


  const { loanRef, userId} = queryString.parse(location.search)

  useEffect(() => {
    loanRef && getLoanByRef(loanRef, userId)
    getStates();

    //eslint-disable-next-line
  }, [])

  const getActiveStepContent = ( index ) => {
    switch (index) {
      case 0:
        return <VerifyStudent />;

      case 1:
        case 2:
          case 3:
            case 4:
              case 5:
        return (
          <ApplicationInformation // providerInfo={providerInfo}
          />
        );

        case 6:
          return (
            <Redirect to={link} />
          )

      default:
        return <Spinner />;
    }
  };

  return (
  
    <Box>
      {
        gettingLoan ? <Spinner /> : getActiveStepContent(activeStep)
      }
    </Box>
  );
};

export default CredPalForm;

CredPalForm.propTypes = {
  // providerInfo: PropTypes.object.isRequired,
}

// loading ? <Spinner /> : getActiveStepContent(activeStep)
