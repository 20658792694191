import React, { useState, useEffect, useContext } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useTheme } from "@material-ui/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Paper from "../../../../Paper";
import Spinner from "../../../../Reusables/Spinner";
import {
  Box,
  Typography,
  useMediaQuery,
  FormControl,
  Select,
  Grid,
  MenuItem,
} from "@material-ui/core";
import LoanContext from "../../../../../Contexts/loan/loanContext";

import { useStyles } from "./style";

const LoanChart = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(1);
  const [data, setData] = useState([]);

  const { getGraphReport } = useContext(LoanContext);
  let isMounted = true

  useEffect(() => {
    getGraphReportData();
    
    return (() => isMounted = false)
  }, [value]);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const getGraphReportData = async () => {
    setLoading(true);
    try {
      const res = await getGraphReport(value);
      const { responseData, requestSuccessful } = res.data;
      if(isMounted){
        if (requestSuccessful === true) {
          setData(responseData);
        }
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Paper borderRadius={"0.4rem"} padding={"0.4rem 0"}>
      <Box className={classes.loanChart}>
        {loading && !data.length ? (
          <Box padding={"0 2rem"}>
            <Skeleton variant="text" width="100%" height={"15rem"} />
          </Box>
        ) : (
          <>
            <Grid container spacing={2} className={classes.graphActions}>
              <Grid item xs={12} sm={9}>
                <Box style={{ display: "flex" }}>
                  <Typography variant="h5" gutterBottom>
                    Applications Summary
                  </Typography>
                  {loading && (
                    <Box className={classes.loader}>
                      <Spinner size={20} height={"2rem"} />
                    </Box>
                  )}
                </Box>

                <Box
                  style={{ display: "flex", alignItems: "center", gap: "2rem" }}
                >
                  <Box
                  className={classes.header}
                  >
                    <Box
                      className={classes.colorIndicator}
                      style={{ backgroundColor: "#F08829" }}
                    ></Box>
                    {" "}
                    <Typography variant="caption">
                      Signed-Up Applicants
                    </Typography>
                  </Box>

                  <Box
                   className={classes.header}
                  >
                    <Box
                      className={classes.colorIndicator}
                      style={{ backgroundColor: "#8AAEF4" }}
                    ></Box>
                    <Typography variant="caption">
                      Successful Applications
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl
                  variant="outlined"
                  style={{ width: "90%" }}
                  size="small"
                >
                  <label htmlFor="sort" className={classes.label}>Sort By</label>
                  <Select
                    defaultValue=""
                    labelId="sortBy"
                    id="SortBy"
                    name="SortBy"
                    value={value}
                    // label="Sort By"
                    onChange={(e) => setValue(e.target.value)}
                  >
                    <MenuItem value={1}>Weekly</MenuItem>
                    <MenuItem value={2}>Monthly</MenuItem>
                    <MenuItem value={3}>Quaterly</MenuItem>
                    <MenuItem value={4}>Yearly</MenuItem>
                    {/* {sortOptions.map((option, key) => (
                      <MenuItem key={key} value={option}>
                        {option}
                      </MenuItem>
                    ))} */}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <ResponsiveContainer height="65%">
              {/* {console.log(data)} */}
              <BarChart
                width={600}
                height={100}
                data={data}
                barSize={18}
                // barGap={2}
                // barCategoryGap="20%"
                margin={{
                  //   top: 100,
                  right: isMobile ? 30 : 60,
                  left: isMobile ? -20 : 0,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="data" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="user" fill="#F08829" />
                <Bar dataKey="loan" fill="#8AAEF4" />
              </BarChart>
            </ResponsiveContainer>
            <Box align="center">
              <Typography variant="body2" style={{ color: "#3A434B" }}>
                Date
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Paper>
  );
};

export default LoanChart;