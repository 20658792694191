import React, { useState, useEffect, useContext, useRef } from "react";
import { useStyles } from "./style";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import FormErrors from "../../../../FormErrors";
import Spinner from "../../../../Reusables/Spinner";
import * as Yup from "yup";
import MomentUtils from "@date-io/moment";
import Button from "../../../../Button";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  FormControl,
  Paper,
  Select,
  MenuItem,
  TextField,
  Container,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Box,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { DropzoneArea } from "material-ui-dropzone";

import CredpalContext from "../../../../../Contexts/credpal/credpalContext";
import LoanContext from "../../../../../Contexts/loan/loanContext";
import AlertContext from "../../../../../Contexts/alert/alertContext";

const CredPalForm = () => {
  const classes = useStyles();

  const credPalProps = useContext(CredpalContext);

  const {
    getLoanByRef,
    getBanks,
    currentLoan,
    gettingLoan,
    banks,
    updateLoanRequest,
  } = credPalProps;

  const { currentProvider, states, getStates, getCities } = useContext(LoanContext);

  const { setAlert } = useContext(AlertContext);

  const location = useLocation();
  const { userId, loanRef } = queryString.parse(location.search);

  const { allowReview, requestDocument } = currentProvider;
  const history = useHistory();

  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current === false) {
      isMounted.current = true;
    }
    getStates();
    !banks.length && getBanks();
    getLoanByRef(loanRef, userId);

    return () => (isMounted.current = false);
    //eslint-disable-next-line
  }, []);

  const [studentCities, setStudentCities] = useState([]);

  const [sponsorCities, setSponsorCities] = useState([]);

 const getSponsorCity = async (stateId) => {
    let cityFromStore = await getCities(stateId);
    setSponsorCities(cityFromStore);
  };

 const getStudentCity = async (stateId) => {
    let cityFromStore = await getCities(stateId);
    setStudentCities(cityFromStore);
  };

  useEffect(() => {
    (() => {
      if (states.length) {
        if (currentLoan?.city) {
          getStudentCity(
            states.find((state) => state.name === currentLoan.state)?.id
          );
        }

        if (currentLoan?.sponsorInfo) {
          getSponsorCity(
            states.find((state) => state.name === currentLoan.sponsorInfo.state)
              ?.id
          );
        }
      }
    })();

    //eslint-disable-next-line
  }, [states]);

  const {
    providerCode,
    regNo,
    availableLoanTypeId,
    firstName,
    lastName,
    address,
    state,
    city,
    dateOfBirth,
    gender,
    email,
    phoneNumber,
    identificationType,
    idNumber,
    isThirdParty,
    sponsorInfo,
    nextOfKin,
    guarantors,
    bankAccount,
    employment,
    loanRequest,
    institutionInfo,
    documents,
  } = currentLoan || {};

  console.log("The document value is: ", documents);

  const initialValues = {
    organizationCode: institutionInfo && institutionInfo.code,
    providerCode,
    availableLoanTypeId,
    regNo,
    firstName,
    lastName,
    address,
    state,
    city,
    dateOfBirth,
    gender,
    email,
    phoneNumber,
    identificationType,
    idNumber,
    isThirdParty,

    sponsorInfo: {
      firstName: sponsorInfo && sponsorInfo.firstName,
      lastName: sponsorInfo && sponsorInfo.lastName,
      gender: sponsorInfo && sponsorInfo.gender,
      dateOfBirth: sponsorInfo && sponsorInfo.dateOfBirth,
      phoneNumber: sponsorInfo && sponsorInfo.phoneNumber,
      email: sponsorInfo && sponsorInfo.email,
      address: sponsorInfo && sponsorInfo.address,
      state: sponsorInfo && sponsorInfo.state,
      city: sponsorInfo && sponsorInfo.city,
      bvn: sponsorInfo && sponsorInfo.bvn,
      NIN: sponsorInfo && sponsorInfo.nin,
      relationship: sponsorInfo && sponsorInfo.relationship,
    },

    nextOfKin: {
      firstName: nextOfKin && nextOfKin.firstName,
      lastName: nextOfKin && nextOfKin.lastName,
      phoneNumber: nextOfKin && nextOfKin.phoneNumber,
      address: nextOfKin && nextOfKin.address,
      relationship: nextOfKin && nextOfKin.relationship,
    },

    bankAccount: {
      bankId: bankAccount && bankAccount.bankId,
      accountNumber: bankAccount && bankAccount.accountNumber,
      accountName: bankAccount && bankAccount.accountName,
      bankCode: bankAccount && bankAccount.bankCode,
      bvn: bankAccount && bankAccount.bvn,
    },

    guarantors: !(guarantors && guarantors.length) ? [] : [
      {
        id: guarantors && guarantors[0].id,
        firstName: guarantors && guarantors[0].firstName,
        lastName: guarantors && guarantors[0].lastName,
        phoneNumber: guarantors && guarantors[0].phoneNumber,
        relationship: guarantors && guarantors[0].relationship,
      },
      {
        id: guarantors ? (guarantors[1] ? guarantors[1].id : "") : "",
        firstName: guarantors
          ? guarantors[1]
            ? guarantors[1].firstName
            : ""
          : "",
        lastName: guarantors
          ? guarantors[1]
            ? guarantors[1].lastName
            : ""
          : "",
        phoneNumber: guarantors
          ? guarantors[1]
            ? guarantors[1].phoneNumber
            : ""
          : "",
        relationship: guarantors
          ? guarantors[1]
            ? guarantors[1].relationship
            : ""
          : "",
      },
    ],

    employment: {
      employmentStatus: employment && employment.employmentStatus,
      employerName: employment && employment.employerName,
      employerAddress: employment && employment.employerAddress,
      workplaceEmail: employment && employment.workPlaceEmail,
      salaryAmount: employment && employment.salaryAmount,
      salaryDueDate: employment ? employment.salaryDueDate : new Date(),
    },

    loanRequest: {
      loanAmount: loanRequest && loanRequest.loanAmount,
      tenure: loanRequest && loanRequest.tenure,
      isSelfSponsored: loanRequest && loanRequest.isSelfSponsored,
      isAcceptedAgreement: true,
      isExecutedSponsorAgreement: true,
    },

    institutionInfo: {
      name: institutionInfo && institutionInfo.name,
      code: institutionInfo && institutionInfo.code,
      bankAccountNo: institutionInfo && institutionInfo.bankAccountNo,
      bankAccountName: institutionInfo && institutionInfo.bankAccountName,
      bankName: institutionInfo && institutionInfo.bankName,
      programDescription : institutionInfo && institutionInfo.programDescription
    },

    loanDocuments: {},
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .test({
        name: "validator-custom-name",
        //eslint-disable-next-line object-shorthand
        test: function (value) {
          return /\d/.test(value)
            ? this.createError({
                message: "Invalid name",
                path: "firstName",
              })
            : true;
        },
      })
      .min(3, "Not less than 3 characters")
      .required("First name is empty"),
    lastName: Yup.string()
      .test({
        name: "validator-custom-name",
        //eslint-disable-next-line object-shorthand
        test: function (value) {
          return /\d/.test(value)
            ? this.createError({
                message: "Invalid name",
                path: "lastName",
              })
            : true;
        },
      })
      .min(3, "Not less than 3 characters")
      .required("Surname is empty"),
    address: Yup.string()
      .min(5, "Not less than 5 characters")
      .required("Address is empty"),
    dateOfBirth: Yup.string()
      // .max(new Date() - 86400000, "Date must be in the past")
      // .max(new Date(Date.now() - 86400000), "Date must be in the past")
      .required("Date of birth is empty")
      .nullable(),
    email: Yup.string()
      .email("Invalid email format!")
      .required("Email is empty"),
    gender: Yup.string().required("Gender is empty"),
    phoneNumber: Yup.string()
      .matches(
        /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
        "Enter a valid phone number"
      )
      .min(9, "Minimum of 9 characters")
      .required("Phone Number is empty"),
    // identificationType: requestDocument && Yup.string().required("Identification Type is required"),
    state: Yup.string().required("Select state"),
    city: Yup.string().required("Select city"),
    // idNumber: requestDocument && Yup.string().required("ID number is empty"),

    sponsorInfo: Yup.object({
      firstName: Yup.string()
        .test({
          name: "validator-custom-name",
          //eslint-disable-next-line object-shorthand
          test: function (value) {
            return /\d/.test(value)
              ? this.createError({
                  message: "Invalid name",
                  path: "sponsorInfo.firstName",
                })
              : true;
          },
        })
        .min(3, "Not less than 3 characters")
        .required("First name is empty"),
      lastName: Yup.string()
        .test({
          name: "validator-custom-name",
          //eslint-disable-next-line object-shorthand
          test: function (value) {
            return /\d/.test(value)
              ? this.createError({
                  message: "Invalid name",
                  path: "sponsorInfo.lastName",
                })
              : true;
          },
        })
        .min(3, "Not less than 3 characters")
        .required("Surname is empty"),
      dateOfBirth: Yup.string().test(
        "dateOfBirth",
        "Applicant must be between 18 to 59 years",
        (value) => {
          return (
            moment().diff(moment(value), "years") >= 18 &&
            moment().diff(moment(value), "years") <= 59
          );
        }
      ),
      email: Yup.string()
        .email("Invalid email format!")
        .required("Email is empty"),
      gender: Yup.string().required("Gender is empty"),
      phoneNumber: Yup.string()
        .min(9, "Minimum of 9 characters")
        .matches(
          /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
          "Enter a valid phone number"
        )
        .required("Phone Number is empty"),
      address: Yup.string()
        .min(5, "Not less than 5 characters")
        .required("Address is empty"),
      state: Yup.string().required("Select state"),
      city: Yup.string().required("Select city"),
      NIN: Yup.string()
        .matches(/^[0-9]*$/, "Only numbers allowed")
        .min(11, "Has to be eleven digits")
        .max(11, "Has to be eleven digits")
        .required("NIN is empty"),
      bvn: Yup.string()
        .matches(/^[0-9]*$/, "Only numbers allowed")
        .min(11, "Has to be eleven digits")
        .max(11, "Has to be eleven digits")
        .required("BVN is empty"),
      relationship: Yup.string().required("Relationship is required"),
    }),

    nextOfKin: Yup.object({
      firstName: Yup.string()
        .test({
          name: "validator-custom-name",
          //eslint-disable-next-line object-shorthand
          test: function (value) {
            return /\d/.test(value)
              ? this.createError({
                  message: "Invalid name",
                  path: "sponsorInfo.firstName",
                })
              : true;
          },
        })
        .min(3, "Not less than 3 characters")
        .required("First name is empty"),
      lastName: Yup.string()
        .test({
          name: "validator-custom-name",
          //eslint-disable-next-line object-shorthand
          test: function (value) {
            return /\d/.test(value)
              ? this.createError({
                  message: "Invalid name",
                  path: "sponsorInfo.lastName",
                })
              : true;
          },
        })
        .min(3, "Not less than 3 characters")
        .required("Surname is empty"),
      phoneNumber: Yup.string()
        .min(9, "Minimum of 9 characters")
        .matches(
          /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
          "Enter a valid phone number"
        )
        .required("Phone Number is empty"),
      address: Yup.string()
        .min(5, "Not less than 5 characters")
        .required("Address is empty"),
      relationship: Yup.string().required("Relationship is required"),
    }),

    // guarantors: Yup.array().of(
    //   Yup.lazy((value) => {
    //     if (
    //       value.firstName ||
    //       value.lastName ||
    //       value.phoneNumber !== undefined
    //     ) {
    //       return Yup.object().shape({
    //         firstName: Yup.string()
    //           .test({
    //             name: "validator-custom-name",
    //             //eslint-disable-next-line object-shorthand
    //             test: function (value) {
    //               return /\d/.test(value)
    //                 ? this.createError({
    //                     message: "Invalid name",
    //                     // path: `${value}.firstName`
    //                     path: this.path,
    //                   })
    //                 : true;
    //             },
    //           })
    //           .min(3, "Minimum of 3 characters")
    //           .required("First name is empty"),
    //         lastName: Yup.string()
    //           .test({
    //             name: "validator-custom-name",
    //             //eslint-disable-next-line object-shorthand
    //             test: function (value) {
    //               return /\d/.test(value)
    //                 ? this.createError({
    //                     message: "Invalid name",
    //                     path: this.path,
    //                   })
    //                 : true;
    //             },
    //           })
    //           .min(3, "Minimum of 3 characters")
    //           .required("Surname is empty"),
    //         phoneNumber: Yup.string()
    //           .matches(
    //             /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
    //             "Enter a valid phone number"
    //           )
    //           .min(9, "Minimum of 9 characters")
    //           .required("Phone Number is empty"),
    //         relationship: Yup.string()
    //           .min(3, "Not less than 3 characters")
    //           .test({
    //             name: "validator-custom-name",
    //             //eslint-disable-next-line object-shorthand
    //             test: function (value) {
    //               return /\d/.test(value)
    //                 ? this.createError({
    //                     message: "Invalid relationship",
    //                     path: this.path,
    //                   })
    //                 : true;
    //             },
    //           })
    //           .required("Relationship is empty"),
    //       });
    //     }
    //     return Yup.mixed().notRequired();
    //   })
    // ),
    bankAccount: Yup.object({
      accountName: Yup.string().required("Account Name is empty"),
      bankCode: Yup.string().required("Choose a bank"),
      accountNumber: Yup.string()
        .matches(/^[0-9]*$/, "Only numbers allowed")
        .min(10, "Has to be ten digits")
        .max(10, "Has to be ten digits")
        .required("Account Number is empty"),
    }),

    employment: Yup.object({
      employmentStatus: Yup.string().required("Select Employment status"),
      employerName: Yup.string().required("Employer's name is empty"),
      employerAddress: Yup.string().required("Employer's address is empty"),
      workplaceEmail: Yup.string()
        .email("Invalid email format!")
        .required("Account Number is empty"),
      salaryAmount: Yup.number().required("Salary amount is empty"),
      salaryDueDate: Yup.string()
        //Disallow this validation, we don't know when admin is updating
        // .min(new Date(Date.now() - 86400000), "Date cannot be in the past")
        // .min(new Date(), "Date cannot be in the past")
        .required("Salary due date is empty")
        .nullable(),
    }),

    loanRequest: Yup.object({
      loanAmount: Yup.string().required("Request loan amount is empty"),
      tenure: Yup.number()
        .min(1, "Minimum Tenure is 1 month")
        .max(24, "Maximum Tenure is 24 Months")
        .required("Loan tenure is empty"),
    }),

    institutionInfo: Yup.object({
      name: Yup.string().required("institution Name is empty"),
      //   bankName: Yup.string().required("Select a Bank"),
      //   bankAccountName: Yup.string().required(
      //     "institution account name is empty"
      //   ),
      bankAccountNo: Yup.string()
        .matches(/^[0-9]*$/, "Only numbers allowed")
        .min(10, "Has to be ten digits")
        .max(10, "Has to be ten digits"),
      // .required("Account Number is empty"),
    }),
  });

  const updateLoan = (values) => {
    //format payload to match API
    let payload = { providerCode, requestData: { ...values } };
    payload = { userId, loanRef, ...payload };
    console.log(("The payload for update is: ", payload));

    //format guarantors
    if (
      (payload.requestData.guarantors.length &&
        payload.requestData.guarantors[0].firstName === "" &&
        payload.requestData.guarantors[1].firstName === "")
    ) {
      setAlert("Please provide at least one guarantor", "error");
      return;
    } else {
      for (let i = 0; i < payload.requestData.guarantors.length; i++) {
        if (payload.requestData.guarantors[i].firstName === "") {
          payload.requestData.guarantors.splice(i, 1);
        }
        //send Id together with each when running updates
        if (currentLoan.guarantors && currentLoan.guarantors.length > 0) {
          if (payload.requestData.guarantors[i]) {
            payload.requestData.guarantors[i].id =
              currentLoan.guarantors[i] && currentLoan.guarantors[i].id;
          }
        }
      }
    }

    //admin could change bank, set the bankId again
    payload.requestData.bankAccount.bankId = banks.find(
      (bank) => bank.bankCode === payload.requestData.bankAccount.bankCode
    ).id;

    //should in case bvn changes, reset in bank object too
    payload.requestData.bankAccount.bvn = payload.requestData.sponsorInfo.bvn;

    updateLoanRequest(payload);
    //   console.log(JSON.stringify(payload, null, 2))
  };

  //ACTUAL RENDER

  return gettingLoan || !currentProvider ? (
    <Spinner />
  ) : (
    <Container className={classes.root}>
      <Box className={classes.header}>
        <Box className={classes.goBack}>
          <Link
            to={`/loan?loanRef=${loanRef}&userId=${userId}`}
            style={{ textDecoration: "none", color: "#000000" }}
          >
            <KeyboardArrowLeftIcon size="small" onClick={history.goBack} />
          </Link>
        </Box>

        <Typography variant="h2" gutterBottom display="inline">
          Loan Details
        </Typography>
      </Box>
      <Paper style={{ padding: "2rem 2rem 3rem" }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={updateLoan}
        >
          {({
            values,
            errors,
            touched,
            getFieldProps,
            setFieldValue,
            setTouched,
          }) => (
            <Form noValidate autoComplete="off">
              <Box marginBottom="1.5rem">
                <Typography variant="h5" style={{ marginBottom: "1rem" }}>
                  Student's Personal Details
                </Typography>
                <div className={classes.fieldWrapper}>
                  <FormControl>
                    <label htmlFor="firstName" className={classes.label}>
                      First Name
                    </label>
                    <TextField
                      name="firstName"
                      id="firstName"
                      {...getFieldProps("firstName")}
                      variant="outlined"
                      error={
                        errors.firstName && touched.firstName ? true : false
                      }
                      size="small"
                    />
                    <ErrorMessage name="firstName" component={FormErrors} />
                  </FormControl>

                  <FormControl>
                    <label htmlFor="lastName" className={classes.label}>
                      Last Name
                    </label>
                    <TextField
                      name="lastName"
                      id="lastName"
                      {...getFieldProps("lastName")}
                      variant="outlined"
                      error={errors.lastName && touched.lastName ? true : false}
                      size="small"
                    />
                    <ErrorMessage name="lastName" component={FormErrors} />
                  </FormControl>

                  <FormControl component="fieldset">
                    <label htmlFor="gender" className={classes.label}>
                      Gender
                    </label>
                    <RadioGroup
                      aria-label="gender"
                      name="gender"
                      {...getFieldProps("gender")}
                      className={classes.grouping}
                    >
                      <FormControlLabel
                        value="M"
                        control={<Radio size="small" />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="F"
                        control={<Radio size="small" />}
                        label="Female"
                      />
                    </RadioGroup>
                    <ErrorMessage name="gender" component={FormErrors} />
                  </FormControl>

                  <FormControl>
                    <label htmlFor="dateOfBirth" className={classes.label}>
                      Date Of Birth
                    </label>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        inputVariant="outlined"
                        format="DD/MM/yyyy"
                        variant="outlined"
                        // margin="normal"
                        id="dateOfBirth"
                        name="dateOfBirth"
                        size="small"
                        value={values.dateOfBirth}
                        error={
                          errors.dateOfBirth && touched.dateOfBirth
                            ? true
                            : false
                        }
                        onChange={(value) => {
                          setFieldValue("dateOfBirth", value);
                        }}
                        helperText={""}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    <ErrorMessage name="dateOfBirth" component={FormErrors} />
                  </FormControl>

                  <FormControl>
                    <label htmlFor="email" className={classes.label}>
                      Email
                    </label>
                    <TextField
                      name="email"
                      id="email"
                      {...getFieldProps("email")}
                      variant="outlined"
                      error={errors.email && touched.email ? true : false}
                      size="small"
                    />
                  </FormControl>

                  <FormControl>
                    <label htmlFor="phoneNumber" className={classes.label}>
                      Phone Number
                    </label>
                    <TextField
                      name="phoneNumber"
                      id="phoneNumber"
                      type="tel"
                      {...getFieldProps("phoneNumber")}
                      // error={
                      //   errors.phoneNumber && touched.phoneNumber ? true : false
                      // }
                      variant="outlined"
                      size="small"
                    />
                    <ErrorMessage name="phoneNumber" component={FormErrors} />
                  </FormControl>
                  <FormControl>
                    <label htmlFor="address" className={classes.label}>
                      Residential Address
                    </label>
                    <TextField
                      name="address"
                      id="address"
                      {...getFieldProps("address")}
                      variant="outlined"
                      error={errors.address && touched.address ? true : false}
                      size="small"
                    />
                    <ErrorMessage name="address" component={FormErrors} />
                  </FormControl>

                  <FormControl variant="outlined" size="small">
                    <label className={classes.label}>State</label>
                    <Select
                      defaultValue=""
                      labelId="state"
                      id="state"
                      name="state"
                      {...getFieldProps("state")}
                      onChange={(e) => {
                        setFieldValue("city", "");
                        const val = e.target.value;
                        if (val !== "") {
                          setFieldValue("state", val);
                          setTouched({ ...touched, state: false, city: false });
                          getStudentCity(states.find((state) => state.name === val)?.id)
                        }
                      }}
                      value={values.state}
                    >
                      {!states.length ? (
                        <MenuItem value={""} defaultValue>
                          Loading States...
                        </MenuItem>
                      ) : (
                        states.map((state, key) => (
                          <MenuItem key={key} value={state.name}>
                            {state.name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                    <ErrorMessage name="state" component={FormErrors} />
                  </FormControl>
                  <FormControl variant="outlined" size="small">
                    <label className={classes.label}>City</label>
                    <Select
                      className={classes.select}
                      defaultValue=""
                      labelId="city"
                      disabled={values.state === ""}
                      id="city"
                      name="city"
                      {...getFieldProps("city")}
                    >
                      {!studentCities.length ? (
                        <MenuItem value={""} defaultValue>
                          Loading Cities...
                        </MenuItem>
                      ) : (
                        studentCities.map((city, key) => (
                          <MenuItem key={key} value={city.name}>
                            {city.name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                    <ErrorMessage name="city" component={FormErrors} />
                  </FormControl>

                  {requestDocument && (
                    <>
                      <FormControl variant="outlined" size="small">
                        <label
                          htmlFor="identificationType"
                          className={classes.label}
                        >
                          ID Type
                        </label>
                        <Select
                          labelId="id_label"
                          id="identificationType"
                          name="identificationType"
                          defaultValue=""
                          {...getFieldProps("identificationType")}
                          error={
                            errors.identificationType &&
                            touched.identificationType
                              ? true
                              : false
                          }
                        >
                          <MenuItem value={"International Passport"}>
                            International Passport
                          </MenuItem>
                          <MenuItem value={"Voter's Card"}>
                            Voter's Card
                          </MenuItem>
                          <MenuItem value={"NIM"}>National ID Card</MenuItem>
                          <MenuItem value={"Driver's License"}>
                            Driver's License
                          </MenuItem>
                        </Select>
                        <ErrorMessage
                          name="identificationType"
                          component={FormErrors}
                        />
                      </FormControl>

                      <FormControl>
                        <label htmlFor="idNumber" className={classes.label}>
                          ID Number
                        </label>
                        <TextField
                          name="idNumber"
                          id="idNumber"
                          {...getFieldProps("idNumber")}
                          variant="outlined"
                          error={
                            errors.idNumber && touched.idNumber ? true : false
                          }
                          size="small"
                        />
                        <ErrorMessage name="idNumber" component={FormErrors} />
                      </FormControl>
                    </>
                  )}
                </div>
                <div className={classes.section}>
                  <Typography variant="h5">
                    Sponsor's Personal Details
                  </Typography>

                  <div className={classes.fieldWrapper}>
                    <FormControl>
                      <label
                        htmlFor="sponsorInfo.firstName"
                        className={classes.label}
                      >
                        First Name
                      </label>
                      <TextField
                        name={"sponsorInfo.firstName"}
                        id="sponsorInfo.firstName"
                        {...getFieldProps("sponsorInfo.firstName")}
                        variant="outlined"
                        error={
                          errors.sponsorInfo?.firstName &&
                          touched.sponsorInfo?.firstName
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage
                        name="sponsorInfo.firstName"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="sponsorInfo.lastName"
                        className={classes.label}
                      >
                        Last Name
                      </label>
                      <TextField
                        name="sponsorInfo.lastName"
                        id="sponsorInfo.lastName"
                        {...getFieldProps("sponsorInfo.lastName")}
                        variant="outlined"
                        error={
                          errors.sponsorInfo?.lastName &&
                          touched.sponsorInfo?.lastName
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage
                        name="sponsorInfo.lastName"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl component="fieldset">
                      <label htmlFor="gender" className={classes.label}>
                        Gender
                      </label>
                      <RadioGroup
                        aria-label="sponsorInfo.gender"
                        name="sponsorInfo.gender"
                        {...getFieldProps("sponsorInfo.gender")}
                        className={classes.grouping}
                      >
                        <FormControlLabel
                          value="M"
                          control={<Radio size="small" />}
                          label="Male"
                        />
                        <FormControlLabel
                          value="F"
                          control={<Radio size="small" />}
                          label="Female"
                        />
                      </RadioGroup>
                      <ErrorMessage
                        name="sponsorInfo.gender"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label htmlFor="dateOfBirth" className={classes.label}>
                        Date Of Birth
                      </label>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          inputVariant="outlined"
                          format="DD/MM/yyyy"
                          variant="outlined"
                          // margin="normal"
                          id="sponsorInfo.dateOfBirth"
                          name="sponsorInfo.dateOfBirth"
                          size="small"
                          value={values.sponsorInfo?.dateOfBirth}
                          error={
                            errors.sponsorInfo?.dateOfBirth &&
                            touched.sponsorInfo?.dateOfBirth
                              ? true
                              : false
                          }
                          onChange={(value) => {
                            setFieldValue("sponsorInfo.dateOfBirth", value);
                          }}
                          helperText={""}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <ErrorMessage
                        name="sponsorInfo.dateOfBirth"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="sponsorInfo.email"
                        className={classes.label}
                      >
                        Email
                      </label>
                      <TextField
                        name="sponsorInfo.email"
                        id="sponsorInfo.email"
                        {...getFieldProps("sponsorInfo.email")}
                        variant="outlined"
                        error={
                          errors.sponsorInfo?.email &&
                          touched.sponsorInfo?.email
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage
                        name="sponsorInfo.email"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="sponsorInfo.phoneNumber"
                        className={classes.label}
                      >
                        Phone Number
                      </label>
                      <TextField
                        name="sponsorInfo.phoneNumber"
                        id="sponsorInfo.phoneNumber"
                        type="tel"
                        {...getFieldProps("sponsorInfo.phoneNumber")}
                        variant="outlined"
                        size="small"
                        error={
                          errors.sponsorInfo?.phoneNumber &&
                          touched.sponsorInfo?.phoneNumber
                            ? true
                            : false
                        }
                        className={classes.numberInput}
                      />
                      <ErrorMessage
                        name="sponsorInfo.phoneNumber"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="sponsorInfo.phoneNumber"
                        className={classes.label}
                      >
                        Relationship
                      </label>
                      <TextField
                        name="sponsorInfo.relationship"
                        id="sponsorInfo.relationship"
                        {...getFieldProps("sponsorInfo.relationship")}
                        variant="outlined"
                        error={
                          errors.sponsorInfo?.relationship &&
                          touched.sponsorInfo?.relationship
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage
                        name="sponsorInfo.relationship"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label htmlFor="address" className={classes.label}>
                        Residential Address
                      </label>
                      <TextField
                        name="sponsorInfo.address"
                        id="sponsorInfo.address"
                        {...getFieldProps("sponsorInfo.address")}
                        variant="outlined"
                        error={
                          errors.sponsorInfo?.address &&
                          touched.sponsorInfo?.address
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage
                        name="sponsorInfo.address"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl variant="outlined" size="small">
                      <label className={classes.label}>State</label>
                      <Select
                        defaultValue=""
                        labelId="sponsorInfo.state"
                        id="sponsorInfo.state"
                        name="sponsorInfo.state"
                        {...getFieldProps("sponsorInfo.state")}
                        onChange={(e) => {
                          setFieldValue("sponsorInfo.city", "");
                          const val = e.target.value;
                          if (val !== "") {
                            setFieldValue("sponsorInfo.state", val);
                            setTouched({
                              ...touched,
                              sponsorInfo: {
                                ...touched.sponsorInfo,
                                state: false,
                                city: false,
                              },
                            });
                            getSponsorCity(
                              states.find((state) => state.name === val)?.id
                            );
                          }
                        }}
                        value={values.sponsorInfo.state}
                      >
                        {!states.length ? (
                          <MenuItem value={""} defaultValue>
                            Loading States...
                          </MenuItem>
                        ) : (
                          states.map((state, key) => (
                            <MenuItem key={key} value={state.name}>
                              {state.name}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                      <ErrorMessage
                        name="sponsorInfo.state"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl variant="outlined" size="small">
                      <label className={classes.label}>City</label>
                      <Select
                        className={classes.select}
                        defaultValue=""
                        labelId="sponsorInfo.city"
                        disabled={values.sponsorInfo?.state === ""}
                        id="sponsorInfo.city"
                        name="sponsorInfo.city"
                        {...getFieldProps("sponsorInfo.city")}
                      >
                        {!sponsorCities.length ? (
                          <MenuItem value={null} defaultValue>
                            Loading Cities...
                          </MenuItem>
                        ) : (
                          sponsorCities.map((city, key) => (
                            <MenuItem key={key} value={city.name}>
                              {city.name}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                      <ErrorMessage
                        name="sponsorInfo.city"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="sponsorInfo.NIN"
                        className={classes.label}
                      >
                        NIN
                      </label>
                      <TextField
                        name="sponsorInfo.NIN"
                        id="sponsorInfo.NIN"
                        {...getFieldProps("sponsorInfo.NIN")}
                        variant="outlined"
                        error={
                          errors.sponsorInfo?.NIN && touched.sponsorInfo?.NIN
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage
                        name="sponsorInfo.NIN"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="sponsorInfo.bvn"
                        className={classes.label}
                      >
                        BVN
                      </label>
                      <TextField
                        name="sponsorInfo.bvn"
                        id="sponsorInfo.bvn"
                        {...getFieldProps("sponsorInfo.bvn")}
                        variant="outlined"
                        size="small"
                        error={
                          errors.sponsorInfo?.bvn && touched.sponsorInfo?.bvn
                            ? true
                            : false
                        }
                        type="text"
                        inputProps={{
                          type: "text",
                          pattern: "[0-9]*",
                          inputMode: "numeric",
                        }}
                        className={classes.numberInput}
                      />
                      <ErrorMessage
                        name="sponsorInfo.bvn"
                        component={FormErrors}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className={classes.section}>
                  <Typography variant="h5">
                    Next of Kin Details
                  </Typography>

                  <div className={classes.fieldWrapper}>
                    <FormControl>
                      <label
                        htmlFor="nextOfKin.firstName"
                        className={classes.label}
                      >
                        First Name
                      </label>
                      <TextField
                        name={"nextOfKin.firstName"}
                        id="nextOfKin.firstName"
                        {...getFieldProps("nextOfKin.firstName")}
                        variant="outlined"
                        error={
                          errors.nextOfKin?.firstName &&
                          touched.nextOfKin?.firstName
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage
                        name="nextOfKin.firstName"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="nextOfKin.lastName"
                        className={classes.label}
                      >
                        Last Name
                      </label>
                      <TextField
                        name="nextOfKin.lastName"
                        id="nextOfKin.lastName"
                        {...getFieldProps("nextOfKin.lastName")}
                        variant="outlined"
                        error={
                          errors.nextOfKin?.lastName &&
                          touched.nextOfKin?.lastName
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage
                        name="nextOfKin.lastName"
                        component={FormErrors}
                      />
                    </FormControl>
                    
                    <FormControl>
                      <label
                        htmlFor="nextOfKin.phoneNumber"
                        className={classes.label}
                      >
                        Phone Number
                      </label>
                      <TextField
                        name="nextOfKin.phoneNumber"
                        id="nextOfKin.phoneNumber"
                        type="tel"
                        {...getFieldProps("nextOfKin.phoneNumber")}
                        variant="outlined"
                        size="small"
                        error={
                          errors.nextOfKin?.phoneNumber &&
                          touched.nextOfKin?.phoneNumber
                            ? true
                            : false
                        }
                        className={classes.numberInput}
                      />
                      <ErrorMessage
                        name="nextOfKin.phoneNumber"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="nextOfKin.relationship"
                        className={classes.label}
                      >
                        Relationship
                      </label>
                      <TextField
                        name="nextOfKin.relationship"
                        id="nextOfKin.relationship"
                        {...getFieldProps("nextOfKin.relationship")}
                        variant="outlined"
                        error={
                          errors.nextOfKin?.relationship &&
                          touched.nextOfKin?.relationship
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage
                        name="nextOfKin.relationship"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label htmlFor="address" className={classes.label}>
                        Residential Address
                      </label>
                      <TextField
                        name="nextOfKin.address"
                        id="address"
                        {...getFieldProps("nextOfKin.address")}
                        variant="outlined"
                        error={
                          errors.nextOfKin?.address &&
                          touched.nextOfKin?.address
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage
                        name="nextOfKin.address"
                        component={FormErrors}
                      />
                    </FormControl>
                  </div>
                </div>
                {/* )} */}

                <Box className={classes.section}>
                  <Typography variant="h5">Guarantors' Details</Typography>

                  <Typography variant="body2" style={{ marginTop: "1rem" }}>
                    Guarantor 1
                  </Typography>
                  <div className={classes.fieldWrapper}>
                    <FormControl>
                      <label
                        htmlFor="guarantors[0].firstName"
                        className={classes.label}
                      >
                        First Name
                      </label>
                      <TextField
                        name="guarantors[0].firstName"
                        id="guarantors[0].firstName"
                        {...getFieldProps("guarantors[0].firstName")}
                        variant="outlined"
                        // error={errors.guarantors?[0].firstName && touched.guarantors?[0].firstName ? true : false}
                        size="small"
                      />
                      <ErrorMessage
                        name="guarantors[0].firstName"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="guarantors[0].lastName"
                        className={classes.label}
                      >
                        Last Name
                      </label>
                      <TextField
                        name="guarantors[0].lastName"
                        id="guarantors[0].lastName"
                        {...getFieldProps("guarantors[0].lastName")}
                        variant="outlined"
                        // error={errors.guarantors && errors.guarantors[0].lastName && touched.guarantors[0].lastName ? true : false}
                        size="small"
                      />
                      <ErrorMessage
                        name="guarantors[0].lastName"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="guarantors[0].phoneNumber"
                        className={classes.label}
                      >
                        Phone Number
                      </label>
                      <TextField
                        name="guarantors[0].phoneNumber"
                        id="guarantors[0].phoneNumber"
                        type="tel"
                        {...getFieldProps("guarantors[0].phoneNumber")}
                        variant="outlined"
                        size="small"
                        // error={errors.guarantors?[0].phoneNumber && touched.guarantors?[0].phoneNumber ? true : false}
                        className={classes.numberInput}
                      />
                      <ErrorMessage
                        name="guarantors[0].phoneNumber"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="guarantors[0].relationship"
                        className={classes.label}
                      >
                        Relationship
                      </label>
                      <TextField
                        name="guarantors[0].relationship"
                        id="guarantors[0].relationship"
                        {...getFieldProps("guarantors[0].relationship")}
                        variant="outlined"
                        // error={errors.guarantors?[0].relationship && touched.guarantors?[0].relationship ? true : false}
                        size="small"
                      />
                      <ErrorMessage
                        name="guarantors[0].relationship"
                        component={FormErrors}
                      />
                    </FormControl>
                  </div>

                  <Typography variant="body2" style={{ margin: "1rem 0" }}>
                    Guarantor 2
                  </Typography>
                  <div className={classes.fieldWrapper}>
                    <FormControl>
                      <label
                        htmlFor="guarantors[1].firstName"
                        className={classes.label}
                      >
                        First Name
                      </label>
                      <TextField
                        name="guarantors[1].firstName"
                        id="guarantors[1].firstName"
                        {...getFieldProps("guarantors[1].firstName")}
                        variant="outlined"
                        // error={errors.guarantors?[1].relationship && touched.guarantors?[1].relationship ? true : false}
                        size="small"
                      />
                      <ErrorMessage
                        name="guarantors[1].firstName"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="guarantors[1].lastName"
                        className={classes.label}
                      >
                        Last Name
                      </label>
                      <TextField
                        name="guarantors[1].lastName"
                        id="guarantors[1].lastName"
                        {...getFieldProps("guarantors[1].lastName")}
                        variant="outlined"
                        // error={errors.guarantors?[1].lastName && touched.guarantors?[1].lastName ? true : false}
                        size="small"
                      />
                      <ErrorMessage
                        name="guarantors[1].lastName"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="guarantors[1].phoneNumber"
                        className={classes.label}
                      >
                        Phone Number
                      </label>
                      <TextField
                        name="guarantors[1].phoneNumber"
                        id="guarantors[1].phoneNumber"
                        type="tel"
                        {...getFieldProps("guarantors[1].phoneNumber")}
                        variant="outlined"
                        // error={errors.guarantors?[1].phoneNumber && touched.guarantors?[1].phoneNumber ? true : false}
                        size="small"
                        className={classes.numberInput}
                      />
                      <ErrorMessage
                        name="guarantors[1].phoneNumber"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="guarantors[1].relationship"
                        className={classes.label}
                      >
                        Relationship
                      </label>
                      <TextField
                        name="guarantors[1].relationship"
                        id="guarantors[1].relationship"
                        {...getFieldProps("guarantors[1].relationship")}
                        variant="outlined"
                        // error={errors.guarantors?[1].relationship && touched.guarantors?[1].relationship ? true : false}
                        size="small"
                      />
                      <ErrorMessage
                        name="guarantors[1].relationship"
                        component={FormErrors}
                      />
                    </FormControl>
                  </div>
                </Box>

                <Box className={classes.section}>
                  <Typography variant="h5">Bank Account Details</Typography>

                  <div className={classes.fieldWrapper}>
                    <FormControl>
                      <label
                        htmlFor="bankAccount.accountName"
                        className={classes.label}
                      >
                        Account Name
                      </label>
                      <TextField
                        name="bankAccount.accountName"
                        id="bankAccount.accountName"
                        {...getFieldProps("bankAccount.accountName")}
                        variant="outlined"
                        error={
                          errors.bankAccount?.accountName &&
                          touched.bankAccount?.accountName
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage
                        name="bankAccount.accountName"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="bankAccount.accountNumber"
                        className={classes.label}
                      >
                        Account Number
                      </label>
                      <TextField
                        name="bankAccount.accountNumber"
                        id="bankAccount.accountNumber"
                        {...getFieldProps("bankAccount.accountNumber")}
                        error={
                          errors.bankAccount?.accountNumber &&
                          touched.bankAccount?.accountNumber
                            ? true
                            : false
                        }
                        variant="outlined"
                        // type="number"
                        inputProps={{
                          type: "text",
                          pattern: "[0-9]*",
                          inputMode: "numeric",
                        }}
                        size="small"
                        className={classes.numberInput}
                      />
                      <ErrorMessage
                        name="bankAccount.accountNumber"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl variant="outlined" size="small">
                      <label
                        htmlFor="bankAccount.accountName"
                        className={classes.label}
                      >
                        Select Bank
                      </label>
                      <Select
                        labelId="bankCode_label"
                        defaultValue=""
                        id="bankAccount.bankCode"
                        {...getFieldProps("bankAccount.bankCode")}
                        error={
                          errors.bankAccount?.bankCode &&
                          touched.bankAccount?.bankCode
                            ? true
                            : false
                        }
                      >
                        {banks.map((bank, key) => (
                          <MenuItem key={key} value={bank.bankCode}>
                            {bank.bankName}
                          </MenuItem>
                        ))}
                      </Select>
                      <ErrorMessage
                        name="bankAccount.bankCode"
                        component={FormErrors}
                      />
                    </FormControl>
                  </div>
                </Box>

                <Box className={classes.section}>
                  <Typography variant="h5">Employment Details</Typography>
                  <div className={classes.fieldWrapper}>
                    <FormControl component="fieldset">
                      <label
                        htmlFor="bankAccount.accountName"
                        className={classes.label}
                      >
                        Employment Status
                      </label>
                      <RadioGroup
                        aria-label="employmentStatus"
                        name="employment.employmentStatus"
                        {...getFieldProps("employment.employmentStatus")}
                        className={classes.grouping}
                      >
                        <FormControlLabel
                          value="Employed"
                          control={<Radio size="small" />}
                          label="Employed"
                        />
                        <FormControlLabel
                          value="Self-employed"
                          control={<Radio size="small" />}
                          label="Self-employed"
                        />
                      </RadioGroup>
                      <ErrorMessage
                        name="employment.employmentStatus"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="employment.employerName"
                        className={classes.label}
                      >
                        Employer Name
                      </label>
                      <TextField
                        name="employment.employerName"
                        id="employment.employerName"
                        {...getFieldProps("employment.employerName")}
                        variant="outlined"
                        error={
                          errors.employment?.employerName &&
                          touched.employment?.employerName
                            ? true
                            : false
                        }
                        size="small"
                      />

                      <ErrorMessage
                        name="employment.employerName"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="employment.employerAddress"
                        className={classes.label}
                      >
                        Employer Address
                      </label>
                      <TextField
                        name="employment.employerAddress"
                        id="employment.employerAddress"
                        {...getFieldProps("employment.employerAddress")}
                        variant="outlined"
                        error={
                          errors.employment?.employerAddress &&
                          touched.employment?.employerAddress
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage
                        name="employment.employerAddress"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="employment.employerAddress"
                        className={classes.label}
                      >
                        Employer's Email
                      </label>
                      <TextField
                        name="employment.workplaceEmail"
                        id="employment.workplaceEmail"
                        {...getFieldProps("employment.workplaceEmail")}
                        variant="outlined"
                        error={
                          errors.employment?.workplaceEmail &&
                          touched.employment?.workplaceEmail
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage
                        name="employment.workplaceEmail"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="employment.salaryAmount"
                        className={classes.label}
                      >
                        Salary Amount
                      </label>
                      <TextField
                        name="employment.salaryAmount"
                        id="employment.salaryAmount"
                        {...getFieldProps("employment.salaryAmount")}
                        variant="outlined"
                        size="small"
                        type="number"
                        error={
                          errors.employment?.salaryAmount &&
                          touched.employment?.salaryAmount
                            ? true
                            : false
                        }
                        className={classes.numberInput}
                      />
                      <ErrorMessage
                        name="employment.salaryAmount"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="employment.salaryDueDate"
                        className={classes.label}
                      >
                        Salary Due Date
                      </label>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          // disableToolbar
                          inputVariant="outlined"
                          format="DD/MM/yyyy"
                          variant="outlined"
                          // margin="normal"
                          id="employment.salaryDueDate"
                          name="employment.salaryDueDate"
                          size="small"
                          value={values.employment.salaryDueDate}
                          error={
                            errors.employment?.salaryDueDate &&
                            touched.employment?.salaryDueDate
                              ? true
                              : false
                          }
                          onChange={(value) => {
                            setFieldValue("employment.salaryDueDate", value);
                          }}
                          helperText={""}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <ErrorMessage
                        name="employment.salaryDueDate"
                        component={FormErrors}
                      />
                    </FormControl>
                  </div>
                </Box>

                <Box className={classes.section}>
                  <Typography variant="h5">Loan Details</Typography>

                  <div className={classes.fieldWrapper}>
                    <FormControl>
                      <label
                        htmlFor="employment.loanAmount"
                        className={classes.label}
                      >
                        Loan Amount
                      </label>
                      <TextField
                        name="loanRequest.loanAmount"
                        id="loanRequest.loanAmount"
                        type="number"
                        className={classes.numberInput}
                        {...getFieldProps("loanRequest.loanAmount")}
                        variant="outlined"
                        size="small"
                        error={
                          errors.employment?.loanAmount &&
                          touched.employment?.loanAmount
                            ? true
                            : false
                        }
                      />
                      <ErrorMessage
                        name="loanRequest.loanAmount"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl>
                      <label
                        htmlFor="employment.loanAmount"
                        className={classes.label}
                      >
                        Loan Tenure (Months)
                      </label>
                      <TextField
                        name="loanRequest.tenure"
                        id="loanRequest.tenure"
                        type="number"
                        className={classes.numberInput}
                        {...getFieldProps("loanRequest.tenure")}
                        variant="outlined"
                        error={
                          errors.loanRequest?.loanTenure &&
                          touched.loanRequest?.loanTenure
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage
                        name="loanRequest.tenure"
                        component={FormErrors}
                      />
                    </FormControl>

                    <FormControl variant="outlined" size="small">
                      <label
                        htmlFor="loanRequest.isSelfSponsored"
                        className={classes.label}
                      >
                        Loan Type
                      </label>
                      <Select
                        defaultValue=""
                        labelId="loanRequest.isSelfSponsored"
                        id="loanRequest.isSelfSponsored"
                        name="loanRequest.isSelfsponsored"
                        error={
                          errors.loanRequest?.isSelfSponsored &&
                          touched.loanRequest?.isSelfSponsored
                            ? true
                            : false
                        }
                        {...getFieldProps("loanRequest.isSelfSponsored")}
                      >
                        <MenuItem value={true}>Self</MenuItem>
                        <MenuItem value={false}>Guardian</MenuItem>
                      </Select>
                      <ErrorMessage
                        name="loanRequest.isSelfsponsored"
                        component={FormErrors}
                      />
                    </FormControl>
                  </div>

                  <Box className={classes.section}>
                    <Typography variant="h5">Institution Details</Typography>

                    <div className={classes.fieldWrapper}>
                      <FormControl>
                        <label
                          htmlFor="institutionInfo.name"
                          className={classes.label}
                        >
                          Institution Name
                        </label>
                        <TextField
                          name="institutionInfo.name"
                          id="institutionInfo.name"
                          {...getFieldProps("institutionInfo.name")}
                          variant="outlined"
                          error={
                            errors.institutionInfo?.name &&
                            touched.institutionInfo?.name
                              ? true
                              : false
                          }
                          size="small"
                        />
                        <ErrorMessage
                          name="institutionInfo.name"
                          component={FormErrors}
                        />
                      </FormControl>

                      <FormControl variant="outlined" size="small">
                        <label
                          htmlFor="institutionInfo.name"
                          className={classes.label}
                        >
                          Bank Name
                        </label>
                        <Select
                          defaultValue=""
                          labelId="institutionInfo.bankName"
                          id="institutionInfo.bankName"
                          name="institutionInfo.bankName"
                          error={
                            errors.institutionInfo?.bankName &&
                            touched.institutionInfo.bankName
                              ? true
                              : false
                          }
                          {...getFieldProps("institutionInfo.bankName")}
                        >
                          {banks.length < 1 ? (
                            <MenuItem value={null} defaultValue>
                              Loading Banks...
                            </MenuItem>
                          ) : (
                            banks.map((bank, key) => (
                              <MenuItem key={key} value={bank.bankName}>
                                {bank.bankName}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>

                      <FormControl>
                        <label
                          htmlFor="institutionInfo.bankAccountNo"
                          className={classes.label}
                        >
                          Account Number
                        </label>
                        <TextField
                          name="institutionInfo.bankAccountNo"
                          id="institutionInfo.bankAccountNo"
                          {...getFieldProps("institutionInfo.bankAccountNo")}
                          variant="outlined"
                          error={
                            errors.institutionInfo?.bankAccountNo &&
                            touched.institutionInfo.bankAccountNo
                              ? true
                              : false
                          }
                          size="small"
                        />
                        <ErrorMessage
                          name="institutionInfo.bankAccountNo"
                          component={FormErrors}
                        />
                      </FormControl>

                      <FormControl>
                        <label
                          htmlFor="institutionInfo.bankAccountNo"
                          className={classes.label}
                        >
                          Account Name
                        </label>
                        <TextField
                          name="institutionInfo.bankAccountName"
                          id="institutionInfo.bankAccountName"
                          {...getFieldProps("institutionInfo.bankAccountName")}
                          variant="outlined"
                          error={
                            errors.institutionInfo?.bankAccountName &&
                            touched.institutionInfo.bankAccountName
                              ? true
                              : false
                          }
                          size="small"
                        />
                        <ErrorMessage
                          name="institutionInfo.bankAccountName"
                          component={FormErrors}
                        />
                      </FormControl>
                    </div>
                  </Box>
                </Box>
              </Box>
              {requestDocument && currentLoan && isMounted.current && (
                <Box className={classes.section}>
                  <Typography gutterBottom variant="h5">
                    Upload Documents
                  </Typography>
                  <Box className={classes.loanDocuments}>
                    {documents?.length && documents.map((data, key) => (
                      <Box marginTop={2} key={key}>
                        <Typography variant="body1" gutterBottom>
                          {data.documentType.replace("_", " ")}
                        </Typography>
                        <FormControl
                          component="fieldset"
                          style={{ width: "100%" }}
                        >
                          <DropzoneArea
                            acceptedFiles={[
                              "image/*",
                              "application/pdf",
                              "application/msword",
                            ]}
                            dropzoneText={`Upload ${data.documentType.replace("_", " ")} here`}
                            maxFileSize={5000000}
                            showPreviewsInDropzone
                            // useChipsForPreview
                            showFileNames
                            showFileNamesInPreview
                            getDropRejectMessage={() =>
                              "File should be the required format and not more than 5MB"
                            }
                            filesLimit={1}
                            onChange={(file) =>
                              setFieldValue(
                                `loanDocuments.${data.key}`,
                                file[0]
                              )
                            }
                          />
                          <div className={classes.uploadInfo}>
                            <Typography variant="caption">
                              Files Supported: PNG, Jpeg, PDF{" "}
                            </Typography>
                            <Typography variant="caption">
                              Maximum Size: 5MB
                            </Typography>
                          </div>
                          <ErrorMessage
                            name={`loanDocuments.${data.documentName}`}
                            component={FormErrors}
                          />
                        </FormControl>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}

              <Box
                align="right"
                marginTop={"5rem"}
                style={{ display: allowReview ? "block" : "none" }}
              >
                <Button
                  variant="contained"
                  padding={"0.7rem"}
                  type="submit"
                  color="primary"
                  disabled={credPalProps.isSubmitting}
                  loading={credPalProps.isSubmitting}
                >
                  Update
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
};

export default CredPalForm;
