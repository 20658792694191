import React from "react";
import { useStyles } from "./style";
import { Formik, Form, ErrorMessage } from "formik";
import { FormControl, TextField, Typography, Box } from "@material-ui/core";
import * as Yup from "yup";
import Button from "../../../Button";
import FormErrors from "../../../FormErrors";
import {
  nameValidation,
  phoneNumberValidation,
  emailValidation,
} from "../../../../Actions/formActions";
import { envURL } from "../../../../Actions/helpers";

// import userContext from "../../../../../Contexts/users/userContext";

const ProfileUserLayout = (props) => {
  const classes = useStyles();

  //   const { Login } = useContext(userContext);

  const initialValues = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  };

  const validationSchema = Yup.object({
    firstName: nameValidation("first name", "FirstName"),
    lastName: nameValidation("last name", "LastName"),
    phoneNumber: phoneNumberValidation("Phone Number", "phoneNumber"),
    email: emailValidation(),
  });

  return (
    <div>
      <Box marginBottom={"1.5rem"}>
        <Typography variant="h6" align="center">
          Create Account
        </Typography>
        <Typography
          align="center"
          style={{ color: "#9DA9B3", marginTop: ".2rem" }}
        >
          Enter Details to proceed
        </Typography>
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => console.log(values)}
      >
        {({ values, errors, touched, getFieldProps, isSubmitting }) => (
          <Form noValidate autoComplete="off">
            <FormControl className={classes.formControl}>
              <label htmlFor="firstName" className={classes.label}>
                First Name
              </label>
              <TextField
                name="firstName"
                placeholder="Jane"
                id="firstName"
                variant="outlined"
                {...getFieldProps("firstName")}
                error={errors.firstName && touched.firstName ? true : false}
                size="small"
              />
              <ErrorMessage name="firstName" component={FormErrors} />
            </FormControl>
            <FormControl className={classes.formControl}>
              <label htmlFor="lastName" className={classes.label}>
                Last Name
              </label>
              <TextField
                name="lastName"
                placeholder="Doe"
                id="firstName"
                variant="outlined"
                {...getFieldProps("lastName")}
                error={errors.lasttName && touched.lastName ? true : false}
                size="small"
              />
              <ErrorMessage name="lastName" component={FormErrors} />
            </FormControl>

            <FormControl className={classes.formControl}>
              <label htmlFor="phoneNumber" className={classes.label}>
                Phone Number
              </label>
              <TextField
                name="phoneNumber"
                id="phoneNumber"
                type="tel"
                {...getFieldProps("phoneNumber")}
                placeholder="0803854272"
                variant="outlined"
                size="small"
                className={classes.numberInput}
                error={errors.phoneNumber && touched.phoneNumber ? true : false}
              />
              <ErrorMessage name="phoneNumber" component={FormErrors} />
            </FormControl>

            <FormControl className={classes.formControl}>
              <label htmlFor="email" className={classes.label}>
                Email Address
              </label>
              <TextField
                name="email"
                id="email"
                {...getFieldProps("email")}
                placeholder="janedoe@yahoo.com"
                variant="outlined"
                size="small"
                error={errors.email && touched.email ? true : false}
              />
              <ErrorMessage name="email" component={FormErrors} />
            </FormControl>

            <FormControl
              className={classes.formControl}
              style={{ marginTop: "2rem" }}
            >
              <Button
                variant="contained"
                color="primary"
                // size="large"
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Continue
              </Button>
            </FormControl>
          </Form>
        )}
      </Formik>
      <Box align="center">
        <span>
          <small>Already have an account?</small>{" "}
        </span>
        <span>
          <small>
            <a href={`${envURL}/user/login`} className={classes.loginCTA}>
              Login
            </a>{" "}
          </small>
        </span>
      </Box>
    </div>
  );
};

export default ProfileUserLayout;
