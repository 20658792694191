import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},

  buttons: {
    width: "44%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down('xs')]: {
        width: "100%"
    }
  },
}));

export { useStyles };
