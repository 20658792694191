import React from "react";
import { Typography, Box } from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { formatDateTime } from "../../../../../Actions/helpers";
import Paper from "../../../../Paper";
import StatusIndicator from "../../../../Reusables/StatusIndicator";

import { useStyles } from "./style";

const ViewPartnerLayout = (props) => {
  const classes = useStyles();

  const partner = props.location?.state?.data && props.location?.state?.data;
  const {
    orgName,
    phoneNumber,
    redirectUrl,
    status,
    baseUrl,
    hashRequired,
    contactPersonName,
    initial,
    currency,
    dateCreated,
    email,
    hashKey,
    logoUrl,
    minLoanAmount,
    orgCode,
    organizationProviders,
  } = partner;

  const getProviders = () => {
    let result = "";
    organizationProviders.forEach(
      (provider, i) =>
        (result += `${provider.bankName}${
          organizationProviders[i + 1] ? ", " : ""
        }`)
    );
    return result;
  };

  return (
    <Box>
      <Box className={classes.header}>
        <Box
          className={classes.goBack}
          onClick={() => props.history.push("/partners")}
        >
          <KeyboardArrowLeftIcon />
        </Box>

        <Typography variant="h4" gutterBottom display="inline">
          Partner Details
        </Typography>
      </Box>

      <Paper borderRadius="0" padding="1.5rem 2rem 2rem">
        <Typography variant="h4" gutterBottom>
          Partner Information
        </Typography>
        <Box className={classes.partnerDetail}>
          <Box className={classes.partnerItem}>
            <Typography variant="subtitle2" gutterBottom>
              Partner's Name
            </Typography>
            <Typography variant="body1">{orgName}</Typography>
          </Box>
          <Box className={classes.partnerItem}>
            <Typography variant="subtitle2" gutterBottom>
              Partner's Code
            </Typography>
            <Typography variant="body1">{orgCode}</Typography>
          </Box>
          <Box className={classes.partnerItem}>
            <Typography variant="subtitle2" gutterBottom>
              Date Created
            </Typography>
            <Typography>{formatDateTime(dateCreated)}</Typography>
          </Box>
          <Box className={classes.partnerItem}>
            <Typography variant="subtitle2" gutterBottom>
              Provider(s)
            </Typography>
            <Typography>{getProviders()}</Typography>
          </Box>

          <Box className={classes.partnerItem}>
            <Typography variant="subtitle2" gutterBottom>
              Hash Key
            </Typography>
            <Typography>{hashKey}</Typography>
          </Box>
          <Box className={classes.partnerItem}>
            <Typography variant="subtitle2" gutterBottom>
              Initial
            </Typography>
            <Typography>{initial}</Typography>
          </Box>
          <Box className={classes.partnerItem}>
            <Typography variant="subtitle2" gutterBottom>
              Base Url
            </Typography>
            <Typography>{baseUrl}</Typography>
          </Box>
          <Box className={classes.partnerItem}>
            <Typography variant="subtitle2" gutterBottom>
              Redirect Url
            </Typography>
            <Typography>{redirectUrl}</Typography>
          </Box>
          <Box className={classes.partnerItem}>
            <Typography variant="subtitle2" gutterBottom>
              Currency
            </Typography>
            <Typography>{currency}</Typography>
          </Box>
          <Box className={classes.partnerItem}>
            <Typography variant="subtitle2" gutterBottom>
              Minimum Loan Amount
            </Typography>
            <Typography>{minLoanAmount}</Typography>
          </Box>
          <Box className={classes.partnerItem}>
            <Typography variant="subtitle2" gutterBottom>
              Displayed on Website
            </Typography>
            <StatusIndicator
              status={status}
              text={status === true ? "Enabled" : "Disabled"}
              size="large"
            />
          </Box>
          <Box className={classes.partnerItem}>
            <Typography variant="subtitle2" gutterBottom>
              Hash Required
            </Typography>
            <StatusIndicator
              status={hashRequired}
              text={hashRequired === true ? "Enabled" : "Disabled"}
              size="large"
            />
          </Box>

          <Box className={classes.partnerItem}>
            <Box className={classes.logoBox}>
              <Box
                style={{
                  height: "4rem",
                  width: "50%",
                }}
              >
                <img
                  src={logoUrl}
                  alt=""
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.contact}>
          <Typography variant="h4" gutterBottom>
            Contact Information
          </Typography>
          <Box className={classes.partnerDetail}>
            <Box className={classes.partnerItem}>
              <Typography variant="subtitle2" gutterBottom>
                Contact Person's Name
              </Typography>
              <Typography variant="body1">{contactPersonName}</Typography>
            </Box>
            <Box className={classes.partnerItem}>
              <Typography variant="subtitle2" gutterBottom>
                Phone Number
              </Typography>
              <Typography variant="body1">{phoneNumber}</Typography>
            </Box>
            <Box className={classes.partnerItem}>
              <Typography variant="subtitle2" gutterBottom>
                Email
              </Typography>
              <Typography variant="body1">{email}</Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ViewPartnerLayout;
