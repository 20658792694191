import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    formControl: {
        width : '100%',
        marginBottom: '1rem!important',
    },
    forgotCTA: {
        color: theme.palette.primary.main,
        float: 'right',
        marginBottom: '1rem',
        cursor: 'pointer',
        textDecoration: 'none'
    }
}));

export { useStyles }