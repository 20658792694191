import React from 'react';
import { TextField } from '@material-ui/core';

const Input = (props) => {
    const error = props.errors && props.touched
    return(
        <TextField 
            {...props}
            fullWidth 
            margin="dense"  
            id="outlined-basic" 
            variant="outlined"
            error={error && props.errors[props.name] && props.touched[props.name]}
            helperText={error && props.errors[props.name] && props.touched[props.name] && props.errors[props.name]}
        />
    )
};

export default Input;