import { makeStyles } from '@material-ui/core/styles'
import Verification from '../../../../../Assets/images/Verification.png'

const useStyles = makeStyles(theme => ({
   root: {},

   container: {
       width: "60%",
       margin: "5rem auto",
       [theme.breakpoints.down('md')]: {
        width: "90%"
    },
    
   },
   image: {
    backgroundImage: `url(${Verification});`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
   height:"100%",
    padding: "2rem 4rem",
    // marginBottom:"5rem",
    [theme.breakpoints.down('sm')]: {
        padding: "1rem 2rem"
    },
  },
  backButton: {
    padding: "0!important",
  },
  title: {
    fontSize: "24px",
    fontWeight: "600",
    
  },
  formControl: {
    minWidth: `100%`,
    margin: theme.spacing(1, 0),
  },
  form: {
    width: "100%",
  },
  label: {
    fontSize: "14px",
    fontWeight: "400",
    marginBottom: ".3rem",
  },
  select: {
      height: '2.7rem'
  }
}))

export { useStyles }