import React from 'react'
import { Box, Typography } from '@material-ui/core'
import ProvidersLayout from '../../Components/Layout/AdminLayouts/ProvidersLayout'

const AdminProviders = () => {
    return (
        <div>
            <Box marginBottom="2rem">
                <Typography variant="h3">Providers</Typography>
            </Box>
            <ProvidersLayout/>
        </div>
    )
}

export default AdminProviders
