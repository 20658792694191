import Cookies from "js-cookie";
import  axiosInstance from "../Services/axiosInstance"


// export const getUsersData = async url => {
//   try {
//       const usersData = await AxiosRequest("get", url)
//       return usersData;
//   } catch (error) {
//       throw error
//   }
// }

// export const resendConfrimationLink = async userId => {
// try {
//   const resendLink = await AxiosRequest("get", `User/ResendEmailConfirmation/${userId}`)
//   return resendLink;
// } catch (error) {
//   throw error
// }
// }

export const removeTokens = () => {
  localStorage.removeItem("email");
  localStorage.removeItem("valid");
  Cookies.remove("accessToken");
  delete axiosInstance.defaults.headers.Authorization
}

export const logOut = () => {
  removeTokens()
  if(!axiosInstance.defaults.headers.Authorization){
    if(window.location.pathname !== "/adminlogin" ){
      window.location.href = "/adminlogin";
    }
  }
 
}


