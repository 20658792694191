import React, { useState, useEffect, useContext } from "react";
import { ErrorMessage } from "formik";
import FormErrors from "../../../../../FormErrors";
import ControlButtons from "../../ControlButtons";
import { connect } from "formik";
import { useLocation } from "react-router-dom";
import {
  FormControl,
  Box,
  TextField,
  Paper,
  Select,
  MenuItem,
  Typography,
} from "@material-ui/core";

import MomentUtils from "@date-io/moment";
import queryString from "query-string";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Button from "../../../../../Button";

import credPalContext from "../../../../../../Contexts/credpal/credpalContext";
import loanContext from "../../../../../../Contexts/loan/loanContext";

import { moneyFormat } from "../../../../../../Actions/helpers";

import { useStyles } from "./style";

const StudentInformation = (props) => {
  const classes = useStyles();

  const {
    formik: { getFieldProps, setFieldValue, setTouched, touched, values },
    studentCities,
    getCities,
  } = props;

  const [showCityHelper, setShowCityHelper] = useState(false);
  const [partnerInstitutions, setPartnerInstitutions] = useState([]);
  const [corporatePartners, setCorporatePartners] = useState([]);

  const location = useLocation();

  let loanRef;
  if (queryString.parse(location.search).loanRef) {
    loanRef = queryString.parse(location.search).loanRef;
  } else {
    loanRef = localStorage.loanRef;
  }

  // const loanRef = queryString.parse(location.search).loanRef;
  const userId = queryString.parse(location.search).userId;

  //if applicant already has a loan ref, they should be made to attach new provider to the ref
  // when they go back. Else, it should just be a link to redirect to/apply with the providerId
  // so this being the case, i'm sending the credentials in the url so we can use on the provider selection page
  const link =
    loanRef && userId
      ? `/application?loanRef=${loanRef}&userId=${userId}`
      : `/application?userId=${userId}`;

  const { dateOfBirth, phoneNumber, state } = values;

  const { user, verified, informationMismatch, currentLoan} =
    useContext(credPalContext);

  const isThirdParty = currentLoan?.isThirdParty;

  const { currentPartner, currentProvider, states, makeFilterRequest } = useContext(loanContext);

  const { orgName, providers } = currentPartner;

  const getCityData = (val) =>
    getCities(states.find((state) => state.name === val)?.id, "student");

  useEffect(() => {
    if (values.city) {
      getCityData(values.state);
    }

    const getPartnerData = async () => {
      const partnerInstitutionsData = [];
      const corporatePartnersData = [];

      const partners = await makeFilterRequest("Partner");
      partners.data.responseData.items.forEach(element => {
        if (element.type === "INSTITUTION") {
          partnerInstitutionsData.push(element)
        } else if (element.type === "COORPERATE") {
          corporatePartnersData.push(element);
        };
      });
      setPartnerInstitutions(partnerInstitutionsData);
      setCorporatePartners(corporatePartnersData);

      console.log("The partner details are: ", partnerInstitutions, corporatePartners);
    }

    getPartnerData();
  }, []);


  //Either not a partner's e.g schoolable applicant or partner has more than one mapped provider
  const displayChangeBtn = () =>
    JSON.stringify(currentPartner) === "{}" || providers.length > 1;

  return (
    <div className={classes.container}>
      <Paper style={{ padding: "1rem 2rem 3rem " }}>
        <Box className={classes.intro}>
          <Box>
            {/* <label
              style={{ fontWeight: "500", fontSize: "16px" }}
              display="inline"
            >
              You are applying for{" "}
            </label>
            <label className={classes.title} display="inline">
              {currentProvider.bankName} Loan
            </label> */}
          </Box>

          {/* {displayChangeBtn() && (
            <Button
              variant="outlined"
              linkPage={link}
              size="small"
              to={link}
              type="submit"
              color="primary"
            >
              Change Loan Plan
            </Button>
          )} */}
        </Box>

        <div className={classes.fieldWrapper}>
          <FormControl className={classes.formControl}>
            <label className={classes.label}>Loan Amount (NGN)</label>
            <TextField
              name="loanRequest.loanAmount"
              id="loanRequest.loanAmount"
              type="text"
              className={classes.numberInput}
              //This implementation is temporal, should be configured from admin
              disabled={
                isThirdParty || orgName?.toLowerCase().includes("choolab")
              }
              // {...getFieldProps("loanRequest.loanAmount")}
              value={moneyFormat(values.loanRequest.loanAmount)}
              onChange={(e) => {
                const val = e.target.value;

                if (/^[0-9_,.]*$/i.test(val) && !/\d\.\.?(\.+)/gm.test(val)) {
                  setFieldValue("loanRequest.loanAmount", val);
                }
              }}
              variant="outlined"
              size="small"
            />
            <ErrorMessage
              name="loanRequest.loanAmount"
              component={FormErrors}
            />
          </FormControl>

          <FormControl
            className={classes.formControl}
            variant="outlined"
            size="small"
          >
            <label className={classes.label}>Loan Tenure (Months) - Max 24 months</label>
            <TextField
              name="loanRequest.tenure"
              id="loanRequest.tenure"
              {...getFieldProps("loanRequest.tenure")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage name="loanRequest.tenure" component={FormErrors} />
          </FormControl>
        </div>

        <Box marginTop={3} marginBottom={2}>
          <label variant="h5">Student's Information</label>
        </Box>

        <div className={classes.fieldWrapper}>
          <FormControl className={classes.formControl}>
            <label className={classes.label}>First Name</label>
            <TextField
              name="firstName"
              id="firstName"
              disabled={isThirdParty || verified}
              {...getFieldProps("firstName")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage name="firstName" component={FormErrors} />
          </FormControl>

          <FormControl className={classes.formControl}>
            <label className={classes.label}>Surname</label>
            <TextField
              name="lastname"
              id="lastName"
              disabled={isThirdParty || verified}
              {...getFieldProps("lastName")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage name="lastName" component={FormErrors} />
          </FormControl>

          <FormControl
            className={classes.formControl}
            variant="outlined"
            size="small"
          >
            <label className={classes.label}>Gender</label>
            <Select
              defaultValue=""
              labelId="gender"
              id="gernder"
              name="gender"
              disabled={isThirdParty || verified}
              {...getFieldProps("gender")}
            >
              <MenuItem value="M">Male</MenuItem>
              <MenuItem value="F">Female</MenuItem>
            </Select>
            <ErrorMessage name="gender" component={FormErrors} />
          </FormControl>

          <FormControl className={classes.formControl}>
            <label className={classes.label}>Date Of Birth (dd/mm/yyyy)</label>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                // disableToolbar
                inputVariant="outlined"
                format="DD/MM/yyyy"
                variant="outlined"
                id="dateOfBirth"
                name="dateOfBirth"
                size="small"
                disabled={isThirdParty || verified}
                value={dateOfBirth}
                onChange={(value) => {
                  setFieldValue("dateOfBirth", value);
                }}
                helperText={""}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            {user.userType === "student" && (
              <Typography variant="caption">
                * Accepted age Range is between 18 to 59 years
              </Typography>
            )}
            <ErrorMessage name="dateOfBirth" component={FormErrors} />
          </FormControl>

          <FormControl className={classes.formControl}>
            <label className={classes.label}>Phone Number</label>
            <TextField
              name="phoneNumber"
              id="phoneNumber"
              type="tel"
              // disabled={verified}
              // {...getFieldProps("phoneNumber")}
              value={phoneNumber}
              onChange={(e) => {
                informationMismatch(); //if a user changes phoneNumber, the bankInfo should not be submitted
                setFieldValue("phoneNumber", e.target.value);
              }}
              variant="outlined"
              size="small"
            />
            {user.userType === "student" && (
              <Typography variant="caption">
                * BVN Registered Phone Number
              </Typography>
            )}
            <ErrorMessage name="phoneNumber" component={FormErrors} />
          </FormControl>

          <FormControl className={classes.formControl}>
            <label className={classes.label}>Residential Address</label>
            <TextField
              name="address"
              id="address"
              disabled={isThirdParty || verified}
              {...getFieldProps("address")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage name="address" component={FormErrors} />
          </FormControl>
          <FormControl
            className={classes.formControl}
            variant="outlined"
            size="small"
          >
            <label className={classes.label}>State</label>
            <Select
              defaultValue=""
              labelId="state"
              id="state"
              name="state"
              {...getFieldProps("state")}
              onChange={(e) => {
                setFieldValue("city", "");
                if (showCityHelper) setShowCityHelper(false);
                const val = e.target.value;
                if (val !== null) {
                  setFieldValue("state", val);
                  setTouched({ ...touched, state: false, city: false });
                  getCityData(val);
                }
              }}
              value={values.state}
            >
              {!states.length ? (
                <MenuItem value={null} defaultValue>
                  Loading States...
                </MenuItem>
              ) : (
                states.map((state, key) => (
                  <MenuItem key={key} value={state.name}>
                    {state.name}
                  </MenuItem>
                ))
              )}
            </Select>
            <ErrorMessage name="state" component={FormErrors} />
          </FormControl>

          <FormControl
            className={classes.formControl}
            variant="outlined"
            size="small"
          >
            <label className={classes.label}>City</label>
            <Select
              defaultValue=""
              labelId="city"
              disabled={state === ""}
              id="city"
              onClick={() => state === "" && setShowCityHelper(true)}
              name="city"
              {...getFieldProps("city")}
            >
              {!studentCities.length ? (
                <MenuItem value={""} defaultValue>
                  Loading Cities...
                </MenuItem>
              ) : (
                studentCities.map((city, key) => (
                  <MenuItem key={key} value={city.name}>
                    {city.name}
                  </MenuItem>
                ))
              )}
            </Select>
            {showCityHelper && (
              <Typography variant="caption">*Select state first</Typography>
            )}
            <ErrorMessage name="city" component={FormErrors} />
          </FormControl>

          {user.userType === "student" && (
            <FormControl className={classes.formControl}>
              <label className={classes.label}>NIN</label>
              <TextField
                name="nin"
                id="nin"
                {...getFieldProps("nin")}
                variant="outlined"
                size="small"
              />
              <Typography variant="caption">
                Dial *346# to get your NIN
              </Typography>
              <ErrorMessage name="nin" component={FormErrors} />
            </FormControl>
          )}

          <FormControl
            className={classes.formControl}
            variant="outlined"
            size="small"
          >
            <label className={classes.label}>Borrower Type</label>
            <Select
              defaultValue=""
              labelId="loanRequest.borrowerType"
              id="borrowerType"
              name="loanRequest.borrowerType"
              {...getFieldProps("loanRequest.borrowerType")}
            >
              <MenuItem value={3}>Individual</MenuItem>
              <MenuItem disabled={!partnerInstitutions.length} value={1}>Institution</MenuItem>
              <MenuItem disabled={!corporatePartners.length} value={2}>Corporate</MenuItem>
            </Select>
            <ErrorMessage name="loanRequest.borrowerType" component={FormErrors} />
          </FormControl>

          {values.loanRequest?.borrowerType === 1 && (
            <FormControl className={classes.formControl} variant="outlined" size="small">
              <label className={classes.label}>Institution Partner</label>
              <Select
                defaultValue=""
                name="institutionPartner"
                id="institutionPartner"
                value={values.institutionPartner}
                onChange={(e) => {
                  const val = e.target.value;
                  const name = partnerInstitutions.find((data) => data.code === val).name || "code";

                  console.log("The event value is: ", val, name);
                  
                  !values.organizationCode && setFieldValue("organizationCode", val);
                  setFieldValue("institutionPartner", val)
                }}
                variant="outlined"
                size="small"
              >
                <MenuItem value={""} disabled defaultValue>Select an Option</MenuItem>
                {partnerInstitutions.map((institution, key) => (
                  <MenuItem key={key} value={institution.code}>
                    {institution.name}
                  </MenuItem>
                ))}
              </Select>
              <ErrorMessage name="institutionPartner" component={FormErrors}/>
            </FormControl>
            )
          }

          { values.loanRequest?.borrowerType === 2 && (
            <FormControl className={classes.formControl} variant="outlined" size="small">
              <label className={classes.label}>Employer's Name</label>
              <Select
                defaultValue=""
                name="employment.employerName"
                id="employment.employerName"
                value={values.employment.employerName}
                onChange={(e) => {
                  const val = e.target.value;
                  const name = corporatePartners.find((data) => data.code === val).name || "Code";

                  !values.organizationCode && setFieldValue("organizationCode", val);
                  setFieldValue("employment.employerName", val);
                }}
                variant="outlined"
                size="small"
              >
                <MenuItem value={""} disabled defaultValue>Select an Option</MenuItem>
                {corporatePartners.map((corporate, key) => (
                  <MenuItem key={key} value={corporate.code}>
                    {corporate.name}
                  </MenuItem>
                ))}
              </Select>
              <ErrorMessage name="employment.employerName" component={FormErrors}/>
            </FormControl>
            )
          }

          {currentProvider?.requestDocument && (
            <>
              <FormControl
                className={classes.formControl}
                size="small"
                variant="outlined"
              >
                <label className={classes.label}>ID Type</label>
                <Select
                  defaultValue=""
                  labelId="identificationType"
                  id="identificationType"
                  name="identificationType"
                  {...getFieldProps("identificationType")}
                >
                  <MenuItem value={"International Passport"}>
                    International Passport
                  </MenuItem>
                  <MenuItem value={"Voter's Card"}>Voter's Card</MenuItem>
                  <MenuItem value={"NIM"}>National ID Card</MenuItem>
                  <MenuItem value={"Driver's Licence"}>
                    Driver's Licence
                  </MenuItem>
                </Select>
                <ErrorMessage
                  name="identificationType"
                  component={FormErrors}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <label className={classes.label}>ID Number</label>
                <TextField
                  name="idNumber"
                  id="idNumber"
                  {...getFieldProps("idNumber")}
                  variant="outlined"
                  size="small"
                />

                <ErrorMessage name="idNumber" component={FormErrors} />
              </FormControl>
            </>
          )}
        </div>

        <Box marginTop={3} marginBottom={2}>
          <label variant="h5">Next of Kin Information</label>
        </Box>

        <div className={classes.fieldWrapper}>
          <FormControl
            className={classes.formControl}
            variant="outlined"
            size="small"
          >
            <label className={classes.label}>First Name</label>
            <TextField
              name="nextOfKin.firstName"
              id="nextOfKin.firstName"
              {...getFieldProps("nextOfKin.firstName")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage name="nextOfKin.firstName" component={FormErrors} />
          </FormControl>

          <FormControl
            className={classes.formControl}
            variant="outlined"
            size="small"
          >
            <label className={classes.label}>Last Name</label>
            <TextField
              name="nextOfKin.lastName"
              id="nextOfKin.lastName"
              {...getFieldProps("nextOfKin.lastName")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage name="nextOfKin.lastName" component={FormErrors} />
          </FormControl>

          <FormControl className={classes.formControl}>
            <label className={classes.label}>Phone Number (NGN)</label>
            <TextField
              name="nextOfKin.phoneNumber"
              id="nextOfKin.phoneNumber"
              type="tel"
              className={classes.numberInput}
              {...getFieldProps("nextOfKin.phoneNumber")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage
              name="nextOfKin.phoneNumber"
              component={FormErrors}
            />
          </FormControl>

          <FormControl
            className={classes.formControl}
            variant="outlined"
            size="small"
          >
            <label className={classes.label}>Home Address</label>
            <TextField
              name="nextOfKin.address"
              id="nextOfKin.address"
              {...getFieldProps("nextOfKin.address")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage name="nextOfKin.address" component={FormErrors} />
          </FormControl>

          <FormControl
            className={classes.formControl}
            variant="outlined"
            size="small"
          >
            <label className={classes.label}>Relationship with Applicant</label>
            <TextField
              name="nextOfKin.relationship"
              id="nextOfKin.relationship"
              {...getFieldProps("nextOfKin.relationship")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage name="nextOfKin.relationship" component={FormErrors} />
          </FormControl>
        </div>
        <ControlButtons step={1} />
      </Paper>
    </div>
  );
};

export default connect(StudentInformation);
