import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Box,
  FormControl,
  Grid,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Formik, Form, FieldArray } from "formik";
import Button from "../../../../Button";

import StatusIndicator from "../../../../Reusables/StatusIndicator";
import AlertContext from "../../../../../Contexts/alert/alertContext";
import AxiosRequest from "../../../../../Services/AxiosRequests";

const ProvidersettingsLayout = ({ partner }) => {
  const [partnersettings, setPartnersettings] = useState([]);
  const { setAlert } = useContext(AlertContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const initialValues = { settings: partnersettings };

  useEffect(() => {
    const getPartnersettings = async () => {
      try {
        const { data } = await AxiosRequest(
          "get",
          `/OrganizationSettings/?orgCode=${partner.orgCode}`
        );
        if (data.requestSuccessful) {
          setPartnersettings(
            data.responseData.find(
              (orgPartner) => orgPartner.orgId === partner.id
            ).settings || []
          );
        }
      } catch (err) {
        //   actionError(err);
      }
    };

    getPartnersettings();

    //eslint
  }, []);

  console.log(partner.orgCode);
  const createPartnersettings = async (payload) => {
    try {
      let url = partnersettings.length
        ? `/OrganizationSettings/update/${partner.id}`
        : `/OrganizationSettings/create?orgCode=${partner.orgCode}`;
      let method = partnersettings.length ? "put" : "post";
      const { data } = await AxiosRequest(method, url, payload);
      if (data.requestSuccessful) {
        setAlert(`Details created successfully`, "success");
      } else {
        setAlert(data.message, "error");
      }
    } catch (err) {
      //   actionError(err);
    }
  };

  return (
    <>
      <Typography variant="h5">Partner Configuration</Typography>
      <Box marginTop={4}>
        <Formik
          initialValues={initialValues}
          onSubmit={createPartnersettings}
          enableReinitialize
        >
          {({ values, isSubmitting, getFieldProps }) => (
            <Form autoComplete="off">
              <FieldArray
                name="settings"
                render={(arrayHelpers) => (
                  <>
                    <Grid container spacing={isMobile ? 1 : 3}>
                      {values.settings.map((value, index) => (
                        <Grid
                          container
                          spacing={isMobile ? 1 : 2}
                          item
                          xs={12}
                          key={index}
                          style={{ alignItems: "flex-end" }}
                        >
                          <Grid item xs={12} sm={2}>
                            <FormControl style={{ width: "100%" }}>
                              <Typography>Parameter Name</Typography>
                              <TextField
                                variant="outlined"
                                required
                                name={`settings[${index}].parameter`}
                                {...getFieldProps(
                                  `settings[${index}].parameter`
                                )}
                                size="small"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <FormControl style={{ width: "100%" }}>
                              <Typography htmlFor="value">Value</Typography>
                              <TextField
                                variant="outlined"
                                required
                                name={`settings.${index}.value`}
                                {...getFieldProps(`settings.${index}.value`)}
                                size="small"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item>
                            <StatusIndicator
                              text={"Delete"}
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                    <Box marginTop={3}>
                      <Typography
                        color="primary"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          arrayHelpers.push({ parameter: "", value: "" })
                        }
                      >
                        <AddCircleOutlineIcon
                          style={{ verticalAlign: "bottom" }}
                        />{" "}
                        Add Configuration
                      </Typography>
                    </Box>
                    <Box marginTop={7} align="right">
                      <Button
                        padding="1"
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={
                          isSubmitting ||
                          (!values.settings.length && !partnersettings.length)
                        }
                        loading={isSubmitting}
                      >
                        {partnersettings.length ? "Update" : "Submit"}
                      </Button>
                    </Box>
                  </>
                )}
              />
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default ProvidersettingsLayout;
