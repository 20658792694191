import React, { useState, useEffect, useContext } from "react";
import { useLocation, withRouter } from "react-router-dom";
import LoanContext from "../../../../Contexts/loan/loanContext";
import Dialog from "../../../Reusables/Dialog";

import queryString from "query-string";
import Spinner from "../../../Reusables/Spinner";
import {
  Box,
  Typography,
  Container,
  Paper,
  useMediaQuery,
  DialogContentText,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { FaCircle } from "react-icons/fa";
import Button from "../../../Button";
import { useTheme } from "@material-ui/styles";

import { useStyles } from "./style";

const ProviderSelectLayout = (props) => {
  const location = useLocation();
  const providerId = queryString.parse(location.search).providerId;
  const [provider, setProvider] = useState(providerId ? providerId : "");

  const loanRef = queryString.parse(location.search).loanRef;
  const userId = queryString.parse(location.search).userId;

  const [open, setOpen] = useState(false);
  const [loanProvider, setLoanProvider] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const {
    getProvidersSettings,
    pSettings,
    isLoading,
    getProviders,
    providers,
    attachProviderToLoan,
    isSubmitting,
    currentPartner,
  } = useContext(LoanContext);

  if (provider !== "" && typeof window !== "undefined") {
    // localStorage.setItem("providerId", provider)
  }

  useEffect(() => {
    getProvidersSettings();
    providers.length < 1 && getProviders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendProviderId = (providerId) => {
    const providerCode = providers.find(
      (provider) => providerId === provider.id
    ).sortCode;

    attachProviderToLoan(loanRef, providerCode, userId);
  };

  let newProviders;
  //First check if there is a current partner held in state
  //Secondly, in case of refresh on this page, check for the partner state in props
  //If both are empty then, we render default

  if (JSON.stringify(currentPartner) !== "{}") {
    newProviders = currentPartner.providers.filter(
      (loanP) => loanP.status === "active"
    );
  } else if (props.history.location.state?.partner) {
    newProviders = props.history.location.state.partner.providers.filter(
      (loanP) => loanP.status === "active"
    );
  } else {
    //default - no partner invloved, defaulting to educollectwebsite entry
    //Or admin has no provider configured for the partner
    newProviders = providers.filter((loanP) => loanP.status === "active");
  }


  const handleClose = () => {
    setOpen(false);
  };

  // get the settings of provider selected
  const showProviderSetting = (id) => {
    setLoanProvider(pSettings.find((provider) => provider.providerId === id));
    setOpen(true);
  };

  //Dialog content
  const content = loanProvider !== null && (
    <Box
      align="center"
      style={{ width: isMobile ? "100%" : "30rem", paddingBottom: "1rem" }}
    >
      <Box align="left">
        <Box
          id="scroll-dialog-title"
          classes={{
            root: classes.dialogTitle,
          }}
        >
          {/* <Typography variant="h4">{loanProvider.providerName} Loan</Typography> */}
          <Typography variant="h4">Loan Application</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogContent
          classes={{
            root: classes.dialogContent,
          }}
        >
          <Box marginTop={"-1rem"}>
            <hr />
          </Box>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            {loanProvider.settings.map((setting, index) => {
              for (const [key, value] of Object.entries(setting)) {
                return (
                  <Box marginTop={3} key={index}>
                    <Typography
                      style={{ color: "#000000", fontSize: "1.4rem" }}
                    >
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </Typography>
                    <Box marginTop={1}>
                      {value.map((val, i) => (
                        <Box
                          marginBottom={1}
                          key={i}
                          style={{
                            display: "flex",
                            gap: "1rem",
                            alignItems: "center",
                          }}
                        >
                          <Typography className={classes.icon}>
                            <FaCircle /> &nbsp;{" "}
                          </Typography>
                          <Typography className={classes.text}>
                            {" "}
                            {val}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                );
              }
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box
            paddingRight={"1rem"}
            style={{ display: "flex", gap: "1rem", alignItems: "center" }}
          >
            <Typography
              onClick={handleClose}
              size="small"
              color="primary"
              style={{ cursor: "pointer", marginRight: "1rem" }}
            >
              Cancel
            </Typography>
            {/* this will be conditional, if there's a loan ref, be a function call that updates loan ref
          and redirects to /apply?loanRef&userId else, be ordinary link*/}
            {loanRef !== undefined && userId !== undefined ? (
              <Button
                onClick={() => sendProviderId(loanProvider.providerId)}
                variant="contained"
                size="small"
                color="primary"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                Proceed
              </Button>
            ) : (
              <Button
                onClick={handleClose}
                linkPage={`apply?providerId=${loanProvider.providerId}&userId=${userId}`}
                variant="contained"
                size="small"
                color="primary"
              >
                Proceed
              </Button>
            )}
          </Box>

          {/* </a> */}
        </DialogActions>
      </Box>
    </Box>
  );

  return !isLoading && newProviders.length > 0 ? (
    <Container className={classes.root}>
      <Typography color="primary" variant="h4" gutterBottom>
        Select a loan plan
      </Typography>

      <Paper className={classes.paper}>
        {
          newProviders.map((loanProvider, index) => {
            const { logoUrl, providerName, id, tenor, maximumLoan } =
              loanProvider;
            return (
              <Box className={classes.provider} marginTop={1} key={index}>
                <div className={classes.subProvider}>
                  {/* <Box>
                    <img
                      src={logoUrl}
                      // width={isMobile ? "80%" : "70%"}
                      alt={providerName}
                      style={{ maxHeight: "3rem", width: "100%" }}
                    />
                  </Box> */}

                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ marginTop: "1.2rem" }}
                    gutterBottom
                  >
                    MAXIMUM AMOUNT
                  </Typography>
                  <Typography variant="h4">
                    NGN {maximumLoan && maximumLoan}{" "}
                  </Typography>
                  <Typography gutterBottom>Tenure: {tenor}</Typography>
                  <Box marginTop={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="medium"
                      // onClick={() => handleOpen(providerName)}
                      //pass the providerId so we use to get settings
                      onClick={() => showProviderSetting(id)}
                    >
                      Apply
                    </Button>
                  </Box>
                </div>
              </Box>
            );
          })
          // <Typography>There are no Providers at the moment</Typography>
        }
      </Paper>
      <Dialog handleClose={handleClose} open={open} content={content} />
    </Container>
  ) : (
    <Spinner />
  );
};

export default withRouter(ProviderSelectLayout);
