import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
   root: {
       height: "100vh",
       padding: theme.spacing(8, 0)
   },

   paper: {
       width: "80%",
       margin: "0 auto",
       height: "100%",
       display: "flex",
       justifyContent: "center",
       flexDirection: "column",
       alignItems: "center",
    padding: theme.spacing(0, 3)
   }
}))

export { useStyles }