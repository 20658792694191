import { Typography } from '@material-ui/core'
import React from 'react'

const FormErrors = props => {
    return (
        <Typography style={{color:"red", fontSize:"13px"}}>
            {console.log(props.children)}
            {props.children}
        </Typography>
    )
}

export default FormErrors
