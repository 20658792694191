import {
  SET_LOADING,
  GET_INSTITUTIONS_SUCCESS,
  GET_BANKS_SUCCESS,
  ACTION_SUCCESS,
  SET_USER_DATA,
  STUDENT_VERIFICATION_SUCCESS,
  STUDENT_VERIFICATION_FAIL,
  ACTION_ERROR,
  HANDLE_PREVIOUS,
  HANDLE_NEXT,
  APPLICATION_FAIL,
  APPLICATION_SUCCESS,
  GET_LOAN_SUCCESS,
  GET_LOAN,
  GET_LOAN_FAIL,
  FILL_BANK_FORM,
  INFORMATION_MISMATCH,
  SET_ISSUBMITTING,
  LOAN_ACTION_SUCCESS,
  LOAN_ACTION_ERROR,
  SET_USER_EMAIL,
  SET_ISCOMPLETED,
} from "../types";

const credpalReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        institutions: payload,
        loading: false,
      };

    case GET_BANKS_SUCCESS:
      return {
        ...state,
        banks: payload,
        loading: false,
      };

    case SET_USER_DATA:
      return {
        ...state,
        isSubmitting: false,
        user: payload,
      };

    case SET_USER_EMAIL:
      console.log("The user email is payload", payload);
      return {
        ...state,
        userEmail: payload,
      };

    case STUDENT_VERIFICATION_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        ...payload,
        verified: payload.verifiedData ? true : false,
      };

    case STUDENT_VERIFICATION_FAIL:
    case APPLICATION_FAIL:
      return {
        ...state,
        isSubmitting: false,
      };

    case APPLICATION_SUCCESS: 
    return {
      ...state,
      isSubmitting: false,
      removeBankInformation: false 
    }

    case GET_LOAN:
      return {
        ...state,
        gettingLoan: true,
      };

    case GET_LOAN_SUCCESS:
      //setting back the user state to use back in component
      const {
        // isSelfSponsored,
        institutionInfo: {
          code,
          name,
          bankAccountName,
          bankAccountNo,
          bankName,
        },
        regNo,
        loanRequest: { status, isSelfSponsored, borrowerType },
      } = payload;
      return {
        ...state,
        currentLoan: payload,
        user: {
          userType: isSelfSponsored ? "student" : "sponsor",
          regNo: regNo,
          institutionCode: code,
          institutionName: name,
          bankAccountName: bankAccountName,
          bankAccountNo: bankAccountNo,
          bankName: bankName,
          borrowerType: borrowerType
        },
        //here's making sure the loan has not been completed, declined or approved
        activeStep: !["INITIATED", "COMPLETED"].includes(status) ? 6 : 1,
        // activeStep: 4,
        gettingLoan: false,
      };

    case GET_LOAN_FAIL:
      return {
        ...state,
        gettingLoan: false,
      };

    case FILL_BANK_FORM:
      return {
        ...state,
        activeStep: 3
      }
    
      case INFORMATION_MISMATCH: 
      return {
        ...state,
        removeBankInformation: true
      }

    case LOAN_ACTION_ERROR:
    case LOAN_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        isSubmitting: false,
      };

    case SET_ISSUBMITTING:
      return {
        ...state,
        isSubmitting: true,
      };

    case HANDLE_NEXT:
      return {
        ...state,
        activeStep: state.activeStep + payload,
      };

    case HANDLE_PREVIOUS:
      return {
        ...state,
        activeStep: state.activeStep - payload,
      };

    case ACTION_ERROR:
    case ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        isSubmitting: false,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SET_ISCOMPLETED:
      console.log("The user loan is completed");
      return {
        ...state,
        isSubmitting : false,
        isLoanCompleted: payload,
      };

    default:
      return state;
  }
};

export default credpalReducer;

