import { useContext } from "react";
import axiosInstance from "./axiosInstance";
import alertContext from "../Contexts/alert/alertContext";

import { logOut } from "../Actions/userActions";
// import { useHistory } from "react-router-dom";

const WithAxios = ({ children }) => {
  // const history = useHistory();

  const { setAlert } = useContext(alertContext);

  const successHandler = (response) => {
    return response;
  };

  const errorHandler = (error) => {
    const status = error.response?.status;
    if (status === 401) {
      console.log("Unauthorized");
      setAlert("Session Expired", "error");
      logOut();
      return {};
    } else if (status === 403) {
      console.log("No Access");
      setAlert("You're not allowed to perform this action", "error");
    } else if (status === 500) {
    //   history.push("/serverError");
    setAlert("An error occured, please try again", "error")
    } else if (error.message === "Network Error") {
      // setAlert("Check your Internet connection", "error");
    }
    console.log(typeof error);
    return Promise.reject(error);
  };

  axiosInstance.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandler(error)
  );

  return children;
};

export default WithAxios;
