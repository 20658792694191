import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  headerTexts: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableCTAs: {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      gap: "1rem",
    },
  },
  filterTrigger: {
    border: "1px solid #D7DCE0",
    borderRadius: "0.3rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 0.8rem",
    cursor: "pointer",
    margin: "0 3rem 0 2rem",
    width: "40%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "3rem",
      margin: 0,
    },
  },
  numberToShow: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  select: {
    marginLeft: "1rem",
    width: "10rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  dialogTitle: {
      display: "flex",
      alignItems: "center",
    padding: theme.spacing(2, 3, 0, 3),
  },
  dialogContent: {
    padding: theme.spacing(0, 3, 3, 3),
  },
  icon: {
    color: "#000000",
    fontSize: "0.5rem",
  },
  formControl: {
    width: "100%",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  filterActions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2rem",
    alignItems: "center",
  },
createActions: {
    marginTop: "2rem",
    display: "flex",
    gap: "2rem",
    alignItems: "center",
    justifyContent: "flex-end"
},
actionText: {
    color: theme.palette.primary.main,
    cursor: "pointer"
},
 numberInput: {
        '& ::-webkit-outer-spin-button, & ::-webkit-inner-spin-button' : {
            '-webkit-appearance': 'none',
            'margin': 0
        },
        '& ::-webkit-inner-spin-button' : {
            '-webkit-appearance': 'none',
            'margin': 0
        },
        '& [type=number]' : {
            '-moz-appearance': 'textfield'
        },
    },
    statusIndicator: {
        borderRadius: "5px",
        display: "flex",
        justifyContent: "center",
      },
      userView: {
          padding: "1rem 0 5rem",
          display: "flex",
          flexWrap: "wrap",
          color: "#3A434B",
          gap: "1rem"
      },
      userItem: {
          wordBreak: "break-all",
          width: "100%",
          [theme.breakpoints.down("xs")]: {
            width: "45%",
            
          },
      },
      role: {
        fontSize: "12px",
         fontWeight: 500
      },
      switchControl: {
        '&.MuiFormControlLabel-labelPlacementStart': {
          marginRight: 'auto',
          marginLeft: '0px',
        }
      },
  addBullet: {
    color: theme.palette.primary.main,
    display: "flex",
    margin: "10px 0px",
  },
  deleteBullet: {
    color: "red",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  }
}));

export { useStyles };




//     "guarantors": [
//       {
//         "id": "0d59f29f-b6c8-4fa3-a144-3f410e8663e0",
//         "firstName": "James",
//         "lastName": "James",
//         "phoneNumber": "0393030303",
//         "relationship": "Sister"
//       }
//     ],
//    
//   },
