import React from "react";
import { css } from "@emotion/core";
import MoonLoader from "react-spinners/MoonLoader";
 
const override = css`
  display: block;
  margin: 0 auto;
  border-color: rgb(17, 122, 243);
`;
const Spinner = ( { size, height } ) => {
 
    return (
      <div  style={{display:"flex", height:height, width:"100%", justifyContent: "center", alignItems:"center"}}>
        <MoonLoader
          css={override}
          size={size}
          color={"rgb(17, 122, 243)"}
          loading={true}
        />
      </div>
    );
  }

  Spinner.defaultProps = {
    height: "100vh"
  }

  export default Spinner