import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  IconButton,
  FormControl,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import debounce from "lodash.debounce";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import clsx from "clsx";

import Paper from "../../../Paper";
import Dialog from "../../../Reusables/Dialog";
import Button from "../../../Button";
import Table from "../../../Reusables/Table";
import Pagination from "../../../Reusables/Pagination";
import StatusIndicator from "../../../Reusables/StatusIndicator";
import TableHead from "../../../Reusables/TableHead";

import alertContext from "../../../../Contexts/alert/alertContext";
import userContext from "../../../../Contexts/users/userContext";

import FormErrors from "../../../FormErrors";
import {
  nameValidation,
  phoneNumberValidation,
  emailValidation,
} from "../../../../Actions/formActions";

import { useStyles } from "./style";
import {
  capitalizeFirstLetter,
  formatDateTime,
  validateDates,
} from "../../../../Actions/helpers";

const AdminUsersLayout = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIssubmitting] = useState(false);
  const [adminUser, setAdminUser] = useState(null);
  const [adminUsers, setAdminUsers] = useState({});
  const [dialogContentIndex, setDialogContentIndex] = useState(3);

  const { setAlert } = useContext(alertContext);
  const { getUsersData, getAdminRoles, roles, createAdmin } = useContext(
    userContext
  );

  const initialState = {
    email: "",
    startDate: "",
    endDate: "",
    firstName: "",
    lastName: "",
    roleName: "",
    pageSize: 10,
    pageNumber: 1,
  };

  const [payload, setPayload] = useState(initialState);
  const {
    email,
    startDate,
    endDate,
    roleName,
    firstName,
    lastName,
    pageSize,
    pageNumber,
  } = payload;

  const _isMounted = useRef(true);

  useEffect(() => {
    if (_isMounted.current === false) {
      _isMounted.current = true;
    }

    getAdminUsers();

    return () => (_isMounted.current = false);
    //eslint-disable-next-line
  }, [pageSize, pageNumber]);

  useEffect(() => {
    getAdminRoles();

    // eslint-disable-next-line
  }, []);

  ////////////////////////**********MODAL ACTIONS**********///////////////////
  const triggerModalOpen = (index) => {
    setDialogContentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    //To fix an issue of having data being set to null first before closing modal
    if (open === false) {
      adminUser !== null && setAdminUser(null);
    }
  };
  ////////////////////////**********MODAL ACTIONS**********///////////////////

  ///////////////////////***********USER CREATE ACTIONS***********//////////////
  const [passwords, showPasswords] = useState({
    password1: false,
    password2: false,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const anchorOpen = Boolean(anchorEl);

  const handleMenuOpen = (e, adminUser) => {
    setAnchorEl(e.currentTarget);
    setAdminUser(adminUser);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // if(dialogContentIndex !==3){
    //   adminUser !== null && setAdminUser(null);
    // }
  };

  const { password1, password2 } = passwords;

  const handleClickShowPassword = (name, value) => {
    showPasswords({ ...passwords, [name]: value });
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const createAdminUser = async (values) => {
    try {
      setIssubmitting(true);
      const res = await createAdmin(values);
      if (res.data.requestSuccessful) {
        let action = adminUser !== null ? "Updated" : "Created";
        setAlert(`Admin User ${action} successfully`, "success");
        getAdminUsers();
        handleClose();
      } else {
        setAlert(res.data.message, "error");
      }
      setIssubmitting(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getAdminUserRoles = () => {
    let roles = [];
    adminUser.roles.map((role) => (roles = [...roles, role.roleId]));
    //would return roles array when a user can belong to more than one
    return roles[0];
  };

  const initialValues = {
    firstName: adminUser?.firstName ? adminUser?.firstName : "",
    lastName: adminUser?.lastName ? adminUser?.lastName : "",
    email: adminUser?.email ? adminUser?.email : "",
    phoneNumber: adminUser?.phoneNumber ? adminUser?.phoneNumber : "",
    password: "",
    password2: "",
    roleId: adminUser?.roles ? getAdminUserRoles() : "",
    isActive: adminUser?.isActive ? adminUser?.isActive : false,
  };

  const validationSchema = Yup.object({
    firstName: nameValidation("First Name", "firstName"),
    lastName: nameValidation("Surname", "lastName"),
    email: emailValidation(),
    phoneNumber: phoneNumberValidation("Phone Number", "phoneNumber"),
    password:
      adminUser === null &&
      Yup.string()
        .required("Password is empty")
        .min(8, "Password must not be less than 8 characters")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]/,
          "Password must contain at least one Uppercase, one Lowercase, one Number and one special character"
        ),
    password2:
      adminUser === null &&
      Yup.string()
        .required("Re-enter Password")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    // roleId: Yup.array().min(1, "Select at least one role"), validation for multiple role selection
    roleId: Yup.string().required("Select a role"),
  });

  const onSubmit = async (values) => {
    const {
      firstName,
      lastName,
      email,
      password,
      phoneNumber,
      roleId,
    } = values;

    values = {
      firstName: capitalizeFirstLetter(firstName),
      lastName: capitalizeFirstLetter(lastName),
      phoneNumber,
      userName: email,
      email,
      password,
      confirmPassword: password,
      roleId,
      // isActive,
    };

    //If its an apdate action
    if (adminUser !== null) {
      delete values.password;
      delete values.confirmPassword;
      values = { ...values, id: adminUser.id };
    }

    createAdminUser(values);
  };

  /////////////////**********USER CREATE *************////////////////

  /////////////////**********TABLE ACTIONS *************////////////////
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    ///always reset pageNumber if pageSize or email changes to avoid wrong data fetch
    if (name === "pageSize" || name === "email") {
      payload.pageNumber = 1;
    }
    setPayload({ ...payload, [name]: value });
  };

  const handlePageClick = (page) => {
    setPayload({ ...payload, pageNumber: page });
  };

  const handleEmailSearch = (e) => {
    setPayload({ ...payload, email: e.target.value });
    loadWithDebounce(e.target.value);
  };

  let url = `User/AdminUsers/?pageSize=${pageSize}&pageNo=${pageNumber}`;
  let filter = false;

  const clearFilters = async () => {
    //make the request without filters, taking into consideration the state of the email field
    setPayload({ ...initialState, email });
    filter = true;
    if (email !== "") {
      url += `&email=${email}`;
    }
    await getAdminUsers(url, filter);
  };

  const getAdminUsersFromFirstPage = () => {
    if (pageNumber !== 1) {
      setPayload({ ...payload, pageNumber: 1 });
    }
    pageNumber === 1 && getAdminUsers();
  };

  const getAdminUsers = async (
    url = `User/AdminUsers?pageSize=${pageSize}&page=${pageNumber}`,
    clearFilter
  ) => {
    if (!clearFilter) {
      if (email !== "") {
        url += `&email=${email}`;
      }

      if (!validateDates(startDate, endDate)) {
        setAlert("Please enter valid dates", "error");
        return;
      } else if (startDate !== "" && endDate !== "") {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }

      if (firstName !== "") {
        url += `&firstName=${firstName}`;
      }

      if (lastName !== "") {
        url += `&lastName=${lastName}`;
      }

      if (roleName !== "") {
        url += `&roleName=${roleName}`;
      }
    }

    setLoading(true);
    try {
      const res = await getUsersData(url);
      if (_isMounted.current) {
        setAdminUsers(res.data.responseData);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
    open && handleClose();
    filter = false;
  };

  const loadWithDebounce = useCallback(
    debounce((email) => {
      if (email === "") {
        getAdminUsers(url);
      } else {
        getAdminUsers(`${url}&email=${email}`);
      }
    }, 1000),
    []
  );

  const columns = [
    {
      Header: "First Name",
      accessor: "firstName",
      Cell: (props) => capitalizeFirstLetter(props.value),
    },
    {
      Header: "last Name",
      accessor: "lastName",
      Cell: (props) => capitalizeFirstLetter(props.value),
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Role",
      accessor: "roles",
      Cell: (props) =>
        props.row.original.roles.map((role, i) => (
          <Typography key={i} className={classes.role}>
            {role.roleName}
          </Typography>
        )),
    },
    {
      Header: "Date Created",
      accessor: "dateCreated",
      Cell: (props) => formatDateTime(props.value),
    },
    {
      Header: "Status",
      accessor: "isActive",
      Cell: (props) => <StatusIndicator status={props.value} />,
    },

    {
      Header: " ",
      Cell: (props) => {
        return (
          <>
            <IconButton
              aria-label="more"
              aria-haspopup="true"
              aria-controls={props.row.original.id}
              onClick={(e) => handleMenuOpen(e, props.row.original)}
            >
              <MoreVertIcon color="primary" style={{ cursor: "pointer" }} />
            </IconButton>
          </>
        );
      },
    },
  ];

  /////////////////**********TABLE ACTIONS *****************////////////////

  /////////////////**********MODAL VIEWS *****************////////////////
  const filterForm = () => (
    <>
      <Grid container spacing={2} style={{ marginTop: "1rem" }}>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <Typography variant="body2" gutterBottom>
              Start Date
            </Typography>
            <TextField
              name="startDate"
              variant="outlined"
              size="small"
              type="date"
              value={startDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <Typography variant="body2" gutterBottom>
              End Date
            </Typography>
            <TextField
              name="endDate"
              variant="outlined"
              size="small"
              type="date"
              value={endDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <Typography variant="body2" gutterBottom>
              First Name
            </Typography>
            <TextField
              name="firstName"
              variant="outlined"
              size="small"
              value={firstName}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <Typography variant="body2" gutterBottom>
              Last Name
            </Typography>
            <TextField
              name="lastName"
              variant="outlined"
              size="small"
              value={lastName}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            size="small"
          >
            <Typography variant="body2" gutterBottom>
              Role
            </Typography>

            <Select
              displayEmpty
              labelId=""
              id="roleName"
              name="roleName"
              value={roleName}
              onChange={handleInputChange}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem disabled value="">
                Select
              </MenuItem>
              {roles?.items?.map((val, key) => (
                <MenuItem key={key} value={val.roleName}>
                  {val.roleName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box className={classes.filterActions}>
        <Typography
          style={{ color: "red", cursor: "pointer" }}
          onClick={clearFilters}
        >
          Clear all filters
        </Typography>
        <Button
          variant="contained"
          size="medium"
          disabled={
            loading ||
            (lastName === "" &&
              firstName === "" &&
              roleName === "" &&
              startDate === "" &&
              endDate === "")
          }
          loading={loading}
          // type="submit"
          color="primary"
          onClick={() => getAdminUsersFromFirstPage()}
        >
          Apply
        </Button>
      </Box>
    </>
  );

  console.log(roles)

  const viewUser = () => {
    if (adminUser !== null) {
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        dateCreated,
        isActive,
        roles,
      } = adminUser;

      return (
        <Box className={classes.userView}>
          <Box className={classes.userItem}>
            <small variant="body1">First Name</small>
            <Typography>{firstName}</Typography>
          </Box>

          <Box className={classes.userItem}>
            <small>Last Name</small>
            <Typography>{lastName}</Typography>
          </Box>
          <Box className={classes.userItem}>
            <small>Email</small>
            <Typography>{email}</Typography>
          </Box>

          <Box className={classes.userItem}>
            <small>Phone Number</small>
            <Typography>{phoneNumber}</Typography>
          </Box>

          <Box className={classes.userItem}>
            <small>Date Created</small>
            <Typography>{formatDateTime(dateCreated)}</Typography>
          </Box>

          <Box className={classes.userItem}>
            <small>Status</small>
            <Typography>{isActive === true ? "Active" : "Inactive"}</Typography>
          </Box>

          <Box className={classes.userItem}>
            <small>Role</small>
            {roles.map((role, key) => (
              <Typography key={key}> {role.roleName}</Typography>
            ))}
          </Box>
        </Box>
      );
    }
  };

  const createUserForm = () => (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, getFieldProps, values }) => (
        <Form noValidate autoComplete="off">
          <Grid container spacing={2} style={{ marginTop: "1rem" }}>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <Typography gutterBottom>First Name</Typography>
                <TextField
                  name="firstName"
                  placeholder="First Name"
                  id="firstName"
                  disabled={adminUser !== null}
                  variant="outlined"
                  {...getFieldProps("firstName")}
                  error={errors.firstName && touched.firstName ? true : false}
                  size="small"
                />
                <ErrorMessage name="firstName" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <Typography gutterBottom>Surname</Typography>
                <TextField
                  name="lastName"
                  placeholder="Last Name"
                  id="lastName"
                  disabled={adminUser !== null}
                  error={errors.lastName && touched.lastName ? true : false}
                  variant="outlined"
                  {...getFieldProps("lastName")}
                  size="small"
                />
                <ErrorMessage name="lastName" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <Typography gutterBottom>Email Address</Typography>
                <TextField
                  name="email"
                  id="email"
                  disabled={adminUser !== null}
                  placeholder="Email Address"
                  error={errors.email && touched.email ? true : false}
                  variant="outlined"
                  {...getFieldProps("email")}
                  size="small"
                />
                <ErrorMessage name="email" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <Typography gutterBottom>Phone Number</Typography>
                <TextField
                  name="phoneNumber"
                  id="phoneNumber"
                  type="number"
                  disabled={adminUser !== null}
                  error={
                    errors.phoneNumber && touched.phoneNumber ? true : false
                  }
                  placeholder="Phone Number"
                  variant="outlined"
                  {...getFieldProps("phoneNumber")}
                  className={classes.numberInput}
                  size="small"
                />
                <ErrorMessage name="phoneNumber" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              style={{ display: adminUser !== null ? "none" : "block" }}
            >
              <FormControl className={classes.formControl}>
                <Typography gutterBottom>Password</Typography>
                <TextField
                  name="password"
                  id="password1"
                  {...getFieldProps("password")}
                  variant="outlined"
                  placeholder="Password"
                  type={password1 ? "text" : "password"}
                  error={errors.password && touched.password ? true : false}
                  size="small"
                  InputProps={{
                    endAdornment: values.password !== "" && (
                      <Typography
                        position="end"
                        className={classes.actionText}
                        onClick={() =>
                          handleClickShowPassword("password1", !password1)
                        }
                        onMouseDown={handleMouseDownPassword}
                      >
                        {password1 ? "Hide" : "Show"}
                      </Typography>
                    ),
                  }}
                />
                <ErrorMessage name="password" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              style={{ display: adminUser !== null ? "none" : "block" }}
            >
              <FormControl className={classes.formControl}>
                <Typography gutterBottom>Confirm Password</Typography>
                <TextField
                  name="password2"
                  id="password2"
                  placeholder="Re-enter Password"
                  {...getFieldProps("password2")}
                  variant="outlined"
                  type={password2 ? "text" : "password"}
                  error={errors.password2 && touched.password2 ? true : false}
                  size="small"
                  InputProps={{
                    endAdornment: values.password2 !== "" && (
                      <Typography
                        position="end"
                        className={clsx(classes.actionText, classes.text)}
                        onClick={() =>
                          handleClickShowPassword("password2", !password2)
                        }
                        onMouseDown={handleMouseDownPassword}
                      >
                        {password2 ? "Hide" : "Show"}
                      </Typography>
                    ),
                  }}
                />
                <ErrorMessage name="password2" component={FormErrors} />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <Typography gutterBottom>Role</Typography>
                <Select
                  displayEmpty
                  error={errors.roleId && touched.roleId ? true : false}
                  {...getFieldProps("roleId")}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  {roles?.items?.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.roleName}
                    </MenuItem>
                  ))}
                </Select>
                {/* <Select
                  multiple
                  displayEmpty
                  error={errors.roleId && touched.roleId ? true : false}
                  {...getFieldProps("roleId")}
                  MenuProps={{
                    getContentAnchorEl: () => null,
                }}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return "Select";
                    }
                    let newSelected = [];
                    selected.map(
                      (item) =>
                        (newSelected = [
                          ...newSelected,
                          roles.items.find((role) => item === role.id).roleName,
                        ])
                    );
                    return newSelected.join(", ");
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  {roles?.items?.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.roleName}
                    </MenuItem>
                  ))}
                </Select> */}
                <ErrorMessage name="roleId" component={FormErrors} />
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <FormControl
                variant="outlined"
                size="small"
                className={classes.formControl}
              >
                <Typography gutterBottom>Toggle to activate user</Typography>
                <Box
                  style={{ display: "flex", gap: "2rem", alignItems: "center" }}
                >
                  <Typography>Enable</Typography>
                  <FormControlLabel
                    control={
                      <Field
                        component={Switch}
                        id="isActive"
                        type="checkbox"
                        checked={values.isActive}
                        name="isActive"
                        {...getFieldProps("isActive")}
                        color="primary"
                      />
                    }
                  />
                </Box>
              </FormControl>
            </Grid> */}
          </Grid>

          <Box className={classes.createActions}>
            <Typography
              variant="body2"
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            >
              Cancel
            </Typography>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              padding={"0.8rem 1.5rem"}
            >
              Submit
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );

  /////////////////**********MODAL VIEWS *****************////////////////

  const getDialogContent = () => {
    switch (dialogContentIndex) {
      case 1:
        return filterForm();

      case 2:
        return viewUser();

      case 3:
        return createUserForm();

      default:
        return "Nothing";
    }
  };

  const dialogContent = () => (
    <Box style={{ width: "100%" }} className={classes.paper}>
      <Box className={classes.dialogTitle}>
        <Typography variant="h4">
          {dialogContentIndex === 1
            ? "Filter"
            : dialogContentIndex === 2
            ? "User Details"
            : adminUser !== null
            ? "Edit Admin User"
            : "Create Admin User"}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className={classes.dialogContent}>
        <hr />
        <Box marginTop={"1rem"}>{getDialogContent()}</Box>
      </Box>
    </Box>
  );

  return (
    <Box>
      <Box className={classes.headerTexts}>
        <Typography variant="h4">User Management</Typography>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => {
            setAdminUser(null);
            triggerModalOpen(3);
          }}
        >
          Create User
        </Button>
      </Box>
      <Box style={{ border: "1px solid #D7DCE0" }} marginTop={"1rem"}>
        <Paper borderRadius={"0"} padding={"2rem"}>
          <TableHead
            title="Total Users"
            searchItem={email}
            onChange={handleEmailSearch}
            loading={loading}
            placeholder="Search By Email"
            totalSize={adminUsers?.totalSize}
            onClick={() => triggerModalOpen(1)}
            handleInputChange={handleInputChange}
          />
          <Box marginTop={"2rem"}>
            {loading && !adminUsers.items ? (
              <p>Loading...</p>
            ) : (!loading && adminUsers.items) ||
              (loading && adminUsers.items) ? (
              <>
                {/* {console.log(users.items)} */}
                <Table
                  columns={columns}
                  data={adminUsers.items}
                  loading={loading}
                />
                <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                  <Grid item xs={12} md={3}>
                    {adminUsers.items.length > 0 && (
                      <Typography>
                        Showing page {adminUsers.pageNumber} of{" "}
                        {Math.ceil(adminUsers?.totalSize / pageSize)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Pagination
                      dataSize={adminUsers?.totalSize}
                      perPage={pageSize}
                      handlePageClick={handlePageClick}
                      forcePage={adminUsers.pageNumber}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <p>Loading...</p>
            )}
          </Box>
        </Paper>
      </Box>
      <>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={anchorOpen}
          onClick={handleMenuClose}
          PaperProps={{
            style: {
              width: "10ch",
            },
          }}
        >
          <MenuItem onClick={() => triggerModalOpen(2)}>View</MenuItem>
          <MenuItem onClick={() => triggerModalOpen(3)}>Edit</MenuItem>
        </Menu>
      </>
      <Dialog handleClose={handleClose} open={open} content={dialogContent()} />
    </Box>
  );
};

export default AdminUsersLayout;
