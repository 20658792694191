import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Cookies from 'js-cookie'

const ProtectedRoute = (props) => {
    const { layout: Layout, component: Component, ...rest } = props;

    return (
        <Route
            {...rest}
            render={
                (props) => {
                        if(Cookies.get("accessToken")){
                        //check for permission here and redirect to steps or component
                        return (
                        <Layout> 
                            <Component {...props}/>
                        </Layout>
                        )
                    }
                    else {
                        return <Redirect to={
                            {
                                pathname: '/adminlogin',
                                state: {
                                    from: props.location
                                }
                            }
                        } />
                    }
                }
            }
        />
    )
}

export default ProtectedRoute
