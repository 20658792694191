import React from 'react'
import { Route } from 'react-router-dom'
import Cookies from 'js-cookie'
import { removeTokens } from '../Actions/userActions'

const ProtectedRoute = (props) => {
    const { component: Component, ...rest } = props;

    return (
        <Route
            {...rest}
            render={
                (props) => {
                    //if admin tries to visit a user's route, log the admin out
                        if(Cookies.get("accessToken")){
                            removeTokens()
                        }
                        return (
                            <Component {...props} />
                        )
                }
            }
        />
    )
}

export default ProtectedRoute
