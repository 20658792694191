import React, { useContext, useState } from "react";
import { useStyles } from "./style";
import { Formik, Form, ErrorMessage } from "formik";
import queryString from "query-string";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import Button from "../../../../Button";
import FormErrors from "../../../../FormErrors";
import { FormControl, TextField, Typography, Box } from "@material-ui/core";
import userContext from "../../../../../Contexts/users/userContext";

const ResetPasswordLayout = () => {
  const classes = useStyles();
  const location = useLocation();

  const { resetPassword } = useContext(userContext);

  const [passwords, showPasswords] = useState({
    password1: false,
    password2: false,
  });

  const { password1, password2 } = passwords;

  const { userId } = queryString.parse(location.search);
  const token = location.search.split("&")[1];
  const initialValues = {
    userId,
    token: token ? token.slice(6) : "",
    password: "",
    password2: "",
  };
  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Password is empty")
      .min(8, "Password must not be less than 8 characters")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]/,
        "Password must contain at least one Uppercase, one Lowercase, one Number and one special character"
      ),
    password2: Yup.string()
      .required("Re-enter Password")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });


  const handleClickShowPassword = (name, value) => {
    showPasswords({ ...passwords, [name]: value });
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <Box marginBottom={"1.8rem"}>
        <Typography variant="h6" align="center" gutterBottom>
          Reset password
        </Typography>
        <Typography align="center" style={{ color: "#9DA9B3" }}>
          Choose a new password
        </Typography>
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          const { password, password2, userId, token } = values;
          values = {
            userId,
            token,
            newPassword: password,
            confirmPassword: password2,
          };
          resetPassword(values, setSubmitting);
        }}
      >
        {({ values, getFieldProps, isSubmitting }) => (
          <Form noValidate autoComplete="off">
            <FormControl className={classes.formControl}>
              <Typography gutterBottom>Password</Typography>
              <TextField
                name="password"
                id="password1"
                {...getFieldProps("password")}
                variant="outlined"
                placeholder="Password"
                type={password1 ? "text" : "password"}
                size="small"
                InputProps={{
                  endAdornment: values.password !== "" && (
                    <Typography
                      position="end"
                      color="primary"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleClickShowPassword("password1", !password1)
                      }
                      onMouseDown={handleMouseDownPassword}
                    >
                      {password1 ? "Hide" : "Show"}
                    </Typography>
                  ),
                }}
              />
              <ErrorMessage name="password" component={FormErrors} />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Typography gutterBottom>Confirm Password</Typography>
              <TextField
                name="password2"
                id="password2"
                placeholder="Re-enter Password"
                {...getFieldProps("password2")}
                variant="outlined"
                type={password2 ? "text" : "password"}
                size="small"
                InputProps={{
                  endAdornment: values.password2 !== "" && (
                    <Typography
                      position="end"
                      style={{ cursor: "pointer" }}
                      color="primary"
                      onClick={() =>
                        handleClickShowPassword("password2", !password2)
                      }
                      onMouseDown={handleMouseDownPassword}
                    >
                      {password2 ? "Hide" : "Show"}
                    </Typography>
                  ),
                }}
              />
              <ErrorMessage name="password2" component={FormErrors} />
            </FormControl>
            <FormControl className={classes.submitCTA}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Reset Password
              </Button>
            </FormControl>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPasswordLayout;
