
// General
export const ACTION_ERROR = "ACTION_ERROR";
export const ACTION_SUCCESS = "ACTION_SUCCESS";
export const SET_LOADING = "SET_LOADING";
export const SET_SUBMITTING = "SET_SUBMITTING";

// Alert
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT"

//Loans
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_PROVIDERS_SUCCESS = "GET_PROVIDERS_SUCCESS";
export const GET_BANKS_SUCCESS = "GET_BANKS_SUCCESS";
export const GET_LOAN_SUCCESS = "GET_LOAN_SUCCESS";
export const LOAN_ACTION_SUCCESS = "LOAN_ACTION_SUCCESS";
export const LOAN_ACTION_ERROR = "LOAN_ACTION_ERROR";
export const GET_USER_LOAN_SUCCESS = "GET_USER_LOAN_SUCCESS";
export const GET_USER_LOAN_FAIL = "GET_USER_LOAN_FAIL";
export const GET_USER_LOAN = "GET_USER_LOAN";
export const CREATE_PROVIDER_SUCCESS = "CREATE_PROVIDER_SUCCESS";
export const GET_PROVIDER_SUCCESS = "GET_PROVIDER_SUCCESS";
export const CLEAR_CURRENT_PROVIDER = "CLEAR_CURRENT_PROVIDER";
export const SET_CURRENT_PROVIDER = "SET_CURRENT_PROVIDER";
export const SET_CURRENT_PARTNER = "SET_CURRENT_PARTNER";
export const REMOVE_CURRENT_PARTNER = "REMOVE_CURRENT_PARTNER";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_LOAN_TYPE_SUCCESS = "GET_LOAN_TYPE_SUCCESS";
export const SET_UPLOAD_TYPES = "SET_UPLOAD_TYPES";
export const SET_CURRENT_LOAN = "SET_CURRENT_LOAN";
export const SET_ISCOMPLETED = "SET_ISCOMPLETED"

//Credpal
export const HANDLE_NEXT = "HANDLE_NEXT";
export const HANDLE_PREVIOUS = "HANDLE_PREVIOUS";
export const GET_INSTITUTIONS_SUCCESS = "GET_INSTITUTIONS_SUCCESS";
export const SET_OTHER_INSTITUTION = "SET_OTHER_INSTITUTION";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const STUDENT_VERIFICATION_SUCCESS = "STUDENT_VERIFICATION_SUCCESS";
export const STUDENT_VERIFICATION_FAIL = "STUDENT_VERIFICATION_FAIL";
export const SET_ISSUBMITTING = "SET_ISSUBMITTING";
export const APPLICATION_SUCCESS = "APPLICATION_SUCCESS";
export const APPLICATION_FAIL = "APPLICATION_FAIL";
export const GET_LOAN = "GET_LOAN";
export const GET_LOAN_FAIL = "GET_LOAN_FAIL";
export const RESET_FORM_STATE = "RESET_FORM_STATE";
export const FILL_BANK_FORM = "FILL_BANK_FORM";
export const INFORMATION_MISMATCH = "INFORMATION_MISMATCH";


//users
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTHENTICATING_USER = "AUTHENTICATING_USER";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";



