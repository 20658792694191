import * as Yup from "yup";


export const nameValidation = (name, path, allowSpace) => (
    Yup.string()
    .test({
      name: "validator-custom-name",
      test: function (value) {
        return /\s/.test(value) && !allowSpace
          ? this.createError({
              message: `${name} should have no space`,
              path,
            })
          : true;
      },
    })
    .test({
      name: "validator-custom-name",
      test: function (value) {
        return /\d/.test(value)
          ? this.createError({
              message: "Invalid name",
              path,
            })
          : true;
      },
    })
    .min(3, "Not less than 3 characters")
    .required(`${name} is empty`)
)

export const phoneNumberValidation = (name, path) => (
  Yup.string()
  .min(9, "Minimum of 9 characters")
  .max(16, "maximum of 15 numbers")
  .test({
    name: "validator-custom-name",
    test: function (value) {
      return /\s/.test(value)
        ? this.createError({
          message: `${name} should have no space`,
            path,
          })
        : true;
    },
  })
  .test({
    name: "validator-custom-name",
    test: function (value) {
      return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)
        ? this.createError({
            message: "Avoid special characters",
            path,
          })
        : true;
    },
  })
  .matches(   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    "Enter a valid phone number"
  )
  .required(`${name} is empty`)

)

export const emailValidation = () => (
  Yup.string()
      .email("Invalid email format!")
      .required("Email is empty")
)

export const addressValidation = () => Yup.string()
.min(5, "Not less than 5 characters")
.required("Address is empty")