import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },

  numberInput: {
    "& ::-webkit-outer-spin-button, & ::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& ::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& [type=number]": {
      "-moz-appearance": "textfield",
    },
  },

  label: {
    marginBottom: "0.35em",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
  },
  switchLabel: {
    marginBottom: "0.35em",
    fontSize: "12px",
  },
  createActions: {
    marginTop: "2rem",
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  enableCTA: {
    display: "flex",
    gap: "1rem",
    marginTop: ".3rem",
    alignItems: "center",
  },
  enable: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",
  },
  uploadInfo: {
    marginTop: ".2rem",
    display: "flex",
    justifyContent: "space-between",
  },
}));

export { useStyles };
