const formIsValid = ({formErrors, ...rest}) => {

    // i pulled out beneficiaryId and transactionReference because they're not form inputs
    
    let valid = true

    // Check if errors are empty
    Object.values(formErrors).forEach(val => {
        val.length > 0 && (valid = false)
    })

    // Check if all fields are filled
    Object.values(rest).forEach(val => {
        val === '' && (valid = false)        
    })
    return valid 
}

export { formIsValid }