import React, { useContext } from "react";
import { useStyles } from "./style";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import Button from "../../../../Button";
import { FormControl, TextField, Typography, Box } from "@material-ui/core";
import userContext from "../../../../../Contexts/users/userContext";

const ForgotPasswordLayout = () => {
  const classes = useStyles();

  const { forgotPassword } = useContext(userContext);

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format!")
      .required("Email is empty!"),
  });

  return (
    <div>
      <Box marginBottom={"1.8rem"}>
        <Typography variant="h6" align="center" gutterBottom>
          Forgot Password
        </Typography>
        <Typography align="center" style={{ color: "#9DA9B3" }}>
          Enter your Email to reset your password
        </Typography>
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit= {(values, { resetForm, setSubmitting }) => {
          forgotPassword(values, resetForm, setSubmitting);
        }}
      >
        {({ values, errors, touched, getFieldProps, isSubmitting }) => (
          <Form noValidate autoComplete="off">
            <FormControl className={classes.formControl}>
              <Typography gutterBottom>Email Address</Typography>
              <TextField
                name="email"
                id="email"
                {...getFieldProps("email")}
                variant="outlined"
                size="small"
                error={errors.email && touched.email ? true : false}
                helperText={errors.email && touched.email ? errors.email : null}
              />
            </FormControl>

            <Box marginTop={"1rem"} align="center">
              <FormControl className={classes.formControl}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  Submit
                </Button>
              </FormControl>
              <small>
                <Link to="/adminlogin" className={classes.loginCTA}>
                  Login
                </Link>{" "}
              </small>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPasswordLayout;
