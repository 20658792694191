import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    gap: ".5rem",
  },
  goBack: {
    cursor: "pointer",
    marginLeft: "-.3rem",
  },
  partnerDetail: {
    display: "flex",
    flexWrap: "wrap",
    color: "#3A434B",
  },
  partnerItem: {
    wordBreak: "break-all",
    width: "22%",
    margin: "0 1rem 2rem 0",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: "1.5rem",
    },
  },
  logoBox: {
      border: "1px solid #677684",
      borderRadius: "4px",
      width: "80%",
      height: "8rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
  },
  contact: {
      borderTop: "2px dashed #677684",
      marginTop: "1rem",
      padding: "2rem 0"
  }
}));

export { useStyles };
