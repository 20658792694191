import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useStyles } from './style'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import Button from '../Button'
import { SelectedEntityContext } from '../../App'
import api from '../../Services/axiosAdmin'
import { FormControl, TextField, Switch, Typography, RadioGroup, Radio, FormControlLabel, FormHelperText, Checkbox } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Link } from 'react-router-dom'

const FormAdmin = props => {
    const classes = useStyles()

    const [isSuccessful, setIsSuccessful] = useState()
    const [ selectedEntity, setSelectedEntity ] = useContext(SelectedEntityContext)
    const { closeModal, view } = props

    // Handle Provider Entity
    const providerValues = {
        bankName: selectedEntity?.bankName ? selectedEntity.bankName : '',
        sortCode: selectedEntity?.sortCode ? selectedEntity.sortCode : '',
        status: selectedEntity?.status ? selectedEntity.status : '',
        baseApiUrl: selectedEntity?.baseApiUrl ? selectedEntity.baseApiUrl :'',
        isAutoIntegration: selectedEntity?.isAutoIntegration === true || selectedEntity?.isAutoIntegration === false ? selectedEntity?.isAutoIntegration : false
    }

    const providerValidationSchema = Yup.object({
        bankName: Yup.string().required('Provider name is empty'),
        sortCode: Yup.string().max(10, 'Maximum of 10 characters').required('Sort code is empty'),
        status: Yup.string().required('Status is not selected'),
        baseApiUrl: Yup.string().required('Url is empty'),
        isAutoIntegration: Yup.bool()
    })

    const handleProviderSubmit = async (values) => {
        // check if provider exists- i.e you're editing a provider
        console.log("The handle provider function is called");
        const request = selectedEntity ? api.put(`Provider/${selectedEntity?.id}`, values) : api.post(`Provider/create`, values)
        
        try {
            const res = await request

            console.log(res);
            if( res.data.requestSuccessful === true ) {
                setIsSuccessful({
                    status: true,
                    message: res.data.message
                })
                setSelectedEntity(res.data.responseData)
                setTimeout(() => {
                    closeModal()
                }, 2000)
            } else {
                setIsSuccessful({
                    status: false,
                    message: res.data.message
                })
            }
        } catch (error) {
            setIsSuccessful({
                status: false,
            })
        }

    }


    // Handle Organization Entity
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    // get all providers 
    const [formOptions, setFormOptions] = useState()
    const getFormOptions = async () => {
        try {
            const res = await api.get('/Provider')
            const provRes = res.data.responseData
            // console.log(provRes);
            if(res.data.requestSuccessful === true) {
                setFormOptions({
                    providers: provRes
                })
            }            

        } catch (error) {

        }
    } 

    useEffect(() => {
        if (view === 'organization') {
            getFormOptions()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    const organizationValues = {
        orgName: selectedEntity?.orgName ? selectedEntity.orgName : '',
        orgCode: selectedEntity?.orgCode ? selectedEntity.orgCode : '',
        hashKey: selectedEntity?.hashKey ? selectedEntity.hashKey : '',
        redirectUrl: selectedEntity?.redirectUrl ? selectedEntity.redirectUrl :'',
        currency: selectedEntity?.currency ? selectedEntity.currency : '',
        initial: selectedEntity?.initial ? selectedEntity.initial : '',
        providers: selectedEntity?.providers ? selectedEntity.providers.map(provider => provider.bankName) : [] 
    }

    const organizationValidationSchema = Yup.object({
        orgName: Yup.string().required('Organization name is empty'),
        orgCode: Yup.string().required('Organization code is empty'),
        hashKey: Yup.string().max(10, 'Hashkey of 10 characters').required('Hash Key is empty'),
        redirectUrl: Yup.string().required('Url is not selected'),
        currency: Yup.string().required('Currency is empty'),
        initial: Yup.string().required('Initial is empty'),
        providers: Yup.array(),
    })

    const handleOrganizationSubmit = async (values) => {

        const newProviders = values?.providers.reduce((acc, curr) => {

            const providerObject = formOptions.providers.find(top => top.bankName === curr);
            if (providerObject) {
                acc.push(providerObject);
            }
            return acc; 
        }, [])

        values = {
            ...values,
            providers: newProviders
        }

        // check if org exists- i.e you're editing an org
        const request = selectedEntity ? api.put(`Organization/${selectedEntity?.id}`, values) : api.post(`Organization/create`, values)
        
        try {
            const res = await request

            console.log(res);
            if( res.data.requestSuccessful === true ) {
                setIsSuccessful({
                    status: true,
                    message: res.data.message
                })
                setSelectedEntity(res.data.responseData)
                setTimeout(() => {
                    closeModal()
                }, 2000)
            } else {
                setIsSuccessful({
                    status: false,
                    message: res.data.message
                })
            }
        } catch (error) {
            setIsSuccessful({
                status: false,
            })
        }

    }


    // Handle Bank Entity
    const bankValues = {
        bankName: selectedEntity?.bankName ? selectedEntity.bankName : '',
        bankCode: selectedEntity?.bankCode ? selectedEntity.bankCode : '',
    }

    const bankValidationSchema = Yup.object({
        bankName: Yup.string().required('Bank name is empty'),
        bankCode: Yup.string().required('Bank code is empty'),
    })

    const handleBankSubmit = async (values) => {
        // check if org exists- i.e you're editing an org
        const request = selectedEntity ? api.put(`Bank/${selectedEntity?.id}`, values) : api.post(`Bank/create`, values)
        
        try {
            const res = await request

            console.log(res);
            if( res.data.requestSuccessful === true ) {
                setIsSuccessful({
                    status: true,
                    message: res.data.message
                })
                setSelectedEntity(res.data.responseData)
                setTimeout(() => {
                    closeModal()
                }, 2000)
            } else {
                setIsSuccessful({
                    status: false,
                    message: res.data.message
                })
            }
        } catch (error) {
            setIsSuccessful({
                status: false,
            })
        }

    }



    // Handle Institution Entity
    const institutionValues = {
        name: selectedEntity?.name ? selectedEntity.name : '',
        code: selectedEntity?.code ? selectedEntity.code : '',
    }

    const institutionValidationSchema = Yup.object({
        name: Yup.string().required('Institution name is empty'),
        code: Yup.string().required('Institution code is empty'),
    })

    const handleInstitutionSubmit = async (values) => {
        // check if org exists- i.e you're editing an org
        const request = selectedEntity ? api.put(`Institution/${selectedEntity?.id}`, values) : api.post(`Institution/create`, values)
        
        try {
            const res = await request

            console.log(res);
            if( res.data.requestSuccessful === true ) {
                setIsSuccessful({
                    status: true,
                    message: res.data.message
                })
                setSelectedEntity(res.data.responseData)
                setTimeout(() => {
                    closeModal()
                }, 2000)
            } else {
                setIsSuccessful({
                    status: false,
                    message: res.data.message
                })
            }
        } catch (error) {
            setIsSuccessful({
                status: false,
            })
        }

    }



    // Handle Users Entity
    // const userValues = {
    //     name: selectedEntity?.name ? selectedEntity.name : '',
    //     code: selectedEntity?.code ? selectedEntity.code : '',
    // }

    // const userValidationSchema = Yup.object({
    //     name: Yup.string().required('Institution name is empty'),
    //     code: Yup.string().required('Institution code is empty'),
    // })

    // const handleUserSubmit = async (values) => {
    //     // check if org exists- i.e you're editing an org
    //     const request = selectedEntity ? api.put(`User/${selectedEntity?.id}`, values) : api.post(`User/Create`, values)
        
    //     try {
    //         const res = await request

    //         console.log(res);
    //         if( res.data.requestSuccessful === true ) {
    //             setIsSuccessful({
    //                 status: true,
    //                 message: res.data.message
    //             })
    //             setSelectedEntity(res.data.responseData)
    //             setTimeout(() => {
    //                 closeModal()
    //             }, 2000)
    //         } else {
    //             setIsSuccessful({
    //                 status: false,
    //                 message: res.data.message
    //             })
    //         }
    //     } catch (error) {
    //         setIsSuccessful({
    //             status: false,
    //         })
    //     }

    // }


    // let initialValues = view === 'provider' ? providerValues : view === 'organization' ? organizationValues : null;
    let initialValues;
    if( view === 'provider' ) {
        initialValues = providerValues
    } else if ( view === 'organization' ) {
        initialValues = organizationValues
    } else if ( view === 'bank' ) {
        initialValues = bankValues
    } else if ( view === 'institution' ) {
        initialValues = institutionValues
    }
    

    // let validationSchema = view === 'provider' ? providerValidationSchema : view === 'organization' ? organizationValidationSchema : null;
    let validationSchema;
    if( view === 'provider' ) {
        validationSchema = providerValidationSchema
    } else if ( view === 'organization' ) {
        validationSchema = organizationValidationSchema
    } else if ( view === 'bank' ) {
        validationSchema = bankValidationSchema
    } else if ( view === 'institution' ) {
        validationSchema = institutionValidationSchema
    }

    // let onSubmit = view === 'provider' ? handleProviderSubmit : view === 'organization' ? handleOrganizationSubmit : null;
    let onSubmit;
    if( view === 'provider' ) {
        onSubmit = handleProviderSubmit
    } else if ( view === 'organization' ) {
        onSubmit = handleOrganizationSubmit
    } else if ( view === 'bank' ) {
        onSubmit = handleBankSubmit
    } else if ( view === 'institution' ) {
        onSubmit = handleInstitutionSubmit
    }


    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({values, errors, touched, getFieldProps, setFieldValue, isSubmitting}) => (
                    <Form noValidate autoComplete="off">
                        {console.log(values)}
                        
                        {view === 'provider' && (
                            <>
                           {selectedEntity && selectedEntity.id &&
                            <Link to={`/providersettings/${selectedEntity.id}`}>
                            <Typography>Settings</Typography>
                            </Link>
}
                            <Typography variant="h6" align="center" gutterBottom>
                                { selectedEntity ? 'Edit Provider' : 'Add New Provider' }
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                    name="bankName" 
                                    id="bankName" 
                                    label="Provider Name"
                                    { ...getFieldProps('bankName')}
                                    variant="outlined"
                                    error={errors.bankName && touched.bankName ? true : false}
                                    helperText={ errors.bankName && touched.bankName ?
                                        errors.bankName : null
                                    }
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                    name="sortCode" 
                                    id="sortCode" 
                                    label="Sort Code"
                                    { ...getFieldProps('sortCode')}
                                    variant="outlined"
                                    error={errors.sortCode && touched.sortCode ? true : false}
                                    helperText={ errors.sortCode && touched.sortCode ?
                                        errors.sortCode : null
                                    }
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <RadioGroup 
                                    className={classes.radioGroup} row aria-label="status" 
                                    name="status"
                                    { ...getFieldProps('status')}
                                >

                                    <FormControlLabel 
                                    value="active"
                                    control={<Radio color="primary" />} 
                                    label={<Typography variant="body2">Active</Typography>}
                                    />
                                    <FormControlLabel 
                                    value="inactive"
                                    control={<Radio color="primary" />} 
                                    label={<Typography variant="body2">Inactive</Typography>}
                                    />

                                </RadioGroup>
                                <FormHelperText
                                    error={errors.status && touched.status ? true : false}
                                >
                                    { errors.status && touched.status ?
                                        errors.status : null
                                    }
                                </FormHelperText>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                    name="baseApiUrl" 
                                    id="baseApiUrl" 
                                    label="Url"
                                    { ...getFieldProps('baseApiUrl')}
                                    variant="outlined"
                                    error={errors.baseApiUrl && touched.baseApiUrl ? true : false}
                                    helperText={ errors.baseApiUrl && touched.baseApiUrl ?
                                        errors.baseApiUrl : null
                                    }
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                {/* <FormControlLabel
                                    control={
                                        <Switch 
                                            name="isAutoIntegration" 
                                            id="isAutoIntegration" 
                                            { ...getFieldProps('isAutoIntegration')} 
                                            color="primary"
                                        />
                                    }
                                    label="Automated?"
                                /> */}
                                <FormControlLabel
                                control={
                                    <Field 
                                        component={Switch} 
                                        type="checkbox" 
                                        name="isAutoIntegration" 
                                        color="primary"
                                    />
                                }
                                label="Automate?"
                                />
                                <FormHelperText
                                    error={errors.isAutoIntegration && touched.isAutoIntegration ? true : false}
                                >
                                    { errors.isAutoIntegration && touched.isAutoIntegration ?
                                        errors.isAutoIntegration : null
                                    }
                                </FormHelperText>
                            </FormControl>
                            </>
                        )}




                        {view === 'organization' && (
                            <>
                            <Typography variant="h6" align="center" gutterBottom>
                                { selectedEntity ? 'Edit Organization' : 'Add New Organization' }
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                    name="orgName" 
                                    id="orgName" 
                                    label="Organization Name"
                                    { ...getFieldProps('orgName')}
                                    variant="outlined"
                                    error={errors.orgName && touched.orgName ? true : false}
                                    helperText={ errors.orgName && touched.orgName ?
                                        errors.orgName : null
                                    }
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                    name="orgCode" 
                                    id="orgCode" 
                                    label="Organization Code"
                                    { ...getFieldProps('orgCode')}
                                    variant="outlined"
                                    error={errors.orgCode && touched.orgCode ? true : false}
                                    helperText={ errors.orgCode && touched.orgCode ?
                                        errors.orgCode : null
                                    }
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                    name="hashKey" 
                                    id="hashKey" 
                                    label="Hash Key"
                                    { ...getFieldProps('hashKey')}
                                    variant="outlined"
                                    error={errors.hashKey && touched.hashKey ? true : false}
                                    helperText={ errors.hashKey && touched.hashKey ?
                                        errors.hashKey : null
                                    }
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                    name="redirectUrl" 
                                    id="redirectUrl" 
                                    label="Url"
                                    { ...getFieldProps('redirectUrl')}
                                    variant="outlined"
                                    error={errors.redirectUrl && touched.redirectUrl ? true : false}
                                    helperText={ errors.redirectUrl && touched.redirectUrl ?
                                        errors.redirectUrl : null
                                    }
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                    name="currency" 
                                    id="currency" 
                                    label="Currency"
                                    { ...getFieldProps('currency')}
                                    variant="outlined"
                                    error={errors.currency && touched.currency ? true : false}
                                    helperText={ errors.currency && touched.currency ?
                                        errors.currency : null
                                    }
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                    name="initial" 
                                    id="initial" 
                                    label="Initial"
                                    { ...getFieldProps('initial')}
                                    variant="outlined"
                                    error={errors.initial && touched.initial ? true : false}
                                    helperText={ errors.initial && touched.initial ?
                                        errors.initial : null
                                    }
                                />
                            </FormControl>
                            {/* <Box> */}
                            <FormControl className={classes.formControl}>
                                <Autocomplete
                                    multiple
                                    id="providers"
                                    options={
                                        formOptions ? formOptions.providers.map(provider => provider.bankName) : [] 
                                        
                                    }
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option}
                                    value={values.providers}
                                    onChange={(event, newValue) => setFieldValue("providers", newValue)}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option}
                                        </React.Fragment>
                                    )}
                                    // style={{ width: 500 }}
                                    renderInput={(params) => (
                                        <TextField {...params} name="providers" variant="outlined" label="Providers" placeholder="Select Providers"
                                        
                                       />
                                    )}
                                />
                            </FormControl>
                            </>
                        )}




                        {view === 'bank' && (
                            <>
                            <Typography variant="h6" align="center" gutterBottom>
                                { selectedEntity ? 'Edit Bank' : 'Add New Bank' }
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                    name="bankName" 
                                    id="bankName" 
                                    label="Bank Name"
                                    { ...getFieldProps('bankName')}
                                    variant="outlined"
                                    error={errors.bankName && touched.bankName ? true : false}
                                    helperText={ errors.bankName && touched.bankName ?
                                        errors.bankName : null
                                    }
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                    name="bankCode" 
                                    id="bankCode" 
                                    label="Bank Code"
                                    { ...getFieldProps('bankCode')}
                                    variant="outlined"
                                    error={errors.bankCode && touched.bankCode ? true : false}
                                    helperText={ errors.bankCode && touched.bankCode ?
                                        errors.bankCode : null
                                    }
                                />
                            </FormControl>
                            </>
                        )}




                        {view === 'institution' && (
                            <>
                            <Typography variant="h6" align="center" gutterBottom>
                                { selectedEntity ? 'Edit Bank' : 'Add New Bank' }
                            </Typography>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                    name="name" 
                                    id="name" 
                                    label="Institution Name"
                                    { ...getFieldProps('name')}
                                    variant="outlined"
                                    error={errors.name && touched.name ? true : false}
                                    helperText={ errors.name && touched.name ?
                                        errors.name : null
                                    }
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                    name="code" 
                                    id="code" 
                                    label="Institution Code"
                                    { ...getFieldProps('code')}
                                    variant="outlined"
                                    error={errors.code && touched.code ? true : false}
                                    helperText={ errors.code && touched.code ?
                                        errors.code : null
                                    }
                                />
                            </FormControl>
                            </>
                        )}


                        <FormControl className={classes.formControl}>
                            <Button 
                            variant="contained" 
                            color="primary" 
                            type="submit"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            >
                                Save
                            </Button>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <FormHelperText 
                            style={{ textAlign: 'center' }} 
                            error={isSuccessful?.status === true ? false : true}
                            >
                                {isSuccessful?.status === false ? isSuccessful.message ? isSuccessful.message : 'an error occured' : null}
                                {isSuccessful?.status === true ? 'Saved!' : null}
                            </FormHelperText>
                        </FormControl>
                    </Form>

                )}
            </Formik>
        </>
    )
}

FormAdmin.propTypes = {
    view: PropTypes.string,
}

export default FormAdmin