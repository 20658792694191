import React from "react";
import Login from "../../Components/Layout/AdminLayouts/AuthLayout/Login"
import AuthLayout from "../../Components/Layout/AdminLayouts/AuthLayout"

const AdminLogin = (props) => {
  return (
        <>
            <AuthLayout>
                <Login history={props.history} />
            </AuthLayout>

        </>
        
  );
};

export default AdminLogin;
