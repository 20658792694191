import React, { useEffect } from "react";
import { Typography, Box } from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { formatDateTime } from "../../../../../Actions/helpers";
import Paper from "../../../../Paper";
import StatusIndicator from "../../../../Reusables/StatusIndicator";

import { useStyles } from "./style";

const ViewStoreLayout = (props) => {
  const classes = useStyles();

  const store = props.location?.state?.data && props.location?.state?.data;
  const {
    storeName,
    storeType,
    firstName,
    lastName,
    email,
    companyRegisteredName,
    contactPersonPhoneNumber,
    contactPersonName,
    country,
    state,
    city,
    isActive,
    isAgreement,
    vat,
    tin,
    cac,
    address,
    accountName,
    accountNo,
    dateCreated,
    legalRepresentativeFirstName,
    legalRepresentativeLastName,
    legalRepresentativeDateOfBirth,
    noOfEmployee,
    storeLogoUrl,
  } = store;

  useEffect(() => {
    console.log("The useEffect value is: ", store);
  })

  return (
    <Box>
      <Box className={classes.header}>
        <Box
          className={classes.goBack}
          onClick={() => props.history.push("/stores")}
        >
          <KeyboardArrowLeftIcon />
        </Box>

        <Typography variant="h4" gutterBottom display="inline">
          Store Details
        </Typography>
      </Box>

      <Paper borderRadius="0" padding="1.5rem 2rem 2rem">
        <Typography variant="h4" gutterBottom>
          Store Information
        </Typography>
        <Box className={classes.storeDetail}>
          <Box className={classes.storeItem}>
            <Typography variant="subtitle2" gutterBottom>
              Store's Name
            </Typography>
            <Typography variant="body1">{storeName}</Typography>
          </Box>
          <Box className={classes.storeItem}>
            <Typography variant="subtitle2" gutterBottom>
              Store Type
            </Typography>
            <Typography variant="body1">{storeType}</Typography>
          </Box>
          <Box className={classes.storeItem}>
            <Typography variant="subtitle2" gutterBottom>
              Company Registered Name
            </Typography>
            <Typography>{companyRegisteredName}</Typography>
          </Box>
          <Box className={classes.storeItem}>
            <Typography variant="subtitle2" gutterBottom>
              Address
            </Typography>
            <Typography>{address}</Typography>
          </Box>
          <Box className={classes.storeItem}>
            <Typography variant="subtitle2" gutterBottom>
              Date Created
            </Typography>
            <Typography>{formatDateTime(dateCreated)}</Typography>
          </Box>
          <Box className={classes.storeItem}>
            <Typography variant="subtitle2" gutterBottom>
              First Name
            </Typography>
            <Typography>{firstName}</Typography>
          </Box>
          <Box className={classes.storeItem}>
            <Typography variant="subtitle2" gutterBottom>
              Last Name
            </Typography>
            <Typography>{lastName}</Typography>
          </Box>
          <Box className={classes.storeItem}>
            <Typography variant="subtitle2" gutterBottom>
              Email
            </Typography>
            <Typography>{email}</Typography>
          </Box>
          <Box className={classes.storeItem}>
            <Typography variant="subtitle2" gutterBottom>
              Country
            </Typography>
            <Typography>{country}</Typography>
          </Box>
          <Box className={classes.storeItem}>
            <Typography variant="subtitle2" gutterBottom>
              State
            </Typography>
            <Typography>{state}</Typography>
          </Box>
          <Box className={classes.storeItem}>
            <Typography variant="subtitle2" gutterBottom>
              City
            </Typography>
            <Typography>{city}</Typography>
          </Box>
          <Box className={classes.storeItem}>
            <Typography variant="subtitle2" gutterBottom>
              Active Status
            </Typography>
            <Typography>{isActive ? "Active" : "Not Active"}</Typography>
          </Box>
          <Box className={classes.storeItem}>
            <Typography variant="subtitle2" gutterBottom>
              No of Employees
            </Typography>
            <Typography>{noOfEmployee}</Typography>
          </Box>
          <Box className={classes.storeItem}>
            <Typography variant="subtitle2" gutterBottom>
              CAC
            </Typography>
            <Typography>{cac}</Typography>
          </Box>
          <Box className={classes.storeItem}>
            <Typography variant="subtitle2" gutterBottom>
              TIN
            </Typography>
            <Typography>{tin}</Typography>
          </Box>
          <Box className={classes.storeItem}>
            <Typography variant="subtitle2" gutterBottom>
              VAT
            </Typography>
            <Typography>{vat ? "TRUE" : "FALSE"}</Typography>
          </Box>
          <Box className={classes.storeItem}>
            <Typography variant="subtitle2" gutterBottom>
              Legal Rep First Name
            </Typography>
            <Typography>{legalRepresentativeFirstName}</Typography>
          </Box>
          <Box className={classes.storeItem}>
            <Typography variant="subtitle2" gutterBottom>
              Legal Rep Last Name
            </Typography>
            <Typography>{legalRepresentativeLastName}</Typography>
          </Box>
          <Box className={classes.storeItem}>
            <Typography variant="subtitle2" gutterBottom>
              Legal Rep D.O.B
            </Typography>
            <Typography>{formatDateTime(legalRepresentativeDateOfBirth)}</Typography>
          </Box>

          <Box className={classes.storeItem}>
            <Box className={classes.logoBox}>
              <Box
                style={{
                  height: "4rem",
                  width: "50%",
                }}
              >
                <img
                  src={storeLogoUrl}
                  alt=""
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.contact}>
          <Typography variant="h4" gutterBottom>
            Contact Information
          </Typography>
          <Box className={classes.storeDetail}>
            <Box className={classes.storeItem}>
              <Typography variant="subtitle2" gutterBottom>
                Contact Person's Name
              </Typography>
              <Typography variant="body1">{contactPersonName}</Typography>
            </Box>
            <Box className={classes.storeItem}>
              <Typography variant="subtitle2" gutterBottom>
                Phone Number
              </Typography>
              <Typography variant="body1">{contactPersonPhoneNumber}</Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default ViewStoreLayout;
