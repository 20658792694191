import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../../../../Button";
import {
  FormControl,
  InputAdornment,
  IconButton,
  TextField,
  Typography,
  Box,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import userContext from "../../../../../Contexts/users/userContext";

const Login = (props) => {
  const classes = useStyles();

  const { Login } = useContext(userContext);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format!")
      .required("Email is empty!"),
    password: Yup.string().required("Password is empty!"),
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <Box marginBottom={"1.8rem"}>
        <Typography variant="h6" align="center" gutterBottom>
          Login
        </Typography>
        <Typography align="center" style={{ color: "#9DA9B3" }}>
          Enter Login Details to proceed
        </Typography>
      </Box>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => Login(values)}
      >
        {({ values, errors, touched, getFieldProps, isSubmitting }) => (
          <Form noValidate autoComplete="off">
            <FormControl className={classes.formControl}>
              <Typography gutterBottom>Email Address</Typography>
              <TextField
                name="email"
                id="email"
                {...getFieldProps("email")}
                variant="outlined"
                size="small"
                error={errors.email && touched.email ? true : false}
                helperText={errors.email && touched.email ? errors.email : null}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Typography gutterBottom>Password</Typography>
              <TextField
                name="password"
                id="password"
                type={showPassword ? "text" : "password"}
                {...getFieldProps("password")}
                variant="outlined"
                size="small"
                error={errors.password && touched.password ? true : false}
                helperText={
                  errors.password && touched.password ? errors.password : null
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        <Typography color="primary">{showPassword ? "Hide" : "Show"}</Typography>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <small>
              <Link to="/user/forgot-password" className={classes.forgotCTA}>
                Forgot Password?
              </Link>{" "}
            </small>
            <FormControl className={classes.formControl}>
              <Button
                variant="contained"
                color="primary"
                // size="large"
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Login
              </Button>
            </FormControl>
          </Form>
        )}
      </Formik>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.any,
};

export default Login;
