import React from 'react';
import PropTypes from 'prop-types';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core/';
import FeedbackResponses from "./FeedbackResponses"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `full-width-tab-${index}`,
//     'aria-controls': `full-width-tabpanel-${index}`,
//   };
// }

// const useStyles = makeStyles((theme) => ({
//   root: {
//     border: "1px solid green",
//     margin: "0",
//     width: "100%",
//     padding: "-10rem!important"
//   },
// }));

export const FeedbackLayout = () => {
  // const classes = useStyles();
  // const theme = useTheme();
  // const [value, setValue] = React.useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // const handleChangeIndex = (index) => {
  //   setValue(index);
  // };

  return (
    <FeedbackResponses />
//     <div className={classes.root}>
//       <AppBar position="static" color="default" style={{border: "1px solid blue"}}>
//         <Tabs
//         style={{border: "1px solid red"}}
//           value={value}
//           onChange={handleChange}
//           indicatorColor="primary"
//           centered={false}
//           textColor="primary"
//           variant="fullWidth"
//           aria-label="full width tabs example"
//         >
//           <Tab label="Item One" {...a11yProps(0)} />
//           <Tab label="Item Two" {...a11yProps(1)} />
//           <Tab label="Item Three" {...a11yProps(2)} />
//         </Tabs>
//       </AppBar>
//       <SwipeableViews
//         axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
//         index={value}
//         onChangeIndex={handleChangeIndex}
//       >
//         <TabPanel value={value} index={0} dir={theme.direction} style={{border: "1px solid yellow", padding:"-2rem!important"}}>
//         {/* <FeedbackResponses /> */}
// <div>Good</div>
//         </TabPanel>
//         <TabPanel value={value} index={1} dir={theme.direction}>
//           Item Two
//         </TabPanel>
      
//       </SwipeableViews>
//     </div>
  );
}

export default FeedbackLayout
