import React from 'react'
import PropTypes from 'prop-types'
import Paper from '../Paper'
import { useStyles } from './styles'
import LogoDark from '../../Assets/images/Educollect-logo.png'
import logoLight from '../../Assets/images/Educollect-logo-dark.png'

const Logo = (props) => {
    const classes = useStyles(props);

    return (
        <div className={classes.root}>
            <Paper width="12.5rem" padding="1rem 2rem .7rem 2rem">
                <img src={ props.LogoDark ? LogoDark : logoLight } alt="logo" className={classes.logo} />
            </Paper>
        </div>
    )
}

export default Logo

Logo.propTypes = {
    logoDark: PropTypes.bool,
    logoLight: PropTypes.bool,
    marginBottom: PropTypes.string
}
