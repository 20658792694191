import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Box,
} from "@material-ui/core";
import { useStyles } from "./style";
import MenuIcon from "@material-ui/icons/Menu";
import { ExpandMore } from "@material-ui/icons";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { logOut } from "../../../Actions/userActions";


const Topbar = (props) => {
  const { onSidebarOpen, ...rest } = props;
  const classes = useStyles(props);


  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    handleMenuClose();
   logOut()
  };

  return (
    <AppBar color="transparent" className={classes.root} {...rest}>
      <Toolbar disableGutters className={classes.toolBar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={onSidebarOpen}
        >
          <MenuIcon />
        </IconButton>
        <Box>
          <Box>
            <IconButton
              edge="start"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleMenuClick}
            >
              <ExpandMore style={{ fill: "#000000" }} />
            </IconButton>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              className={classes.dropDown}
            >
              {/* <Link
                to="/profile"
                style={{ textDecoration: "none", color: "#0A1731" }}
              >
                <MenuItem onClick={handleMenuClose}>
                  <PersonIcon fontSize="small" /> &nbsp; Profile
                </MenuItem>
              </Link> */}
              <MenuItem onClick={handleLogOut}>
                <PowerSettingsNewIcon fontSize="small" /> &nbsp; Logout
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
