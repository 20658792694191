import React, {useEffect, useState, useContext} from 'react'
import { Typography, Grid, CardActionArea, CardContent } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import api from '../../../../Services/axiosAdmin';
import Card from '../../../Card';
import AddIcon from '@material-ui/icons/AddRounded'
import { useStyles } from './style'
import Modal from '../../../Modal'
import { SelectedEntityContext } from '../../../../App'

const OrganizationLayout = () => {
    const classes = useStyles()

    const [organizations, setOrganizations] = useState()

    const getOrganizations = async () => {
        try {
            const res = await api.get('/Organization')
            const orgRes = res.data.responseData
            console.log(orgRes);
            if(res.data.requestSuccessful === true) {
                setOrganizations(orgRes)
            }
        } catch (error) {

        }
    }

    // Modal controls
    const [openModal, setOpenModal] = useState(false);
    const [modalView, setModalView] = useState();
    const [ selectedEntity, setSelectedEntity ] = useContext(SelectedEntityContext)

    const handleOpen = (entity) => {
        setOpenModal(true);
        setSelectedEntity(entity)
    };

    const handleClose = () => {
        setOpenModal(false);
        setSelectedEntity(null)
    };


    useEffect(() => {
        getOrganizations()
    }, [openModal])

    return (
        <div>
            <Grid container spacing={4}>
                <Grid item md={2} sm={6} xs={12}>
                    <Card backgroundColor='primary' height='100%'>
                        <CardActionArea
                            className={classes.card}
                            onClick={() => {
                                setModalView('organization')
                                handleOpen() 
                            }}
                        >
                            <CardContent style={{ textAlign: `center` }}>
                                <AddIcon style={{ fontSize: `5rem` }}/> 
                                <Typography align="center" variant="body2">
                                    Add Organization
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                { !organizations ? (
                    <Skeleton variant="text" width={250} height={180} />
                ) : organizations.length === 0 ? (
                    <div> </div>
                ) : (
                    <>
                    { organizations.map((organization, key) => (
                        <Grid item md={2} sm={6} xs={12} key={key}>
                            <Card height='100%'>
                                <CardActionArea
                                    className={classes.card}
                                    onClick={() => {
                                        setModalView('viewOrganization')
                                        handleOpen(organization) 
                                    }}
                                >
                                    {/* <CardMedia 
                                        className={classes.image} 
                                        image={AccessLogo} 
                                        title=""
                                        component="img"
                                        alt="Access Bank - Bursery Logo"
                                    /> */}
                                    <CardContent style={{ textAlign: `center` }}>
                                        <Typography align="center" variant="body2">
                                            {organization.orgName}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                    </>
                )}



            </Grid>

            {/* Load Custom Modal COmponent */}
            <Modal handleClose={handleClose} openModal={openModal} view={modalView}/>
        </div>
    )
}

export default OrganizationLayout
