import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  loanChart: {
    height: "355px",
    [theme.breakpoints.down("md")]: {
      height: "310px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "450px",
    },
  },
  graphActions: {
    padding: ".5rem 3rem 2rem 3rem",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 3rem",
    },
  },
  colorIndicator: {
    width: "12px",
    height: "12px",
    borderRadius: "50%",
  },
  loader: {
    height: "2rem",
    width: "2rem",
    marginLeft: "1rem",
  },
  header: {
    display: "flex",
    alignItems: "center",
    gap: ".5rem",
  },
  label: {
    fontSize: "12px",
    fontWeight: "400",
  }
}));

export { useStyles };
