import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    display: "flex",
    margin: "-1.5rem 0 1rem -0.3rem",
    gap: "1rem",
    alignItems: "center",
  },
  goBack: {
    extDecoration: "none",
    color: "#000000",
    marginTop: "0.3rem",
  },
  details: {
    marginTop: "1.5rem",
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridRowGap: "2rem",
    gridColumnGap: "2rem",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  other: {
    marginTop: "2rem",
    padding: "2.5rem 0 0",
    borderTop: "2px dashed #677684",
    wordBreak: "break-all",
  },
  documents: {
    marginTop: "1.5rem",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridRowGap: "1.5rem",
    gridColumnGap: "2rem",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  fileContainer: {
    width: "100%",
    height: "7.5rem",
    border: "1.5px solid #F08829",
    backgroundColor: "#FDF2E7",
    padding: ".7rem",
    borderRadius: "4px",
  },
  imageContainer: {
    padding: ".3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    width: "3.5rem",
    height: "3.5rem",
  },
  statusCTA: {
    display: "flex",
    gap: "2rem",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  formControl:{
      width: "100%"
  }
}));

export { useStyles };
