import React from 'react'
import ProfileLayout from '../../Components/Layout/AdminLayouts/ProfileLayout'

const Profile = () => {
    return (
        <ProfileLayout/>
    )
}

export default Profile

