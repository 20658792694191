import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
    },

    export_button : {
        marginLeft : "1rem",
    }
}))

export { useStyles }