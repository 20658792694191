import React, { useEffect, useContext } from "react";
import Routes from "../../../Routes/Routes";
import Alert from "../../Alert";
import Spinner from "../../Reusables/Spinner";
import userContext from "../../../Contexts/users/userContext";
import Cookies from "js-cookie";

//This is a top level layout wrap all components
const Parentlayout = (props) => {
  const { loadUser, user, authenticating } = useContext(userContext);

  useEffect(() => {
    if(Cookies.get("accessToken") && user === null){
      loadUser();
    }
    // Cookies.get("accessToken") && user === null && loadUser()
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {authenticating ? (
        <Spinner />
      ) : (
        <>
          <Routes />
          <Alert />
        </>
      )}
    </>
  );
};

export default Parentlayout;
