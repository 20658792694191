import React from 'react'
import DashboardLayout from '../../Components/Layout/AdminLayouts/DashboardLayout'

const AdminHome = (props) => {
    return (
       <>
        <DashboardLayout/>
       </>
    )
}

export default AdminHome
