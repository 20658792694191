import React from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  FormControl,
  MenuItem,
  Select,
} from "@material-ui/core";
import PropTypes from 'prop-types';
import SearchIcon from "@material-ui/icons/Search";
import TuneIcon from "@material-ui/icons/Tune";
import Spinner from "../Spinner";
import { useStyles } from "./style";

const TableHead = ({
  title,
  totalSize,
  loading,
  searchItem,
  placeholder,
  onChange,
  onClick,
  handleInputChange,
  shouldFocus
}) => {
  const classes = useStyles();
  const rowsPerPageOptions = [10, 20, 50, 100, 200, 500];

  return (
    <Box>
       <Grid container spacing={2}>
            <Grid item xs={12} md={3} style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
              <Typography variant="h5">{title}</Typography>{" "}
              <Typography style={{ color: "#81909D", marginTop: "0.2rem" }}>
                {totalSize}
              </Typography>
              {loading && (
                <Box className={classes.loader}>
                  <Spinner size={20} height={"2rem"} />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} md={9}>
              <Box className={classes.tableCTAs}>
                <TextField
                  autoFocus={shouldFocus}
                  placeholder={placeholder}
                  name="email"
                  variant="outlined"
                  size="small"
                  value={searchItem}
                  onChange={onChange}
                  style={{ width: "100%" }}
                  InputProps={{
                    endAdornment: <SearchIcon />,
                  }}
                />
                <Box
                  className={onClick ? classes.filterTrigger : classes.nofilterTrigger}
                  onClick={() => {
                    onClick(true)
                  }}
                >
                  {onClick && 
                  <><Typography>Filter By</Typography>
                  <TuneIcon /></>}
                </Box>
                <Box className={classes.numberToShow}>
                  <Typography>Showing:</Typography>
                  <FormControl variant="outlined" size="small">
                    <Select
                      name="pageSize"
                      defaultValue="10"
                      className={classes.select}
                      onChange={handleInputChange}
                    >
                      {rowsPerPageOptions.map((val, key) => (
                        <MenuItem key={key} value={val}>
                          {val} Entries
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Grid>
          </Grid>
    </Box>
  );
};

TableHead.propTypes = {
    title: PropTypes.string.isRequired,
  totalSize: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  searchItem: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
}

export default TableHead;
