import React, { useContext, useEffect, useState } from "react";
import { ErrorMessage } from "formik";
import FormErrors from "../../../../../FormErrors";
import ControlButtons from "../../ControlButtons";
import { connect } from "formik";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
  FormControl,
  Box,
  TextField,
  Typography,
  Paper,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  moneyFormat,
  currencyToNumber,
} from "../../../../../../Actions/helpers";

import { useStyles } from "./style";
import loanContext from "../../../../../../Contexts/loan/loanContext";

const EmploymentInformation = (props) => {
  const classes = useStyles();
  const [corporatePartners, setCorporatePartners] = useState([]);
  const {makeFilterRequest} = useContext(loanContext);

  const {
    formik: { getFieldProps, setFieldValue, setTouched },
    values,
    setShowLoanPolicy,
  } = props;

  useEffect(() => {
    setTouched({});

    const getCorporatePartners = async () => {
      const corporatePartners = [];
      const partners = await makeFilterRequest("Partner");
      partners.data.responseData.items.forEach(element => {
        if (element.type === "COORPERATE") corporatePartners.push(element);
      });
      console.log("The corporate partners are: ", corporatePartners, partners);

      setCorporatePartners(corporatePartners);
    }

    getCorporatePartners();
  }, []);

  return (
    <div className={classes.container}>
      <Paper style={{ padding: "1rem 2rem 3rem" }}>
        <Box marginTop={3} marginBottom={2}>
          <Typography variant="h5">Employment Information</Typography>
        </Box>

        <div className={classes.fieldWrapper}>
          <FormControl className={classes.formControl} size="small">
            <Typography className={classes.label}>Emploment Status</Typography>
            <Select
              className={classes.select}
              defaultValue=""
              name="employment.employmentStatus"
              id="employment.employmentStatus"
              variant="outlined"
              {...getFieldProps("employment.employmentStatus")}
            >
              <MenuItem value="Employed">Employed</MenuItem>
              {values.loanRequest?.borrowerType !== 2 && <MenuItem value="Self-employed">Self-employed</MenuItem>}
            </Select>
            <ErrorMessage
              name="employment.employmentStatus"
              component={FormErrors}
            />
          </FormControl>

          { !(values.loanRequest?.borrowerType === 2) &&
            <FormControl className={classes.formControl}>
              <Typography className={classes.label}>Employer's Name</Typography>
              <TextField
                name="employment.employerName"
                id="employment.employerName"
                {...getFieldProps("employment.employerName")}
                variant="outlined"
                size="small"
                disabled={values?.loanRequest?.borrowerType === 2}
              />
              <ErrorMessage
                name="employment.employerName"
                component={FormErrors}
              />
            </FormControl>
          }

          {/* { values.loanRequest?.borrowerType === 2 ? (
            <FormControl className={classes.formControl}>
              <Typography className={classes.label}>Employer's Name</Typography>
              <Select
                className={classes.select}
                defaultValue=""
                name="employment.employerName"
                id="employment.employerName"
                value={values.employment.employerName}
                onChange={(e) => {
                  const name = e.target.value;
                  setFieldValue("employment.employerName", name);
                  // TODO: Update the organisation code here.
                }}
                variant="outlined"
                size="small"
              >
                <MenuItem value={""} disabled defaultValue>Select an Option</MenuItem>
                {corporatePartners.map((corporate, key) => (
                  <MenuItem key={key} value={corporate.name}>
                    {corporate.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>        
          ) : (
            <FormControl className={classes.formControl}>
              <Typography className={classes.label}>Employer's Name</Typography>
              <TextField
                name="employment.employerName"
                id="employment.employerName"
                {...getFieldProps("employment.employerName")}
                variant="outlined"
                size="small"
                disabled={values?.loanRequest?.borrowerType === 2}
              />
              <ErrorMessage
                name="employment.employerName"
                component={FormErrors}
              />
            </FormControl>
          )} */}

          <FormControl className={classes.formControl}>
            <Typography className={classes.label}>Work Email</Typography>
            <TextField
              name="employment.workplaceEmail"
              id="employment.workplaceEmail"
              {...getFieldProps("employment.workplaceEmail")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage
              name="employment.workplaceEmail"
              component={FormErrors}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <Typography className={classes.label}>Employers Address</Typography>
            <TextField
              name="employment.employerAddress"
              id="employment.employerAddress"
              {...getFieldProps("employment.employerAddress")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage
              name="employment.employerAddress"
              component={FormErrors}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <Typography className={classes.label}>Salary Amount</Typography>
            <TextField
              name="employment.salaryAmount"
              id="employment.salaryAmount"
              type="text"
              className={classes.numberInput}
              value={moneyFormat(values.employment.salaryAmount)}
              onChange={(e) => {
                const val = e.target.value;
                setFieldValue("employment.salaryAmount", currencyToNumber(val));
              }}
              variant="outlined"
              size="small"
            />
            <ErrorMessage
              name="employment.salaryAmount"
              component={FormErrors}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <Typography className={classes.label}>
              Salary Payment Date (dd/mm/yyyy)
            </Typography>
            {/* <TextField
              name="employment.salaryDueDate" 
              id="employment.salaryDueDate" 
              type="date" 
              { ...getFieldProps('employment.salaryDueDate')}
              variant="outlined"
              size="small"
            /> */}
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                inputVariant="outlined"
                format="DD/MM/yyyy"
                variant="outlined"
                // margin="normal"
                id="employment.salaryDueDate"
                name="employment.salaryDueDate"
                size="small"
                value={values.employment.salaryDueDate}
                onChange={(value) => {
                  setFieldValue("employment.salaryDueDate", value);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                helperText={""}
              />
            </MuiPickersUtilsProvider>
            <ErrorMessage
              name="employment.salaryDueDate"
              component={FormErrors}
            />
          </FormControl>

          {values.loanRequest.borrowerType == 2 && <FormControl className={classes.formControl}>
            <Typography className={classes.label}>Employee ID</Typography>
            <TextField
              name="employment.staffOREmploymentId"
              id="employment.staffOREmploymentId"
              {...getFieldProps("employment.staffOREmploymentId")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage
              name="employment.staffOREmploymentId"
              component={FormErrors}
            />
          </FormControl>}
        </div>

        {values.loanRequest.borrowerType == 2 && <Box marginTop={1}>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    {...getFieldProps("employment.termsToDecductFromSalary")}
                    name="employment.termsToDecductFromSalary"
                    color="primary"
                    checked={values.employment.termsToDecductFromSalary}
                  />
                }
                label={
                  <Box>
                    <Typography variant="caption" display="inline">
                      By completing this form, you agree to our
                    </Typography>
                    &nbsp;
                    <Typography
                      onClick={() => setShowLoanPolicy(true)}
                      display="inline"
                      color="primary"
                      variant="caption"
                    >
                      Loan Policy Agreement
                    </Typography>
                  </Box>
                }
              />
              
            </FormControl>
          </Box>
        }
        <ControlButtons step={1} />
      </Paper>
    </div>
  );
};

export default connect(EmploymentInformation);

// TO BE Done
// Date picker format
// File container size
