import React from "react";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { useStyles } from "./style";

const StatusIndicator = (props) => {
  const { status, size, onClick } = props;
  const classes = useStyles(props);

  let backgroundColor;
  let color;
  let text;

  if (status === true) {
    backgroundColor = "#EAFBF1";
    color = "#41D87D";
    text = props.text ? props.text : "Active";
  } else {
    backgroundColor = "#FCE9E9";
    color = "#E43535";
    text = props.text ? props.text : "Inactive";
  }

  return (
    <Box>
      <Box
        onClick={onClick}
        className={classes.statusIndicator}
        style={{
          backgroundColor,
          color,
          cursor: onClick ? "pointer" : "",
          border: size === "large" ? `1px solid ${color}` : "",
        }}
      >
        {text}
      </Box>
    </Box>
  );
};

StatusIndicator.propTypes = {
  status: PropTypes.bool,
  size: PropTypes.string,
  text: PropTypes.string,
};

export default StatusIndicator;
