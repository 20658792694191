import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    width: "82%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  title: {
    fontWeight: "500",
    fontSize: "28px",
    color: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(3),
    display: "flex",
    flexWrap: "wrap",
    // justifyContent: "space-between"
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
      justifyContent: "center"
    },
  },

  provider: {
    border: "1px solid #D7DCE0",
    borderRadius: "5px",
    // height: "224px",
    // width: "25%",
    lineHeight: "1rem",
    padding: "1.5rem 1rem",
    width: "264px",
    margin: "1.2rem 2.5rem",
    [theme.breakpoints.down("xs")]: {
        height: "200px",
      },
  },
  subProvider: {
    width: "85%",
    height: "100%",
    // border: "1px solid green"
  },
  dialogTitle: {
    padding: "6px 10px 0px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  dialogContent: {
    padding: theme.spacing(-5, 3, 0),
  },

  icon: {
    color: "#000000",
    fontSize: "0.5rem",
  },
  text: {
    color: "#000000",
    fontSize: "0.9rem",
  },
  closeButton: {
    // position: "absolute",
    // right: theme.spacing(1),
    // top: theme.spacing(1.2),
    color: theme.palette.grey[500],
  },
}));

export { useStyles };
