import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
     formControl: {
        minWidth : `100%`,
        marginBottom: '1rem'
    },
    submitCTA: {
        minWidth : `100%`,
        marginTop: "2rem"
    }
   
}));

export { useStyles }

