import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  activeItem: {
    opacity: "0.9",
    borderLeft: "4px solid white",
    backgroundColor: "rgb(255,250,250,0.22)",
    padding: "0.2rem 0 0.2rem 0.7rem",
  },
  link: {
    textDecoration: "none",
    color: "#000000",
  },
  root: {
    width: "100%",
    maxWidth: 360,
    margin: theme.spacing(8, 0, 0, 0),
    color: "#ffffff",
  },
  navItem: {
    color: "#FFFFFF",
    opacity: "0.77",
    marginLeft: "1rem",
    padding: "0.2rem 0",
    "&:hover": {
      backgroundColor: "rgb(255,250,250,0.22)",
      paddingLeft: "1rem",
      marginLeft: "0",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  icon: {
    color: "#ffffff",
    margin: "0.2rem 1rem 0 0",
  },
  parentNav: {
    paddingLeft: "2.2rem",
    cursor: "pointer",
    opacity: "0.77",
    "&:hover": {
      backgroundColor: "rgb(255,250,250,0.22)",
    },
  },
  parentNavText: {
    color: "#FFFFFF",
    marginLeft: "1rem",
    padding: "0.2rem 0",
    fontSize: "13px",
  },
  subParent: {
    display: "flex",
    alignItems: "center",
    padding: "0.4rem 0 0.3rem",
    justifyContent: "space-between",
    width: "100%",
  },
  title: { color: "#FFFFFF", fontSize: "13px" },
}));

export { useStyles };
