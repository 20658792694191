import React from "react";
import ResetPasswordLayout from "../../Components/Layout/AdminLayouts/AuthLayout/ResetPasswordLayout"
import AuthLayout from "../../Components/Layout/AdminLayouts/AuthLayout"

const AdminResetPassword = () => {
  return (
        <>
            <AuthLayout>
                <ResetPasswordLayout />
            </AuthLayout>

        </>
        
  );
};

export default AdminResetPassword;
