import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  IconButton,
  FormControl,
  TextareaAutosize,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add"
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import debounce from "lodash.debounce";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";
import clsx from "clsx";

import Paper from "../../../Paper";
import Dialog from "../../../Reusables/Dialog";
import Button from "../../../Button";
import Table from "../../../Reusables/Table";
import Pagination from "../../../Reusables/Pagination";
import StatusIndicator from "../../../Reusables/StatusIndicator";
import TableHead from "../../../Reusables/TableHead";

import alertContext from "../../../../Contexts/alert/alertContext";
import userContext from "../../../../Contexts/users/userContext";

import FormErrors from "../../../FormErrors";
import {
  nameValidation,
  phoneNumberValidation,
  emailValidation,
} from "../../../../Actions/formActions";

import { useStyles } from "./style";
import {
  capitalizeFirstLetter,
  formatDateTime,
  validateDates,
} from "../../../../Actions/helpers";

const FaqLayout = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIssubmitting] = useState(false);
  const [faq, setFaq] = useState(null);
  const [faqs, setFaqs] = useState({});
  const [dialogContentIndex, setDialogContentIndex] = useState(3);

  const { setAlert } = useContext(alertContext);
  const { getFaqs,createFaq, deleteFaq } = useContext(userContext);

  const initialState = {
    question: "",
    answer: "",
    startDate: "",
    endDate: "",
    enable: "",
    pageSize: 10,
    pageNumber: 1,
  };

  const [payload, setPayload] = useState(initialState);
  const {
    question,
    answer,
    enable,
    startDate,
    endDate,
    pageSize,
    pageNumber,
  } = payload;

  const _isMounted = useRef(true);

  useEffect(() => {
    if (_isMounted.current === false) {
      _isMounted.current = true;
    }

    getFaqsData();

    return () => (_isMounted.current = false);
    //eslint-disable-next-line
  }, [pageSize, pageNumber]);


  ////////////////////////**********MODAL ACTIONS**********///////////////////
  const triggerModalOpen = (index) => {
    setDialogContentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    //To fix an issue of having data being set to null first before closing modal
    if (open === false) {
      faq !== null && setFaq(null);
    }
  };
  ////////////////////////**********MODAL ACTIONS**********///////////////////

  ///////////////////////***********USER CREATE ACTIONS***********//////////////
 
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorOpen = Boolean(anchorEl);

  const handleMenuOpen = (e, adminUser) => {
    setAnchorEl(e.currentTarget);
    setFaq(adminUser);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // if(dialogContentIndex !==3){
    //   adminUser !== null && setAdminUser(null);
    // }
  };


  const createNewFaq = async (values) => {
    try {
      setIssubmitting(true);
      const res = await createFaq(values);
      if (res.data.requestSuccessful) {
        let action = faq !== null ? "Updated" : "Created";
        setAlert(`FAQ ${action} successfully`, "success");
        getFaqsData();
        handleClose();
      } else {
        setAlert(res.data.message, "error");
      }
      setIssubmitting(false);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteNewFaq = async (values) => {
    try {
      setLoading(true);
      const res = await deleteFaq(values);
      if (res.data.requestSuccessful) {
        setAlert(`FAQ Deleted successfully`, "success");
        getFaqsData();
        handleClose();
      } else {
        setAlert(res.data.message, "error");
      }
      setIssubmitting(false);
    } catch (err) {
      console.log(err);
    }
  };


  const initialValues = {
    question: faq?.question ? faq?.question : "",
    answer: faq?.answer ? faq?.answer : "",
    bulletAnswers : faq?.bulletAnswers ? faq.bulletAnswers.map((data) => ({answer : data.answer})) : [],
    enable: faq?.isApproved ? faq?.isApproved === "APPROVED" : false,
  };

  const validationSchema = Yup.object({
    question: Yup.string().required('Question is empty'),
    answer: Yup.string().required('Answer is empty'),
  });

  const onSubmit = async (values) => {
    let submitValues = {...values};
    let hasError = false;

    console.log("The submit values are: ", submitValues);
    
    for (let i = 0; i < submitValues.bulletAnswers.length; i++) {
      const element = submitValues.bulletAnswers[i].answer;
      if(!element) {
        hasError = true;
        break;
      }
    }

    if(hasError) {
      return setAlert("Fill all bullet answer fields to submit.", "error");
    }

    //If its an update action
    if (faq !== null) {
      submitValues = { ...values, id: faq.id };
    }

    createNewFaq(submitValues);
  };

  /////////////////**********USER CREATE *************////////////////

  /////////////////**********TABLE ACTIONS *************////////////////
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    ///always reset pageNumber if pageSize or email changes to avoid wrong data fetch
    if (name === "pageSize") {
      payload.pageNumber = 1;
    }
    setPayload({ ...payload, [name]: value });
  };

  const handlePageClick = (page) => {
    setPayload({ ...payload, pageNumber: page });
  };

  const handleQuestionSearch = (e) => {
    setPayload({ ...payload, question: e.target.value });
    loadWithDebounce(e.target.value);
  };

  let url = `FAQ/?pageSize=${pageSize}&pageNo=${pageNumber}`;
  let filter = false;

  const clearFilters = async () => {
    //make the request without filters, taking into consideration the state of the email field
    setPayload({ ...initialState});
    filter = true;
    // if (question !== "") {
    //   url += `&question=${question}`;
    // }
    await getFaqsData(url, filter);
  };

  const getFaqsFirstPage = () => {
    if (pageNumber !== 1) {
      setPayload({ ...payload, pageNumber: 1 });
    }
    pageNumber === 1 && getFaqsData();
  };

  const getFaqsData = async (
    url = `FAQ?pageSize=${pageSize}&page=${pageNumber}`,
    clearFilter
  ) => {
    if (!clearFilter) {
      if (question !== "") {
        url += `&question=${question}`;
      }

      if (!validateDates(startDate, endDate)) {
        setAlert("Please enter valid dates", "error");
        return;
      } else if (startDate !== "" && endDate !== "") {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }

      if (answer !== "") {
        url += `&answer=${answer}`;
      }
    }

    setLoading(true);
    try {
      const res = await getFaqs(url);
      if (_isMounted.current) {
        setFaqs(res.data.responseData);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
    open && handleClose();
    filter = false;
  };

  const addBullet = (values, setValues) => {
    console.log("The values are: ", values);
    const bulletPoints = values.bulletAnswers;
    bulletPoints.push({answer : ""});
    setValues({...values, bulletAnswers : bulletPoints})
  }

  const deleteBullet = (values, setValues, index) => {
    const bulletPoints = values.bulletAnswers;
    bulletPoints.splice(index, 1)
    setValues({...values, bulletAnswers : bulletPoints})
    console.log("The values are: ", values);
  }

  const loadWithDebounce = useCallback(
    debounce((question) => {
      if (question === "") {
        getFaqsData(url);
      } else {
        getFaqsData(`${url}&question=${question}`);
      }
    }, 1000),
    []
  );

  const columns = [
    {
      Header: "Question",
      accessor: "question",
    },
    {
      Header: "Answer",
      accessor: "answer",
    },
    {
      Header: "Date Created",
      accessor: "dateCreated",
      Cell: (props) => formatDateTime(props.value),
    },
    {
      Header: " ",
      Cell: (props) => {
        return (
          <>
            <IconButton
              aria-label="more"
              aria-haspopup="true"
              aria-controls={props.row.original.id}
              onClick={(e) => handleMenuOpen(e, props.row.original)}
            >
              <MoreVertIcon color="primary" style={{ cursor: "pointer" }} />
            </IconButton>
          </>
        );
      },
    },
  ];

  /////////////////**********TABLE ACTIONS *****************////////////////

  /////////////////**********MODAL VIEWS *****************////////////////
  const filterForm = () => (
    <>
      <Grid container spacing={2} style={{ marginTop: "1rem" }}>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <Typography variant="body2" gutterBottom>
              Start Date
            </Typography>
            <TextField
              name="startDate"
              variant="outlined"
              size="small"
              type="date"
              value={startDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <Typography variant="body2" gutterBottom>
              End Date
            </Typography>
            <TextField
              name="endDate"
              variant="outlined"
              size="small"
              type="date"
              value={endDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <Typography variant="body2" gutterBottom>
              Question
            </Typography>
            <TextField
              name="question"
              variant="outlined"
              size="small"
              value={question}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <Typography variant="body2" gutterBottom>
              Answer
            </Typography>
            <TextField
              name="answer"
              variant="outlined"
              size="small"
              value={answer}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Box className={classes.filterActions}>
        <Typography
          style={{ color: "red", cursor: "pointer" }}
          onClick={clearFilters}
        >
          Clear all filters
        </Typography>
        <Button
          variant="contained"
          size="medium"
          disabled={
            loading ||
            (question === "" &&
              answer === "" &&
              startDate === "" &&
              endDate === "")
          }
          loading={loading}
          // type="submit"
          color="primary"
          onClick={() => getFaqsFirstPage()}
        >
          Apply
        </Button>
      </Box>
    </>
  );

  const viewFaq = () => {
    if (faq !== null) {
      const {
        question,
        answer,
        bulletAnswers,
        isApproved,
      } = faq;

      return (
        <Box className={classes.userView}>
          <Box className={classes.userItem}>
            <small variant="body1">Question</small>
            <Typography>{question}</Typography>
          </Box>

          <Box className={classes.userItem}>
            <small>Answer</small>
            <Typography>{answer}</Typography>
          </Box>

          <Box className={classes.userItem}>
            <small style={{marginBottom : "10px"}} >Bullet Answers</small>
            {
              !bulletAnswers.length ? 
              <Typography>None</Typography> :
              <ul>
                {
                  bulletAnswers.map((answer, index) => (
                    <li key={index}>{answer.answer}</li>
                  ))
                }
              </ul>
            }
          </Box>

          <Box className={classes.userItem}>
            <small>Display on Website</small>
            <Typography>{isApproved === "APPROVED" ? 'Approved' : 'Disapproved'}</Typography>
          </Box>
        </Box>
      );
    }
  };

  const deleteFaqModal = () => {
    if (faq !== null) {
      return (
        <Box>
          <Grid container spacing={2} style={{ marginTop: "1rem" }}>
            <Grid item xs={12}>
              <Typography variant="body2">You are about to delete this Faq, would you like to continue?</Typography>
            </Grid>
          </Grid>

          <Box className={classes.createActions}>
            <Typography
              variant="body2"
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            >
              Cancel
            </Typography>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              loading={loading}
              padding={"0.8rem 1.5rem"}
              onClick={() => deleteNewFaq(faq)}
            >
              Delete
            </Button>
          </Box>
        </Box>
      );
    }
  };

  const createFaqForm = () => (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, getFieldProps, values, setValues }) => (
        <Form noValidate autoComplete="off">
          <Grid container spacing={2} style={{ marginTop: "1rem" }}>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <Typography gutterBottom>Question</Typography>
                <TextField
                  name="question"
                  placeholder="Question"
                  id="question"
                  variant="outlined"
                  {...getFieldProps("question")}
                  error={errors.question && touched.question ? true : false}
                  size="small"
                />
                <ErrorMessage name="question" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <Typography gutterBottom>Answer</Typography>
                <TextField
                  multiline={true}
                  rows={8}
                  name="answer"
                  placeholder="Answer"
                  id="answer"
                  error={errors.answer && touched.answer ? true : false}
                  variant="outlined"
                  {...getFieldProps("answer")}
                  size="small"
                />
                <ErrorMessage name="answer" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <label htmlFor="enable" className={classes.label} style={{fontSize : "16px"}}>Bullet Answers</label>

              {
                values.bulletAnswers.map((answer, index) => (
                  <Grid key={index} item style={{display : "flex", justifyContent : "space-between", marginBottom : "10px"}} xs={12}>
                    <Grid item xs={9}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          placeholder="Answer"
                          id={`bulletAnswers${index}`}
                          variant="outlined"
                          name={`bulletAnswers[${index}].answer`}
                          {...getFieldProps(`bulletAnswers[${index}].answer`)}
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item className={classes.deleteBullet} xs={2}>
                      <Typography 
                        style={{cursor : "pointer", padding : "10px"}}
                        onClick={() => deleteBullet(values, setValues, index)}
                      >
                        Delete
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              }

              <Grid item className={classes.addBullet} xs={12}>
                <div 
                  style={{cursor : "pointer", display : "flex", alignItems : "center"}}
                  onClick={() => addBullet(values, setValues)}
                >
                  <AddIcon /> Add Bullet Answer
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                  <label htmlFor="enable" className={classes.label}>
                    Display on Website
                  </label>
                  <FormControlLabel
                    control={
                      <Field
                        component={Switch}
                        id="enable"
                        type="checkbox"
                        checked={values.enable}
                        name="enable"
                        {...getFieldProps("enable")}
                        color="primary"
                      />
                    }
                    className={classes.switchControl}
                    label={values.enable ? "Disapprove" : "Approve"}
                    labelPlacement="start"
                  />
                </FormControl>
              </Grid>
          </Grid>

          <Box className={classes.createActions}>
            <Typography
              variant="body2"
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            >
              Cancel
            </Typography>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              padding={"0.8rem 1.5rem"}
            >
              Submit
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );

  /////////////////**********MODAL VIEWS *****************////////////////

  const getDialogContent = () => {
    switch (dialogContentIndex) {
      case 1:
        return filterForm();

      case 2:
        return viewFaq();

      case 3:
        return createFaqForm();

      case 4:
      return deleteFaqModal();
      
      default:
        return "Nothing";
    }
  };

  const dialogContent = () => (
    <Box style={{ width: "100%" }} className={classes.paper}>
      <Box className={classes.dialogTitle}>
        <Typography variant="h4">
          {dialogContentIndex === 1
            ? "Filter"
            : dialogContentIndex === 2
            ? "FAQ Details"
            : dialogContentIndex === 4
            ? "Delete FAQ" 
            : faq !== null
            ? "Edit FAQ"
            : "Create New Question"}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className={classes.dialogContent}>
        <hr />
        <Box marginTop={"1rem"}>{getDialogContent()}</Box>
      </Box>
    </Box>
  );

  return (
    <Box>
      <Box className={classes.headerTexts}>
        <Typography variant="h4">FAQs</Typography>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => {
            setFaq(null);
            triggerModalOpen(3);
          }}
        >
          New question
        </Button>
      </Box>
      <Box style={{ border: "1px solid #D7DCE0" }} marginTop={"1rem"}>
        <Paper borderRadius={"0"} padding={"2rem"}>
          <TableHead
            title="FAQs List"
            searchItem={question}
            onChange={handleQuestionSearch}
            loading={loading}
            placeholder="Search FAQs"
            totalSize={faqs?.totalSize}
            onClick={() => triggerModalOpen(1)}
            handleInputChange={handleInputChange}
          />
          <Box marginTop={"2rem"}>
            {loading && !faqs.items ? (
              <p>Loading...</p>
            ) : (!loading && faqs.items) ||
              (loading && faqs.items) ? (
              <>
                {/* {console.log(users.items)} */}
                <Table
                  columns={columns}
                  data={faqs.items}
                  loading={loading}
                />
                <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                  <Grid item xs={12} md={3}>
                    {faqs.items.length > 0 && (
                      <Typography>
                        Showing page {faqs.pageNumber} of{" "}
                        {Math.ceil(faqs?.totalSize / pageSize)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Pagination
                      dataSize={faqs?.totalSize}
                      perPage={pageSize}
                      handlePageClick={handlePageClick}
                      forcePage={faqs.pageNumber}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <p>Loading...</p>
            )}
          </Box>
        </Paper>
      </Box>
      <>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={anchorOpen}
          onClick={handleMenuClose}
          PaperProps={{
            style: {
              width: "10ch",
            },
          }}
        >
          <MenuItem onClick={() => triggerModalOpen(2)}>View</MenuItem>
          <MenuItem onClick={() => triggerModalOpen(3)}>Edit</MenuItem>
          <MenuItem onClick={() => triggerModalOpen(4)}>Delete</MenuItem>
        </Menu>
      </>
      <Dialog handleClose={handleClose} open={open} content={dialogContent()} />
    </Box>
  );
};

export default FaqLayout;
