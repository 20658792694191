import React from 'react'
import { Box } from '@material-ui/core'
import ProviderLayout from '../../Components/Layout/AdminLayouts/ProviderLayout'

const ProviderSettings = () => {
    return (
        <>
        <Box marginBottom="2rem">
            {/* <Typography variant="h3">Provider Settings</Typography> */}
        </Box>
        <ProviderLayout/>
        </>
    )
}

export default ProviderSettings
