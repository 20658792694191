import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  IconButton,
  FormControl,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import debounce from "lodash.debounce";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import clsx from "clsx";

import Paper from "../../../Paper";
import Dialog from "../../../Reusables/Dialog";
import Button from "../../../Button";
import Table from "../../../Reusables/Table";
import Pagination from "../../../Reusables/Pagination";
import StatusIndicator from "../../../Reusables/StatusIndicator";
import TableHead from "../../../Reusables/TableHead";

import alertContext from "../../../../Contexts/alert/alertContext";
import userContext from "../../../../Contexts/users/userContext";

import FormErrors from "../../../FormErrors";
import {
  nameValidation,
  phoneNumberValidation,
  emailValidation,
} from "../../../../Actions/formActions";

import { useStyles } from "./style";
import {
  capitalizeFirstLetter,
  formatDateTime,
  validateDates,
} from "../../../../Actions/helpers";
import { useHistory } from "react-router";

const RoleLayout = () => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIssubmitting] = useState(false);
  const [role, setRole] = useState(null);
  // const [roles, setRoles] = useState({});
  const [dialogContentIndex, setDialogContentIndex] = useState(3);

  const { setAlert } = useContext(alertContext);
  const { roles, getAdminRoles, deleteRole } = useContext(
    userContext
  );

  console.log(roles)
  const initialState = {
    roleName: "",
    pageSize: 10,
    pageNumber: 1,
  };

  const [payload, setPayload] = useState(initialState);
  const {
    roleName,
    pageSize,
    pageNumber,
  } = payload;

  const _isMounted = useRef(true);

  useEffect(() => {
    if (_isMounted.current === false) {
      _isMounted.current = true;
    }

    getRoles();

    return () => (_isMounted.current = false);
    //eslint-disable-next-line
  }, [pageSize, pageNumber]);

  useEffect(() => {
    if (_isMounted.current === false) {
      _isMounted.current = true;
    }

    getRoles();

    return () => (_isMounted.current = false);
    //eslint-disable-next-line
  }, [roleName]);

  // useEffect(() => {
  //   getRoles();

  //   // eslint-disable-next-line
  // }, []);

  ////////////////////////**********MODAL ACTIONS**********///////////////////
  const triggerModalOpen = (index) => {
    setDialogContentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    //To fix an issue of having data being set to null first before closing modal
    if (open === false) {
      role !== null && setRole(null);
    }
  };
  ////////////////////////**********MODAL ACTIONS**********///////////////////

  ///////////////////////***********USER CREATE ACTIONS***********//////////////
  
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorOpen = Boolean(anchorEl);

  const handleMenuOpen = (e, role) => {
    setAnchorEl(e.currentTarget);
    setRole(role);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // if(dialogContentIndex !==3){
    //   adminUser !== null && setAdminUser(null);
    // }
  };

  const deleteNewRole = async (values) => {
    try {
      setLoading(true);
      const res = await deleteRole(values);
      if (res.data.requestSuccessful) {
        setAlert(`Role deleted successfully`, "success");
        getRoles();
        handleClose();
      } else {
        setAlert(res.data.message, "error");
      }
      setIssubmitting(false);
    } catch (err) {
      console.log(err);
    }
  };

  /////////////////**********TABLE ACTIONS *************////////////////
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    ///always reset pageNumber if pageSize or email changes to avoid wrong data fetch
    if (name === "pageSize" || name === "roleName") {
      payload.pageNumber = 1;
    }
    setPayload({ ...payload, [name]: value });
  };

  const handlePageClick = (page) => {
    setPayload({ ...payload, pageNumber: page });
  };

  const handleRoleNameSearch = (e) => {
    setPayload({ ...payload, roleName: e.target.value });
    loadWithDebounce(e.target.value);
  };

  let url = `Roles/?pageSize=${pageSize}&pageNo=${pageNumber}`;

  const getRoles = async (
    url = `Roles?pageSize=${pageSize}&page=${pageNumber}`
  ) => {
    setLoading(true);
    try {
      const res = await getAdminRoles(url);
      if (_isMounted.current) {
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
    open && handleClose();
    // filter = false;
  };

  const loadWithDebounce = useCallback(
    debounce((roleName) => {
      if (roleName === "") {
        getRoles(url);
      } else {
        getRoles(`${url}&roleName=${roleName}`);
      }
    }, 1000),
    []
  );

  const columns = [
    {
      Header: "Role",
      accessor: "roleName",
    },
    {
      Header: "Date Created",
      accessor: "dateCreated",
      Cell: (props) => formatDateTime(props.value),
    },
    {
      Header: "No of Permissions",
      accessor: "selectedPermissions",
      Cell: (props) =>
        <Typography>
            {props.value.length}
          </Typography>
    },
    {
      Header: " ",
      Cell: (props) => {
        return (
          <>
            <IconButton
              aria-label="more"
              aria-haspopup="true"
              aria-controls={props.row.original.id}
              onClick={(e) => handleMenuOpen(e, props.row.original)}
            >
              <MoreVertIcon color="primary" style={{ cursor: "pointer" }} />
            </IconButton>
          </>
        );
      },
    },
  ];

  /////////////////**********TABLE ACTIONS *****************////////////////

  /////////////////**********MODAL VIEWS *****************////////////////
  const deleteRoleModal = () => {
    if (role !== null) {
      return (
        <Box>
          <Grid container spacing={2} style={{ marginTop: "1rem" }}>
            <Grid item xs={12}>
              <Typography variant="body2">You are about to delete this Role, would you like to continue?</Typography>
            </Grid>
          </Grid>

          <Box className={classes.createActions}>
            <Typography
              variant="body2"
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            >
              Cancel
            </Typography>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              loading={loading}
              padding={"0.8rem 1.5rem"}
              onClick={() => deleteNewRole(role)}
            >
              Delete
            </Button>
          </Box>
        </Box>
      );
    }
  };

  const viewRole = () => {
    if (role !== null) {
      const {
        roleName,
        selectedPermissions,
        dateCreated,
      } = role;

      return (
        <Box className={classes.userView}>
          <Box className={classes.userItem}>
            <small variant="body1">Role Name</small>
            <Typography>{roleName}</Typography>
          </Box>

          <Box className={classes.userItem}>
            <small>No of Permissions</small>
            <Typography>{selectedPermissions.length}</Typography>
          </Box>

          <Box className={classes.userItem}>
            <small>Date Created</small>
            <Typography>{formatDateTime(dateCreated)}</Typography>
          </Box>
        </Box>
      );
    }
  };

  /////////////////**********MODAL VIEWS *****************////////////////

  const getDialogContent = () => {
    switch (dialogContentIndex) {
      case 1:
        return viewRole();

      case 2:
        return deleteRoleModal();

      default:
        return "Nothing";
    }
  };

  const dialogContent = () => (
    <Box style={{ width: "100%" }} className={classes.paper}>
      <Box className={classes.dialogTitle}>
        <Typography variant="h4">Role Details</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className={classes.dialogContent}>
        <hr />
        <Box marginTop={"1rem"}>{getDialogContent()}</Box>
      </Box>
    </Box>
  );

  return (
    <Box>
      <Box className={classes.headerTexts}>
        <Typography variant="h4">User Management / Roles & Permissions</Typography>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => history.push('/users/roles/action')}
        >
          Create Role
        </Button>
      </Box>
      <Box style={{ border: "1px solid #D7DCE0" }} marginTop={"1rem"}>
        <Paper borderRadius={"0"} padding={"2rem"}>
          <TableHead
            title="Users Roles"
            searchItem={roleName}
            onChange={handleRoleNameSearch}
            loading={loading}
            placeholder="Search roles"
            totalSize={roles?.totalSize}
            handleInputChange={handleInputChange}
          />
          <Box marginTop={"2rem"}>
            {loading && !roles.items ? (
              <p>Loading...</p>
            ) : (!loading && roles.items) ||
              (loading && roles.items) ? (
              <>
                {/* {console.log(users.items)} */}
                <Table
                  columns={columns}
                  data={roles.items}
                  loading={loading}
                />
                <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                  <Grid item xs={12} md={3}>
                    {roles.items.length > 0 && (
                      <Typography>
                        Showing page {roles.pageNumber} of{" "}
                        {Math.ceil(roles?.totalSize / pageSize)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Pagination
                      dataSize={roles?.totalSize}
                      perPage={pageSize}
                      handlePageClick={handlePageClick}
                      forcePage={roles.pageNumber}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <p>Loading...</p>
            )}
          </Box>
        </Paper>
      </Box>
      <>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={anchorOpen}
          onClick={handleMenuClose}
          PaperProps={{
            style: {
              width: "10ch",
            },
          }}
        >
          <MenuItem onClick={() => triggerModalOpen(1)}>View</MenuItem>
          <MenuItem onClick={() => history.push({
            pathname: '/users/roles/action',
            state: role,
          })}>Edit</MenuItem>
          <MenuItem onClick={() => triggerModalOpen(2)}>Delete</MenuItem>
        </Menu>
      </>
      <Dialog handleClose={handleClose} open={open} content={dialogContent()} />
    </Box>
  );
};

export default RoleLayout;
