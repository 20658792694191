import React, { useEffect, useContext } from 'react'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import {
   Container
  } from "@material-ui/core"
import UserContext from '../../../../../Contexts/users/userContext'


const ActivateUserLayout = () => {

    const location = useLocation()

    const payload = queryString.parse(location.search) 
    const { activateUserAccount } = useContext(UserContext)
    const data = {
        userId: payload.userId,
        token: location.search.split("=")[2]
    }
   
    useEffect(() => {
        activateUserAccount(data)

        // eslint-disable-next-line
    }, [])


    return (
        <Container>
            <p>Please wait...</p>
        </Container>
        
    )
}

export default ActivateUserLayout

