import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        
    },
    container: {
        maxHeight: '80rem',
        [theme.breakpoints.down('md')]: {
            maxHeight: '42rem',
        }
    },
    row: {
        height: "0.5rem"
    },
    sticky: {
        position: "sticky",
        background: "#fff",
        left: 0,
        zIndex: 2,
    }
}));

export { useStyles }