import React, { useEffect, useContext, useState } from "react";
import { ErrorMessage } from "formik";
import FormErrors from "../../../../../FormErrors";
import ControlButtons from "../../ControlButtons";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { connect } from "formik";
import MomentUtils from "@date-io/moment";
import {
  FormControl,
  Box,
  TextField,
  Typography,
  Paper,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import {
  moneyFormat,
  currencyToNumber,
  envURL,
} from "../../../../../../Actions/helpers";

import credPalContext from "../../../../../../Contexts/credpal/credpalContext";

import { useStyles } from "./style";
import ModalDialog from "../../../../../Reusables/Dialog";

const BankAndGuarantorInformation = (props) => {
  const classes = useStyles();

  const {
    formik: { getFieldProps, setTouched, setFieldValue },
    values,
  } = props;

  // const { firstName, lastName, phoneNumber, relationship } =
  //   values.guarantors[1] || {};

  // const checkGuarantor = () =>
  //   firstName || lastName || phoneNumber || relationship;

  // const [extraGuarantor, setExtraGuarantor] = useState(checkGuarantor());

  const { banks, user, isLoanCompleted, setIsCompleted } = useContext(credPalContext);

  useEffect(() => {
    setTouched({});

    // eslint-disable-next-line
  }, []);

  const step = values.loanRequest.isSelfSponsored ? 2 : 1;
  
  const acceptOffer = () => {
    // go back to the dashboard
    window.location.href = `${envURL}/dashboard`
  }

  const modalContent = (
    <div className={classes.modalContent}>
      <Typography variant="h4" className={classes.modalHead}>
        Congratulations!!! Your Application is Successful
      </Typography>

      <Typography className={classes.modalBody}>
        Your application form was submitted successfully and you’ve been granted a 
        provisional offer. To accept your offer, please click on the button below. 
        You will need the soft copies of some documents on standby to complete 
        your application.
      </Typography>

      <Box className={classes.modalButton}>
        <Button
          variant="contained"
          color="primary"
          padding={"0.7rem 2rem"}
          onClick={acceptOffer}
        >
          Select Offer
        </Button>
      </Box>
    </div>
  );

  return (
    <div className={classes.container}>
      <Paper style={{ padding: "1rem 2rem 3rem" }}>
        {/* <Box className={classes.first}> */}
        <Box marginTop={3} marginBottom={2}>
          <Typography variant="h5">Bank Account Information</Typography>
        </Box>

        <div className={classes.fieldWrapper}>
          <FormControl
            className={classes.formControl}
            variant="outlined"
            size="small"
          >
            <Typography className={classes.label}>
              Select {user.userType === "student" ? "student's" : "sponsor's"}{" "}
              Bank
            </Typography>
            <Select
              className={classes.select}
              defaultValue=""
              labelId="bankCode"
              id="bankCode"
              name="bankAccount.bankCode"
              {...getFieldProps("bankAccount.bankCode")}
            >
              {banks.length < 1 ? (
                <MenuItem value={null} defaultValue>
                  Loading Banks...
                </MenuItem>
              ) : (
                banks.map((bank, key) => (
                  <MenuItem key={key} value={bank.bankCode}>
                    {bank.bankName}
                  </MenuItem>
                ))
              )}
            </Select>
            <ErrorMessage name="bankAccount.bankCode" component={FormErrors} />
          </FormControl>

          <FormControl className={classes.formControl}>
            <Typography className={classes.label}>Account Name</Typography>
            <TextField
              name="bankAccount.accountName"
              id="bankAccount.accountName"
              {...getFieldProps("bankAccount.accountName")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage
              name="bankAccount.accountName"
              component={FormErrors}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <Typography className={classes.label}>Account Number</Typography>
            <TextField
              name="bankAccount.accountNumber"
              id="bankAccount.accountNumber"
              {...getFieldProps("bankAccount.accountNumber")}
              variant="outlined"
              inputProps={{
                type: "text",
                pattern: "[0-9]*",
                inputMode: "numeric",
              }}
              size="small"
            />
            <ErrorMessage
              name="bankAccount.accountNumber"
              component={FormErrors}
            />
          </FormControl>

          {
            // values.loanRequest.isSelfSponsored && (
            <FormControl className={classes.formControl}>
              <Typography className={classes.label}>
                BVN {user.userType === "sponsor" && "(Sponsor)"}
              </Typography>
              <TextField
                name="bankAccount.bvn"
                id="bankAccount.bvn"
                inputProps={{
                  type: "text",
                  pattern: "[0-9]*",
                  inputMode: "numeric",
                }}
                {...getFieldProps("bankAccount.bvn")}
                variant="outlined"
                size="small"
              />
              <Typography variant="caption">
                Dial *565*0# to check your BVN
              </Typography>
              <ErrorMessage name="bankAccount.bvn" component={FormErrors} />
            </FormControl>
            // )
          }
        </div>

        <Box/>

        <Box marginTop={3} marginBottom={2}>
          <Typography variant="h5">Employment Information</Typography>
        </Box>

        <div className={classes.fieldWrapper}>
          <FormControl className={classes.formControl} size="small">
            <Typography className={classes.label}>Emploment Status</Typography>
            <Select
              className={classes.select}
              defaultValue=""
              name="employment.employmentStatus"
              id="employment.employmentStatus"
              variant="outlined"
              {...getFieldProps("employment.employmentStatus")}
            >
              <MenuItem value="Employed">Employed</MenuItem>
              {values.loanRequest?.borrowerType !== 2 && <MenuItem value="Self-employed">Self-employed</MenuItem>}
            </Select>
            <ErrorMessage
              name="employment.employmentStatus"
              component={FormErrors}
            />
          </FormControl>

          { !(values.loanRequest?.borrowerType === 2) &&
            <FormControl className={classes.formControl}>
              <Typography className={classes.label}>Employer's Name</Typography>
              <TextField
                name="employment.employerName"
                id="employment.employerName"
                {...getFieldProps("employment.employerName")}
                variant="outlined"
                size="small"
                disabled={values?.loanRequest?.borrowerType === 2}
              />
              <ErrorMessage
                name="employment.employerName"
                component={FormErrors}
              />
            </FormControl>
          }

          {/* { values.loanRequest?.borrowerType === 2 ? (
            <FormControl className={classes.formControl}>
              <Typography className={classes.label}>Employer's Name</Typography>
              <Select
                className={classes.select}
                defaultValue=""
                name="employment.employerName"
                id="employment.employerName"
                value={values.employment.employerName}
                onChange={(e) => {
                  const name = e.target.value;
                  setFieldValue("employment.employerName", name);
                  // TODO: Update the organisation code here.
                }}
                variant="outlined"
                size="small"
              >
                <MenuItem value={""} disabled defaultValue>Select an Option</MenuItem>
                {corporatePartners.map((corporate, key) => (
                  <MenuItem key={key} value={corporate.name}>
                    {corporate.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>        
          ) : (
            <FormControl className={classes.formControl}>
              <Typography className={classes.label}>Employer's Name</Typography>
              <TextField
                name="employment.employerName"
                id="employment.employerName"
                {...getFieldProps("employment.employerName")}
                variant="outlined"
                size="small"
                disabled={values?.loanRequest?.borrowerType === 2}
              />
              <ErrorMessage
                name="employment.employerName"
                component={FormErrors}
              />
            </FormControl>
          )} */}

          <FormControl className={classes.formControl}>
            <Typography className={classes.label}>Work Email</Typography>
            <TextField
              name="employment.workplaceEmail"
              id="employment.workplaceEmail"
              {...getFieldProps("employment.workplaceEmail")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage
              name="employment.workplaceEmail"
              component={FormErrors}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <Typography className={classes.label}>Employers Address</Typography>
            <TextField
              name="employment.employerAddress"
              id="employment.employerAddress"
              {...getFieldProps("employment.employerAddress")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage
              name="employment.employerAddress"
              component={FormErrors}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <Typography className={classes.label}>Salary Amount</Typography>
            <TextField
              name="employment.salaryAmount"
              id="employment.salaryAmount"
              type="text"
              className={classes.numberInput}
              value={moneyFormat(values.employment.salaryAmount)}
              onChange={(e) => {
                const val = e.target.value;
                setFieldValue("employment.salaryAmount", currencyToNumber(val));
              }}
              variant="outlined"
              size="small"
            />
            <ErrorMessage
              name="employment.salaryAmount"
              component={FormErrors}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <Typography className={classes.label}>
              Salary Payment Date (dd/mm/yyyy)
            </Typography>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                inputVariant="outlined"
                format="DD/MM/yyyy"
                variant="outlined"
                // margin="normal"
                id="employment.salaryDueDate"
                name="employment.salaryDueDate"
                size="small"
                value={values.employment.salaryDueDate}
                onChange={(value) => {
                  setFieldValue("employment.salaryDueDate", value);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                helperText={""}
              />
            </MuiPickersUtilsProvider>
            <ErrorMessage
              name="employment.salaryDueDate"
              component={FormErrors}
            />
          </FormControl>

          {values.loanRequest.borrowerType == 2 && <FormControl className={classes.formControl}>
            <Typography className={classes.label}>Employee ID</Typography>
            <TextField
              name="employment.staffOREmploymentId"
              id="employment.staffOREmploymentId"
              {...getFieldProps("employment.staffOREmploymentId")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage
              name="employment.staffOREmploymentId"
              component={FormErrors}
            />
          </FormControl>}
        </div>

        {/* </Box> */}

        {/* Guarantors INFORMATION */}

        {/* <Box marginTop={6} marginBottom={2}>
          <Typography variant="h5">Guarantors' Information</Typography>
        </Box>
        <Typography variant="body1" gutterBottom>
          Guarantor 1
        </Typography> */}
        {/* <Box className={classes.fieldWrapper} marginBottom={3}>
          <FormControl className={classes.formControl}>
            <Typography className={classes.label}>First Name</Typography>
            <TextField
              name="guarantors[0].firstName"
              id="guarantors[0].firstName"
              variant="outlined"
              {...getFieldProps("guarantors[0].firstName")}
              size="small"
            />
            <ErrorMessage
              name="guarantors[0].firstName"
              component={FormErrors}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <Typography className={classes.label}>Surname</Typography>
            <TextField
              name="guarantors[0].lastName"
              id="guarantors[0].lastName"
              {...getFieldProps("guarantors[0].lastName")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage
              name="guarantors[0].lastName"
              component={FormErrors}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <Typography className={classes.label}>Phone Number</Typography>
            <TextField
              name="guarantors[0].phoneNumber"
              id="guarantors[0].phoneNumber"
              {...getFieldProps("guarantors[0].phoneNumber")}
              variant="outlined"
              type="tel"
              size="small"
            />
            <ErrorMessage
              name="guarantors[0].phoneNumber"
              component={FormErrors}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <Typography className={classes.label}>Relationship To Me</Typography>
            <TextField
              name="guarantors[0].relationship"
              id="guarantors[0].relationship"
              {...getFieldProps("guarantors[0].relationship")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage
              name="guarantors[0].relationship"
              component={FormErrors}
            />
          </FormControl>

          <Box display={!extraGuarantor ? "block" : "none"} marginTop={1}>
            <Typography
              display="inline"
              variant="body2"
              color="primary"
              onClick={() => {
                setExtraGuarantor(true);
              }}
              style={{
                fontSize: "12px",
                marginTop: "0.5rem",
                cursor: "pointer",
              }}
            >
              <Add style={{ fontSize: "0.8rem" }} /> Add another guarantor
            </Typography>
          </Box>
        </Box>

        <Box display={extraGuarantor ? "block" : "none"}>
          <Typography variant="body1" gutterBottom>
            Guarantor 2
          </Typography>
          <Box className={classes.fieldWrapper}>
            <FormControl className={classes.formControl}>
              <Typography className={classes.label}>First Name</Typography>
              <TextField
                name="guarantors[1].firstName"
                id="guarantors[1].firstName"
                {...getFieldProps("guarantors[1].firstName")}
                variant="outlined"
                size="small"
              />
              <ErrorMessage
                name="guarantors[1].firstName"
                component={FormErrors}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <Typography className={classes.label}>Surname</Typography>
              <TextField
                name="guarantors[1].lastName"
                id="guarantors[1].lastName"
                {...getFieldProps("guarantors[1].lastName")}
                variant="outlined"
                size="small"
              />
              <ErrorMessage
                name="guarantors[1].lastName"
                component={FormErrors}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <Typography className={classes.label}>Phone Number</Typography>
              <TextField
                name="guarantors[1].phoneNumber"
                id="guarantors[1].phoneNumber"
                {...getFieldProps("guarantors[1].phoneNumber")}
                variant="outlined"
                type="tel"
                size="small"
              />
              <ErrorMessage
                name="guarantors[1].phoneNumber"
                component={FormErrors}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <Typography className={classes.label}>Relationship</Typography>
              <TextField
                name="guarantors[1].relationship"
                id="guarantors[1].relationship"
                {...getFieldProps("guarantors[1].relationship")}
                variant="outlined"
                size="small"
              />
              <ErrorMessage
                name="guarantors[1].relationship"
                component={FormErrors}
              />
            </FormControl>
          </Box>
        </Box> */}

        <ControlButtons step={step} />
      </Paper>
      <ModalDialog open={isLoanCompleted} content={modalContent} />
    </div>
  );
};

export default connect(BankAndGuarantorInformation);
