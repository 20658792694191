import React from "react";
import LoanState from "../credpal/CredpalState";
import CredpalState from "../loan/LoanState";
import UserState from "../users/UserState";
import AlertState from "../alert/AlertState"

const ContextWrapper = (props) => {
    return (
        <>
        <AlertState>
        <UserState>
          <LoanState>
            <CredpalState>{props.children}</CredpalState>
          </LoanState>
        </UserState>
        </AlertState>
      </>
    )

};

export default ContextWrapper;
