import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  IconButton,
  FormControl,
} from "@material-ui/core";
import Paper from "../../../../Paper";
import { useStyles } from "./style";
import Dialog from "../../../../Reusables/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Button from "../../../../Button";
import Slider from "../../../../Reusables/Slider";
import Table from "../../../../Reusables/Table";
import Pagination from "../../../../Reusables/Pagination";
import TableHead from "../../../../Reusables/TableHead";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import debounce from "lodash.debounce";
// import json2csv from "json2csv";
import credpalContext from "../../../../../Contexts/credpal/credpalContext";
import loanContext from "../../../../../Contexts/loan/loanContext";
import alertContext from "../../../../../Contexts/alert/alertContext";
import { formatDateTime, validateDates } from "../../../../../Actions/helpers";
// import

const LoanTable = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const initialAmountState = [20000, 500000];
  const [amount, setAmount] = useState(initialAmountState);
  const [loading, setLoading] = useState(false);
  const [loans, setLoans] = useState({});

  const { getInstitutions, institutions } = useContext(credpalContext);
  const { setAlert } = useContext(alertContext);
  const { makeFilterRequest } = useContext(loanContext);

  const initialState = {
    name: "",
    email: "",
    phoneNumber: "",
    startDate: "",
    endDate: "",
    startAmount: "",
    endAmount: "",
    institutionCode: "",
    sponsor: "",
    status: "",
    pageSize: 10,
    pageNumber: 1,
  };

  const [payload, setPayload] = useState(initialState);
  const {
    name,
    email,
    phoneNumber,
    startDate,
    endDate,
    startAmount,
    endAmount,
    institutionCode,
    sponsor,
    status,
    pageSize,
    pageNumber,
  } = payload;

  useEffect(() => {
    getInstitutions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _isMounted = useRef(true);
  useEffect(() => {
    // using the ref value to avoid the component leak warning
    //only set state if component is in DOM
    if (_isMounted.current === false) {
      _isMounted.current = true;
    }
    getLoans();

    return () => (_isMounted.current = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageNumber]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // /always reset pageNumber if pageSize or Name changes to avoid wrong data fetch
    if (name === "pageSize" || name === "name") {
      payload.pageNumber = 1;
    }
    setPayload({ ...payload, [name]: value });
  };

  const handleSliderChange = (event, newValue) => {
    setAmount(newValue);
    setPayload({
      ...payload,
      startAmount: newValue[0],
      endAmount: newValue[1],
    });
  };

  const handlePageClick = (page) => {
    setPayload({ ...payload, pageNumber: page });
  };

  const handleNameSearch = (e) => {
    setPayload({ ...payload, name: e.target.value });
    loadWithDebounce(e.target.value);
  };

  let url = `LoanTransaction/loans?pageSize=${pageSize}&page=${pageNumber}`;
  let filter = false;

  const clearFilters = async () => {
    //make the request without filters, taking into consideration the state of the name field
    setPayload({ ...initialState, name });
    setAmount(initialAmountState);
    filter = true;
    if (name !== "") {
      url += `&name=${name}`;
    }
    await getLoans(url, filter);
  };

  const getLoansFromFirstPage = () => {
    if (pageNumber !== 1) {
      setPayload({ ...payload, pageNumber: 1 });
    }
    pageNumber === 1 && getLoans();
  };

  const getLoans = async (
    url = `LoanTransaction/loans?pageSize=${pageSize}&page=${pageNumber}`,
    clearFilter
  ) => {
    if (!clearFilter) {
      if (name !== "") {
        url += `&name=${name}`;
      }
      if (!validateDates(startDate, endDate)) {
        setAlert("Please enter valid dates", "error");
        return;
      } else if (startDate !== "" && endDate !== "") {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }

      if (email !== "") {
        url += `&email=${email}`;
      }

      // if (phoneNumber !== "") {
      //   url += `&phoneNumber=${phoneNumber}`;
      // }

      if (startAmount !== "" && endAmount !== "") {
        url += `&startAmount=${startAmount}&endAmount=${endAmount}`;
      }

      if (institutionCode !== "") {
        url += `&institutionCode=${institutionCode}`;
      }

      if (status !== "") {
        url += `&status=${status}`;
      }

      if (sponsor !== "") {
        url += `&sponsor=${sponsor}`;
      }
      // console.log(url);
    }

    setLoading(true);
    try {
      const res = await makeFilterRequest(url);
      if (_isMounted.current) {
        console.log("The loan documents are: ", res.data.responseData);
        setLoans(res.data.responseData);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
    open && handleClose();
    filter = false;
  };

  const loadWithDebounce = useCallback(
    debounce((name) => {
      if (name === "") {
        getLoans(url);
      } else {
        getLoans(`${url}&name=${name}`);
      }
    }, 1000),
    []
  );
  // console.log(loans);

  const getLoanStatus = (status) => {
    let backgroundColor;
    let color;

    if (status === "DECLINED" || status === "TERMINATED") {
      backgroundColor = "#FCE9E9";
      color = "#E43535";
    } else if (status === "DISBURSED") {
      backgroundColor = "#EAFBF1";
      color = "#41D87D";
    } else if (status === "PENDINGAPPROVAL") {
      backgroundColor = "#FDF2E7";
      status = "PENDING";
      color = "#F08829";
    } else if (status === "INITIATED") {
      backgroundColor = "#FDF2EF";
      color = "#D66F0F";
    } else {
      backgroundColor = "#E8EFFD";
      color = "#1354D3";
    }

    return (
      <Box>
        <Box
          className={classes.statusIndicator}
          style={{ backgroundColor, color }}
        >
          {status.charAt(0) + status.slice(1).toLowerCase()}
        </Box>
      </Box>
    );
  };

  const columns = [
    {
      Header: "Name",
      accessor: "sponsor",
      sticky: true,
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Amount (NGN)",
      accessor: "loanAmount",
    },
    {
      Header: "Institution",
      accessor: "institution",
    },
    {
      Header: "Sponsor Type",
      accessor: "isSelfSponsored",
    },
    {
      Header: "Loan Ref",
      accessor: "loanRef",
    },

    {
      Header: "Status",
      accessor: "status",
      Cell: (props) => (props.value ? getLoanStatus(props.value) : ""),
    },
    {
      Header: "Date Created",
      accessor: "dateCreated",
      Cell: (props) => formatDateTime(props.value),
    },
    {
      Header: " ",
      Cell: (props) => {
        return (
          <Link
            style={{ textDecoration: "none", cursor: "pointer" }}
            to={`/loan?loanRef=${props.row.allCells[5]?.value}&userId=${props.row.original.userId}`}
          >
            <Typography color="primary" variant="caption">
              View Details
            </Typography>
          </Link>
        );
      },
    },
  ];

  const dialogContent = () => (
    <Box style={{ width: "100%" }} className={classes.paper}>
      <Box className={classes.dialogTitle}>
        <Typography variant="h4">Filter</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className={classes.dialogContent}>
        <hr />
        <Grid container spacing={2} style={{ marginTop: "1rem" }}>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <Typography gutterBottom>
                Start Date
              </Typography>
              <TextField
                name="startDate"
                variant="outlined"
                size="small"
                type="date"
                value={startDate}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <Typography  gutterBottom>
                End Date
              </Typography>
              <TextField
                name="endDate"
                variant="outlined"
                size="small"
                type="date"
                value={endDate}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              className={classes.formControl}
              size="small"
              variant="outlined"
            >
              <Typography gutterBottom>
                Status
              </Typography>
              <Select
                id="status"
                name="status"
                defaultValue=""
                value={status}
                onChange={handleInputChange}
              >
                <MenuItem value="INITIATED">Initiated</MenuItem>
                <MenuItem value="COMPLETED">Completed</MenuItem>
                <MenuItem value="APPROVED">Approved</MenuItem>
                <MenuItem value="PENDINGAPPROVAL">Pending</MenuItem>
                <MenuItem value="DECLINED">Declined</MenuItem>
                <MenuItem value="DISBURSED">Disbursed</MenuItem>
                <MenuItem value="TERMINATED">Terminated</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <Typography gutterBottom>
                Amount (NGN)
              </Typography>
              <Slider
                handleChange={handleSliderChange}
                step={50000}
                min={0}
                max={500000}
                value={amount}
                range
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <Typography gutterBottom>
                Email
              </Typography>
              <TextField
                name="email"
                variant="outlined"
                size="small"
                value={email}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <Typography variant="body2" gutterBottom>
                Phone Number
              </Typography>
              <TextField
                name="phoneNumber"
                variant="outlined"
                type="number"
                size="small"
                value={phoneNumber}
                onChange={handleInputChange}
                className={classes.numberInput}
              />
            </FormControl>
          </Grid> */}
          <Grid item xs={12} md={6}>
            <FormControl
              className={classes.formControl}
              variant="outlined"
              size="small"
            >
              <Typography gutterBottom>
                Institution
              </Typography>
              <Select
                id="institutionCode"
                name="institutionCode"
                defaultValue=""
                value={institutionCode}
                onChange={handleInputChange}
              >
                {institutions.length < 1 ? (
                  <MenuItem value="" defaultValue>
                    Loading Institutions...
                  </MenuItem>
                ) : (
                  institutions.map((institution, key) => (
                    <MenuItem key={key} value={institution.code}>
                      {institution.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              className={classes.formControl}
              size="small"
              variant="outlined"
            >
              <Typography gutterBottom>
                Status
              </Typography>
              <Select
                id="status"
                name="status"
                defaultValue=""
                value={status}
                onChange={handleInputChange}
              >
                <MenuItem value="INITIATED">Initiated</MenuItem>
                <MenuItem value="COMPLETED">Completed</MenuItem>
                <MenuItem value="PENDINGAPPROVAL">Pending</MenuItem>
                <MenuItem value="APPROVED">Approved</MenuItem>
                <MenuItem value="DECLINED">Declined</MenuItem>
                <MenuItem value="DISBURSED">Disbursed</MenuItem>
                <MenuItem value="TERMINATED">Terminated</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              className={classes.formControl}
              size="small"
              variant="outlined"
            >
              <Typography gutterBottom>
                Sponsor Type
              </Typography>
              <Select
                id="sponsor"
                name="sponsor"
                defaultValue=""
                value={sponsor}
                onChange={handleInputChange}
              >
                <MenuItem value={true}>Self Sponsored</MenuItem>
                <MenuItem value={false}>Guardian Sponsored</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box className={classes.filterActions}>
          <Typography
            style={{ color: "red", cursor: "pointer" }}
            onClick={clearFilters}
          >
            Clear all filters
          </Typography>
          <Button
            variant="contained"
            size="medium"
            loading={loading}
            disabled={
              loading ||
              (email === "" &&
                phoneNumber === "" &&
                startDate === "" &&
                endDate === "" &&
                startAmount === "" &&
                status === "" &&
                institutionCode === "" &&
                sponsor === "")
            }
            color="primary"
            onClick={() => getLoansFromFirstPage()}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Box>
  );

  // let headers = [];
  // columns.map((column) => {
  //   let newColumn = {};
  //   newColumn.label = column.Header;
  //   newColumn.key = column.accessor;
  //   if (column.Header !== " ") {
  //     headers = [...headers, newColumn];
  //   }
  //   return headers;
  // });

  let headers = [
    {
      label: "Name",
      key: "beneficiary",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Phone Number",
      key: "phoneNumber",
    },
    {
      label: "Amount (NGN)",
      key: "loanAmount",
    },
    {
      label: "Institution",
      key: "institution",
    },
    {
      label: "Sponsor Type",
      key: "isSelfSponsored",
    },
    {
      label: "Date Created",
      key: "dateCreated",
    },
    {
      label: "Loan Ref",
      key: "loanRef",
    },
    {
      label: "Status",
      key: "status",
    },
  ];

  const exportData = loans?.items?.length > 0 ? loans.items : [];
  exportData.length > 0 &&
    exportData.forEach((item) => {
      if (item.isSelfSponsored === true) {
        item.isSelfSponsored = "Self";
      } else {
        item.isSelfSponsored = "Guardian";
      }
      // item.dateCreated = formatDateTime(item.dateCreated)
    });

  // console.log(JSON.stringify(exportData, null, 2))

  return (
    <Box>
      <Box className={classes.headerTexts}>
        <Typography variant="h4">Recent Applications</Typography>
        <Button
          variant="outlined"
          size="medium"
          disabled={!Array.isArray(loans?.items) || !loans?.items.length}
          color="primary"
        >
          {/* If the loan item array is populated, allow export else, be dummy */}

          {Array.isArray(loans?.items) && loans?.items?.length > 0 ? (
            <CSVLink
              filename={"loans.csv"}
              data={exportData}
              headers={headers}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              Export
            </CSVLink>
          ) : (
            "Export"
          )}
        </Button>
      </Box>
      <Box style={{ border: "1px solid #D7DCE0" }} marginTop={"1rem"}>
        <Paper borderRadius={"0"} padding={"2rem"}>
          <TableHead
            title="Loan Requests"
            searchItem={name}
            onChange={handleNameSearch}
            loading={loading}
            placeholder="Search By First Or Last Name"
            totalSize={loans?.totalSize}
            onClick={setOpen}
            handleInputChange={handleInputChange}
          />

          <Box marginTop={"2rem"}>
            {loading && !loans.items ? (
              <p>Loading...</p>
            ) : (!loading && loans.items) || (loading && loans.items) ? (
              <>
                <Table
                  columns={columns}
                  data={loans.items}
                  loading={loading}
                  size="small"
                />
                <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                  <Grid item xs={12} md={3}>
                    {loans.items.length > 0 && (
                      <Typography>
                        Showing page {loans.pageNumber} of{" "}
                        {Math.ceil(loans?.totalSize / pageSize)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Pagination
                      dataSize={loans?.totalSize}
                      perPage={pageSize}
                      hideNavigation={!loans?.items}
                      handlePageClick={handlePageClick}
                      forcePage={loans?.pageNumber}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <p>Loading...</p>
            )}
          </Box>
        </Paper>
      </Box>
      <Dialog handleClose={handleClose} open={open} content={dialogContent()} />
    </Box>
  );
};

export default LoanTable;
