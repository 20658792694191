import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  IconButton,
  FormControl,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CSVLink } from "react-csv";
import debounce from "lodash.debounce";

import Paper from "../../../../Paper";
import Dialog from "../../../../Reusables/Dialog";
import Button from "../../../../Button";
import Table from "../../../../Reusables/Table";
import Pagination from "../../../../Reusables/Pagination";
import TableHead from "../../../../Reusables/TableHead";

import alertContext from "../../../../../Contexts/alert/alertContext";
import loanContext from "../../../../../Contexts/loan/loanContext";

import { useStyles } from "./style";
import { formatDateTime, validateDates } from "../../../../../Actions/helpers";

const FeedbackResponses = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [feedbackResponses, setfeedbackResponses] = useState({});

  const { setAlert } = useContext(alertContext);
  const { makeFilterRequest } = useContext(loanContext);

  const initialState = {
    email: "",
    phoneNumber: "",
    comment: "",
    startDate: "",
    endDate: "",
    pageSize: 10,
    pageNumber: 1,
  };

  const [payload, setPayload] = useState(initialState);
  const {
    email,
    phoneNumber,
    comment,
    startDate,
    endDate,
    pageSize,
    pageNumber,
  } = payload;

  const _isMounted = useRef(true)

  useEffect(() => {
    if(_isMounted.current === false){
      _isMounted.current = true
    }

    getFeedbackResponses();

    return () => _isMounted.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageNumber]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    //always reset pageNumber if pageSize or Email changes to avoid wrong data fetch
    if (name === "pageSize" || name === "email") {
      payload.pageNumber = 1;
    }
    setPayload({ ...payload, [name]: value });
  };

  const handlePageClick = (page) => {
    setPayload({ ...payload, pageNumber: page });
  };

  const handleEmailSearch = (e) => {
    setPayload({ ...payload, email: e.target.value });
    loadWithDebounce(e.target.value);
  };

  let url = `Feedback?pageSize=${pageSize}&page=${pageNumber}`;
  let filter = false;

  const clearFilters = async () => {
    //make the request without filters, taking into consideration the state of the name field
    setPayload({ ...initialState, email });
    filter = true;
    if (email !== "") {
      url += `&email=${email}`;
    }
    await getFeedbackResponses(url, filter);
  };

  const getFeedbacksFromFirstPage = () => {
    if (pageNumber !== 1) {
      setPayload({ ...payload, pageNumber: 1 });
    }
    pageNumber === 1 && getFeedbackResponses();
  };

  const getFeedbackResponses = async (
    url = `Feedback?pageSize=${pageSize}&page=${pageNumber}`,
    clearFilter
  ) => {
    if (!clearFilter) {
      if (email !== "") {
        url += `&email=${email}`;
      }
      if (!validateDates(startDate, endDate)) {
        setAlert("Please enter valid dates", "error");
        return;
      } else if (startDate !== "" && endDate !== "") {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }

      if (comment !== "") {
        url += `&comment=${comment}`;
      }

      if (phoneNumber !== "") {
        url += `&phoneNumber=${phoneNumber}`;
      }
    }

    setLoading(true);
    try {
      const res = await makeFilterRequest(url);
      // if (_isMounted.curent) {
        setfeedbackResponses(res.data.responseData);
        setLoading(false);
      // }
    } catch (err) {
      console.log(err);
    }
    open && handleClose();
    filter = false;
  };


  const loadWithDebounce = useCallback(
    debounce((email) => {
      if (email=== "") {
        getFeedbackResponses(url);
      } else {
        getFeedbackResponses(`${url}&email=${email}`);
      }
    }, 1000),
    []
  );
  

  // console.log(feedbackResponses);

  const columns = [
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone",
      accessor: "phoneNo",
      Cell: (props) =>
        props.value ? props.value : <Box align="center">-</Box>,
    },
    {
      Header: "Date Created",
      accessor: "dateCreated",
      Cell: (props) => formatDateTime(props.value),
    },
    {
      Header: "Comment",
      accessor: "comment",
    },
  ];

  const dialogContent = () => (
    <Box style={{ width: "100%" }} className={classes.paper}>
      <Box className={classes.dialogTitle}>
        <Typography variant="h4">Filter</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className={classes.dialogContent}>
        <hr />
        <Grid container spacing={2} style={{ marginTop: "1rem" }}>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <Typography variant="body2" gutterBottom>
                Start Date
              </Typography>
              <TextField
                name="startDate"
                variant="outlined"
                size="small"
                type="date"
                value={startDate}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <Typography variant="body2" gutterBottom>
                End Date
              </Typography>
              <TextField
                name="endDate"
                variant="outlined"
                size="small"
                type="date"
                value={endDate}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <Typography variant="body2" gutterBottom>
                Comment
              </Typography>
              <TextField
                name="comment"
                variant="outlined"
                size="small"
                value={comment}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <Typography variant="body2" gutterBottom>
                Phone Number
              </Typography>
              <TextField
                name="phoneNumber"
                type="number"
                className={classes.numberInput}
                variant="outlined"
                size="small"
                value={phoneNumber}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Box className={classes.filterActions}>
          <Typography
            style={{ color: "red", cursor: "pointer" }}
            onClick={clearFilters}
          >
            Clear all filters
          </Typography>
          <Button
            variant="contained"
            size="medium"
            disabled={
              loading ||
              (phoneNumber === "" &&
                comment === "" &&
                startDate === "" &&
                endDate === "")
            }
            loading={loading}
            // type="submit"
            color="primary"
            onClick={() => getFeedbacksFromFirstPage()}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Box>
  );

  let headers = [];
  columns.map((column) => {
    let newColumn = {};
    newColumn.label = column.Header;
    newColumn.key = column.accessor;
    headers = [...headers, newColumn];
    return headers;
  });

  return (
    <Box>
      <Box className={classes.headerTexts}>
        <Typography variant="h4">Users' Feedback</Typography>
        <Button
          variant="outlined"
          size="medium"
          disabled={
            !Array.isArray(feedbackResponses?.items) ||
            !feedbackResponses?.items.length
          }
          color="primary"
        >
          {Array.isArray(feedbackResponses?.items) &&
          feedbackResponses?.items?.length > 0 ? (
            <CSVLink
              filename={"feedback.csv"}
              data={feedbackResponses.items}
              headers={headers}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              Export
            </CSVLink>
          ) : (
            "Export"
          )}
        </Button>
      </Box>
      <Box style={{ border: "1px solid #D7DCE0" }} marginTop={"1rem"}>
        <Paper borderRadius={"0"} padding={"2rem"}>
          <TableHead
            title="Feedback"
            searchItem={email}
            onChange={handleEmailSearch}
            loading={loading}
            placeholder="Search By Sender's Email"
            totalSize={feedbackResponses?.totalSize}
            onClick={setOpen}
            handleInputChange={handleInputChange}
          />
          <Box marginTop={"2rem"}>
            {loading && !feedbackResponses.items ? (
              <p>Loading...</p>
            ) : (!loading && feedbackResponses.items) ||
              (loading && feedbackResponses.items) ? (
              <>
                {/* {console.log(feedbackResponses.items)} */}
                <Table
                  columns={columns}
                  data={feedbackResponses.items}
                  loading={loading}
                />
                <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                  <Grid item xs={12} md={3}>
                    {feedbackResponses.items.length > 0 && (
                      <Typography>
                        Showing page {feedbackResponses.pageNumber} of{" "}
                        {Math.ceil(feedbackResponses?.totalSize / pageSize)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Pagination
                      dataSize={feedbackResponses?.totalSize}
                      perPage={pageSize}
                      handlePageClick={handlePageClick}
                      forcePage={feedbackResponses.pageNumber}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <p>Loading...</p>
            )}
          </Box>
        </Paper>
      </Box>
      <Dialog handleClose={handleClose} open={open} content={dialogContent()} />
    </Box>
  );
};

export default FeedbackResponses;
