import React from 'react'
import { Container, Typography } from '@material-ui/core';
import Logo from '../Components/Logo/';
import Section from '../Components/Section';



const Steps = props => {
    return (
        <Section paddingTop="3rem">
            <Container maxWidth="md">
                <Logo logoDark/>
                    <>
                        <Typography variant="h2" gutterBottom align="center">
                            Hey there!
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                            The page You're looking for does not exist
                        </Typography>
                    </>
                   
                </Container>
        </Section>
    )
    
}

export default Steps