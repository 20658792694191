import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  headerTexts: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableCTAs: {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      gap: "1rem",
    },
  },
  filterTrigger: {
    border: "1px solid #D7DCE0",
    borderRadius: "0.3rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 0.8rem",
    cursor: "pointer",
    margin: "0 3rem 0 2rem",
    width: "40%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "3rem",
      margin: 0,
    },
  },
  nofilterTrigger: {
    // border: "1px solid #D7DCE0",
    // borderRadius: "0.3rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 0.8rem",
    cursor: "pointer",
    margin: "0 3rem 0 2rem",
    width: "40%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "3rem",
      margin: 0,
    },
  },
  numberToShow: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  select: {
    marginLeft: "1rem",
    width: "10rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  formControl: {
    width: "100%",
    marginTop: "0.2rem",
    color: "#6e747d",
  },
  filterActions: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2rem",
    alignItems: "center",
  },
}));

export { useStyles };
