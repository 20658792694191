import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  IconButton,
  FormControl,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CSVLink } from "react-csv";
import debounce from 'lodash.debounce';

import Paper from "../../../Paper";
import Dialog from "../../../Reusables/Dialog";
import Button from "../../../Button";
import Table from "../../../Reusables/Table";
import Pagination from "../../../Reusables/Pagination";
import TableHead from "../../../Reusables/TableHead";

import alertContext from "../../../../Contexts/alert/alertContext";
import loanContext from "../../../../Contexts/loan/loanContext";


import { useStyles } from "./style";
import { formatDateTime, validateDates } from "../../../../Actions/helpers";

const Referrals = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [referrals, setReferrals] = useState({});

  const { setAlert } = useContext(alertContext);
  const { makeFilterRequest } = useContext(loanContext)


  const initialState = {
    referralEmail: "",
    referralName: "",
    recipientEmail: "",
    recipientName: "",
    startDate: "",
    endDate: "",
    pageSize: 10,
    pageNumber: 1,
  };

  const [payload, setPayload] = useState(initialState);
  const {
    referralName, //search parameter
    recipientEmail,
    recipientName,
    startDate,
    endDate,
    pageSize,
    pageNumber,
  } = payload;

  const _isMounted = useRef(true)

  useEffect(() => {
    if(_isMounted.current === false){
      _isMounted.current = true
    }
    getReferrals();
    return (() => _isMounted.current = false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageNumber]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    //always reset pageNumber if pageSize or Name changes to avoid wrong data fetch
    if (name === "pageSize" || name === "referralName") {
      // console.log
      payload.pageNumber = 1;
    }
    setPayload({ ...payload, [name]: value });
  };

  const handlePageClick = (page) => {
    setPayload({ ...payload, pageNumber: page });
  };


  const handleNameSearch = (e) => {
    setPayload({ ...payload, referralName: e.target.value });
    loadWithDebounce(e.target.value)
  };


  let url = `Refer/referrals?pageSize=${pageSize}&page=${pageNumber}`;
  let filter = false;

  const clearFilters = async () => {
    //make the request without filters, taking into consideration the state of the name field
    setPayload({ ...initialState, referralName });
    filter = true;
    if (referralName !== "") {
      url += `&referalName=${referralName}`;
    }
    await getReferrals(url, filter);
  };


  const getReferralsFromFirstPage = () => {
    if(pageNumber !== 1){
      setPayload({...payload, pageNumber: 1})
    }
    pageNumber === 1 && getReferrals()
  }




  const getReferrals = async (
    url = `Refer/referrals?pageSize=${pageSize}&page=${pageNumber}`,
    clearFilter
  ) => {
    if (!clearFilter) {
      if (referralName !== "") {
        url += `&referalName=${referralName}`;
      }
      if (
        !validateDates(startDate, endDate)
      ) {
        setAlert("Please enter valid dates", "error");
        return;
      } else if (startDate !== "" && endDate !== "") {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }

      if (recipientEmail !== "") {
        url += `&recipientEmail=${recipientEmail}`;
      }

      if (recipientName !== "") {
        url += `&recipientName=${recipientName}`;
      }
    }

    setLoading(true);
    try {
      const res = await makeFilterRequest(url);
      if(_isMounted.current){
        setReferrals(res.data.responseData);
        setLoading(false);
      }
     
    } catch (err) {
      console.log(err);
    }
    open && handleClose();
    filter = false;
  };
  // console.log(Referrals);


  const loadWithDebounce = useCallback(
    debounce((name) => {
      if (name === "") {
        getReferrals(url);
      } else {
        getReferrals(`${url}&referalName=${name}`);
      }
    }, 1000),
    []
  );

  const columns = [
    {
      Header: "Referer's Name",
      accessor: "referralName",
    },
    {
      Header: "Referer's Email",
      accessor: "referralEmail",
    },
    {
      Header: "Referees",
      accessor: "referrals",
      Cell: (props) => {
        let val = props.value.map((prop, i) => (
          <Box marginBottom={"0.5rem"} key={i}>
            <Typography variant="body2" style={{ fontSize: "12px" }}>
              Name: {prop.name}
            </Typography>
            <Typography variant="body2" style={{ fontSize: "12px" }}>
              Email: {prop.email}
            </Typography>
          </Box>
        ));
        return val;
      },
    },
    {
      Header: "Date Created",
      accessor: "dateCreated",
      Cell: (props) => formatDateTime(props.value),
    },
    {
      Header: "Message",
      accessor: "message",
    },
  ];

  const dialogContent = () => (
    <Box style={{ width: "100%" }} className={classes.paper}>
      <Box className={classes.dialogTitle}>
        <Typography variant="h4">Filter</Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className={classes.dialogContent}>
        <hr />
        <Grid container spacing={2} style={{ marginTop: "1rem" }}>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <Typography variant="body2" gutterBottom>
                Start Date
              </Typography>
              <TextField
                name="startDate"
                variant="outlined"
                size="small"
                type="date"
                value={startDate}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <Typography variant="body2" gutterBottom>
                End Date
              </Typography>
              <TextField
                name="endDate"
                variant="outlined"
                size="small"
                type="date"
                value={endDate}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <Typography variant="body2" gutterBottom>
                Recipient's Name
              </Typography>
              <TextField
                name="recipientName"
                placeholder="First or Last Name"
                variant="outlined"
                size="small"
                value={recipientName}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <Typography variant="body2" gutterBottom>
                Recipient's Email
              </Typography>
              <TextField
                name="recipientEmail"
                variant="outlined"
                size="small"
                value={recipientEmail}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Box className={classes.filterActions}>
          <Typography
            style={{ color: "red", cursor: "pointer" }}
            onClick={clearFilters}
          >
            Clear all filters
          </Typography>
          <Button
            variant="contained"
            size="medium"
            loading={loading}
            disabled={
              loading ||
              (recipientEmail === "" &&
                recipientName === "" &&
                startDate === "" &&
                endDate === "")
            }
            color="primary"
            onClick={() => getReferralsFromFirstPage()}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Box>
  );

  let headers = [];
  columns.map((column) => {
    let newColumn = {};
    newColumn.label = column.Header;
    newColumn.key = column.accessor;
    if (column.Header === "Referees") {
      newColumn.key = "referral";
    }
    headers = [...headers, newColumn];
    return headers;
  });

  const exportData = referrals?.items;

  exportData?.length &&
    exportData.forEach((exp, i) => {
      let result = "";
      let format =
        exp?.referrals.length &&
        exp?.referrals?.map(
          (ref, i) =>
            (result += `Name: ${ref.name}, Email: ${ref.email}${
              exp?.referrals[i + 1] ? ";" : "."
            } `)
        );
      exp.referral = result;
    });

  return (
    <Box>
      <Box className={classes.headerTexts}>
        <Typography variant="h4"></Typography>
        <Button
          variant="outlined"
          disabled={
            !Array.isArray(referrals?.items) || !referrals?.items?.length
          }
          size="medium"
          color="primary"
        >
          {Array.isArray(referrals?.items) && referrals?.items?.length > 0 ? (
            <CSVLink
              filename={"referrals.csv"}
              data={exportData}
              headers={headers}
              style={{ color: "inherit", textDecoration: "none" }}
            >
              Export
            </CSVLink>
          ) : (
            "Export"
          )}
        </Button>
      </Box>
      <Box style={{ border: "1px solid #D7DCE0", wordBreak : "break-word" }} marginTop={"1rem"}>
        <Paper borderRadius={"0"} padding={"2rem"}>
        <TableHead
            title="Referrals"
            searchItem={referralName}
            onChange={handleNameSearch}
            loading={loading}
            placeholder="Search By Referrer's Name"
            totalSize={referrals?.totalSize}
            onClick={setOpen}
            handleInputChange={handleInputChange}
          />
         
          <Box marginTop={"2rem"}>
            {loading && !referrals.items ? (
              <p>Loading...</p>
            ) : (!loading && referrals.items) ||
              (loading && referrals.items) ? (
              <>
                {/* {console.log(Referrals.items)} */}
                <Table
                  columns={columns}
                  data={referrals.items}
                  loading={loading}
                />
                <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                  <Grid item xs={12} md={3}>
                    {referrals.items.length > 0 && (
                      <Typography>
                        Showing page {referrals.pageNumber} of{" "}
                        {Math.ceil(referrals?.totalSize / pageSize)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Pagination
                      dataSize={referrals?.totalSize}
                      perPage={pageSize}
                      handlePageClick={handlePageClick}
                      forcePage={referrals.pageNumber}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <p>Loading...</p>
            )}
          </Box>
        </Paper>
      </Box>
      <Dialog handleClose={handleClose} open={open} content={dialogContent()} />
    </Box>
  );
};

export default Referrals;
