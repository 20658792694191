import React from 'react'
import EditLoanLayout from '../../Components/Layout/AdminLayouts/EditLoanLayout'

const EditLoan = props => {
    return (
        <>
        <EditLoanLayout { ...props }/>
        </>
    )
}

export default EditLoan
