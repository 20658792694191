import Cookies from "js-cookie";
import axios from "axios";
import { EnvVariables } from "../env/Env";

const { REACT_APP_API_URL } = process.env
const { API_URL } = EnvVariables;

 let baseURL;
// if(process.env.NODE_ENV === "development"){
//   baseURL = REACT_APP_API_URL
// }else{
//   baseURL = API_URL
// }

baseURL = REACT_APP_API_URL || API_URL
// baseURL = "https://gateway-api.bursery.com/api/"
const axiosInstance = axios.create({
  baseURL
});

const accessToken = Cookies.get("accessToken");

if (accessToken) {
  axiosInstance.defaults.headers.Authorization = `Bearer ${accessToken}`;
}

export default axiosInstance;
