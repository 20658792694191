import {
  SET_LOADING,
  GET_PROVIDERS_SUCCESS,
  GET_SETTINGS_SUCCESS,
  GET_LOAN_SUCCESS,
  ACTION_SUCCESS,
  ACTION_ERROR,
  LOAN_ACTION_SUCCESS,
  LOAN_ACTION_ERROR,
  SET_SUBMITTING,
  CREATE_PROVIDER_SUCCESS,
  GET_PROVIDER_SUCCESS,
  SET_CURRENT_PROVIDER,
  CLEAR_CURRENT_PROVIDER,
  SET_CURRENT_PARTNER,
  REMOVE_CURRENT_PARTNER,
  GET_STATES_SUCCESS,
  GET_LOAN_TYPE_SUCCESS,
  SET_UPLOAD_TYPES,
  SET_CURRENT_LOAN
} from "../types";

const loanReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_PROVIDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        providers: payload,
      };

    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pSettings: payload,
      };

    case GET_LOAN_SUCCESS:
      return {
        ...state,
        currentProvider: payload
          ? state.providers.find(
              (provider) => provider.bankName === payload.providerName
            )
          : null,
        redirectUrl: payload ? payload.redirectUrl : null,
        isLoading: false,
      };

    case CREATE_PROVIDER_SUCCESS:
    case GET_PROVIDER_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        isLoading: false,
        provider: payload,
      };

    case CLEAR_CURRENT_PROVIDER:
      return {
        ...state,
        provider: null,
      };

    case SET_CURRENT_PROVIDER:
      return {
        ...state,
        currentProvider: state.providers.find(
          (provider) => provider.id === payload
        ),
      };

    case SET_CURRENT_PARTNER:
      return {
        ...state,
        currentPartner: payload,
      };

    case REMOVE_CURRENT_PARTNER: {
      return {
        ...state,
        currentPartner: {},
      };
    }

    case GET_LOAN_TYPE_SUCCESS : {
      return {
        ...state,
        availableLoanTypes : payload,
      }
    }

    case SET_CURRENT_LOAN : {
      return {
        ...state,
        currentLoan : payload,
      }
    }

    case SET_UPLOAD_TYPES : {
      return {
        ...state,
        uploadTypes : payload,
      }
    }

    case GET_STATES_SUCCESS: {
      return {
        ...state,
        states: payload,
      };
    }

    case ACTION_ERROR:
    case ACTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSubmitting: false,
      };

    case LOAN_ACTION_SUCCESS:
    case LOAN_ACTION_ERROR:
      return {
        ...state,
        isSubmitting: false,
      };

    case SET_SUBMITTING: {
      return {
        ...state,
        isSubmitting: true,
      };
    }

    case SET_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    default:
      return state;
  }
};

export default loanReducer;
