import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";

const useStyles = makeStyles((theme) => ({
  root: {
    // width: 300
  },
  margin: {
    height: theme.spacing(1),
  },
}));

const CustomSlider = withStyles({
  root: {
    color: "#117AF3",
    height: 6,
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: "#fff",
    border: "2px solid #117AF3",
    marginTop: -6,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  // valueLabel: {
  //   left: "calc(-50% - 4px)",
  // },
  valueLabel: {
    left: 'calc(-50% - 4px)',
    top: -50,
    '& *': {
      // background: 'transparent',
      // color: '#000',
      padding: "1.5rem"
    },
  },
  track: {
    height: 5,
    borderRadius: 4,
  },
  rail: {
    height: 5,
    borderRadius: 4,
  },
})(Slider);

function valuetext(value) {
  return value * 1000;
}

const InputSlider = ( props
) => {
  const { handleChange,
    value,
    range,
    min,
    max,
    defaultValue,
    step,
    } = props
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>{min}</Typography>
        <Typography>{max}</Typography>
      </div>
      {range ? (
        <CustomSlider
          max={max ? max : 100}
          min={min ? min : 0}
          value={value}
          step={step}
          onChange={handleChange}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          getAriaValueText={valuetext}
        />
      ) : (
        <CustomSlider
          valueLabelDisplay="auto"
          max={max ? max : 100}
          min={min ? min : 0}
          onChange={handleChange}
          defaultValue={defaultValue ? defaultValue : 0}
        />
      )}
    </div>
  );
};

export default InputSlider;
