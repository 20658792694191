import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        padding: "2rem",
        marginTop: "-2rem"
    },
    formControl: {
        width: "100%"
    },
    numberInput: {
        '& ::-webkit-outer-spin-button, & ::-webkit-inner-spin-button' : {
            '-webkit-appearance': 'none',
            'margin': 0
        },
        '& ::-webkit-inner-spin-button' : {
            '-webkit-appearance': 'none',
            'margin': 0
        },
        '& [type=number]' : {
            '-moz-appearance': 'textfield'
        },
    },
    uploadInfo: {
        display: "flex",
        justifyContent: "space-between",
    },
    select: {
        height: '2.8rem',
        marginTop : "3px",
    },
}));

export { useStyles }
