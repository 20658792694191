import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        // marginBottom: "2rem"
    },
    toolBar: {
        width: "calc(100vw - 1px)", 
        padding: theme.spacing(0, 2),
        height: "4rem",
        display: "flex",
        justifyContent: "space-between",
        // width: 
        backgroundColor: "#FFFFFF"
    },
    title: {
        // flexGrow: 1
    },
    dropDown: {
        marginTop: "2.5rem",
      },
   
}))

export { useStyles }