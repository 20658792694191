import AxiosRequest from "../../../../Services/AxiosRequests";
import { EnvVariables } from "../../../../env/Env";

const advanclyWidget = (payload) => {
  const widgetUrl = window.location.hostname.includes("localhost") 
  ? "https://advancly-borrower.staging.vggdev.com/create-loan-account" 
  : window.location.hostname.includes("educollect")
  ? "https://advancly-borrower.staging.vggdev.com/create-loan-account"
  : "https://borrower.advancly.com/create-loan-account"
  // const widgetUrl = EnvVariables.ADVANCLY_WIDGET_URL

  const { redirect, ...rest } = payload;
  console.log("The widget Url is: ", widgetUrl);

  const openWidget = () => {
    let iframeContainer = document.createElement("div");
    iframeContainer.setAttribute("class", "advancly-widget");
    let iframe = document.createElement("iframe");
    iframe.setAttribute("class", "advancly");
    iframe.src = widgetUrl;

    let closeButton = document.createElement("div");
    closeButton.classList.add("close-advancly-button");
    closeButton.innerHTML = "<span>&times;</span>";

    const closeWidget = () => iframeContainer.remove();

    closeButton.addEventListener("click", () => closeWidget());

    window.addEventListener("message", async (e) => {
      if (e.data.success) {
        try {
          const { data } = await AxiosRequest(
            "post",
            `LoanTransaction/advanclyToApproved?loanRef=${rest.aggregator_loan_ref}&advanclyLoanRef=${e.data.success.loan_application_referenceno}`
          );
          if (data.requestSuccessful === true) {
            closeWidget();
            window.location.href = redirect;
          }
        } catch (error) {
          console.log(error);
        }
      }
    });

    var style = document.createElement("style");
    style.innerHTML = `.advancly-widget {
        position: fixed;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        z-index: 9999999;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .advancly {
        border: none;
        border-radius: 12px;
        width: 75%;
        height: 75%;
      }
      .close-advancly-button {
        position: absolute;
        top: 40px;
        right: 40px;
        cursor: pointer;
        color: #ca1b1b;
        font-size: 28px;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #fff;
      }`;

    iframeContainer.appendChild(closeButton);
    iframeContainer.appendChild(iframe);
    document.head.appendChild(style);
    document.body.appendChild(iframeContainer);

    const msgToPost = {
      hello: {
        ...rest,

        customStyles: {
          buttonBackgroundColor: "#377dff",
          buttonTextColor: "#fff",
          acceptButtonBackgroundColor: "#377dff",
          acceptButtonTextColor: "#fff",
          declineButtonBackgroundColor: "#377dff",
          declineButtonTextColor: "#fff",
          dropdownTextColor: "#000",
          dropdownBackgroundColor: "#fff",
        },
      },
    };

    iframe.onload = () =>
      iframe.contentWindow.postMessage(msgToPost, widgetUrl);
  };

  openWidget();
  window.openWidget = openWidget;
};

export default advanclyWidget;

// aggregator_id: 81,
// bvn_number: "22166030817",
// aggregator_loan_ref: "1234556666",
// cac_number: "000000",
// company_name: "HQ Company",
// borrower_phone: "07012345678",
// borrower_id: "07012345678",
// city: "Ikeja",
// first_name: "John",
// last_name: "Doe",
// email: "ayantunjitimilehin@gmail.com",
// residence_address: "1 Neverland drive",
// state: "Lagos",
// gender: "Male",
// photo_url: "",
// public_key: "11KN2v2BtBUe7tJw",
// customer_type: "1",
// bank_code: "058",
// bank_account_number: "0122449696",
