import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  IconButton,
  FormControl,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import debounce from "lodash.debounce";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";

import Paper from "../../../Paper";
import Dialog from "../../../Reusables/Dialog";
import Button from "../../../Button";
import Table from "../../../Reusables/Table";
import Pagination from "../../../Reusables/Pagination";
import StatusIndicator from "../../../Reusables/StatusIndicator";
import TableHead from "../../../Reusables/TableHead";

import alertContext from "../../../../Contexts/alert/alertContext";
import loanContext from "../../../../Contexts/loan/loanContext";
import credpalContext from "../../../../Contexts/credpal/credpalContext";

import FormErrors from "../../../FormErrors";
import {
  nameValidation,
  phoneNumberValidation,
  emailValidation,
} from "../../../../Actions/formActions";

import { useStyles } from "./style";
import { formatDateTime, validateDates } from "../../../../Actions/helpers";

const InstitutionsLayout = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIssubmitting] = useState(false);
  const [institution, setInstitution] = useState(null);
  const [institutions, setInstitutions] = useState({});
  const [dialogContentIndex, setDialogContentIndex] = useState(3);

  const { setAlert } = useContext(alertContext);
  const { makeFilterRequest, addOrUpdateIntstitution } = useContext(
    loanContext
  );
  const { getBanks, banks } = useContext(credpalContext);

  const initialState = {
    institutionName: "",
    startDate: "",
    endDate: "",
    pageSize: 10,
    pageNumber: 1,
  };

  const [payload, setPayload] = useState({ ...initialState });
  const { pageSize, pageNumber, startDate, endDate, institutionName } = payload;

  const _isMounted = useRef(true);

  useEffect(() => {
    if (_isMounted.current === false) {
      _isMounted.current = true;
    }

    getInstitutions();
    return () => (_isMounted.current = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageNumber]);

  useEffect(() => {
    getBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ////////////////////////**********MODAL ACTIONS**********///////////////////
  const triggerModalOpen = (index) => {
    setDialogContentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    //To fix an issue of having data being set to null first before closing modal
    if (open === false) {
      institution !== null && setInstitution(null);
    }
  };
  ////////////////////////**********MODAL ACTIONS**********///////////////////

  ///////////////////////***********INSTITUTION CREATE ACTIONS***********//////////////

  const [anchorEl, setAnchorEl] = useState(null);
  const anchorOpen = Boolean(anchorEl);

  const handleMenuOpen = (e, institution) => {
    setAnchorEl(e.currentTarget);
    console.log(institution);
    setInstitution(institution);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // if(dialogContentIndex !==3){
    //   institution !== null && setinstitution(null);
    // }
  };

  const createInstitution = async (values) => {
    try {
      setIssubmitting(true);
      const res = await addOrUpdateIntstitution(values);
      if (res.data.requestSuccessful) {
        let action = institution !== null ? "Updated" : "Created";
        setAlert(`Institution ${action} successfully`, "success");
        getInstitutions();
        handleClose();
      } else {
        setAlert(res.data.message, "error");
      }
      setIssubmitting(false);
    } catch (err) {
      console.log(err);
    }
  };

  const initialValues = {
    institutionName: institution?.name ? institution?.name : "",
    institutionCode: institution?.code ? institution?.code : "",
    contactName: institution?.contactPerson ? institution?.contactPerson : "",
    phoneNumber: institution?.phoneNumber ? institution?.phoneNumber : "",
    email: institution?.email ? institution?.email : "",
    bankCode: institution?.bankCode ? institution?.bankCode : "",
    bankAccountName: institution?.accountName ? institution?.accountName : "",
    bankAccountNo: institution?.accountNo ? institution?.accountNo : "",
    url: institution?.websiteUrl ? institution?.websiteUrl : "",
    isIntegrated: institution?.isIntegrated ? institution.isIntegrated : false,
    isActive: institution?.isActive ? institution?.isActive : false,
  };

  const validationSchema = Yup.object({
    institutionName: nameValidation(
      "Institution Name",
      "institutionName",
      true
    ),
    institutionCode: Yup.string().required("Institution Code is Required"),
    contactName: nameValidation("Contact Person Name", "contactName", true),
    phoneNumber: phoneNumberValidation("Phone Number", "phoneNumber"),
    email: emailValidation(),
    bankCode: Yup.string().required("Select a Bank"),
    bankAccountName: nameValidation(
      "Institution Account Name",
      "bankAccountName",
      true
    ),
    bankAccountNo: Yup.string()
      .matches(/^[0-9]*$/, "Only numbers allowed")
      .min(10, "Has to be ten digits")
      .max(10, "Has to be ten digits")
      .required("Account Number is empty"),
    url: Yup.string().url("Invalid url format e.g (https://www.abc.com)"),
  });

  const onSubmit = async (values) => {
    const {
      institutionCode,
      institutionName,
      email,
      bankAccountName,
      bankAccountNo,
      bankCode,
      contactName,
      isActive,
      isIntegrated,
      phoneNumber,
      url,
    } = values;
    const bank = banks.find((bank) => bank.bankCode === bankCode);
    values = {
      code: institutionCode.toUpperCase(),
      name: institutionName,
      bankName: bank?.bankName,
      bankCode,
      accountNo: bankAccountNo,
      accountName: bankAccountName,
      contactPerson: contactName,
      email,
      phoneNumber,
      websiteUrl: url,
      isIntegrated,
      isActive,
    };

    //If its an apdate action
    if (institution !== null) {
      values = { ...values, id: institution.id };
    }

    createInstitution(values);
  };

  /////////////////**********INSTITUTION CREATE *************////////////////

  /////////////////**********TABLE ACTIONS *************////////////////
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    ///always reset pageNumber if pageSize or name changes to avoid wrong data fetch
    if (name === "pageSize" || name === "institutionName") {
      payload.pageNumber = 1;
    }
    setPayload({ ...payload, [name]: value });
  };

  const handlePageClick = (page) => {
    setPayload({ ...payload, pageNumber: page });
  };

  const handleInstitutionNameSearch = (e) => {
    setPayload({ ...payload, institutionName: e.target.value });
    loadWithDebounce(e.target.value);
  };

  let url = `institution?pageSize=${pageSize}&pageNo=${pageNumber}`;
  let filter = false;

  const clearFilters = async () => {
    //make the request without filters, taking into consideration the state of the institutionName field
    setPayload({ ...initialState, institutionName });
    filter = true;
    if (institutionName !== "") {
      url += `&name=${institutionName}`;
    }
    await getInstitutions(url, filter);
  };

  const getInstitutionsFromFirstPage = () => {
    if (pageNumber !== 1) {
      setPayload({ ...payload, pageNumber: 1 });
    }
    pageNumber === 1 && getInstitutions();
  };

  const getInstitutions = async (
    url = `institution?pageSize=${pageSize}&page=${pageNumber}`,
    clearFilter
  ) => {
    if (!clearFilter) {
      if (institutionName !== "") {
        url += `&name=${institutionName}`;
      }

      if (!validateDates(startDate, endDate)) {
        setAlert("Please enter valid dates", "error");
        return;
      } else if (startDate !== "" && endDate !== "") {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }
    }

    setLoading(true);
    try {
      const res = await makeFilterRequest(url);
      if (_isMounted.current) {
        setInstitutions(res.data.responseData);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
    open && handleClose();
    filter = false;
  };

  const loadWithDebounce = useCallback(
    debounce((institutionName) => {
      if (institutionName === "") {
        getInstitutions(url);
      } else {
        getInstitutions(`${url}&Name=${institutionName}`);
      }
    }, 1000),
    []
  );

  const columns = [
    {
      Header: "Institution Name",
      accessor: "name",
    },
    {
      Header: "Institution Code",
      accessor: "code",
    },
    {
      Header: "Contact Person",
      accessor: "contactPerson",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Status",
      accessor: "isActive",
      Cell: (props) => <StatusIndicator status={props.value} />,
    },
    // {
    //   Header: "Integration Status",
    //   accessor: "isIntegrated",
    // Cell: (props) => <StatusIndicator status={props.value} />,
    // },
    {
      Header: "Date Created",
      accessor: "dateCreated",
      Cell: (props) => formatDateTime(props.value),
    },

    {
      Header: " ",
      Cell: (props) => {
        return (
          <>
            <IconButton
              aria-label="more"
              aria-haspopup="true"
              aria-controls={props.row.original.id}
              onClick={(e) => handleMenuOpen(e, props.row.original)}
            >
              <MoreVertIcon color="primary" style={{ cursor: "pointer" }} />
            </IconButton>
          </>
        );
      },
    },
  ];

  /////////////////**********TABLE ACTIONS *****************////////////////

  /////////////////**********MODAL VIEWS *****************////////////////
  const filterForm = () => (
    <>
      <Grid container spacing={2} style={{ marginTop: "1rem" }}>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <label htmlFor="startDate" className={classes.label}>
              Start Date
            </label>
            <TextField
              name="startDate"
              variant="outlined"
              size="small"
              type="date"
              value={startDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <label htmlFor="endDate" className={classes.label}>
              End Date
            </label>
            <TextField
              name="endDate"
              variant="outlined"
              size="small"
              type="date"
              value={endDate}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Box className={classes.filterActions}>
        <Typography
          style={{ color: "red", cursor: "pointer" }}
          onClick={clearFilters}
        >
          Clear all filters
        </Typography>
        <Button
          variant="contained"
          size="medium"
          disabled={loading || (startDate === "" && endDate === "")}
          loading={loading}
          // type="submit"
          color="primary"
          onClick={() => getInstitutionsFromFirstPage()}
        >
          Apply
        </Button>
      </Box>
    </>
  );

  const viewInstitution = () => {
    if (institution !== null) {
      const {
        name,
        code,
        contactPerson,
        email,
        phoneNumber,
        bankName,
        accountNo,
        accountName,
        isIntegrated,
        isActive,
        websiteUrl,
        dateCreated,
      } = institution;

      return (
        <Box className={classes.institutionView}>
          <Box className={classes.institutionItem}>
            <small variant="body1">Institution Name</small>
            <Typography>{name}</Typography>
          </Box>

          <Box className={classes.institutionItem}>
            <small>Institution Code</small>
            <Typography>{code}</Typography>
          </Box>
          <Box className={classes.institutionItem}>
            <small>Contact Person</small>
            <Typography>{contactPerson}</Typography>
          </Box>

          <Box className={classes.institutionItem}>
            <small>Email</small>
            <Typography>{email}</Typography>
          </Box>

          <Box className={classes.institutionItem}>
            <small>phone Number</small>
            <Typography>{phoneNumber}</Typography>
          </Box>

          <Box className={classes.institutionItem}>
            <small>Bank Name</small>
            <Typography>{bankName}</Typography>
          </Box>

          <Box className={classes.institutionItem}>
            <small>Account Name</small>
            <Typography>{accountName}</Typography>
          </Box>

          <Box className={classes.institutionItem}>
            <small>Account Number</small>
            <Typography>{accountNo}</Typography>
          </Box>

          <Box className={classes.institutionItem}>
            <small>Website</small>
            <Typography>{websiteUrl}</Typography>
          </Box>

          <Box className={classes.institutionItem}>
            <small>Active Status</small>
            <Typography>{isActive === true ? "Active" : "Inactive"}</Typography>
          </Box>

          <Box className={classes.institutionItem}>
            <small>Integration Status</small>
            <Typography>
              {isIntegrated === true ? "Active" : "Inactive"}
            </Typography>
          </Box>

          <Box className={classes.institutionItem}>
            <small>Date Created</small>
            <Typography>{formatDateTime(dateCreated)}</Typography>
          </Box>
        </Box>
      );
    }
  };

  const createInstitutionForm = () => (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, getFieldProps, values }) => (
        <Form noValidate autoComplete="off">
          <Grid container spacing={2} style={{ marginTop: "1rem" }}>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <label htmlFor="institutionName" className={classes.label}>
                  Institution Name
                </label>
                <TextField
                  name="institutionName"
                  placeholder="Babcock University"
                  id="institutionName"
                  variant="outlined"
                  {...getFieldProps("institutionName")}
                  error={
                    errors.institutionName && touched.institutionName
                      ? true
                      : false
                  }
                  size="small"
                />
                <ErrorMessage name="institutionName" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <label htmlFor="institutionCode" className={classes.label}>
                  Institution Code
                </label>
                <TextField
                  name="institutionCode"
                  placeholder="BAO"
                  id="institutionCode"
                  variant="outlined"
                  {...getFieldProps("institutionCode")}
                  error={
                    errors.institutionCode && touched.institutionCode
                      ? true
                      : false
                  }
                  size="small"
                />
                <ErrorMessage name="institutionCode" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <label htmlFor="contactName" className={classes.label}>
                  Contact Person's Name
                </label>
                <TextField
                  name="contactName"
                  placeholder="Jane Doe"
                  id="contactName"
                  error={errors.lastName && touched.lastName ? true : false}
                  variant="outlined"
                  {...getFieldProps("contactName")}
                  size="small"
                />
                <ErrorMessage name="contactName" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <label htmlFor="email" className={classes.label}>
                  Email Address
                </label>
                <TextField
                  name="email"
                  id="email"
                  placeholder="janedoe@yahoo.com"
                  error={errors.email && touched.email ? true : false}
                  variant="outlined"
                  {...getFieldProps("email")}
                  size="small"
                />
                <ErrorMessage name="email" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <label htmlFor="phoneNumber" className={classes.label}>
                  Phone Number
                </label>
                <TextField
                  name="phoneNumber"
                  id="phoneNumber"
                  type="tel"
                  error={
                    errors.phoneNumber && touched.phoneNumber ? true : false
                  }
                  placeholder="08038564272"
                  variant="outlined"
                  {...getFieldProps("phoneNumber")}
                  className={classes.numberInput}
                  size="small"
                />
                <ErrorMessage name="phoneNumber" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl
                size="small"
                variant="outlined"
                className={classes.formControl}
              >
                <label htmlFor="bankName" className={classes.label}>
                  Bank Name
                </label>
                <Select
                  defaultValue=""
                  placeholder="Select"
                  displayEmpty
                  id="bankCode"
                  name="bankCode"
                  {...getFieldProps("bankCode")}
                  error={errors.bankCode && touched.bankCode ? true : false}
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  {banks?.map((bank) => (
                    <MenuItem key={bank.id} value={bank.bankCode}>
                      {bank.bankName}
                    </MenuItem>
                  ))}
                </Select>

                <ErrorMessage name="bankName" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <label htmlFor="bankAccountName" className={classes.label}>
                 Institution Bank Name
                </label>
                <TextField
                  name="bankAccountName"
                  id="bankAccountName"
                  placeholder="Jane Doe"
                  {...getFieldProps("bankAccountName")}
                  variant="outlined"
                  error={
                    errors.bankAccountName && touched.bankAccountName
                      ? true
                      : false
                  }
                  size="small"
                />
                <ErrorMessage name="bankAccountName" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <label htmlFor="bankAccountNo" className={classes.label}>
                  Bank Account Number
                </label>
                <TextField
                  name="bankAccountNo"
                  id="bankAccountNo"
                  placeholder="0122449274"
                  className={classes.numberInput}
                  {...getFieldProps("bankAccountNo")}
                  variant="outlined"
                  error={
                    errors.bankAccountNo && touched.bankAccountNo ? true : false
                  }
                  size="small"
                />
                <ErrorMessage name="bankAccountNo" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <label htmlFor="url" className={classes.label}>
                  Website Url
                </label>
                <TextField
                  name="url"
                  id="url"
                  placeholder="https://www.edutechng.com"
                  {...getFieldProps("url")}
                  variant="outlined"
                  error={errors.url && touched.url ? true : false}
                  size="small"
                />
                <ErrorMessage name="url" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6} style={{ display: "flex", gap: "40px" }}>
              <FormControl
              // className={classes.formControl}
              >
                <label htmlFor="isActive" className={classes.label}>
                  Activate
                </label>
                <FormControlLabel
                  control={
                    <Field
                      component={Switch}
                      id="isActive"
                      type="checkbox"
                      checked={values.isActive}
                      name="isActive"
                      {...getFieldProps("isActive")}
                      color="primary"
                    />
                  }
                />
              </FormControl>
              <FormControl
              // className={classes.formControl}
              >
                <label htmlFor="isIntegrated" className={classes.label}>
                  Integrated?
                </label>
                <FormControlLabel
                  control={
                    <Field
                      component={Switch}
                      id="isIntegrated"
                      type="checkbox"
                      checked={values.isIntegrated}
                      name="isIntegrated"
                      {...getFieldProps("isIntegrated")}
                      color="primary"
                    />
                  }
                />
              </FormControl>
            </Grid>
          </Grid>

          <Box className={classes.createActions}>
            <Typography
              variant="body2"
              color="primary"
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            >
              Cancel
            </Typography>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              padding={"0.8rem 1.5rem"}
            >
              {institution !== null ? "Update" : "Add"}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );

  /////////////////**********MODAL VIEWS *****************////////////////

  const getDialogContent = () => {
    switch (dialogContentIndex) {
      case 1:
        return filterForm();

      case 2:
        return viewInstitution();

      case 3:
        return createInstitutionForm();

      default:
        return "Nothing";
    }
  };

  const dialogContent = () => (
    <Box style={{ width: "100%" }} className={classes.paper}>
      <Box className={classes.dialogTitle}>
        <Typography variant="h4">
          {dialogContentIndex === 1
            ? "Filter"
            : dialogContentIndex === 2
            ? "Institution Details"
            : institution !== null
            ? "Edit Institution"
            : "Add Institution"}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className={classes.dialogContent}>
        <hr />
        <Box marginTop={"1rem"}>{getDialogContent()}</Box>
      </Box>
    </Box>
  );

  return (
    <Box>
      <Box className={classes.headerTexts}>
        <Typography variant="h4">Institution Management</Typography>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => {
            setInstitution(null);
            triggerModalOpen(3);
          }}
        >
          Add Institution
        </Button>
      </Box>
      <Box style={{ border: "1px solid #D7DCE0" }} marginTop={"1rem"}>
        <Paper borderRadius={"0"} padding={"2rem"}>
          <TableHead
            title="Total Institutions"
            searchItem={institutionName}
            onChange={handleInstitutionNameSearch}
            loading={loading}
            placeholder="Search By Institution Name"
            totalSize={institutions.totalSize}
            onClick={() => triggerModalOpen(1)}
            handleInputChange={handleInputChange}
          />

          <Box marginTop={"2rem"}>
            {loading && !institutions.items ? (
              <p>Loading...</p>
            ) : (!loading && institutions.items) ||
              (loading && institutions.items) ? (
              <>
                {/* {console.log(users.items)} */}
                <Table
                  columns={columns}
                  data={institutions.items}
                  loading={loading}
                />
                <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                  <Grid item xs={12} md={3}>
                    {institutions.items.length > 0 && (
                      <Typography>
                        Showing page {institutions.pageNumber} of{" "}
                        {Math.ceil(institutions?.totalSize / pageSize)}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Pagination
                      dataSize={institutions?.totalSize}
                      perPage={pageSize}
                      handlePageClick={handlePageClick}
                      forcePage={institutions.pageNumber}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <p>Loading...</p>
            )}
          </Box>
        </Paper>
      </Box>
      <>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={anchorOpen}
          onClick={handleMenuClose}
          PaperProps={{
            style: {
              width: "10ch",
            },
          }}
        >
          <MenuItem onClick={() => triggerModalOpen(2)}>View</MenuItem>
          <MenuItem onClick={() => triggerModalOpen(3)}>Edit</MenuItem>
        </Menu>
      </>
      <Dialog handleClose={handleClose} open={open} content={dialogContent()} />
    </Box>
  );
};

export default InstitutionsLayout;

