import { logOut } from "../Actions/userActions";
import axiosInstance from "./axiosInstance";
import Cookies from "js-cookie";

const AxiosRequest = (requestType, appendUrl, payLoad) => {
  const axiosGet = () => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(appendUrl)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const axiosPost = () => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(appendUrl, payLoad, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };

  const axiosDelete = () => {
    return new Promise((resolve, reject) => {
      axiosInstance.delete(appendUrl).then(res => {
          resolve(res);
      })
      .catch(error => {
          reject(error)
      })
    });
  };

  const axiosPut = () => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(appendUrl, payLoad)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  if (
    (Date.parse(new Date()) > Date.parse(localStorage.valid) &&
      Cookies.get("accessToken")) ||
    (Cookies.get("accessToken") && !localStorage.valid)
  ) {
     logOut();
     return
  }

  switch (requestType) {
    case "post":
      return axiosPost();

    case "get":
      return axiosGet();

    case "delete":
      return axiosDelete();

    case "put":
      return axiosPut();

    default:
      break;
  }
};

export default AxiosRequest;
