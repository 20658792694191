import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  IconButton,
  FormControl,
  Tabs,
  Tab,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import debounce from "lodash.debounce";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";

import Paper from "../../../Paper";
import Dialog from "../../../Reusables/Dialog";
import Button from "../../../Button";
import Table from "../../../Reusables/Table";
import Pagination from "../../../Reusables/Pagination";
import StatusIndicator from "../../../Reusables/StatusIndicator";
import TableHead from "../../../Reusables/TableHead";

import alertContext from "../../../../Contexts/alert/alertContext";
import loanContext from "../../../../Contexts/loan/loanContext";
import credpalContext from "../../../../Contexts/credpal/credpalContext";

import FormErrors from "../../../FormErrors";
import {
  nameValidation,
} from "../../../../Actions/formActions";

import { useStyles } from "./style";
import { validateDates } from "../../../../Actions/helpers";
import AxiosRequest from "../../../../Services/AxiosRequests";

const BrandsLayout = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIssubmitting] = useState(false);
  const [brand, setBrand] = useState(null);
  const [brands, setBrands] = useState({});

  const [categories, setCategories] = useState([]);

  const [delBrand, setDelBrand] = useState(null);
  const [delBrands, setDelBrands] = useState({});

  const [dialogContentIndex, setDialogContentIndex] = useState(3);
  const [tabView, setTabView] = useState(0);

  const { setAlert } = useContext(alertContext);
  const { makeFilterRequest, addOrUpdateBrands, deleteBrand, reverseCategory : reverseBrand } = useContext(
    loanContext
  );

  const initialState = {
    brandName: "",
    startDate: "",
    endDate: "",
    pageSize: 10,
    pageNumber: 1,
  };

  const initialProviderState = {
    providerName: "",
    startDate: "",
    endDate: "",
    pageSize: 10,
    pageNumber: 1,
  };

  const [payload, setPayload] = useState({ ...initialState });
  const { pageSize, pageNumber, startDate, endDate, brandName } = payload;

  const [providerPayload, setProviderPayload] = useState({ ...initialProviderState });
  const { pageSize : pageSize2, pageNumber : pageNumber2, startDate : startDate2, endDate : endDate2, providerName } = providerPayload;

  const _isMounted = useRef(true);

  useEffect(() => {
    if (_isMounted.current === false) {
      _isMounted.current = true;
    }

    getBrands();
    return () => (_isMounted.current = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageNumber]);

  useEffect(() => {
    getDelBrands();
  }, [pageNumber2, pageSize2]);

  useEffect(() => {
    getCategories()
  }, [])

  ////////////////////////**********TAB ACTIONS**********///////////////////
  const handleTabChange = (event, value) => {
    setTabView(value)
  }

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function allyProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  ////////////////////////**********MODAL ACTIONS**********///////////////////
  const triggerModalOpen = (index) => {
    setDialogContentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    //To fix an issue of having data being set to null first before closing modal
    if (open === false) {
      brand !== null && setBrand(null);
      delBrand !== null && setDelBrand(null);
    }
  };
  ////////////////////////**********MODAL ACTIONS**********///////////////////

  ///////////////////////***********brand CREATE ACTIONS***********//////////////

  const [anchorEl, setAnchorEl] = useState(null);
  const anchorOpen = Boolean(anchorEl);

  const handleMenuOpen = (index, brand) => {
    console.log("The selected brand is: ", brand);
    setBrand(brand);
    index === 0 ? deleteUserBrand(brand) : triggerModalOpen(index);
  };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  //   // if(dialogContentIndex !==3){
  //   //   brand !== null && setbrand(null);
  //   // }
  // };

  const createBrands = async (values) => {
    let action = brand !== null ? "Updated" : "Created";
    try {
      setIssubmitting(true);
      const res = await addOrUpdateBrands(values);
      if (res.data.requestSuccessful) {
        setAlert(`Brand ${action} successfully`, "success");
        getBrands();
        handleClose();
      } else {
        setAlert(res.data.message, "error");
      }
      setIssubmitting(false);
    } catch (err) {
      console.log(err);
      setAlert(err.response?.data?.message || `Brand ${action === "Updated" ? "Update" : "Create"} error. Please try again`, "error");
      setIssubmitting(false);
    }
  };

  const deleteUserBrand = async (brand) => {
    let confirmation = window.confirm("Are you sure you want to delete this brand?");
    if(confirmation) {
      try {
        setIssubmitting(true);
        const res = await deleteBrand(brand.id);
        if (res.data.requestSuccessful) {
          let action = "deleted";
          setAlert(`Brand ${action} successfully`, "success");
          getBrands();
          getDelBrands();
          handleClose();
        } else {
          setAlert(res.data.message, "error");
        }
        setIssubmitting(false);
      } catch (err) {
        console.log(err);
        setAlert(err.response?.data?.message || `Brand delete error. Please try again`, "error");
        setIssubmitting(false);
      }
    }
  }

  const reverseDelBrand = async () => {
    let confirmation = window.confirm("Are you sure you want to reverse this deleted brand?");
    if(confirmation) {
      try {
        setIssubmitting(true);
        const res = await reverseBrand(delBrand.id);
        if (res.data.requestSuccessful) {
          let action = "reversed";
          setAlert(`Brand ${action} successfully`, "success");
          getBrands();
          getDelBrands();
          handleClose();
        } else {
          setAlert(res.data.message, "error");
        }
        setIssubmitting(false);
      } catch (err) {
        console.log(err);
        setAlert(err.response?.data?.message || `Brand reverse error. Please try again`, "error");
        setIssubmitting(false);
      }
    }
  }

  const initialValues = {
    brandName : brand ? brand.brandName : "",
    categoryName : brand ? brand.categoryName : "",
  };

  const validationSchema = Yup.object({
    brandName: nameValidation(
      "Brand Name",
      "brandName",
      true
    ),
    categoryName: nameValidation(
      "Category Name",
      "categoryName",
      true
    ),
  });

  const onSubmit = async (values) => {
    const {
      brandName,
      categoryName
    } = values;

    const categoryId = categories.find((category) => category.name === categoryName).id

    let payload = {
      brandName,
      categoryId,
    };

    //If its an update action
    if (brand !== null) {
      payload = {...payload, id: brand.id };
    }

    console.log(`The payload to submit is: `, payload);

    createBrands(payload);
  };

  /////////////////**********CUSTOMERS CREATE *************////////////////

  /////////////////**********TABLE ACTIONS *************////////////////
  const handleInputChange = (e) => {
    if(tabView === 0) {
      const { name, value } = e.target;
      //always reset pageNumber if pageSize or name changes to avoid wrong data fetch
      if (name === "pageSize" || name === "brandName") {
        payload.pageNumber = 1;
      }
      setPayload({ ...payload, [name]: value });
    } else if(tabView === 1) {
      const { name, value } = e.target;
      //always reset pageNumber if pageSize or name changes to avoid wrong data fetch
      if (name === "pageSize" || name === "providerName") {
        console.log("The input name is: ", name, value);
        providerPayload.pageNumber = 1;
      }
      setProviderPayload({ ...providerPayload, [name]: value });
    }
  };

  const handlePageClick = (page, isProvider) => {
    isProvider ? setProviderPayload({...providerPayload, pageNumber : page})
    : setPayload({ ...payload, pageNumber: page });
  };

  const handleBrandNameSearch = (e) => {
    setPayload({ ...payload, brandName: e.target.value });
    loadWithDebounce(e.target.value, true);
  };
  
  const handleDelBrandNameSearch = (e) => {
    setProviderPayload({ ...providerPayload, providerName: e.target.value });
    loadWithDebounce(e.target.value, false);
  };

  let url = `brands?pageSize=${pageSize}&page=${pageNumber}`;
  let providerUrl = `brands/brands_with_deleted_category?pageSize=${pageSize2}&page=${pageNumber2}`;

  let filter = false;

  const clearFilters = async () => {
    //make the request without filters, taking into consideration the state of the brandName/providerName field
    if(tabView === 0) {
      setPayload({ ...initialState, brandName });
      filter = true;
      if (brandName !== "") {
        url += `&BrandName=${brandName}`;
      }
      await getBrands(url, filter);
    } else if(tabView === 1) {
      setProviderPayload({ ...initialProviderState, providerName });
      filter = true;
      if (providerName !== "") {
        providerUrl += `&name=${providerName}`;
      }
      await getDelBrands(providerUrl, filter);
    }
  };

  const getdataFromFirstPage = () => {
    if(tabView === 0) {
      if (pageNumber !== 1) {
        setPayload({ ...payload, pageNumber: 1 });
      }
      pageNumber === 1 && getBrands();
    } else if(tabView === 1) {
      if (pageNumber2 !== 1) {
        setProviderPayload({ ...providerPayload, pageNumber: 1 });
      }
      pageNumber2 === 1 && getDelBrands();
    }
  };

  const getCategories = async () => {
    try {
      const res = await makeFilterRequest("category");

      if(res.data.requestSuccessful) {
        setCategories(res.data.responseData.items);
        console.log("Value for categories is: ", res.data.responseData, categories);
      } else {
        setAlert(res.data?.message || "Error! Category list could not be fetched. Reload page", "error")
        console.log("Get categories error", res.data);
      }
    } catch (error) {
      setAlert(error.data?.message || "Error! Category list could not be fetched. Reload page", "error")
      console.log(error);
    }
  }

  const getBrands = async (
    url = `brands?pageSize=${pageSize}&page=${pageNumber}`,
    clearFilter
  ) => {
    if (!clearFilter) {
      if (brandName !== "") {
        url += `&BrandName=${brandName}`;
      }

      if (!validateDates(startDate, endDate)) {
        setAlert("Please enter valid dates", "error");
        return;
      } else if (startDate !== "" && endDate !== "") {
        url += `&startDate=${startDate}&endDate=${endDate}`;
      }
    }

    setLoading(true);
    try {
      const res = await makeFilterRequest(url);
      if (_isMounted.current) {
        console.log("Brand Response data is: ", res.data.responseData);
        setBrands(res.data.responseData);
        setLoading(false);
      }
    } catch (err) {
      setAlert(err.response?.data?.message || "Error! Brands list could not be fetched. Reload page", "error")
      console.log(err);
    }
    open && handleClose();
    filter = false;
  };

  const getDelBrands = async (
    url = `brands/brands_with_deleted_category?pageSize=${pageSize2}&page=${pageNumber2}`,
    clearFilter
  ) => {
    if (!clearFilter) {
      if (providerName) {
        url += `&BrandName=${providerName}`;
      }

      if (!validateDates(startDate2, endDate2)) {
        setAlert("Please enter valid dates", "error");
        return;
      } else if (startDate2 !== "" && endDate2 !== "") {
        url += `&startDate=${startDate2}&endDate=${endDate2}`;
      }
    }

    setLoading(true);
    try {
      const res = await makeFilterRequest(url);
      if (_isMounted.current) {
        console.log("Response data is: ", res.data.responseData);
        setDelBrands(res.data.responseData);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
    open && handleClose();
    filter = false;
  };

  const loadWithDebounce = useCallback(
    debounce((brandName, isBrand) => {
      if (brandName === "" && isBrand) {
        getBrands(url);
      } else if (brandName === "" && !isBrand) {
        getDelBrands(providerUrl);
      } else {
        isBrand ? getBrands(`${url}&BrandName=${brandName}`) : getDelBrands(`${providerUrl}&Name=${brandName}`);
      }
    }, 1000),
    []
  );

  const columns = [
    {
      Header: "Brand Name",
      accessor: "brandName",
    },
    {
      Header: "Brand Category",
      accessor: "categoryName",
    },
    {
      Header: "Date Created",
      accessor: "dateCreated",
      Cell: (props) => <span>{new Date(props.value).toDateString()}</span>,
    },
    {
      Header: " ",
      Cell: (props) => (
        <span 
          onClick={(e) => handleMenuOpen(2, props.row.original)}
          style={{cursor : "pointer", color : "#117AF3"}}
        >
          View
        </span>
      )
    },
    {
      Header: "  ",
      Cell: (props) => (
        <span 
          onClick={(e) => handleMenuOpen(3, props.row.original)}
          style={{cursor : "pointer", color : "#117AF3"}}
        >
          Edit
        </span>
      )
    },
    {
      Header: "   ",
      Cell: (props) => (
        <span 
          onClick={(e) => handleMenuOpen(0, props.row.original)}
          style={{cursor : "pointer", color : "red"}}
        >
          Delete
        </span>
      )
    },
  ];

  const softDeletedColumns = [
    {
      Header: "Brand Name",
      accessor: "brandName",
    },
    {
      Header: "Category Name",
      accessor: "categoryName",
    },
    {
      Header: "Date Created",
      accessor: "dateCreated",
      Cell: (props) => <span>{new Date(props.value).toDateString()}</span>,
    },
    {
      Header: " ",
      Cell: (props) => (
        <span 
          onClick={(e) => handleMenuOpen(2, props.row.original)}
          style={{cursor : "pointer", color : "#117AF3"}}
        >
          View
        </span>
      )
    },
    {
      Header: "  ",
      Cell: (props) => (
        <span 
          onClick={(e) => handleMenuOpen(3, props.row.original)}
          style={{cursor : "pointer", color : "#117AF3"}}
        >
          Edit
        </span>
      )
    },
  ];

  /////////////////**********TABLE ACTIONS *****************////////////////

  /////////////////**********MODAL VIEWS *****************////////////////
  const filterForm = () => (
    <>
      <Grid container spacing={2} style={{ marginTop: "1rem" }}>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <label htmlFor="startDate" className={classes.label}>
              Start Date
            </label>
            <TextField
              name="startDate"
              variant="outlined"
              size="small"
              type="date"
              value={tabView === 0 ? startDate : startDate2}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.formControl}>
            <label htmlFor="endDate" className={classes.label}>
              End Date
            </label>
            <TextField
              name="endDate"
              variant="outlined"
              size="small"
              type="date"
              value={tabView === 0 ? endDate : endDate2}
              onChange={handleInputChange}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Box className={classes.filterActions}>
        <Typography
          style={{ color: "red", cursor: "pointer" }}
          onClick={clearFilters}
        >
          Clear all filters
        </Typography>
        <Button
          variant="contained"
          size="medium"
          disabled={loading || 
            (tabView === 0 ? 
            (startDate === "" && endDate === "") 
            : (startDate2 === "" && endDate2 === ""))
          }
          loading={loading}
          // type="submit"
          color="primary"
          onClick={() => getdataFromFirstPage()}
        >
          Apply
        </Button>
      </Box>
    </>
  );

  const viewBrand = () => {
    if (brand !== null) {
      const {
        brandName,
        categoryName,
        dateCreated
      } = brand;

      return (
        <Box className={classes.brandView}>
          <Box className={classes.brandItem}>
            <small variant="body1">Brand Name:</small>
            <Typography>{brandName}</Typography>
          </Box>

          <Box className={classes.brandItem}>
            <small variant="body1">Category Name:</small>
            <Typography>{categoryName}</Typography>
          </Box>
          
          <Box className={classes.brandItem}>
            <small>Date Created:</small>
            <Typography>{new Date(dateCreated).toDateString()}</Typography>
          </Box>
        </Box>
      );
    }
  };

  const viewDeletedBrand = () => {
    if (delBrand !== null) {
      const {
        brandName,
        categoryName,
        dateCreated,
      } = delBrand;

      return (
        <Box className={classes.brandView}>
          <Box className={classes.brandItem}>
            <small variant="body1">Brand Name:</small>
            <Typography>{brandName}</Typography>
          </Box>

          <Box className={classes.brandItem}>
            <small variant="body1">Category Name:</small>
            <Typography>{categoryName}</Typography>
          </Box>
          
          <Box className={classes.brandItem}>
            <small>Date Created:</small>
            <Typography>{new Date(dateCreated).toDateString()}</Typography>
          </Box>
        </Box>
      );
    }
  };

  const createBrandForm = () => (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, getFieldProps, values, setFieldValue }) => (
        <Form noValidate autoComplete="off">
          <Grid container spacing={2} style={{ marginTop: "1rem", minWidth : "200px" }}>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <label htmlFor="brandName" className={classes.label}>
                  Brand Name
                </label>
                <TextField
                  name="brandName"
                  placeholder="Enter Brand Name"
                  id="brandName"
                  variant="outlined"
                  {...getFieldProps("brandName")}
                  error={
                    errors.brandName && touched.brandName
                      ? true
                      : false
                  }
                  size="small"
                />
                <ErrorMessage name="brandName" component={FormErrors} />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl className={classes.formControl}
                variant="outlined"
                size="small"
              >
                <label htmlFor="Type" className={classes.label}>
                  Category
                </label>
                <Select
                  name="categoryName"
                  {...getFieldProps("categoryName")}

                  error={
                    errors.brandName && touched.brandName
                      ? true
                      : false
                  }
                >
                  {
                    categories.map((category, index) => (
                      <MenuItem key={index} value={category.name}>{category.name}</MenuItem>
                    ))
                  }
                </Select>
                <ErrorMessage name="brandName" component={FormErrors} />
              </FormControl>
            </Grid>
          </Grid>

          <Box className={classes.createActions}>
            <Typography
              variant="body2"
              color="primary"
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            >
              Cancel
            </Typography>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              padding={"0.8rem 1.5rem"}
            >
              {brand !== null ? "Update" : "Add"}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );

  const editDelBrandForm = () => {
    if (delBrand !== null) {
      const {
        name,
        dateCreated,
      } = delBrand;

      return (
        <Box className={classes.brandView} style={{paddingBottom : "0px"}}>
          <Box className={classes.brandItem}>
            <small variant="body1">Brand Name:</small>
            <Typography>{name}</Typography>
          </Box>
          
          <Box className={classes.brandItem}>
            <small>Date Created:</small>
            <Typography>{new Date(dateCreated).toDateString()}</Typography>
          </Box>
          
          <div style={{display : "flex", marginTop : "20px", justifyContent : "end", width : "100%"}}>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={reverseDelBrand}
            >
              Reverse Delete
            </Button>
          </div>
        </Box>
      );
    }
  };

  /////////////////**********MODAL VIEWS *****************////////////////

  const getDialogContent = () => {
    switch (dialogContentIndex) {
      case 1:
        return filterForm();

      case 2:
        return viewBrand();

      case 3:
        return createBrandForm();

      case 4:
        return viewDeletedBrand();
      
      case 5:
        return editDelBrandForm();

      default:
        return "Nothing";
    }
  };

  const dialogContent = () => (
    <Box style={{ width: "100%", minWidth : "300px" }} className={classes.paper}>
      <Box className={classes.dialogTitle}>
        <Typography variant="h4">
          {dialogContentIndex === 1
            ? "Filter"
            : dialogContentIndex === 2
            ? "Brand Details"
            : dialogContentIndex === 4
            ? "Deleted Brand Details"
            : dialogContentIndex === 5
            ? "Reverse Deleted Brand"
            : brand !== null
            ? "Edit Brand"
            : "Add Brand"
          }
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box className={classes.dialogContent}>
        <hr />
        <Box marginTop={"1rem"}>{getDialogContent()}</Box>
      </Box>
    </Box>
  );

  return (
    <Box>
      <Box className={classes.headerTexts}>
        <Typography variant="h4">Brands</Typography>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => {
            setBrand(null);
            triggerModalOpen(3);
          }}
        >
          Add Brand
        </Button>
      </Box>

      <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
        <Tabs value={tabView} onChange={handleTabChange} 
          textColor="primary"
          indicatorColor="primary" 
          aria-label="loan brands tab"
        >
          <Tab label="Brands" {...allyProps(0)} />
          <Tab label="Brands with Deleted Category" {...allyProps(1)}/>
        </Tabs>
      </Box>

      <TabPanel value={tabView} index={0}>
        <Box style={{ border: "1px solid #D7DCE0" }} marginTop={"1rem"}>
          <Paper borderRadius={"0"} padding={"2rem"}>
            <TableHead
              title="Total Brands"
              searchItem={brandName}
              onChange={handleBrandNameSearch}
              loading={loading}
              placeholder="Search By brand Name"
              totalSize={brands.totalSize}
              onClick={() => triggerModalOpen(1)}
              handleInputChange={handleInputChange}
              shouldFocus={true}
            />

            <Box marginTop={"2rem"}>
              {loading && !brands.items ? (
                <p>Loading...</p>
              ) : (!loading && brands.items) ||
                (loading && brands.items) ? (
                <>
                  {/* {console.log(users.items)} */}
                  <Table
                    columns={columns}
                    data={brands.items}
                    loading={loading}
                  />
                  <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                    <Grid item xs={12} md={3}>
                      {brands.items.length > 0 && (
                        <Typography>
                          Showing page {brands.pageNumber} of{" "}
                          {Math.ceil(brands?.totalSize / pageSize)}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Pagination
                        dataSize={brands?.totalSize}
                        perPage={pageSize}
                        handlePageClick={handlePageClick}
                        forcePage={brands.pageNumber}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <p>Loading...</p>
              )}
            </Box>
          </Paper>
        </Box>
      </TabPanel>

      <TabPanel value={tabView} index={1}>
        <Box style={{ border: "1px solid #D7DCE0" }} marginTop={"1rem"}>
          <Paper borderRadius={"0"} padding={"2rem"}>
            <TableHead
              title="Total Providers"
              searchItem={providerName}
              onChange={handleDelBrandNameSearch}
              loading={loading}
              placeholder="Search By Brand Name"
              totalSize={delBrands.totalSize}
              onClick={() => triggerModalOpen(1)}
              handleInputChange={handleInputChange}
              shouldFocus={true}
            />

            <Box marginTop={"2rem"}>
              {loading && !delBrands?.items ? (
                <p>Loading...</p>
              ) : (!loading && delBrands?.items) ||
                (loading && delBrands?.items.length) ? (
                <>
                  {/* {console.log(users.items)} */}
                  <Table
                    columns={softDeletedColumns}
                    data={delBrands.items}
                    loading={loading}
                  />
                  <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                    <Grid item xs={12} md={3}>
                      {delBrands.items.length > 0 && (
                        <Typography>
                          Showing page {delBrands.pageNumber || 1} of{" "}
                          {Math.ceil(delBrands?.totalSize / pageSize) || 1}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Pagination
                        dataSize={delBrands?.totalSize}
                        perPage={pageSize2}
                        handlePageClick={(page) => handlePageClick(page, true)}
                        forcePage={delBrands.pageNumber}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <p>Loading...</p>
              )}
            </Box>
          </Paper>
        </Box>
      </TabPanel>

      {/* <>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={anchorOpen}
          onClick={handleMenuClose}
          PaperProps={{
            style: {
              width: "10ch",
            },
          }}
        >
          <MenuItem onClick={() => triggerModalOpen(2)}>View</MenuItem>
          <MenuItem onClick={() => triggerModalOpen(3)}>Edit</MenuItem>
          <MenuItem onClick={deleteUserBrand}>Delete</MenuItem>
        </Menu>
      </> */}
      <Dialog handleClose={handleClose} open={open} content={dialogContent()} />
    </Box>
  );
};

export default BrandsLayout;

