import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        padding: '2rem',
        margin: '15px 0px',
    },
    permission: {
        border: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(0, 1),
        margin: theme.spacing(2, 0)
    },
    permissionBox: {
        marginBottom: '2rem'
    }
}));

export default useStyles;