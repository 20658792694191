import React, { useEffect } from "react";
import { Box, Typography, Paper, Container } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import LoanSuccess from "../../../../Assets/images/LoanSuccess.svg";
import Button from "../../../Button";

import { useStyles } from "./style";
import { envURL } from "../../../../Actions/helpers";

const SuccessPage = () => {
  const classes = useStyles();

  useEffect(() => {
    localStorage.removeItem("userId");
    localStorage.removeItem("providerId");
    localStorage.removeItem("loanRef");
  }, []);

  const location = useLocation();
  const redirect = queryString.parse(location.search).redirect;

  const link = redirect ? redirect : `${envURL}/dashboard`;

  return (
    <Container className={classes.root}>
      <Paper className={classes.paper}>
        <Box marginBottom={3}>
          <img src={LoanSuccess} widh="80px" alt="" />
        </Box>
        <Box align="center">
          <Typography variant="h4">Thank You!</Typography>
          <Typography variant="body2">
            Your application has been received and we will contact you soon.
          </Typography>
        </Box>

        <Box marginTop={3}>
          {/* <a href="https://educollectfinance.com/dashboard" style={{textDecoration: "none"}} rel="noopener noreferrer"> */}
          <a
            href={link}
            style={{ textDecoration: "none" }}
            rel="noopener noreferrer"
          >
            <Button variant="contained" color="primary">
              Go To Dashboard
            </Button>
          </a>
        </Box>
      </Paper>
    </Container>
  );
};

export default SuccessPage;
