import React from 'react'
import { Box } from '@material-ui/core'
import LoanLayout from '../../Components/Layout/AdminLayouts/LoanLayout'

const ViewLoan = () => {
    return (
        <>
        <Box marginBottom="2rem">
            {/* <Typography variant="h3">Provider Settings</Typography> */}
        </Box>
        <LoanLayout/>
        </>
    )
}

export default ViewLoan
