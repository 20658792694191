import React, { useEffect, useContext } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import Spinner from "../../../Reusables/Spinner";
import CredPalForm from "./CredPalForm";

import LoanContext from "../../../../Contexts/loan/loanContext";

const EditLoanLayout = (props) => {
  const location = useLocation();
  const userId = queryString.parse(location.search).userId
    ? queryString.parse(location.search).userId
    : "";
  const loanRef = queryString.parse(location.search).loanRef
    ? queryString.parse(location.search).loanRef
    : "";

  const { getProviders, isLoading, getAdminLoanByRef, currentProvider } =
    useContext(LoanContext);

  useEffect(() => {
    getProviders();
    if (loanRef !== "") {
      getAdminLoanByRef(loanRef, userId);
    }
    // eslint-disable-next-line
  }, []);

  const showProviderView = (name) => {
    console.log("Show provider view is called: ");
    switch (name) {
      case "CredPal":
      case "Advancly":
        return <CredPalForm />;

      default:
        return <CredPalForm />;
    }
  };

  return isLoading || !currentProvider ? (
    <Spinner />
  ) : (
    showProviderView(currentProvider.bankName)
  );
};

export default EditLoanLayout;
