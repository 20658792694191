import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    statusIndicator: {
      width: "fit-content",
      borderRadius: props => props.size === "large" ? "3px" : "7px",
      // padding:  props => props.size === "large" ? "3px 10px" : "1px 10px",
      padding: "4px 8px"
      },
}));

export { useStyles };
