import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useRef,
  } from "react";
  import {
    Box,
    Typography,
    Grid,
    TextField,
    Select,
    MenuItem,
    IconButton,
    FormControl,
    Tabs,
    Tab,
    FormGroup,
    FormControlLabel,
    Checkbox,
  } from "@material-ui/core";
  import CloseIcon from "@material-ui/icons/Close";
  import Menu from "@material-ui/core/Menu";
  import MoreVertIcon from "@material-ui/icons/MoreVert";
  
  import debounce from "lodash.debounce";
  import * as Yup from "yup";
  import { Formik, Form, ErrorMessage, Field } from "formik";
  
  import Paper from "../../../Paper";
  import Dialog from "../../../Reusables/Dialog";
  import Button from "../../../Button";
  import Table from "../../../Reusables/Table";
  import Pagination from "../../../Reusables/Pagination";
  import StatusIndicator from "../../../Reusables/StatusIndicator";
  import TableHead from "../../../Reusables/TableHead";
  
  import alertContext from "../../../../Contexts/alert/alertContext";
  import loanContext from "../../../../Contexts/loan/loanContext";
  import credpalContext from "../../../../Contexts/credpal/credpalContext";
  
  import FormErrors from "../../../FormErrors";
  import {
    nameValidation,
  } from "../../../../Actions/formActions";
  
  import { useStyles } from "./style";
  import { validateDates } from "../../../../Actions/helpers";
  import AxiosRequest from "../../../../Services/AxiosRequests";
  
  const CategoriesLayout = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
  
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIssubmitting] = useState(false);
    const [category, setCategory] = useState(null);
    const [categories, setCategories] = useState({});

    const [delCategory, setDelCategory] = useState(null);
    const [delCategories, setDelCategories] = useState({});

    const [dialogContentIndex, setDialogContentIndex] = useState(3);
    const [tabView, setTabView] = useState(0);
  
    const { setAlert } = useContext(alertContext);
    const { makeFilterRequest, addOrUpdateCategories, deleteCategory, reverseCategory } = useContext(
      loanContext
    );
  
    const initialState = {
      categoryName: "",
      startDate: "",
      endDate: "",
      pageSize: 10,
      pageNumber: 1,
    };

    const initialProviderState = {
      providerName: "",
      startDate: "",
      endDate: "",
      pageSize: 10,
      pageNumber: 1,
    };
  
    const [payload, setPayload] = useState({ ...initialState });
    const { pageSize, pageNumber, startDate, endDate, categoryName } = payload;

    const [providerPayload, setProviderPayload] = useState({ ...initialProviderState });
    const { pageSize : pageSize2, pageNumber : pageNumber2, startDate : startDate2, endDate : endDate2, providerName } = providerPayload;
  
    const _isMounted = useRef(true);
  
    useEffect(() => {
      if (_isMounted.current === false) {
        _isMounted.current = true;
      }
  
      getCategories();
      return () => (_isMounted.current = false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, pageNumber]);

    useEffect(() => {
      getDelCategories();
    }, [pageNumber2, pageSize2]);

    ////////////////////////**********TAB ACTIONS**********///////////////////
    const handleTabChange = (event, value) => {
      setTabView(value)
    }

    const TabPanel = (props) => {
      const { children, value, index, ...other } = props;
    
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
    }

    function allyProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }
  
    ////////////////////////**********MODAL ACTIONS**********///////////////////
    const triggerModalOpen = (index) => {
      setDialogContentIndex(index);
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      //To fix an issue of having data being set to null first before closing modal
      if (open === false) {
        category !== null && setCategory(null);
        delCategory !== null && setDelCategory(null);
      }
    };
    ////////////////////////**********MODAL ACTIONS**********///////////////////
  
    ///////////////////////***********document CREATE ACTIONS***********//////////////
  
    const [anchorEl, setAnchorEl] = useState(null);
    const anchorOpen = Boolean(anchorEl);
  
    const handleMenuOpen = (index, category) => {
      console.log("The selected category is: ", category);
      setCategory(category);
      index === 0 ? deleteUserCategory(category) : triggerModalOpen(index);
    };

    const handleDelCategoryMenuOpen = (index, categoryData) => {
      setDelCategory(categoryData);

      triggerModalOpen(index);
    };
  
    // const handleMenuClose = () => {
    //   setAnchorEl(null);
    //   // if(dialogContentIndex !==3){
    //   //   document !== null && setdocument(null);
    //   // }
    // };
  
    const createDocuments = async (values) => {
      let action = category !== null ? "Updated" : "Created";
      try {
        setIssubmitting(true);
        const res = await addOrUpdateCategories(values);
        if (res.data.requestSuccessful) {
          setAlert(`Category ${action} successfully`, "success");
          getCategories();
          handleClose();
        } else {
          setAlert(res.data.message, "error");
        }
        setIssubmitting(false);
      } catch (err) {
        console.log(err);
        setAlert(err.response?.data?.message || `Category ${action === "Updated" ? "Update" : "Create"} error. Please try again`, "error");
        setIssubmitting(false);
      }
    };
  
    const deleteUserCategory = async (category) => {
      let confirmation = window.confirm("Are you sure you want to delete this document?");
      if(confirmation) {
        try {
          setIssubmitting(true);
          const res = await deleteCategory(category.id);
          if (res.data.requestSuccessful) {
            let action = "deleted";
            setAlert(`Category ${action} successfully`, "success");
            getCategories();
            getDelCategories();
            handleClose();
          } else {
            setAlert(res.data.message, "error");
          }
          setIssubmitting(false);
        } catch (err) {
          console.log(err);
          setAlert(err.response?.data?.message || `Category delete error. Please try again`, "error");
          setIssubmitting(false);
        }
      }
    }

    const reverseDelCategory = async () => {
      let confirmation = window.confirm("Are you sure you want to reverse this deleted category?");
      if(confirmation) {
        try {
          setIssubmitting(true);
          const res = await reverseCategory(delCategory.id);
          if (res.data.requestSuccessful) {
            let action = "reversed";
            setAlert(`Category ${action} successfully`, "success");
            getCategories();
            getDelCategories();
            handleClose();
          } else {
            setAlert(res.data.message, "error");
          }
          setIssubmitting(false);
        } catch (err) {
          console.log(err);
          setAlert(err.response?.data?.message || `Category reverse error. Please try again`, "error");
          setIssubmitting(false);
        }
      }
    }
  
    const initialValues = {
      name : category ? category.name : "",
    };
  
    const validationSchema = Yup.object({
      name: nameValidation(
        "Category Name",
        "categoryName",
        true
      ),
    });
  
    const onSubmit = async (values) => {
      const {
        name,
      } = values;

      let payload = {
        name,
      };
  
      //If its an update action
      if (category !== null) {
        payload = { ...values, id: category.id };
      }
  
      createDocuments(payload);
    };
  
    /////////////////**********CUSTOMERS CREATE *************////////////////
  
    /////////////////**********TABLE ACTIONS *************////////////////
    const handleInputChange = (e) => {
      if(tabView === 0) {
        const { name, value } = e.target;
        //always reset pageNumber if pageSize or name changes to avoid wrong data fetch
        if (name === "pageSize" || name === "categoryName") {
          payload.pageNumber = 1;
        }
        setPayload({ ...payload, [name]: value });
      } else if(tabView === 1) {
        const { name, value } = e.target;
        //always reset pageNumber if pageSize or name changes to avoid wrong data fetch
        if (name === "pageSize" || name === "providerName") {
          console.log("The input name is: ", name, value);
          providerPayload.pageNumber = 1;
        }
        setProviderPayload({ ...providerPayload, [name]: value });
      }
    };
  
    const handlePageClick = (page, isProvider) => {
      isProvider ? setProviderPayload({...providerPayload, pageNumber : page})
      : setPayload({ ...payload, pageNumber: page });
    };
  
    const handleCategoryNameSearch = (e) => {
      setPayload({ ...payload, categoryName: e.target.value });
      loadWithDebounce(e.target.value, true);
    };
    
    const handleDelCategoryNameSearch = (e) => {
      setProviderPayload({ ...providerPayload, providerName: e.target.value });
      loadWithDebounce(e.target.value, false);
    };
  
    let url = `category?pageSize=${pageSize}&page=${pageNumber}`;
    let providerUrl = `category/soft_deleted_categories?pageSize=${pageSize2}&page=${pageNumber2}`;

    let filter = false;
  
    const clearFilters = async () => {
      //make the request without filters, taking into consideration the state of the categoryName/providerName field
      if(tabView === 0) {
        setPayload({ ...initialState, categoryName });
        filter = true;
        if (categoryName !== "") {
          url += `&name=${categoryName}`;
        }
        await getCategories(url, filter);
      } else if(tabView === 1) {
        setProviderPayload({ ...initialProviderState, providerName });
        filter = true;
        if (providerName !== "") {
          providerUrl += `&name=${providerName}`;
        }
        await getDelCategories(providerUrl, filter);
      }
    };
  
    const getdataFromFirstPage = () => {
      if(tabView === 0) {
        if (pageNumber !== 1) {
          setPayload({ ...payload, pageNumber: 1 });
        }
        pageNumber === 1 && getCategories();
      } else if(tabView === 1) {
        if (pageNumber2 !== 1) {
          setProviderPayload({ ...providerPayload, pageNumber: 1 });
        }
        pageNumber2 === 1 && getDelCategories();
      }
    };
  
    const getCategories = async (
      url = `category?pageSize=${pageSize}&page=${pageNumber}`,
      clearFilter
    ) => {
      if (!clearFilter) {
        if (categoryName !== "") {
          url += `&name=${categoryName}`;
        }
  
        if (!validateDates(startDate, endDate)) {
          setAlert("Please enter valid dates", "error");
          return;
        } else if (startDate !== "" && endDate !== "") {
          url += `&startDate=${startDate}&endDate=${endDate}`;
        }
      }
  
      setLoading(true);
      try {
        const res = await makeFilterRequest(url);
        if (_isMounted.current) {
          console.log("Category Response data is: ", res.data.responseData);
          setCategories(res.data.responseData);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
      open && handleClose();
      filter = false;
    };

    const getDelCategories = async (
      url = `category/soft_deleted_categories?pageSize=${pageSize2}&page=${pageNumber2}`,
      clearFilter
    ) => {
      if (!clearFilter) {
        if (providerName) {
          url += `&name=${providerName}`;
        }
  
        if (!validateDates(startDate2, endDate2)) {
          setAlert("Please enter valid dates", "error");
          return;
        } else if (startDate2 !== "" && endDate2 !== "") {
          url += `&startDate=${startDate2}&endDate=${endDate2}`;
        }
      }
  
      setLoading(true);
      try {
        const res = await makeFilterRequest(url);
        if (_isMounted.current) {
          console.log("Response data is: ", res.data.responseData);
          setDelCategories(res.data.responseData);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
      open && handleClose();
      filter = false;
    };
  
    const loadWithDebounce = useCallback(
      debounce((categoryName, isDocument) => {
        if (categoryName === "" && isDocument) {
          getCategories(url);
        } else if (categoryName === "" && !isDocument) {
          getDelCategories(providerUrl);
        } else {
          isDocument ? getCategories(`${url}&Name=${categoryName}`) : getDelCategories(`${providerUrl}&Name=${categoryName}`);
        }
      }, 1000),
      []
    );

    const columns = [
      {
        Header: "Category Name",
        accessor: "name",
      },
      {
        Header: "Date Created",
        accessor: "dateCreated",
        Cell: (props) => <span>{new Date(props.value).toDateString()}</span>,
      },
      {
        Header: " ",
        Cell: (props) => (
          <span 
            onClick={(e) => handleMenuOpen(2, props.row.original)}
            style={{cursor : "pointer", color : "#117AF3"}}
          >
            View
          </span>
        )
      },
      {
        Header: "  ",
        Cell: (props) => (
          <span 
            onClick={(e) => handleMenuOpen(3, props.row.original)}
            style={{cursor : "pointer", color : "#117AF3"}}
          >
            Edit
          </span>
        )
      },
      {
        Header: "   ",
        Cell: (props) => (
          <span 
            onClick={(e) => handleMenuOpen(0, props.row.original)}
            style={{cursor : "pointer", color : "red"}}
          >
            Delete
          </span>
        )
      },
    ];

    const softDeletedColumns = [
      {
        Header: "Category Name",
        accessor: "name",
      },
      {
        Header: "Date Created",
        accessor: "dateCreated",
        Cell: (props) => <span>{new Date(props.value).toDateString()}</span>,
      },
      {
        Header: " ",
        Cell: (props) => (
          <span 
            onClick={(e) => handleDelCategoryMenuOpen(4, props.row.original)}
            style={{cursor : "pointer", color : "#117AF3"}}
          >
            View
          </span>
        )
      },
      {
        Header: "  ",
        Cell: (props) => (
          <span 
            onClick={(e) => handleDelCategoryMenuOpen(5, props.row.original)}
            style={{cursor : "pointer", color : "#117AF3"}}
          >
            Edit
          </span>
        )
      },
    ];
  
    /////////////////**********TABLE ACTIONS *****************////////////////
  
    /////////////////**********MODAL VIEWS *****************////////////////
    const filterForm = () => (
      <>
        <Grid container spacing={2} style={{ marginTop: "1rem" }}>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <label htmlFor="startDate" className={classes.label}>
                Start Date
              </label>
              <TextField
                name="startDate"
                variant="outlined"
                size="small"
                type="date"
                value={tabView === 0 ? startDate : startDate2}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <label htmlFor="endDate" className={classes.label}>
                End Date
              </label>
              <TextField
                name="endDate"
                variant="outlined"
                size="small"
                type="date"
                value={tabView === 0 ? endDate : endDate2}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
        </Grid>
  
        <Box className={classes.filterActions}>
          <Typography
            style={{ color: "red", cursor: "pointer" }}
            onClick={clearFilters}
          >
            Clear all filters
          </Typography>
          <Button
            variant="contained"
            size="medium"
            disabled={loading || 
              (tabView === 0 ? 
              (startDate === "" && endDate === "") 
              : (startDate2 === "" && endDate2 === ""))
            }
            loading={loading}
            // type="submit"
            color="primary"
            onClick={() => getdataFromFirstPage()}
          >
            Apply
          </Button>
        </Box>
      </>
    );
  
    const viewCategory = () => {
      if (category !== null) {
        const {
          name,
          dateCreated
        } = category;
  
        return (
          <Box className={classes.documentView}>
            <Box className={classes.documentItem}>
              <small variant="body1">Category Name:</small>
              <Typography>{name}</Typography>
            </Box>
            
            <Box className={classes.documentItem}>
              <small>Date Created:</small>
              <Typography>{new Date(dateCreated).toDateString()}</Typography>
            </Box>
          </Box>
        );
      }
    };

    const viewDeletedCategory = () => {
      if (delCategory !== null) {
        const {
          name,
          dateCreated,
        } = delCategory;
  
        return (
          <Box className={classes.documentView}>
            <Box className={classes.documentItem}>
              <small variant="body1">Category Name:</small>
              <Typography>{name}</Typography>
            </Box>
            
            <Box className={classes.documentItem}>
              <small>Date Created:</small>
              <Typography>{new Date(dateCreated).toDateString()}</Typography>
            </Box>
          </Box>
        );
      }
    };
  
    const createCategoryForm = () => (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, getFieldProps, values, setFieldValue }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={2} style={{ marginTop: "1rem", minWidth : "200px" }}>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <label htmlFor="categoryName" className={classes.label}>
                    Category Name
                  </label>
                  <TextField
                    name="categoryName"
                    placeholder="Enter Category Name"
                    id="categoryName"
                    variant="outlined"
                    {...getFieldProps("name")}
                    error={
                      errors.categoryName && touched.categoryName
                        ? true
                        : false
                    }
                    size="small"
                  />
                  <ErrorMessage name="categoryName" component={FormErrors} />
                </FormControl>
              </Grid>
            </Grid>
  
            <Box className={classes.createActions}>
              <Typography
                variant="body2"
                color="primary"
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              >
                Cancel
              </Typography>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
                padding={"0.8rem 1.5rem"}
              >
                {category !== null ? "Update" : "Add"}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    );

    const editDelCategoryForm = () => {
      if (delCategory !== null) {
        const {
          name,
          dateCreated,
        } = delCategory;
  
        return (
          <Box className={classes.documentView} style={{paddingBottom : "0px"}}>
            <Box className={classes.documentItem}>
              <small variant="body1">Category Name:</small>
              <Typography>{name}</Typography>
            </Box>
            
            <Box className={classes.documentItem}>
              <small>Date Created:</small>
              <Typography>{new Date(dateCreated).toDateString()}</Typography>
            </Box>
            
            <div style={{display : "flex", marginTop : "20px", justifyContent : "end", width : "100%"}}>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={reverseDelCategory}
              >
                Reverse Delete
              </Button>
            </div>
          </Box>
        );
      }
    };
  
    /////////////////**********MODAL VIEWS *****************////////////////
  
    const getDialogContent = () => {
      switch (dialogContentIndex) {
        case 1:
          return filterForm();
  
        case 2:
          return viewCategory();
  
        case 3:
          return createCategoryForm();

        case 4:
          return viewDeletedCategory();
        
        case 5:
          return editDelCategoryForm();

        default:
          return "Nothing";
      }
    };
  
    const dialogContent = () => (
      <Box style={{ width: "100%", minWidth : "300px" }} className={classes.paper}>
        <Box className={classes.dialogTitle}>
          <Typography variant="h4">
            {dialogContentIndex === 1
              ? "Filter"
              : dialogContentIndex === 2
              ? "Category Details"
              : dialogContentIndex === 4
              ? "Deleted Category Details"
              : dialogContentIndex === 5
              ? "Reverse Deleted Category"
              : category !== null
              ? "Edit Category"
              : "Add Category"
            }
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
  
        <Box className={classes.dialogContent}>
          <hr />
          <Box marginTop={"1rem"}>{getDialogContent()}</Box>
        </Box>
      </Box>
    );
  
    return (
      <Box>
        <Box className={classes.headerTexts}>
          <Typography variant="h4">Categories</Typography>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => {
              setCategory(null);
              triggerModalOpen(3);
            }}
          >
            Add Category
          </Button>
        </Box>

        <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
          <Tabs value={tabView} onChange={handleTabChange} 
            textColor="primary"
            indicatorColor="primary" 
            aria-label="loan documents tab"
          >
            <Tab label="Categories" {...allyProps(0)} />
            <Tab label="Soft Deleted Categories" {...allyProps(1)}/>
          </Tabs>
        </Box>

        <TabPanel value={tabView} index={0}>
          <Box style={{ border: "1px solid #D7DCE0" }} marginTop={"1rem"}>
            <Paper borderRadius={"0"} padding={"2rem"}>
              <TableHead
                title="Total Categories"
                searchItem={categoryName}
                onChange={handleCategoryNameSearch}
                loading={loading}
                placeholder="Search By category Name"
                totalSize={categories.totalSize}
                onClick={() => triggerModalOpen(1)}
                handleInputChange={handleInputChange}
                shouldFocus={true}
              />

              <Box marginTop={"2rem"}>
                {loading && !categories.items ? (
                  <p>Loading...</p>
                ) : (!loading && categories.items) ||
                  (loading && categories.items) ? (
                  <>
                    {/* {console.log(users.items)} */}
                    <Table
                      columns={columns}
                      data={categories.items}
                      loading={loading}
                    />
                    <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                      <Grid item xs={12} md={3}>
                        {categories.items.length > 0 && (
                          <Typography>
                            Showing page {categories.pageNumber} of{" "}
                            {Math.ceil(categories?.totalSize / pageSize)}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <Pagination
                          dataSize={categories?.totalSize}
                          perPage={pageSize}
                          handlePageClick={handlePageClick}
                          forcePage={categories.pageNumber}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <p>Loading...</p>
                )}
              </Box>
            </Paper>
          </Box>
        </TabPanel>

        <TabPanel value={tabView} index={1}>
          <Box style={{ border: "1px solid #D7DCE0" }} marginTop={"1rem"}>
            <Paper borderRadius={"0"} padding={"2rem"}>
              <TableHead
                title="Total Providers"
                searchItem={providerName}
                onChange={handleDelCategoryNameSearch}
                loading={loading}
                placeholder="Search By Category Name"
                totalSize={delCategories.totalSize}
                onClick={() => triggerModalOpen(1)}
                handleInputChange={handleInputChange}
                shouldFocus={true}
              />

              <Box marginTop={"2rem"}>
                {loading && !delCategories?.items ? (
                  <p>Loading...</p>
                ) : (!loading && delCategories?.items) ||
                  (loading && delCategories?.items.length) ? (
                  <>
                    {/* {console.log(users.items)} */}
                    <Table
                      columns={softDeletedColumns}
                      data={delCategories.items}
                      loading={loading}
                    />
                    <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                      <Grid item xs={12} md={3}>
                        {delCategories.items.length > 0 && (
                          <Typography>
                            Showing page {delCategories.pageNumber || 1} of{" "}
                            {Math.ceil(delCategories?.totalSize / pageSize) || 1}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <Pagination
                          dataSize={delCategories?.totalSize}
                          perPage={pageSize2}
                          handlePageClick={(page) => handlePageClick(page, true)}
                          forcePage={delCategories.pageNumber}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <p>Loading...</p>
                )}
              </Box>
            </Paper>
          </Box>
        </TabPanel>

        {/* <>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={anchorOpen}
            onClick={handleMenuClose}
            PaperProps={{
              style: {
                width: "10ch",
              },
            }}
          >
            <MenuItem onClick={() => triggerModalOpen(2)}>View</MenuItem>
            <MenuItem onClick={() => triggerModalOpen(3)}>Edit</MenuItem>
            <MenuItem onClick={deleteUserCategory}>Delete</MenuItem>
          </Menu>
        </> */}
        <Dialog handleClose={handleClose} open={open} content={dialogContent()} />
      </Box>
    );
  };
  
  export default CategoriesLayout;
  
  