import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useRef,
  } from "react";
  import {
    Box,
    Typography,
    Grid,
    TextField,
    Select,
    MenuItem,
    IconButton,
    FormControl,
    Tabs,
    Tab,
    FormGroup,
    FormControlLabel,
    Checkbox,
  } from "@material-ui/core";
  import CloseIcon from "@material-ui/icons/Close";
  import Menu from "@material-ui/core/Menu";
  import MoreVertIcon from "@material-ui/icons/MoreVert";
  
  import debounce from "lodash.debounce";
  import * as Yup from "yup";
  import { Formik, Form, ErrorMessage, Field } from "formik";
  
  import Paper from "../../../Paper";
  import Dialog from "../../../Reusables/Dialog";
  import Button from "../../../Button";
  import Table from "../../../Reusables/Table";
  import Pagination from "../../../Reusables/Pagination";
  import StatusIndicator from "../../../Reusables/StatusIndicator";
  import TableHead from "../../../Reusables/TableHead";
  
  import alertContext from "../../../../Contexts/alert/alertContext";
  import loanContext from "../../../../Contexts/loan/loanContext";
  import credpalContext from "../../../../Contexts/credpal/credpalContext";
  
  import FormErrors from "../../../FormErrors";
  import {
    nameValidation,
  } from "../../../../Actions/formActions";
  
  import { useStyles } from "./style";
  import { formatDateTime, validateDates } from "../../../../Actions/helpers";
import AxiosRequest from "../../../../Services/AxiosRequests";
  
  const DocumentsLayout = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
  
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIssubmitting] = useState(false);
    const [document, setDocument] = useState(null);
    const [documents, setDocuments] = useState({});

    const [allDocuments, setAllDocuments] = useState([]);

    const [provider, setProvider] = useState(null);
    const [providers, setProviders] = useState({});

    const [currentRequirements, setCurrentRequirements] = useState([])

    const [dialogContentIndex, setDialogContentIndex] = useState(3);
    const [tabView, setTabView] = useState(0);
  
    const { setAlert } = useContext(alertContext);
    const { makeFilterRequest, addOrUpdateDocuments, deleteDocumentDetails } = useContext(
      loanContext
    );
    const { getBanks, banks } = useContext(credpalContext);
  
    const initialState = {
      documentName: "",
      startDate: "",
      endDate: "",
      pageSize: 10,
      pageNumber: 1,
    };

    const initialProviderState = {
      providerName: "",
      startDate: "",
      endDate: "",
      pageSize: 10,
      pageNumber: 1,
    };
  
    const [payload, setPayload] = useState({ ...initialState });
    const { pageSize, pageNumber, startDate, endDate, documentName } = payload;

    const [providerPayload, setProviderPayload] = useState({ ...initialProviderState });
    const { pageSize : pageSize2, pageNumber : pageNumber2, startDate : startDate2, endDate : endDate2, providerName } = providerPayload;
  
    const _isMounted = useRef(true);
  
    useEffect(() => {
      if (_isMounted.current === false) {
        _isMounted.current = true;
      }
  
      getDocuments();
      return () => (_isMounted.current = false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, pageNumber]);

    useEffect(() => {
      getProviders();
    }, [pageNumber2, pageSize2]);
  
    useEffect(() => {
      getBanks();
      getAllDocuments();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    ////////////////////////**********TAB ACTIONS**********///////////////////
    const handleTabChange = (event, value) => {
      setTabView(value)
    }

    const TabPanel = (props) => {
      const { children, value, index, ...other } = props;
    
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
    }

    function allyProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }
  
    ////////////////////////**********MODAL ACTIONS**********///////////////////
    const triggerModalOpen = (index) => {
      setDialogContentIndex(index);
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      //To fix an issue of having data being set to null first before closing modal
      if (open === false) {
        document !== null && setDocument(null);
        provider !== null && setProvider(null);
      }
    };
    ////////////////////////**********MODAL ACTIONS**********///////////////////
  
    ///////////////////////***********document CREATE ACTIONS***********//////////////
  
    const [anchorEl, setAnchorEl] = useState(null);
    const anchorOpen = Boolean(anchorEl);
  
    const handleMenuOpen = (index, document) => {
      console.log("The selected document is: ", document);
      setDocument(document);
      index === 0 ? deleteDocument() : triggerModalOpen(index);
    };

    const handleProviderMenuOpen = (index, providerData) => {
      setProvider(providerData);
      
      if(index === 5) {
        setCurrentRequirements(providerData.uploadType.map((data) => {
          return {id : data.id};
        }));
      }
      triggerModalOpen(index);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
      // if(dialogContentIndex !==3){
      //   document !== null && setdocument(null);
      // }
    };
  
    const createDocuments = async (values) => {
      let action = document !== null ? "Updated" : "Created";
      try {
        setIssubmitting(true);
        const res = await addOrUpdateDocuments(values);
        if (res.data.requestSuccessful) {
          setAlert(`Document ${action} successfully`, "success");
          getDocuments();
          handleClose();
        } else {
          setAlert(res.data.message, "error");
        }
        setIssubmitting(false);
      } catch (err) {
        console.log(err);
        setAlert(err.response?.data?.message || `Document ${action === "Updated" ? "Update" : "Create"} error. Please try again`, "error");
        setIssubmitting(false);
      }
    };
  
    const deleteDocument = async () => {
      let confirmation = window.confirm("Are you sure you want to delete this document?");
      if(confirmation) {
        try {
          setIssubmitting(true);
          const res = await deleteDocumentDetails(document.id);
          if (res.data.requestSuccessful) {
            let action = "deleted";
            setAlert(`Document ${action} successfully`, "success");
            getDocuments();
            handleClose();
          } else {
            setAlert(res.data.message, "error");
          }
          setIssubmitting(false);
        } catch (err) {
          console.log(err);
          setAlert(err.response?.data?.message || `Document delete error. Please try again`, "error");
          setIssubmitting(false);
        }
      }
    }
  
    const initialValues = {
      name : document ? document.name : "",
    };
  
    const validationSchema = Yup.object({
      name: nameValidation(
        "Document Name",
        "documentName",
        true
      ),
    });
  
    const onSubmit = async (values) => {
      const {
        name,
      } = values;

      let payload = {
        name,
      };
  
      //If its an update action
      if (document !== null) {
        payload = { ...values, id: document.id };
      }
  
      createDocuments(payload);
    };

    const handleSubmit = async () => {
      setIssubmitting(true);

      const currentProvider = provider;
      delete currentProvider.uploadType
      const requestData = {...currentProvider, uploadTypes : currentRequirements}
      console.log("The requestData is: ", requestData);

      try {
        const res = await AxiosRequest("put", `provider/${requestData.id}`, requestData);

        if(res.data.requestSuccessful) {
          console.log("The provider requirements is updated: ", res);
          setAlert("Provider requirements updated successfully.", "success")
          open && handleClose()
          getProviders();
        } else {
          setAlert(res.data.message || "Provider requirements update error.", "error")
          console.log("The provider update error is: ", res.data);
        }
        setIssubmitting(false);
      } catch (error) {
        console.log("Updating provider failed", error);
        setAlert(error.response?.data?.message || "Provider requirements update error.", "error")
        setIssubmitting(false);
      }
    }
  
    /////////////////**********CUSTOMERS CREATE *************////////////////
  
    /////////////////**********TABLE ACTIONS *************////////////////
    const handleInputChange = (e) => {
      if(tabView === 0) {
        const { name, value } = e.target;
        //always reset pageNumber if pageSize or name changes to avoid wrong data fetch
        if (name === "pageSize" || name === "documentName") {
          payload.pageNumber = 1;
        }
        setPayload({ ...payload, [name]: value });
      } else if(tabView === 1) {
        const { name, value } = e.target;
        //always reset pageNumber if pageSize or name changes to avoid wrong data fetch
        if (name === "pageSize" || name === "providerName") {
          console.log("The input name is: ", name, value);
          providerPayload.pageNumber = 1;
        }
        setProviderPayload({ ...providerPayload, [name]: value });
      }
    };
  
    const handlePageClick = (page, isProvider) => {
      isProvider ? setProviderPayload({...providerPayload, pageNumber : page})
      : setPayload({ ...payload, pageNumber: page });
    };
  
    const handleDocumentNameSearch = (e) => {
      setPayload({ ...payload, documentName: e.target.value });
      loadWithDebounce(e.target.value, true);
    };
    
    const handleProviderNameSearch = (e) => {
      setProviderPayload({ ...providerPayload, providerName: e.target.value });
      loadWithDebounce(e.target.value, false);
    };
  
    let url = `documentType?pageSize=${pageSize}&page=${pageNumber}`;
    let providerUrl = `provider?pageSize=${pageSize2}&page=${pageNumber2}`;

    let filter = false;
  
    const clearFilters = async () => {
      //make the request without filters, taking into consideration the state of the documentName/providerName field
      if(tabView === 0) {
        setPayload({ ...initialState, documentName });
        filter = true;
        if (documentName !== "") {
          url += `&name=${documentName}`;
        }
        await getDocuments(url, filter);
      } else if(tabView === 1) {
        setProviderPayload({ ...initialProviderState, providerName });
        filter = true;
        if (providerName !== "") {
          providerUrl += `&name=${providerName}`;
        }
        await getProviders(providerUrl, filter);
      }
    };
  
    const getdataFromFirstPage = () => {
      if(tabView === 0) {
        if (pageNumber !== 1) {
          setPayload({ ...payload, pageNumber: 1 });
        }
        pageNumber === 1 && getDocuments();
      } else if(tabView === 1) {
        if (pageNumber2 !== 1) {
          setProviderPayload({ ...providerPayload, pageNumber: 1 });
        }
        pageNumber2 === 1 && getProviders();
      }
    };

    const getAllDocuments = async() => {
      try {
        const res = await makeFilterRequest('documentType');
        console.log("Document Response data is: ", res.data.responseData);
        setAllDocuments(res.data.responseData.items);
      } catch (err) {
        console.log(err);
      }
    }
  
    const getDocuments = async (
      url = `documentType?pageSize=${pageSize}&page=${pageNumber}`,
      clearFilter
    ) => {
      if (!clearFilter) {
        if (documentName !== "") {
          url += `&name=${documentName}`;
        }
  
        if (!validateDates(startDate, endDate)) {
          setAlert("Please enter valid dates", "error");
          return;
        } else if (startDate !== "" && endDate !== "") {
          url += `&startDate=${startDate}&endDate=${endDate}`;
        }
      }
  
      setLoading(true);
      try {
        const res = await makeFilterRequest(url);
        if (_isMounted.current) {
          console.log("Document Response data is: ", res.data.responseData);
          setDocuments(res.data.responseData);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
      open && handleClose();
      filter = false;
    };

    const getProviders = async (
      url = `provider?pageSize=${pageSize2}&page=${pageNumber2}`,
      clearFilter
    ) => {
      if (!clearFilter) {
        if (providerName) {
          url += `&name=${providerName}`;
        }
  
        if (!validateDates(startDate2, endDate2)) {
          setAlert("Please enter valid dates", "error");
          return;
        } else if (startDate2 !== "" && endDate2 !== "") {
          url += `&startDate=${startDate2}&endDate=${endDate2}`;
        }
      }
  
      setLoading(true);
      try {
        const res = await makeFilterRequest(url);
        if (_isMounted.current) {
          console.log("Response data is: ", res.data.responseData);
          setProviders(res.data.responseData);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
      open && handleClose();
      filter = false;
    };
  
    const loadWithDebounce = useCallback(
      debounce((documentName, isDocument) => {
        if (documentName === "" && isDocument) {
          getDocuments(url);
        } else if (documentName === "" && !isDocument) {
          getProviders(providerUrl);
        } else {
          isDocument ? getDocuments(`${url}&Name=${documentName}`) : getProviders(`${providerUrl}&Name=${documentName}`);
        }
      }, 1000),
      []
    );

    const handleCheckboxChange = (document) => {
      let newDocs = currentRequirements;
      const isListed = currentRequirements.findIndex((data => data.id === document.id));

      if(isListed === -1) {
        newDocs.push({id : document.id})
      } else {
        newDocs.splice(isListed, 1);
      }
      // use spread operator to make the checkbox re-evaluate the check status.
      setCurrentRequirements([...newDocs]);
    }

    const columns = [
      {
        Header: "Document Name",
        accessor: "name",
      },
      {
        Header: "Date Created",
        accessor: "dateCreated",
        Cell: (props) => <span>{new Date(props.value).toDateString()}</span>,
      },
      {
        Header: " ",
        Cell: (props) => (
          <span 
            onClick={(e) => handleMenuOpen(2, props.row.original)}
            style={{cursor : "pointer", color : "#117AF3"}}
          >
            View Document
          </span>
        )
      },
      {
        Header: "  ",
        Cell: (props) => (
          <span 
            onClick={(e) => handleMenuOpen(3, props.row.original)}
            style={{cursor : "pointer", color : "#117AF3"}}
          >
            Edit Document
          </span>
        )
      },
      {
        Header: "   ",
        Cell: (props) => (
          <span 
            onClick={(e) => handleMenuOpen(0, props.row.original)}
            style={{cursor : "pointer", color : "red"}}
          >
            Delete Document
          </span>
        )
      },
    ];

    const providerColumns = [
      {
        Header: "Provider Name",
        accessor: "bankName",
      },
      {
        Header: "Date Created",
        accessor: "dateCreated",
        Cell: (props) => <span>{new Date(props.value).toDateString()}</span>,
      },
      {
        Header: " ",
        Cell: (props) => (
          <span 
            onClick={(e) => handleProviderMenuOpen(4, props.row.original)}
            style={{cursor : "pointer", color : "#117AF3"}}
          >
            View Provider Documents
          </span>
        )
      },
      {
        Header: "  ",
        Cell: (props) => (
          <span 
            onClick={(e) => handleProviderMenuOpen(5, props.row.original)}
            style={{cursor : "pointer", color : "#117AF3"}}
          >
            Edit Provider Document
          </span>
        )
      },
    ];
  
    /////////////////**********TABLE ACTIONS *****************////////////////
  
    /////////////////**********MODAL VIEWS *****************////////////////
    const filterForm = () => (
      <>
        <Grid container spacing={2} style={{ marginTop: "1rem" }}>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <label htmlFor="startDate" className={classes.label}>
                Start Date
              </label>
              <TextField
                name="startDate"
                variant="outlined"
                size="small"
                type="date"
                value={tabView === 0 ? startDate : startDate2}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <label htmlFor="endDate" className={classes.label}>
                End Date
              </label>
              <TextField
                name="endDate"
                variant="outlined"
                size="small"
                type="date"
                value={tabView === 0 ? endDate : endDate2}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
        </Grid>
  
        <Box className={classes.filterActions}>
          <Typography
            style={{ color: "red", cursor: "pointer" }}
            onClick={clearFilters}
          >
            Clear all filters
          </Typography>
          <Button
            variant="contained"
            size="medium"
            disabled={loading || 
              (tabView === 0 ? 
              (startDate === "" && endDate === "") 
              : (startDate2 === "" && endDate2 === ""))
            }
            loading={loading}
            // type="submit"
            color="primary"
            onClick={() => getdataFromFirstPage()}
          >
            Apply
          </Button>
        </Box>
      </>
    );
  
    const viewdocument = () => {
      console.log("The document viewed is: ", document);
      if (document !== null) {
        const {
          name,
          dateCreated
        } = document;
  
        return (
          <Box className={classes.documentView}>
            <Box className={classes.documentItem}>
              <small variant="body1">Document Name:</small>
              <Typography>{name}</Typography>
            </Box>
            
            <Box className={classes.documentItem}>
              <small>Date Created:</small>
              <Typography>{new Date(dateCreated).toDateString()}</Typography>
            </Box>
          </Box>
        );
      }
    };

    const viewProviderDocument = () => {
      console.log("The provider document viewed is: ", provider);
      if (provider !== null) {
        const {
          bankName,
          uploadType,
        } = provider;
  
        return (
          <Box className={classes.documentView}>
            <Box style={{width : "100%", display : "flex", justifyContent : "center"}}>
              <Typography variant="h4">{bankName}</Typography>
            </Box>
            {
              (uploadType && uploadType.length) ? 
              uploadType.map((data, index) => (
                <Box key={index} className={classes.documentItem}>
                  <small variant="body1">Document Name:</small>
                  <Typography>{data.name}</Typography>
                </Box>
              ))  
              : <Typography>This provider has no upload requirement.</Typography>
            }
          </Box>
        );
      }
    };
  
    const createdocumentForm = () => (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, getFieldProps, values, setFieldValue }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={2} style={{ marginTop: "1rem", minWidth : "200px" }}>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <label htmlFor="documentName" className={classes.label}>
                    Document Name
                  </label>
                  <TextField
                    name="documentName"
                    placeholder="Enter document name"
                    id="documentName"
                    variant="outlined"
                    {...getFieldProps("name")}
                    error={
                      errors.documentName && touched.documentName
                        ? true
                        : false
                    }
                    size="small"
                  />
                  <ErrorMessage name="documentName" component={FormErrors} />
                </FormControl>
              </Grid>
            </Grid>
  
            <Box className={classes.createActions}>
              <Typography
                variant="body2"
                color="primary"
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              >
                Cancel
              </Typography>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
                padding={"0.8rem 1.5rem"}
              >
                {document !== null ? "Update" : "Add"}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    );

    const editRequirementForm = () => {
      console.log("The provider selected is: ", provider, currentRequirements);
      return (
        <>
            {provider && provider.title}
            <Grid container spacing={2} style={{ marginTop: "1rem", minWidth : "200px" }}>
              {
                allDocuments.map((document, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox 
                            checked={currentRequirements.find((data) => data.id === document.id)}
                            onChange={() => handleCheckboxChange(document)} name={document.name} 
                          />
                        }
                        label={document.name}
                      />
                    </FormGroup>
                  </Grid>
                ))
              }
            </Grid>
  
            <Box className={classes.createActions}>
              <Typography
                variant="body2"
                color="primary"
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              >
                Cancel
              </Typography>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                loading={isSubmitting}
                padding={"0.8rem 1.5rem"}
                onClick={handleSubmit}
              >
                Update
              </Button>
            </Box>
        </>
      )
    };
  
    /////////////////**********MODAL VIEWS *****************////////////////
  
    const getDialogContent = () => {
      switch (dialogContentIndex) {
        case 1:
          return filterForm();
  
        case 2:
          return viewdocument();
  
        case 3:
          return createdocumentForm();

        case 4:
          return viewProviderDocument();
        
        case 5:
          return editRequirementForm();

        default:
          return "Nothing";
      }
    };
  
    const dialogContent = () => (
      <Box style={{ width: "100%", minWidth : "300px" }} className={classes.paper}>
        <Box className={classes.dialogTitle}>
          <Typography variant="h4">
            {dialogContentIndex === 1
              ? "Filter"
              : (dialogContentIndex === 2 || dialogContentIndex === 4)
              ? "Document Details"
              : document !== null
              ? "Edit Document"
              : provider !== null
              ? "Edit Provider Documents"
              : "Add Document"}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
  
        <Box className={classes.dialogContent}>
          <hr />
          <Box marginTop={"1rem"}>{getDialogContent()}</Box>
        </Box>
      </Box>
    );
  
    return (
      <Box>
        <Box className={classes.headerTexts}>
          <Typography variant="h4">Loan Vendor Documents</Typography>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => {
              setDocument(null);
              triggerModalOpen(3);
            }}
          >
            Add Document
          </Button>
        </Box>

        <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
          <Tabs value={tabView} onChange={handleTabChange} 
            textColor="primary"
            indicatorColor="primary" 
            aria-label="loan documents tab"
          >
            <Tab label="Loan Vendor Documents" {...allyProps(0)} />
            <Tab label="Loan Provider Requirements" {...allyProps(1)}/>
          </Tabs>
        </Box>

        <TabPanel value={tabView} index={0}>
          <Box style={{ border: "1px solid #D7DCE0" }} marginTop={"1rem"}>
            <Paper borderRadius={"0"} padding={"2rem"}>
              <TableHead
                title="Total Documents"
                searchItem={documentName}
                onChange={handleDocumentNameSearch}
                loading={loading}
                placeholder="Search By document Name"
                totalSize={documents.totalSize}
                onClick={() => triggerModalOpen(1)}
                handleInputChange={handleInputChange}
                shouldFocus={true}
              />

              <Box marginTop={"2rem"}>
                {loading && !documents.items ? (
                  <p>Loading...</p>
                ) : (!loading && documents.items) ||
                  (loading && documents.items) ? (
                  <>
                    {/* {console.log(users.items)} */}
                    <Table
                      columns={columns}
                      data={documents.items}
                      loading={loading}
                    />
                    <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                      <Grid item xs={12} md={3}>
                        {documents.items.length > 0 && (
                          <Typography>
                            Showing page {documents.pageNumber} of{" "}
                            {Math.ceil(documents?.totalSize / pageSize)}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <Pagination
                          dataSize={documents?.totalSize}
                          perPage={pageSize}
                          handlePageClick={handlePageClick}
                          forcePage={documents.pageNumber}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <p>Loading...</p>
                )}
              </Box>
            </Paper>
          </Box>
        </TabPanel>

        <TabPanel value={tabView} index={1}>
          <Box style={{ border: "1px solid #D7DCE0" }} marginTop={"1rem"}>
            <Paper borderRadius={"0"} padding={"2rem"}>
              <TableHead
                title="Total Providers"
                searchItem={providerName}
                onChange={handleProviderNameSearch}
                loading={loading}
                placeholder="Search By provider Name"
                totalSize={providers.totalSize}
                onClick={() => triggerModalOpen(1)}
                handleInputChange={handleInputChange}
                shouldFocus={true}
              />

              <Box marginTop={"2rem"}>
                {loading && !providers?.items ? (
                  <p>Loading...</p>
                ) : (!loading && providers?.items) ||
                  (loading && providers?.items.length) ? (
                  <>
                    {/* {console.log(users.items)} */}
                    <Table
                      columns={providerColumns}
                      data={providers.items}
                      loading={loading}
                    />
                    <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                      <Grid item xs={12} md={3}>
                        {providers.items.length > 0 && (
                          <Typography>
                            Showing page {providers.pageNumber || 1} of{" "}
                            {Math.ceil(providers?.totalSize / pageSize) || 1}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <Pagination
                          dataSize={providers?.totalSize}
                          perPage={pageSize2}
                          handlePageClick={(page) => handlePageClick(page, true)}
                          forcePage={providers.pageNumber}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <p>Loading...</p>
                )}
              </Box>
            </Paper>
          </Box>
        </TabPanel>

        <>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={anchorOpen}
            onClick={handleMenuClose}
            PaperProps={{
              style: {
                width: "10ch",
              },
            }}
          >
            <MenuItem onClick={() => triggerModalOpen(2)}>View</MenuItem>
            <MenuItem onClick={() => triggerModalOpen(3)}>Edit</MenuItem>
            <MenuItem onClick={deleteDocument}>Delete</MenuItem>
          </Menu>
        </>
        <Dialog handleClose={handleClose} open={open} content={dialogContent()} />
      </Box>
    );
  };
  
  export default DocumentsLayout;
  
  