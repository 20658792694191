import React from 'react'
import PropTypes from 'prop-types'
import Section from '../../Section'
import { Container, Typography } from '@material-ui/core'
import SuccessSVG from '../../../Assets/images/Success.svg'

const Success = props => {
    
    return (
        <Section textAlign="center">
            <Container>
                <img src={SuccessSVG} alt="success"  style={{ height: `8rem` }}/>
                {props.purpose === 'verifyAccount' && (
                    <Typography variant="subtitle2" gutterBottom align="center">
                        Account Verified!
                    </Typography>
                )}
                {props.purpose === 'addAccount' && (
                    <Typography variant="subtitle2" gutterBottom align="center">
                        {props.message}
                    </Typography>
                )}
            </Container>
        </Section>
    )
}

export default Success

Success.propTypes = {
    purpose : PropTypes.string,
    message : PropTypes.string
}