import {
  LOGIN_SUCCESS,
  AUTH_SUCCESS,
  AUTHENTICATING_USER,
  GET_ROLES_SUCCESS,
  SET_LOADING,
  SET_SUBMITTING,
  ACTION_ERROR,
  ACTION_SUCCESS,

} from "../types";

const alertReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {

    case LOGIN_SUCCESS:
      case AUTH_SUCCESS:
      return {
        ...state,
        user: payload,
        isAuthenticated: true,
        authenticating: false
      }

    case AUTHENTICATING_USER:
      return {
        ...state,
        authenticating: true
      }
    
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: payload,
        loading: false
      }


    case ACTION_SUCCESS:
    case ACTION_ERROR:
      return {
        ...state,
        loading: false,
        isSubmitting: false,
      };

    case SET_SUBMITTING: {
      return {
        ...state,
        isSubmitting: true,
      };
    }
    

    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default alertReducer;
