import React, {useEffect, useState, useContext} from 'react'
import { Typography, Grid, CardActionArea, CardContent } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import Card from '../../../Card';
import AddIcon from '@material-ui/icons/AddRounded'
import { useStyles } from './style'
import Modal from '../../../Modal'
import { SelectedEntityContext } from '../../../../App'

import AxiosRequest from "../../../../Services/AxiosRequests"

const BanksLayout = () => {
    const classes = useStyles()

    const [banks, setBanks] = useState()

    const getBanks = async () => {
        try {
            // const res = await api.get('/Bank')
            const res = await AxiosRequest("get", "/Bank")
            const bankRes = res.data.responseData
            // console.log(bankRes);
            if(res.data.requestSuccessful === true) {
                setBanks(bankRes)
            }
        } catch (error) {

        }
    }

    

    // Modal controls
    const [openModal, setOpenModal] = useState(false);
    const [modalView, setModalView] = useState();
    const [ setSelectedEntity ] = useContext(SelectedEntityContext)

    const handleOpen = (entity) => {
        setOpenModal(true);
        setSelectedEntity(entity)
    };

    const handleClose = () => {
        setOpenModal(false);
        setSelectedEntity(null)
    };

    useEffect(() => {
        getBanks()
    }, [openModal])

    return (
        <>
        <div>
        <Grid container spacing={4}>
                <Grid item md={2} sm={6} xs={12}>
                    <Card backgroundColor='primary' height='100%'>
                        <CardActionArea
                            className={classes.card}
                            onClick={() => {
                                setModalView('bank')
                                handleOpen() 
                            }}
                        >
                            <CardContent style={{ textAlign: `center` }}>
                                <AddIcon style={{ fontSize: `5rem` }}/> 
                                <Typography align="center" variant="body2">
                                    Add Bank
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                { !banks ? (
                    <Skeleton variant="text" width={250} height={180} />
                ) : banks.length === 0 ? (
                    <div> </div>
                ) : (
                    <>
                    { banks.map((bank, key) => (
                        <Grid item md={2} sm={6} xs={12} key={key}>
                            <Card height='100%'>
                                <CardActionArea
                                    className={classes.card}
                                    onClick={() => {
                                        setModalView('viewBank')
                                        handleOpen(bank) 
                                    }}
                                >
                                    {/* <CardMedia 
                                        className={classes.image} 
                                        image={AccessLogo} 
                                        title=""
                                        component="img"
                                        alt="Access Bank - Bursery Logo"
                                    /> */}
                                    <CardContent style={{ textAlign: `center` }}>
                                        <Typography align="center" variant="body2">
                                            {bank.bankName}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                    </>
                )}



            </Grid>

        </div>

        {/* Load Custom Modal COmponent */}
        <Modal handleClose={handleClose} openModal={openModal} view={modalView}/>
                     
        </>
    )
}

export default BanksLayout
