import React from 'react'
import { Box, Typography, Grid } from '@material-ui/core'

import LoanChart from "./LoanChart"
import LoanTable from "./LoanTable"
import LoanCounts from "./LoanCounts"
import { useStyles } from "./style"

const DashboardLayout = () => {

    const classes = useStyles()

    return (
       <Box>
           <Typography style={{fontSize:"28px", fontWeight: "500"}}>
               Overview
           </Typography>

            <Box className={classes.contentContainer} marginTop={"1.5rem"}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={3}>
                        <LoanCounts />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <LoanChart />
                    </Grid>
                </Grid>
                <Box className={classes.loanTable} marginTop={"3rem"}>
                    <LoanTable />
                </Box>
            </Box>
       </Box>
    )
}

export default DashboardLayout
