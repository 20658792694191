import React, {useEffect, useState, useContext} from 'react'
import { Typography, Grid, CardActionArea, CardContent } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { Link } from 'react-router-dom'
import Card from '../../../Card';
import AddIcon from '@material-ui/icons/AddRounded'
import { useStyles } from './style'
import Modal from '../../../Modal'
import { SelectedEntityContext } from '../../../../App'
import loanContext from '../../../../Contexts/loan/loanContext'



const ProvidersLayout = () => {
    const classes = useStyles()

    // Modal controls
    const [openModal, setOpenModal] = useState(false);
    const [modalView, setModalView] = useState();
    const [ selectedEntity, setSelectedEntity ] = useContext(SelectedEntityContext)

    const { providers, getProviders, loading } = useContext(loanContext)

    const handleOpen = (entity) => {
        setOpenModal(true);
        setSelectedEntity(entity)
    };

    const handleClose = () => {
        setOpenModal(false);
        setSelectedEntity(null)
    };

    useEffect(() => {
       getProviders()

    //    eslint-disable-next-line
    }, [])
    
    return (
        <>
        <div>
            <Grid container spacing={4}>
                <Grid item md={2} sm={6} xs={12}>
                    <Link to="/provider" style={{textDecoration: "none"}}>
                    <Card backgroundColor='primary' height='100%'>
                        <CardActionArea
                            className={classes.card}
                            // onClick={() => {
                            //     setModalView('provider')
                            //     handleOpen() 
                            // }}
                        >
                            <CardContent style={{ textAlign: `center` }}>
                                <AddIcon style={{ fontSize: `5rem` }}/> 
                                <Typography align="center" variant="body2">
                                    Add Provider
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    </Link>
                    
                </Grid>
                { loading ? (
                    <Skeleton variant="text" width={250} height={180} />
                ) : providers.length === 0 ? (
                    <div> </div>
                ) : (
                    <>
                    { providers.map((provider, key) => (
                        <Grid item md={2} sm={6} xs={12} key={key}>
                            <Card height='100%'>
                                <CardActionArea
                                    className={classes.card}
                                    onClick={() => {
                                        setModalView('viewProvider')
                                        handleOpen(provider) 
                                    }}
                                >
                                    {/* <CardMedia 
                                        className={classes.image} 
                                        image={AccessLogo} 
                                        title=""
                                        component="img"
                                        alt="Access Bank - Bursery Logo"
                                    /> */}
                                    <CardContent style={{ textAlign: `center` }}>
                                        <Typography align="center" variant="body2">
                                            {provider.bankName}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                    </>
                )}



            </Grid>

        </div>

        {/* Load Custom Modal COmponent */}
        <Modal handleClose={handleClose} openModal={openModal} view={modalView}/>
                     
        </>
    )
}

ProvidersLayout.propTypes = {

}

export default ProvidersLayout
