import React, {useContext, useEffect, useState} from 'react'
import { Typography, Box, Button } from '@material-ui/core'
import { SelectedEntityContext } from '../../../../../App'
import Modal from '../../../../Modal'
import { Link } from "react-router-dom"
import { useStyles } from "./style";
import AxiosRequest from '../../../../../Services/AxiosRequests';
import { CSVLink } from 'react-csv';

const ProviderView = props => {
    const [ selectedEntity ] = useContext(SelectedEntityContext)
    console.log('selectedEntity', selectedEntity);

    const classes = useStyles();
    const [exportData, setExportData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Modal controls 
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        ["212", "599"].includes(selectedEntity.sortCode) && fetchData();
    }, [selectedEntity])

    const csvData = exportData.map((data) => {
        const {documents} = data;
        const formattedDocuments = documents.map((doc) => doc.pathURL);
        const csvToUpload = {...data, documents : formattedDocuments};

        return {...data, documents : formattedDocuments};
    })

    const handleOpen = (entity) => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const fetchData = async () => {
        setIsLoading(true);
        console.log("The selected entity is: ", selectedEntity, selectedEntity.id);
        
        try {
            const res = await AxiosRequest(
                'get',
                `/Export/export_based_on_provider?providerId=${selectedEntity.id}`,
            )
    
            if(res.data.requestSuccessful) {
                console.log("The documents to export are: ", res.data);
                
                setExportData(res.data.responseData);
                setIsLoading(false);
            } else {
                console.log("Export data fetching failed", res.data);
                setIsLoading(false);
            }
        } catch (error) {
            console.log("Export data fetching failed", error);
            setIsLoading(false);
        }
    }

    const headers = [
        {
            label: "First Name",
            key: "firstName",
        },
        {
            label: "Last Name",
            key: "lastName",
        },
        {
            label: "Email",
            key: "email",
        },
        {
            label: "Phone Number",
            key : "phoneNumber",
        },
        {
            label : "Gender",
            key : "gender",
        },
        {
            label : "Address",
            key : "address",
        },
        {
            label : "Date Created",
            key : "dateCreated",
        },
        {
            label : "Loan Amount",
            key : "loanAmount",
        },
        {
            label : "Tenure",
            key : "tenure",
        },
        {
            label : "Salary Amount",
            key : "salaryAmount",
        },
        {
            label : "Salary Date",
            key : "salaryDueDate",
        },
        {
            label : "Document Links",
            key : "documents",
        },
    ];

    return (
        <div>
            <Typography variant="h6" align="center" gutterBottom>
                {selectedEntity.bankName}
            </Typography>
            <Box textAlign='left'>
                <Typography variant="body1" gutterBottom>
                    Sort Code: <strong>{ selectedEntity.sortCode }</strong>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Status: <strong>{ selectedEntity.status }</strong>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Url: <strong>{ selectedEntity.baseApiUrl ? selectedEntity.baseApiUrl : 'not set' }</strong>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Integration Type: <strong>{ selectedEntity.isAutoIntegration === true ? 'Automated' : 'Manual'}</strong>
                </Typography>
                <Link to={`/provider/${selectedEntity.id}`} style={{textDecoration: "none"}}>
                <Button color='secondary' variant='contained' onClick={handleOpen}>
                    Edit
                </Button> 
                </Link>
                {  
                    <Button disabled={isLoading} className={classes.export_button} color='primary' variant='contained'>
                        { isLoading ? "Loading..." : (["212", "599"].includes(selectedEntity.sortCode) && Array.isArray(csvData) && csvData.length > 0) ?
                            <CSVLink
                                filename={`${selectedEntity.bankName}loans.csv`}
                                data={csvData}
                                headers={headers}
                                style={{ color: "inherit", textDecoration: "none" }}
                            >
                                Export
                            </CSVLink>
                            : "No Export Data"
                        }
                    </Button>
                }
            </Box>
            
            {/* Load Custom Modal COmponent */}
            <Modal handleClose={handleClose} openModal={openModal} view='provider' />

        </div>
    )
}

ProviderView.propTypes = {

}

export default ProviderView
