import React, { useEffect, useState, useContext } from "react";
import { connect } from "formik";
import {
  FormControl,
  Box,
  Typography,
  Paper,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
// import MomentUtils from "@date-io/moment";
import { DropzoneArea } from "material-ui-dropzone";
import Button from "../../../../../Button";

import { useStyles } from "./style";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import AxiosRequest from "../../../../../../Services/AxiosRequests";
import loanContext from "../../../../../../Contexts/loan/loanContext";
import alertContext from "../../../../../../Contexts/alert/alertContext";
import credpalContext from "../../../../../../Contexts/credpal/credpalContext";
import Spinner from "../../../../../Reusables/Spinner";
import { envURL } from "../../../../../../Actions/helpers";

const LoanDocuments = (props) => {
  const classes = useStyles();

  const location = useLocation();
  const history = useHistory();
  const credPalProps = useContext(credpalContext);

  const { banks, getBanks } = credPalProps;

  const [loanDocuments, setLoanDocuments] = useState({});
  const [zedvanceAddress, setZedvanceAddress] = useState("");
  const loanRef = queryString.parse(location.search).loanRef || "";
  const [userId, setUserId] = useState("");

  const [open, setOpen] = useState(false);
  const [isReadPolicy, setIsReadPolicy] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // show extra documents for bishop gates.
  const [showDocuments, setShowDocuments] = useState(false);
  const optionalDocuments = [
    { name: "Guarantor Means Identity", keyName: "guarantor_means_identity" },
    { name: "Guarantor Passport", keyName: "guarantor_passport" },
    { name: "Guarantor Utility Bill", keyName: "guarantor_utility_bill" },
    { name: "CBN Compliant Cheque", keyName: "cbn_compliant_cheque" },
  ];

  const { currentLoan, uploadTypes, setUploadTypes, setCurrentLoan } =
    useContext(loanContext);
  const { submitAdvanclyApplication } = useContext(credpalContext);
  const { setAlert } = useContext(alertContext);

  useEffect(() => {
    const userId = queryString.parse(location.search).userId || "";
    setUserId(userId);

    // use global state for this implementation
    loanRef && userId && fetchUploadTypes(loanRef, userId);
    !banks.length && getBanks();
    console.log("The bank list is: ", banks);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (data = true) => {
    setOpen(false);
    setIsReadPolicy(data);
  };

  // const {
  //   formik: { setFieldValue, setTouched },
  // } = props;

  // const {isSubmitting, setIsSubmitting } = useContext(credPalContext);

  const setFieldValue = (field, value) => {
    const userValue = { ...loanDocuments };
    userValue[field] = value;
    setLoanDocuments(userValue);
  };

  const handleSubmit = async () => {
    const userId = queryString.parse(location.search).userId;
    console.log("The user Id is:", userId);

    setIsSubmitting(true);
    console.log("Handle submit button is called: ", loanDocuments);
    let hasErrors = false;

    for (const property in loanDocuments) {
      if (!loanDocuments[property]) {
        hasErrors = true;
        setAlert(`${property} is required.`, "error");
        break;
      }
    }

    if (hasErrors) {
      return setIsSubmitting(false);
    }

    let loanData = new FormData();

    for (const key in loanDocuments) {
      const element = loanDocuments[key];

      loanData.append(key, element);
    }

    console.log("The form data is: ", loanData);

    try {
      const uploadDocs = await AxiosRequest(
        "post",
        `Upload?loanRef=${loanRef}`,
        loanData
      );

      if (uploadDocs.data?.requestSuccessful) {
        history.replace("application/success");
      } else {
        setAlert(
          uploadDocs.data?.message || "Document upload failed. Try again.",
          "error"
        );
        setIsSubmitting(false);
      }
    } catch (error) {
      setIsSubmitting(false);
      setAlert(
        error.response.data?.message || "Document upload failed. Try again.",
        "error"
      );
    }
  };

  const handleZedvanceSubmit = async () => {
    if (!zedvanceAddress || zedvanceAddress.length < 4) {
      return setAlert("Enter a valid residential address description", "error");
    }

    setIsSubmitting(true);

    try {
      //TODO: Structure this payload to follow loan update data structure
      const bankAccount = currentLoan.bankAccount;
      const bankCode = banks.find(
        (bank) => bank.id === bankAccount.bankId
      )?.bankCode;

      let payload = {
        ...currentLoan,
        idNumber: zedvanceAddress,
        organizationCode: currentLoan.institutionInfo?.code || "",
        bankAccount: { ...bankAccount, bankCode },
      };

      payload = {
        userId,
        loanRef,
        providerCode: currentLoan.providerCode,
        requestData: { ...payload },
      };

      console.log("The update request is: ", payload);

      const res = await AxiosRequest(
        "post",
        "LoanTransaction/loanRequest",
        payload
      );
      console.log("The request response is : ", res);
      if (res.data.requestSuccessful) {
        setAlert(
          "Residential Address description updated successfully",
          "success"
        );
      } else {
        setAlert(
          res.data?.message ||
            "Residential Address description update error. Try Again",
          "error"
        );
      }
    } catch (error) {
      setAlert(
        error.response?.data?.message ||
          "Address Description update failed. Try again.",
        "error"
      );
      console.log("The upload error is: ", error);
    }

    setIsSubmitting(false);
  };

  const fetchUploadTypes = async (loanRef, userId) => {
    const res = await AxiosRequest(
      "get",
      `LoanTransaction/loan/${loanRef}?userId=${userId}`
    );

    if (res.data?.requestSuccessful) {
      const loanRefData = res.data.responseData;
      setCurrentLoan(loanRefData);

      if (loanRefData.providerName === "Advancly") {
        const { documents, ...rest } = loanRefData;
        return submitAdvanclyApplication(rest);
      }

      setOpen(true); //set the modal to open by default, if provider is not advancly

      const response = await AxiosRequest("get", `Provider`);
      const responseData = response.data.responseData.items;

      for (let i = 0; i < responseData.length; i++) {
        const element = responseData[i];
        if (element.sortCode === loanRefData?.providerCode) {
          setUploadTypes(element.uploadType);

          break;
        }
      }
    }
  };

  const updateShowDocuments = () => {
    if (showDocuments) {
      optionalDocuments.forEach(
        (document) => delete loanDocuments[document.keyName]
      );
      console.log("loan documents: ", loanDocuments);
      setShowDocuments(!showDocuments);
    } else setShowDocuments(!showDocuments);
  };

  return (
    <div className={classes.container}>
      <Button
        onClick={() => {
          window.location.href = `${envURL}/dashboard`;
        }}
      >
        &#60; BACK HOME
      </Button>
      {currentLoan?.providerName && currentLoan.providerName !== "Advancly" ? (
        <Paper style={{ padding: "1rem 2rem 3rem" }}>
          {currentLoan && currentLoan.providerCode === "123" && (
            <Box style={{ display: "flex", marginBottom: "20px" }}>
              <Grid item xs={9} md={9}>
                <FormControl>
                  <label htmlFor="address" className={classes.label}>
                    Residential Address Description
                    <span style={{ fontSize: "10px" }}>
                      {" "}
                      - (e.g Flat 4 in Cream house, Brown gate behind Total
                      station)
                    </span>
                  </label>
                  <TextField
                    name="address"
                    id="address"
                    value={zedvanceAddress}
                    onChange={(e) => setZedvanceAddress(e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid className={classes.addressSubmit} item xs={3} md={3}>
                <Button
                  variant="contained"
                  padding={"0.7rem 2rem"}
                  disabled={isSubmitting}
                  // loading={isSubmitting}
                  color="primary"
                  onClick={handleZedvanceSubmit}
                >
                  UPDATE
                </Button>
              </Grid>
            </Box>
          )}

          <Box marginTop={1} marginBottom={2}>
            <Typography variant="h5">
              Upload Documents (all fields are required)
            </Typography>
          </Box>
          <Box marginTop={3}>
            {uploadTypes.length > 0 &&
              uploadTypes.map((documentType, index) => {
                return (
                  <Box key={index}>
                    <Typography variant="body1" gutterBottom>
                      {documentType.name}
                    </Typography>
                    <FormControl component="fieldset" style={{ width: "100%" }}>
                      <DropzoneArea
                        style={{ border: "3px dashed blue !important" }}
                        acceptedFiles={[
                          "image/*",
                          "application/pdf",
                          "application/msword",
                        ]}
                        dropzoneText={"Upload your document here"}
                        maxFileSize={5000000}
                        showPreviewsInDropzone
                        // useChipsForPreview
                        showFileNames
                        showFileNamesInPreview
                        getDropRejectMessage={() =>
                          "File should be the required format and not more than 5MB"
                        }
                        filesLimit={1}
                        onChange={(file) =>
                          setFieldValue(documentType.keyName, file[0])
                        }
                      />
                      <div className={classes.uploadInfo}>
                        <Typography variant="caption">
                          Files Supported: PNG, Jpeg, PDF{" "}
                        </Typography>
                        <Typography variant="caption">
                          Maximum Size: 5MB
                        </Typography>
                      </div>
                      {/* <ErrorMessage name="loanDocuments.id" component={FormErrors} /> */}
                    </FormControl>
                  </Box>
                );
              })}

            {currentLoan && currentLoan.providerCode === "MIFOS" && (
              <FormControl className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="showDocuments"
                      color="primary"
                      checked={showDocuments}
                      onChange={() => updateShowDocuments()}
                    />
                  }
                  label={
                    <Box>
                      <Typography variant="caption" display="inline">
                        Check here if you want the funds disbursed into personal
                        account.
                      </Typography>
                    </Box>
                  }
                />
              </FormControl>
            )}

            {showDocuments &&
              optionalDocuments.map((documentType, index) => {
                return (
                  <Box key={index}>
                    <Typography variant="body1" gutterBottom>
                      {documentType.name}
                    </Typography>
                    <FormControl component="fieldset" style={{ width: "100%" }}>
                      <DropzoneArea
                        style={{ border: "3px dashed blue !important" }}
                        acceptedFiles={[
                          "image/*",
                          "application/pdf",
                          "application/msword",
                        ]}
                        dropzoneText={"Upload your document here"}
                        maxFileSize={5000000}
                        showPreviewsInDropzone
                        // useChipsForPreview
                        showFileNames
                        showFileNamesInPreview
                        getDropRejectMessage={() =>
                          "File should be the required format and not more than 5MB"
                        }
                        filesLimit={1}
                        onChange={(file) =>
                          setFieldValue(documentType.keyName, file[0])
                        }
                      />
                      <div className={classes.uploadInfo}>
                        <Typography variant="caption">
                          Files Supported: PNG, Jpeg, PDF{" "}
                        </Typography>
                        <Typography variant="caption">
                          Maximum Size: 5MB
                        </Typography>
                      </div>
                      {/* <ErrorMessage name="loanDocuments.id" component={FormErrors} /> */}
                    </FormControl>
                  </Box>
                );
              })}
          </Box>

          <Box marginTop={1}>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    // {...getFieldProps("loanRequest.isAcceptedAgreement")}
                    name="loanRequest.isAcceptedAgreement"
                    color="primary"
                    checked={isReadPolicy}
                    onChange={() => {
                      setIsReadPolicy(!isReadPolicy);
                    }}
                  />
                }
                label={
                  <Box>
                    <Typography variant="caption" display="inline">
                      By submitting this form, you agree to our
                    </Typography>
                    &nbsp;
                    <Typography
                      onClick={handleClickOpen}
                      display="inline"
                      color="primary"
                      variant="caption"
                    >
                      Terms and Conditions
                    </Typography>
                  </Box>
                }
              />
              {/* <ErrorMessage
                name="loanRequest.isAcceptedAgreement"
                component={FormErrors}
              /> */}
            </FormControl>
          </Box>

          <Box align="right" marginTop={6} style={{ width: "100%" }}>
            <Box className={classes.buttons}>
              <Button
                variant="contained"
                padding={"0.7rem 2rem"}
                disabled={isSubmitting || !isReadPolicy}
                loading={isSubmitting}
                type="submit"
                color="primary"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Paper>
      ) : (
        <Spinner />
      )}

      <Dialog
        open={open}
        // onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Agreement Summary</DialogTitle>
        <DialogContent dividers={true}>
          {!currentLoan ? (
            <Spinner />
          ) : currentLoan.providerCode === "MIFOS" ? (
            <DialogContentText
              id="scroll-dialog-description"
              // ref={descriptionElementRef}
              tabIndex={-1}
            >
              Dear Customer, thank you for using our service. Please carefully
              read the customers' agreement summarized below for your
              information and execution.
              <br /> <br />
              You agree that by clicking on the accept below, you will fulfil
              your financial obligation which is upfront payment of the interest
              rate at 4% monthly for the credit sum requested.
            </DialogContentText>
          ) : (
            <DialogContentText
              id="scroll-dialog-description"
              // ref={descriptionElementRef}
              tabIndex={-1}
            >
              Dear Customer, thank you for using our service. Please carefully
              read the customers' agreement summarized below for your
              information and execution.
              <br /> <br />
              You agree that by clicking on the accept button below, you will
              fulfil your financial obligation which includes prompt repayment
              of the credit sum and interest rate to the provider, should you
              refuse to repay the credit sum (interest rate inclusive) allocated
              to you, the provider has the right to take any or all the
              following actions against you.
              <ul>
                <li>
                  The provider may notify the Credit Bureau of your default,
                  this will result in you being unable to credit for at least 10
                  years.
                </li>

                <li>
                  The provider may notify your employer of your indebtedness.
                </li>
              </ul>
              The provider may institute legal proceedings against you and is
              under no obligation to inform you before the proceedings commence
              and you shall be responsible for all legal costs and expenses
              incurred by the provider in attempting to obtain repayment of any
              outstanding loan balance owed by you. Interest on any amount which
              becomes due and payable shall be charged.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose(false)}
            variant="contained"
            padding={"0.4rem 1rem"}
          >
            Decline
          </Button>
          <Button
            onClick={() => handleClose(true)}
            variant="contained"
            padding={"0.4rem 1rem"}
            color="primary"
          >
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default connect(LoanDocuments);
