import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Spinner from "../../../Reusables/Spinner";
import CredPalForm from "../CredPalForm";

import LoanContext from "../../../../Contexts/loan/loanContext";
import { Typography } from "@material-ui/core";

const LoanApplicationLayout = (props) => {
  const {
    getProviders,
    providers,
    isLoading,
    getLoanByRef,
    currentProvider,
    setCurrentProvider,
  } = useContext(LoanContext);

  const [loading, setLoading] = useState(false);

  const location = useLocation();

  const { providerId, userId, loanRef } = queryString.parse(location.search);

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    setLoading(true);
    async function getData() {
      await getProviders();
      
      if (loanRef && userId && !providerId) {
        localStorage.removeItem("loanRef"); //in case there was any
        localStorage.setItem("loanRef", loanRef);

        await getLoanByRef(loanRef, userId);
      }else if (providerId){
        setCurrentProvider(providerId);
      }

      if (isMounted.current) {
        setLoading(false);
      }
    }

    getData();


    //component will unmount
    return () => {
      localStorage.removeItem("loanRef");
      isMounted.current = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (typeof window !== "undefined") {
    window.addEventListener("beforeunload", function (event) {
      localStorage.removeItem("loanRef");
    });
  }

  //call this directly if a user lands with loanRef
  const showProviderForm = (name) => {
    
    //since this is a get-back-to function for both entry points, we set the currentProvider in state using the name
    // so we can pull values from the provider details set by the admin and use for validation
    
    // const providerInfo = providers.find(
    //   (loanProvider) => name === loanProvider.bankName
    // );

    // //render corresponding provider form
    // switch (name) {
    //   case "CredPal":
    //   case "Advancly":
    //     return <CredPalForm providerInfo={providerInfo} />;

    //   default:
    //     return (
    //       <Typography variant="h2">This provider has no form yet</Typography>
    //     );
    // }

    //because of the workflow change, we no longer require the providerInfo until the loan request form has been completed
    return <CredPalForm />;
  };

  //call this function if a user lands here with a providerId
  const getSelectedProvider = () => {
    let provider;
    if (!isLoading) {
      provider = providers.find(
        (loanProvider) => loanProvider.id === providerId
      );
    }
    if (provider) {
      return showProviderForm(provider.bankName);
    }
    return <Spinner />;
  };

  //depends if the user lands here with loanRef or providerId

  if (loading) return <Spinner />;

  if (!isLoading && providerId && providers.length)
    return getSelectedProvider();

  // if (!loading && !isLoading && currentProvider)
  //   return showProviderForm(currentProvider.bankName);

  if (!loading && !isLoading && (loanRef || userId))
    return showProviderForm();

  return <Spinner />;
};

export default LoanApplicationLayout;
