import React, { useState, useContext, useEffect } from "react";
import { ErrorMessage } from "formik";
import FormErrors from "../../../../../FormErrors";
import ControlButtons from "../../ControlButtons";
import { connect } from "formik";
import {
  FormControl,
  Box,
  TextField,
  Typography,
  Paper,
  Select,
  MenuItem,
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import loanContext from "../../../../../../Contexts/loan/loanContext";
import credPalContext from "../../../../../../Contexts/credpal/credpalContext";

import { useStyles } from "./style";

const SponsorInformation = (props) => {
  const classes = useStyles();

  const [showCityHelper, setShowCityHelper] = useState(false);

  const { informationMismatch } = useContext(credPalContext);

  const { states } = useContext(loanContext);

  const {
    formik: { getFieldProps, setTouched, setFieldValue, touched, values },
    sponsorCities,
    getCities,
  } = props;

  const getCityData = (val) =>
    getCities(states.find((state) => state.name === val)?.id, "sponsor");

  useEffect(() => {
    setTouched({});

    if (values.sponsorInfo.city) {
      getCityData(values.sponsorInfo.state);
    }
    //eslint-disable-next-line
  }, []);

  const relationship = [
    "Father",
    "Mother",
    "Sister",
    "Brother",
    "Nephew",
    "Niece",
    "Cousin",
    "Uncle",
    "Aunty",
    "Guardian",
    "Other"
  ];

  return (
    <div className={classes.container}>
      <Paper style={{ padding: "1rem 2rem 3rem" }}>
        <Box marginTop={3} marginBottom={2}>
          <Typography variant="h5">Sponsor's Information</Typography>
          <Typography variant="caption" style={{fontStyle:"italic"}}>
            This applies to anyone taking responsibility and would make repayment
            of the loan on behalf of the student i.e. parents, guardians etc.
          </Typography>
        </Box>

        <div className={classes.fieldWrapper}>
          <FormControl className={classes.formControl}>
            <label className={classes.label}>First Name</label>
            <TextField
              name="sponsorInfo.firstName"
              id="sponsorInfo.firstName"
              {...getFieldProps("sponsorInfo.firstName")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage name="sponsorInfo.firstName" component={FormErrors} />
          </FormControl>

          <FormControl className={classes.formControl}>
            <label className={classes.label}>Surname</label>
            <TextField
              name="sponsorInfo.lastName"
              id="sponsorInfo.lastName"
              {...getFieldProps("sponsorInfo.lastName")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage name="sponsorInfo.lastName" component={FormErrors} />
          </FormControl>

          <FormControl
            className={classes.formControl}
            variant="outlined"
            size="small"
          >
            <label className={classes.label}>Gender</label>
            <Select
              className={classes.select}
              defaultValue=""
              labelId="sponsorInfo.gender"
              id="sponsorInfo.gender"
              name="sponsorInfo.gender"
              {...getFieldProps("sponsorInfo.gender")}
            >
              <MenuItem value="M">Male</MenuItem>
              <MenuItem value="F">Female</MenuItem>
            </Select>
            <ErrorMessage name="sponsorInfo.gender" component={FormErrors} />
          </FormControl>

          <FormControl className={classes.formControl}>
            <label className={classes.label}>Date Of Birth (dd/mm/yyyy)</label>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                inputVariant="outlined"
                format="DD/MM/yyyy"
                variant="outlined"
                id="sponsorInfo.dateOfBirth"
                name="sponsorInfo.dateOfBirth"
                size="small"
                value={values.sponsorInfo.dateOfBirth}
                onChange={(value) => {
                  setFieldValue("sponsorInfo.dateOfBirth", value);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <Typography variant="caption">
              * Accepted age Range is between 18 to 59 years
            </Typography>
            <ErrorMessage
              name="sponsorInfo.dateOfBirth"
              component={FormErrors}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <label className={classes.label}>Phone Number</label>
            <TextField
              name="sponsorInfo.phoneNumber"
              id="sponsorInfo.phoneNumber"
              type="tel"
              {...getFieldProps("sponsorInfo.phoneNumber")}
              value={values.sponsorInfo.phoneNumber}
              onChange={(e) => {
                informationMismatch(); //if a user changes phoneNumber, the bankInfo should not be submitted
                setFieldValue("sponsorInfo.phoneNumber", e.target.value);
              }}
              variant="outlined"
              size="small"
            />
            <Typography variant="caption">
              * BVN Registered Phone Number
            </Typography>
            <ErrorMessage
              name="sponsorInfo.phoneNumber"
              component={FormErrors}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <label className={classes.label}>Email</label>
            <TextField
              name="sponsorInfo.email"
              id="sponsorInfo.email"
              {...getFieldProps("sponsorInfo.email")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage name="sponsorInfo.email" component={FormErrors} />
          </FormControl>

          <FormControl className={classes.formControl} size="small">
            <label className={classes.label}>Relationship to student</label>
            <Select
              className={classes.select}
              defaultValue=""
              name="sponsorInfo.relationship"
              id="sponsorInfo.relationship"
              {...getFieldProps("sponsorInfo.relationship")}
              variant="outlined"
            >
              {relationship.map((value, key) => (
                <MenuItem key={key} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            <ErrorMessage
              name="sponsorInfo.relationship"
              component={FormErrors}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <label className={classes.label}>Residential Address</label>
            <TextField
              name="sponsorInfo.address"
              id="sponsorInfo.address"
              {...getFieldProps("sponsorInfo.address")}
              variant="outlined"
              size="small"
            />
            <ErrorMessage name="sponsorInfo.address" component={FormErrors} />
          </FormControl>

          <FormControl
            className={classes.formControl}
            variant="outlined"
            size="small"
          >
            <label className={classes.label}>State</label>
            <Select
              className={classes.select}
              defaultValue=""
              labelId="state"
              id="state"
              name="sponsorInfo.state"
              {...getFieldProps("sponsorInfo.state")}
              onChange={(e) => {
                setFieldValue("sponsorInfo.city", "");
                if (showCityHelper) setShowCityHelper(false);
                const val = e.target.value;
                if (val !== null) {
                  setFieldValue("sponsorInfo.state", val);
                  setTouched({
                    ...touched,
                    sponsorInfo: {
                      ...touched.sponsorInfo,
                      state: false,
                      city: false,
                    },
                  });
                  getCityData(val);
                }
              }}
              value={values.sponsorInfo.state}
            >
              {!states.length ? (
                <MenuItem value={""} defaultValue>
                  Loading States...
                </MenuItem>
              ) : (
                states.map((state, key) => (
                  <MenuItem key={key} value={state.name}>
                    {state.name}
                  </MenuItem>
                ))
              )}
            </Select>
            <ErrorMessage name="sponsorInfo.state" component={FormErrors} />
          </FormControl>
          <FormControl
            className={classes.formControl}
            variant="outlined"
            size="small"
          >
            <label className={classes.label}>City</label>
            <Select
              className={classes.select}
              defaultValue=""
              labelId="sponsorInfo.city"
              renderValue={() => values.sponsorInfo.city}
              disabled={values.sponsorInfo.state === ""}
              id="sponsorInfo.city"
              onClick={() =>
                values.sponsorInfo.state === "" && setShowCityHelper(true)
              }
              name="sponsorInfo.city"
              {...getFieldProps("sponsorInfo.city")}
            >
              {!sponsorCities.length ? (
                <MenuItem value="" defaultValue>
                  Loading Cities...
                </MenuItem>
              ) : (
                sponsorCities.map((city, key) => (
                  <MenuItem key={key} value={city.name}>
                    {city.name}
                  </MenuItem>
                ))
              )}
            </Select>
            {showCityHelper && (
              <Typography variant="caption">*Select state first</Typography>
            )}
            <ErrorMessage name="sponsorInfo.city" component={FormErrors} />
          </FormControl>

          <FormControl className={classes.formControl}>
            <label className={classes.label}>NIN</label>
            <TextField
              name="sponsorInfo.nin"
              id="sponsorInfo.nin"
              {...getFieldProps("sponsorInfo.nin")}
              variant="outlined"
              size="small"
            />
            <Typography variant="caption">
              Dial *346# to get your NIN
            </Typography>
            <ErrorMessage name="sponsorInfo.nin" component={FormErrors} />
          </FormControl>
        </div>
        <ControlButtons step={1} />
      </Paper>
    </div>
  );
};

export default connect(SponsorInformation);
