import React, { createContext, useState } from "react";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { eCTheme } from "./Styles/Jss/global";

import ContextWapper from "./Contexts/ContextWrapper";
import WithAxios from "./Services/withAxiosInstance";
import Parentlayout from "./Components/Layout/ParentLayout";
import "./App.css";

export const SelectedEntityContext = createContext();

const App = () => {
  const [selectedEntity, setSelectedEntity] = useState();
  // console.log("The env variable is: ", envURL, process.env.REACT_APP_API_URL);
  console.log("New Deployment update");

  //REMOVE THIS AFTER DEPLOYMENT TO PRODUCTION
  localStorage.removeItem("auth");
  return (
    <ContextWapper>
      <WithAxios>
        <SelectedEntityContext.Provider
          value={[selectedEntity, setSelectedEntity]}
        >
          <ThemeProvider theme={eCTheme}>
            <CssBaseline />
            <div className="parent-wrapper">
              <Parentlayout />
            </div>
          </ThemeProvider>
        </SelectedEntityContext.Provider>
      </WithAxios>
    </ContextWapper>
  );
};

export default App;
