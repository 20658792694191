import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useRef,
    Fragment,
  } from "react";
  import {
    Box,
    Typography,
    Grid,
    TextField,
    Select,
    MenuItem,
    IconButton,
    FormControl,
    Switch,
    FormControlLabel,
  } from "@material-ui/core";
  import { DropzoneArea } from "material-ui-dropzone";
  import CloseIcon from "@material-ui/icons/Close";
  import Menu from "@material-ui/core/Menu";
  import MoreVertIcon from "@material-ui/icons/MoreVert";
  
  import debounce from "lodash.debounce";
  import * as Yup from "yup";
  import { Formik, Form, ErrorMessage, Field } from "formik";
  
  import Paper from "../../../Paper";
  import Dialog from "../../../Reusables/Dialog";
  import Button from "../../../Button";
  import Table from "../../../Reusables/Table";
  import Pagination from "../../../Reusables/Pagination";
  import StatusIndicator from "../../../Reusables/StatusIndicator";
  import TableHead from "../../../Reusables/TableHead";
  
  import alertContext from "../../../../Contexts/alert/alertContext";
  import loanContext from "../../../../Contexts/loan/loanContext";
  
  import FormErrors from "../../../FormErrors";
  import {
    nameValidation,
    phoneNumberValidation,
    emailValidation,
  } from "../../../../Actions/formActions";
  
  import { useStyles } from "./style";
  import { formatDateTime, validateDates } from "../../../../Actions/helpers";
import AxiosRequest from "../../../../Services/AxiosRequests";
  
  const VendorsLayout = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
  
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setIssubmitting] = useState(false);
    const [statusSubmitting, setStatusSubmitting] = useState(false);
    const [vendor, setVendor] = useState(null);
    const [vendors, setVendors] = useState({});
    const [dialogContentIndex, setDialogContentIndex] = useState(3);
    const [documents, setDocuments] = useState({TIN : "", CAC : ""});
  
    const { setAlert } = useContext(alertContext);
    const { makeFilterRequest, addOrUpdateVendors,} = useContext(
      loanContext
    );
  
    const initialState = {
      vendorName: "",
      startDate: "",
      endDate: "",
      pageSize: 10,
      pageNumber: 1,
    };
  
    const [payload, setPayload] = useState({ ...initialState });
    const { pageSize, pageNumber, startDate, endDate, vendorName } = payload;
  
    const _isMounted = useRef(true);
  
    useEffect(() => {
      if (_isMounted.current === false) {
        _isMounted.current = true;
      }
  
      getVendors();
      return () => (_isMounted.current = false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, pageNumber]);
  
  
    ////////////////////////**********MODAL ACTIONS**********///////////////////
    const triggerModalOpen = (index) => {
      setDialogContentIndex(index);
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      //To fix an issue of having data being set to null first before closing modal
      if (open === false) {
        vendor !== null && setVendor(null);
      }
    };
    ////////////////////////**********MODAL ACTIONS**********///////////////////
  
    ///////////////////////***********vendor CREATE ACTIONS***********//////////////
  
    const [anchorEl, setAnchorEl] = useState(null);
    const anchorOpen = Boolean(anchorEl);
  
    const handleMenuOpen = (e, vendor) => {
      setAnchorEl(e.currentTarget);
      console.log("The selected vendor is: ", vendor);
      setVendor(vendor);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
      // if(dialogContentIndex !==3){
      //   vendor !== null && setvendor(null);
      // }
    };
  
    const createVendors = async (values) => {
      try {
        setIssubmitting(true);
        const res = await addOrUpdateVendors(values);
        if (res.data.requestSuccessful) {
          let action = vendor !== null ? "Updated" : "Created";
          setAlert(`Vendor ${action} successfully`, "success");
          getVendors();
          handleClose();
        } else {
          setAlert(res.data.message || "Error occured while performing request", "error");
        }
        setIssubmitting(false);
      } catch (err) {
        setAlert(err.response?.data?.message || "Error occured while performing request", "error");
        setIssubmitting(false);
      }
    };
  
    // const deleteVendor = async () => {
    //   try {
    //     setIssubmitting(true);
    //     const res = await deleteVendorDetails(vendor.id);
    //     if (res.data.requestSuccessful) {
    //       let action = "deleted";
    //       setAlert(`Vendor ${action} successfully`, "success");
    //       getVendors();
    //       handleClose();
    //     } else {
    //       setAlert(res.data.message, "error");
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }
  
    const initialValues = {
      firstName: vendor?.firstName ? vendor.firstName : "",
      lastName: vendor?.lastName ? vendor.lastName : "",
      email: vendor?.email ? vendor.email : "",
      phoneNumber: vendor?.phoneNumber ? vendor.phoneNumber : "",
      isActive: vendor?.isActive ? vendor?.isActive : false,
    };
  
    const validationSchema = Yup.object({
      firstName: nameValidation(
        "First Name",
        "firstName",
        true
      ),
      lastName: nameValidation(
        "Last Name",
        "lastName",
        true
      ),
      email: emailValidation(),
      phoneNumber: phoneNumberValidation("Phone Number", "contactNumber"),
      password: vendor === null && Yup.string().min(8, "not less than 8 characters").required(),
      confirmPassword: vendor === null && Yup.string().min(8, "not less than 8 characters").required(),
    });
  
    const onSubmit = async (values) => {
      console.log("The submitted values is: ", values);
      let submitValues;
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
      } = values;
      
  
      //If its an update action
      if (vendor !== null) {
        submitValues = { ...vendor, firstName, lastName, email, phoneNumber, roleId : "a695feb6-b01d-4313-8d6c-01191062a6e7", userName : email };
        console.log("The updated submit values are: ", submitValues);

        createVendors(submitValues);
      } else {
        submitValues = {...values, terms : true};
        console.log("The vendor sign-up values are: ", submitValues);

        if(submitValues.password && submitValues.password !== submitValues.confirmPassword) {
          return setAlert("The password and confirmed password must match", "error");
        }

        createVendors(submitValues);
      }
    };
  
    /////////////////**********VENDORS CREATE *************////////////////
  
    /////////////////**********TABLE ACTIONS *************////////////////
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      ///always reset pageNumber if pageSize or name changes to avoid wrong data fetch
      if (name === "pageSize" || name === "vendorName") {
        payload.pageNumber = 1;
      }
      setPayload({ ...payload, [name]: value });
    };
  
    const handlePageClick = (page) => {
      setPayload({ ...payload, pageNumber: page });
    };
  
    const handleVendorNameSearch = (e) => {
      setPayload({ ...payload, vendorName: e.target.value });
      loadWithDebounce(e.target.value);
    };
  
    let url = `vendor?pageSize=${pageSize}&pageNo=${pageNumber}`;
    let filter = false;
  
    const clearFilters = async () => {
      //make the request without filters, taking into consideration the state of the vendorName field
      setPayload({ ...initialState, vendorName });
      filter = true;
      if (vendorName !== "") {
        url += `&firstName=${vendorName}`;
      }
      await getVendors(url, filter);
    };
  
    const getvendorsFromFirstPage = () => {
      if (pageNumber !== 1) {
        setPayload({ ...payload, pageNumber: 1 });
      }
      pageNumber === 1 && getVendors();
    };
  
    const getVendors = async (
      url = `vendor?pageSize=${pageSize}&page=${pageNumber}`,
      clearFilter
    ) => {
      if (!clearFilter) {
        if (vendorName !== "") {
          url += `&firstName=${vendorName}`;
        }
  
        if (!validateDates(startDate, endDate)) {
          setAlert("Please enter valid dates", "error");
          return;
        } else if (startDate !== "" && endDate !== "") {
          url += `&startDate=${startDate}&endDate=${endDate}`;
        }
      }
  
      setLoading(true);
      try {
        const res = await makeFilterRequest(url);
        if (_isMounted.current) {
          setVendors(res.data.responseData);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
      open && handleClose();
      filter = false;
    };
  
    const loadWithDebounce = useCallback(
      debounce((vendorName) => {
        if (vendorName === "") {
          getVendors(url);
        } else {
          getVendors(`${url}&firstName=${vendorName}`);
        }
      }, 1000),
      []
    );

    const updateVendorStatus = async () => {
      try {
        setStatusSubmitting(true);
        const res = await AxiosRequest("post", `Vendor/disable_or_enable_vendor_account?email=${vendor.email}`);
        if (res.data.requestSuccessful) {
          let action = vendor !== null ? "Updated" : "Created";
          setAlert(`Vendor ${action} successfully`, "success");
          getVendors();
          handleClose();
        } else {
          setAlert(res.data.message, "error");
        }
        setStatusSubmitting(false);
      } catch (err) {
        setAlert(err.response?.data?.message || "Error occured while performing request", "error");
        setStatusSubmitting(false);
      }
    }

    const updateDocuments = (doc, name) => {
      console.log(`The ${name} document is: `, doc);
      setDocuments({...documents, [name] : doc});
    }

    const uploadDocuments = async () => { 
      try {
        let errors = 0;
        const docList = Object.keys(documents);
        for (let i = 0; i < docList.length; i++) {
          const data = docList[i];
          if(!documents[data]) {
            errors += 1;
            setAlert(`Vendor Document - ${data} - is required`, "error");
          }
        }

        if(!errors) {
          setIssubmitting(true);
          const formData = new FormData();

          for (let a = 0; a < docList.length; a++) {
            const name = docList[a];
            documents[name] && formData.append(name, documents[name]);

            console.log("The important details are: ", name, documents[name], formData);
          }
          console.log("The form data is: ", formData)

          const res = await AxiosRequest("post", `Stores/upload_vendor_documents_for_store?vendorId=${vendor?.id}`, formData)
          if (res.data.requestSuccessful) {
            setAlert(`Vendor documents uploaded successfully`, "success");
            handleClose();
          } else {
            setAlert(res.data.message, "error");
          }
          setIssubmitting(false);
        }
      } catch (err) {
        console.log("Update logo error", err)
        setAlert(err.response?.data?.message || "Error occured while uploading documents", "error");
        setIssubmitting(false);
      }
    }
  
    const columns = [
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
      },
      {
        Header: "Active Status",
        accessor: "isActive",
        Cell: (props) => <span>{props.value === true ?  "Active" : "Not Active"}</span>,
      },
      {
        Header: " ",
        Cell: (props) => {
          return (
            <>
              <IconButton
                aria-label="more"
                aria-haspopup="true"
                aria-controls={props.row.original.id}
                onClick={(e) => handleMenuOpen(e, props.row.original)}
              >
                <MoreVertIcon color="primary" style={{ cursor: "pointer" }} />
              </IconButton>
            </>
          );
        },
      },
    ];
  
    /////////////////**********TABLE ACTIONS *****************////////////////
  
    /////////////////**********MODAL VIEWS *****************////////////////
    const filterForm = () => (
      <>
        <Grid container spacing={2} style={{ marginTop: "1rem" }}>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <label htmlFor="startDate" className={classes.label}>
                Start Date
              </label>
              <TextField
                name="startDate"
                variant="outlined"
                size="small"
                type="date"
                value={startDate}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <label htmlFor="endDate" className={classes.label}>
                End Date
              </label>
              <TextField
                name="endDate"
                variant="outlined"
                size="small"
                type="date"
                value={endDate}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
        </Grid>
  
        <Box className={classes.filterActions}>
          <Typography
            style={{ color: "red", cursor: "pointer" }}
            onClick={clearFilters}
          >
            Clear all filters
          </Typography>
          <Button
            variant="contained"
            size="medium"
            disabled={loading || (startDate === "" && endDate === "")}
            loading={loading}
            // type="submit"
            color="primary"
            onClick={() => getvendorsFromFirstPage()}
          >
            Apply
          </Button>
        </Box>
      </>
    );
  
    const viewvendor = () => {
      if (vendor !== null) {
        const {
          firstName,
          lastName,
          email,
          phoneNumber,
          emailConfirmed,
          isActive,
        } = vendor;
  
        return (
          <Box className={classes.vendorView}>
            <Box className={classes.vendorItem}>
              <small variant="body1">Vendor First Name</small>
              <Typography>{firstName}</Typography>
            </Box>

            <Box className={classes.vendorItem}>
              <small variant="body1">Vendor Last Name</small>
              <Typography>{lastName}</Typography>
            </Box>
  
            <Box className={classes.vendorItem}>
              <small>Vendor Email</small>
              <Typography>{email}</Typography>
            </Box>
  
            <Box className={classes.vendorItem}>
              <small>Vendor Phone Number</small>
              <Typography>{phoneNumber}</Typography>
            </Box>
  
            <Box className={classes.vendorItem}>
              <small>Active Status</small>
              <Typography>{isActive === true ? "ACTIVE" : "INACTIVE"}</Typography>
            </Box>
  
            <Box className={classes.vendorItem}>
              <small>Email Confirmation</small>
              <Typography>{emailConfirmed === true ? "CONFIRMED" : "NOT CONFIRMED"}</Typography>
            </Box>
          </Box>
        );
      }
    };
  
    const createvendorForm = () => (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, getFieldProps, values, setFieldValue }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={2} style={{ marginTop: "1rem" }}>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <label htmlFor="firstName" className={classes.label}>
                    First Name
                  </label>
                  <TextField
                    name="firstName"
                    placeholder="Enter vendor's first name"
                    id="firstName"
                    variant="outlined"
                    {...getFieldProps("firstName")}
                    error={
                      errors.firstName && touched.firstName
                        ? true
                        : false
                    }
                    size="small"
                  />
                  <ErrorMessage name="firstName" component={FormErrors} />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <label htmlFor="firstName" className={classes.label}>
                    Last Name
                  </label>
                  <TextField
                    name="lastName"
                    placeholder="Enter vendor's last name"
                    id="lastName"
                    variant="outlined"
                    {...getFieldProps("lastName")}
                    error={
                      errors.lastName && touched.lastName
                        ? true
                        : false
                    }
                    size="small"
                  />
                  <ErrorMessage name="lastName" component={FormErrors} />
                </FormControl>
              </Grid>
  
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <label htmlFor="email" className={classes.label}>
                    Vendor's Email
                  </label>
                  <TextField
                    name="email"
                    placeholder="okay@fine.com"
                    id="email"
                    error={errors.email && touched.email ? true : false}
                    variant="outlined"
                    {...getFieldProps("email")}
                    size="small"
                  />
                  <ErrorMessage name="email" component={FormErrors} />
                </FormControl>
              </Grid>
  
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <label htmlFor="phoneNumber" className={classes.label}>
                    Vendor's Phone Number
                  </label>
                  <TextField
                    name="phoneNumber"
                    placeholder="07012345678"
                    id="phoneNumber"
                    error={errors.phoneNumber && touched.phoneNumber ? true : false}
                    variant="outlined"
                    {...getFieldProps("phoneNumber")}
                    size="small"
                  />
                  <ErrorMessage name="phoneNumber" component={FormErrors} />
                </FormControl>
              </Grid>
              
              { vendor === null &&
                <Fragment>
                  <Grid item xs={12} md={6}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="password" className={classes.label}>
                        Account Password
                      </label>
                      <TextField
                        name="password"
                        id="password"
                        type="password"
                        placeholder="enter vendor's password"
                        {...getFieldProps("password")}
                        variant="outlined"
                        error={
                          errors.password && touched.password
                            ? true
                            : false
                        }
                        size="small"
                      />
                      <ErrorMessage name="password" component={FormErrors} />
                    </FormControl>
                  </Grid>
  
                  <Grid item xs={12} md={6}>
                    <FormControl className={classes.formControl}>
                      <label htmlFor="confirmPassword" className={classes.label}>
                        Confirm Password
                      </label>
                      <TextField
                        name="confirmPassword"
                        id="confirmPassword"
                        type="password"
                        placeholder="re-enter vendor's password"
                        className={classes.numberInput}
                        {...getFieldProps("confirmPassword")}
                        variant="outlined"
                        error={
                          errors.confirmPassword && touched.confirmPassword ? true : false
                        }
                        size="small"
                      />
                      <ErrorMessage name="confirmPassword" component={FormErrors} />
                    </FormControl>
                  </Grid>
                </Fragment>
              }
            </Grid>
  
            <Box className={classes.createActions}>
              <Typography
                variant="body2"
                color="primary"
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              >
                Cancel
              </Typography>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
                padding={"0.8rem 1.5rem"}
              >
                {vendor !== null ? "Update" : "Add"}
              </Button>
            </Box>

            <Grid container spacing={2} style={{ marginTop: "1rem" }}>
              { vendor !== null &&
                <Fragment>
                  <Grid item xs={12} md={6} sm={6}>
                    <FormControl
                      component="fieldset"
                      style={{ width: "100%" }}
                      className={classes.formControl}
                    >
                      <label htmlFor="Logo" className={classes.label}>
                        CAC Certificate
                      </label>
                      <DropzoneArea
                        acceptedFiles={["image/*"]}
                        dropzoneText={
                          <Box>
                            <Typography display="inline" variant="body2">
                              Drag and drop or
                            </Typography>
                            <Typography
                              display="inline"
                              color="primary"
                              variant="body2"
                            >
                              Browse
                            </Typography>
                          </Box>
                        }
                        maxFileSize={1000000}
                        showPreviewsInDropzone
                        showFileNamesp
                        showFileNamesInPreview
                        getDropRejectMessage={() =>
                          "File should be the required format and not more than 800KB"
                        }
                        filesLimit={1}
                        onChange={(file) => updateDocuments(file[0], "CAC")}
                      />
                      <div className={classes.uploadInfo}>
                        <small style={{ fontSize: "10px", fontWeight: 300 }}>
                          Files Supported: JPEG, PNG, SVG
                        </small>
                        <small style={{ fontSize: "10px", fontWeight: 300 }}>
                          Max File Size: 1 MB
                        </small>
                      </div>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} sm={6}>
                    <FormControl
                      component="fieldset"
                      style={{ width: "100%" }}
                      className={classes.formControl}
                    >
                      <label htmlFor="Logo" className={classes.label}>
                        TIN Certificate
                      </label>
                      <DropzoneArea
                        acceptedFiles={["image/*"]}
                        dropzoneText={
                          <Box>
                            <Typography display="inline" variant="body2">
                              Drag and drop or
                            </Typography>
                            <Typography
                              display="inline"
                              color="primary"
                              variant="body2"
                            >
                              Browse
                            </Typography>
                          </Box>
                        }
                        maxFileSize={1000000}
                        showPreviewsInDropzone
                        showFileNamesp
                        showFileNamesInPreview
                        getDropRejectMessage={() =>
                          "File should be the required format and not more than 800KB"
                        }
                        filesLimit={1}
                        onChange={(file) => updateDocuments(file[0], "TIN")}
                      />
                      <div className={classes.uploadInfo}>
                        <small style={{ fontSize: "10px", fontWeight: 300 }}>
                          Files Supported: JPEG, PNG, SVG
                        </small>
                        <small style={{ fontSize: "10px", fontWeight: 300 }}>
                          Max File Size: 1 MB
                        </small>
                      </div>
                    </FormControl>
                  </Grid>
                </Fragment>
              }
            </Grid>

            { vendor !== null &&
              <Box className={classes.createActions}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  padding={"0.8rem 1.5rem"}
                  onClick={uploadDocuments}
                >
                  Submit
                </Button>
              </Box>
            }

            <Grid container spacing={2} style={{ marginTop: "1rem" }}>
              { vendor !== null &&
                <Fragment>
                  <Grid item xs={12} md={6}>
                    <FormControl className={classes.formControl} size="small" variant="outlined">
                      <label htmlFor="isActive" className={classes.label}>
                        Update Vendor's Active Status
                      </label>
                      <Switch
                        id="isActive"
                        checked={values.isActive}
                        disabled={statusSubmitting}
                        onChange={updateVendorStatus}
                      />
                    </FormControl>
                  </Grid>
                </Fragment>
              }
            </Grid>
          </Form>
        )}
      </Formik>
    );
  
    /////////////////**********MODAL VIEWS *****************////////////////
  
    const getDialogContent = () => {
      switch (dialogContentIndex) {
        case 1:
          return filterForm();
  
        case 2:
          return viewvendor();
  
        case 3:
          return createvendorForm();
  
        default:
          return "Nothing";
      }
    };
  
    const dialogContent = () => (
      <Box style={{ width: "100%" }} className={classes.paper}>
        <Box className={classes.dialogTitle}>
          <Typography variant="h4">
            {dialogContentIndex === 1
              ? "Filter"
              : dialogContentIndex === 2
              ? "Vendor Details"
              : vendor !== null
              ? "Edit Vendor"
              : "Add Vendor"}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
  
        <Box className={classes.dialogContent}>
          <hr />
          <Box marginTop={"1rem"}>{getDialogContent()}</Box>
        </Box>
      </Box>
    );
  
    return (
      <Box>
        <Box className={classes.headerTexts}>
          <Typography variant="h4">Vendor Management</Typography>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => {
              setVendor(null);
              triggerModalOpen(3);
            }}
          >
            Add Vendor
          </Button>
        </Box>
        <Box style={{ border: "1px solid #D7DCE0" }} marginTop={"1rem"}>
          <Paper borderRadius={"0"} padding={"2rem"}>
            <TableHead
              title="Total Vendors"
              searchItem={vendorName}
              onChange={handleVendorNameSearch}
              loading={loading}
              placeholder="Search By Vendor Name"
              totalSize={vendors.totalSize}
              onClick={() => triggerModalOpen(1)}
              handleInputChange={handleInputChange}
            />
  
            <Box marginTop={"2rem"}>
              {loading && !vendors.items ? (
                <p>Loading...</p>
              ) : (!loading && vendors.items) ||
                (loading && vendors.items) ? (
                <>
                  {/* {console.log(users.items)} */}
                  <Table
                    columns={columns}
                    data={vendors.items}
                    loading={loading}
                  />
                  <Grid container spacing={2} style={{ padding: "1rem 0.6rem" }}>
                    <Grid item xs={12} md={3}>
                      {vendors.items.length > 0 && (
                        <Typography>
                          Showing page {vendors.pageNumber} of{" "}
                          {Math.ceil(vendors?.totalSize / pageSize)}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Pagination
                        dataSize={vendors?.totalSize}
                        perPage={pageSize}
                        handlePageClick={handlePageClick}
                        forcePage={vendors.pageNumber}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <p>Loading...</p>
              )}
            </Box>
          </Paper>
        </Box>
        <>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={anchorOpen}
            onClick={handleMenuClose}
            PaperProps={{
              style: {
                width: "10ch",
              },
            }}
          >
            <MenuItem onClick={() => triggerModalOpen(2)}>View</MenuItem>
            <MenuItem onClick={() => triggerModalOpen(3)}>Edit</MenuItem>
          </Menu>
        </>
        <Dialog handleClose={handleClose} open={open} content={dialogContent()} />
      </Box>
    );
  };
  
  export default VendorsLayout;
  
  