import React, { useEffect } from "react";
import { ErrorMessage } from "formik";
import FormErrors from "../../../../../FormErrors";
import ControlButtons from "../../ControlButtons";
import { connect } from "formik";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
  FormControl,
  Box,
  TextField,
  Typography,
  Paper,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  moneyFormat,
  currencyToNumber,
} from "../../../../../../Actions/helpers";

import { useStyles } from "./style";

const LoanPolicy = (props) => {
  const classes = useStyles();

  const {
    formik: { getFieldProps, setFieldValue, setTouched },
    values,
  } = props;

  useEffect(() => {
    setTouched({});

    //eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      <Paper style={{ padding: "1rem 2rem 3rem", minHeight: '100vh' }}>
        <Box marginTop={3} marginBottom={1}>
          <Typography variant="h5">Irrevocable Mandate</Typography>
        </Box>

        <Box>
          <Typography>Having executed a Contract of Employment with my Employer (“Employment Contract”) and also having obtained an education financing loan via the Bursery Platform, I hereby voluntarily and irrevocably mandate the Human Resources team of my Employer to deduct the Monthly Repayment Installment from my salary, on a monthly basis, for the tenure of my Loan until my debt is fully repaid.</Typography>
        </Box>

        <Box marginTop={3} marginBottom={1}>
          <Typography variant="h5">Termination of Employment</Typography>
        </Box>

        <Box>
          <Typography>Should my Employment Contract become terminated for any reason, including voluntary resignation, before the Deduction Completion Date,  I hereby voluntarily and irrevocably mandate the Human Resources team of my Employer to deduct as much of my salary or any other financial benefits or terminal/termination benefits or entitlements as is required to fully defray any outstanding loan sum and interest (even if same shall exceed the Monthly Repayment Instalment) and I further undertake that I shall immediately pay up all of the unpaid outstanding loan sum and interest until my debt is fully repaid.</Typography>
        </Box>
      </Paper>
    </div>
  );
};

export default connect(LoanPolicy);

// TO BE Done
// Date picker format
// File container size
