import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
   root: {},

   container: {
    width: "50%",
       margin: "2rem auto 5rem",
       [theme.breakpoints.down('md')]: {
        width: "60%"
       },
       [theme.breakpoints.down('sm')]: {
        width: "90%"
    }
   },
   title: {
       fontWeight: "500",
       fontSize: "18px",
       color: theme.palette.primary.main
   },
   fieldWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    // '& > * ' : {
    //     minWidth: '100%',
    //     margin: theme.spacing(0,0,2,0),

    //     [theme.breakpoints.up('sm')]: {
    //         minWidth: '47%',
    //         margin: theme.spacing(0,2,2,0),
        // }

    // }
},
dropZone: {
    height: "2%!important"
},
   intro: {
      marginBottom: "1rem",
       display:"flex",
      justifyContent:"space-between",
       alignItems: "center"
   },

   formControl: {
    minWidth: `48%`,
    margin: theme.spacing(0.5,0),
         [theme.breakpoints.down('xs')]: {
            minWidth: '100%',
            // margin: theme.spacing(0.5,0),
        }
    // margin: theme.spacing(1, 0),
  },
  form: {
    width: "100%",
  },
  label: {
    fontSize: "13px",
    fontWeight: "400",
    marginBottom: ".1rem",
  },
  select: {
      height: '2.7rem'
  }

}))

export { useStyles }