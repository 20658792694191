import React from 'react'
import ProviderSelectLayout from '../../Components/Layout/UsersLayouts/ProviderSelectLayout'

const ProviderSelect = () => {
    return (
        <div>
            {/* <Box marginBottom="2rem">
                <Typography variant="h3">Users</Typography>
            </Box> */}
            <ProviderSelectLayout/> 
        </div>
    )
}

export default ProviderSelect
