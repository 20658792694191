import React, {useState } from 'react'
import { CSVLink } from "react-csv"
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Button from '../Button'
import { useStyles } from './style'
import api from '../../Services/axiosAdmin'
import { FormControl, TextField, Typography, FormHelperText } from '@material-ui/core'

const FormExport = props => {
    const classes = useStyles()
    const csvLink = React.useRef()

    const [isSuccessful, setIsSuccessful] = useState()
    const [data, setData] = useState([])

    const exportData = () => {
        // click the CSVLink component to trigger the CSV download
            csvLink.current.link.click()
    }


    const initialValues = {
        startDate: '',
        endDate: ''
    }

    const validationSchema = Yup.object({
        startDate: Yup.string(),
        endDate: Yup.string()
    })

    const onSubmit = async (values) => {
        
        try {
            const res = await api.get(`Export/export?startDate=${values.startDate}&endDate=${values.endDate}`,)

            console.log(res);
            if( res.data.requestSuccessful === true ) {
                console.log(res.data.responseData);
                setData(res.data.responseData)
                setIsSuccessful({
                    status: true,
                    message: res.data.message
                })
                exportData()
            } else {
                setIsSuccessful({
                    status: false,
                    message: res.data.message
                })
            }

        } catch (error){
            setIsSuccessful({
                status: false,
            })
        }
    }

        
    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({values, errors, touched, getFieldProps, isSubmitting}) => (
                    <Form noValidate autoComplete="off">
                        <Typography variant="h6" align="center" gutterBottom>
                            Export Data
                        </Typography>
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="startDate"
                                name="startDate"
                                label="Start Date"
                                type="date"
                                { ...getFieldProps('startDate')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                error={errors.startDate && touched.startDate ? true : false}
                                helperText={ errors.startDate && touched.startDate ?
                                    errors.startDate : null
                                }
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                id="endDate"
                                name="endDate"
                                label="End Date"
                                type="date"
                                { ...getFieldProps('endDate')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                error={errors.endDate && touched.endDate ? true : false}
                                helperText={ errors.endDate && touched.endDate ?
                                    errors.endDate : null
                                }
                            />
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <Button 
                            variant="contained" 
                            color="primary" 
                            type="submit"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            >
                                Export
                            </Button>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <FormHelperText 
                            style={{ textAlign: 'center' }} 
                            error={isSuccessful?.status === true ? false : true}
                            >
                                {isSuccessful?.status === false ? isSuccessful.message ? isSuccessful.message : 'an error occured' : null}
                                {isSuccessful?.status === true ? 'Exported!' : null}
                            </FormHelperText>
                        </FormControl>


                        
                    </Form>

                )}
            </Formik>

            {/* <Hidden> */}
            {/* {data && ( */}
                <CSVLink
                    data={data}
                    filename="loan-requests.csv"
                    ref={csvLink}
                    className={classes.hidden}
                    target="_blank" 
                />
                
            {/* )} */}
            {/* </Hidden> */}
        </div>
    )
}

FormExport.propTypes = {

}

export default FormExport
