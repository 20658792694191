import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    drawer: {
        width: 240,
        backgroundColor: "#0F42A4!important",
        // padding: theme.spacing(2, 0)
        // [theme.breakpoints.up('lg')]: {
        //     marginTop: 64,
        //     height: 'calc(100% - 64px)'
        // }
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    divider: {
        margin: theme.spacing(2, 0)
    },
    nav: {
        marginBottom: theme.spacing(2),
        textAlign: 'center'
    }
}))

export { useStyles }