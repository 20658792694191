import React from "react";
import { Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import Paper from "../../../Paper";
import logo from "../../../../Assets/images/Educollect-logo-dark.png";
import { useStyles } from "./style";

const AuthLayout = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box align="center" margin={props.custom ? "2rem 0 1rem" : "4rem 0 3rem"}>
        <img src={logo} width="38%" height="38%" alt="" />
      </Box>
      <Paper borderRadius={"0"} padding={"2rem 3rem 3rem"}>
        {props.children}
      </Paper>
      {!props.custom && (
        <Typography style={{ marginTop: "1rem" }} align="center">
          © {new Date().getFullYear()} Bursery - All Rights Reserved.
        </Typography>
      )}
    </Box>
  );
};

export default AuthLayout;

AuthLayout.propTypes = {
  custom: PropTypes.bool,
};
