import React, { useEffect, useContext, useState } from "react";
import { useStyles } from "./style";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import Spinner from "../../../../Reusables/Spinner";
import { Formik, Form, ErrorMessage } from "formik";
import FormErrors from "../../../../FormErrors";
import moment from "moment";
import * as Yup from "yup";
import Button from "../../../../Button";
import Dialog from "../../../../Reusables/Dialog";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
// import FileImage from "../../../../../Assets/images/Verification.png";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/styles";
import {
  Paper,
  Container,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  useMediaQuery,
  Typography,
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem,
  Select,
} from "@material-ui/core";

import CredpalContext from "../../../../../Contexts/credpal/credpalContext";
import LoanContext from "../../../../../Contexts/loan/loanContext";
import { moneyFormat } from "../../../../../Actions/helpers";

const CredPalView = (props) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");

  const handleOpen = (value) => {
    setStatus(value);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const credPalProps = useContext(CredpalContext);

  const { approveOrDisburseLoan, declineLoan, isSubmitting, currentProvider, providers } =
    useContext(LoanContext);

  const { getLoanByRef, getBanks, currentLoan, gettingLoan, banks, updateLoanProvider } =
    credPalProps;

  const { allowReview, requestDocument, bankName } = currentProvider || {};

  const {
    regNo,
    firstName,
    lastName,
    dateOfBirth,
    gender,
    email,
    phoneNumber,
    address,
    state,
    city,
    identificationType,
    idNumber,
    sponsorInfo,
    nextOfKin,
    guarantors,
    bankAccount,
    employment,
    loanRequest,
    institutionInfo,
    documents,
    advanclyLoanRef,
    providerName,
    providerCode,
    disburseDate,
    dateCreated
  } = currentLoan || {};

  const location = useLocation();
  const route = useHistory()

  const { userId, loanRef } = queryString.parse(location.search);
  const [providerValue, setProviderValue] = useState(providerCode);

  const declineValues = {
    loanRef,
    message: "",
  };

  console.log("The providers are: ", providers);

  const declineValidation = Yup.object({
    message: Yup.string().required("Message is empty"),
  });

  const submitForDecline = (values) => {
    declineLoan(values);
  };

  useEffect(() => {
    !banks.length && getBanks();
    if (loanRef && userId) {
      getLoanByRef(loanRef, userId);
    }
  }, []);

  const getDocumentName = (name) => {
    switch (name) {
      case "studentId":
        return "Student ID Card";

      case "employeeID":
        return "Employee ID";

      case "bankStatement":
        return "Bank Statement";

      case "id":
        return "Applicant ID";

      case "employmentLetter":
        return "Employment Letter";

      default:
        return "Document";
    }
  };

  const handleProviderUpdate = async () => {
    const providerId = providers.find((provider) => provider.sortCode === providerValue)?.id || "";
    const result = await updateLoanProvider({
      loanRef,
      providerId
    })

    if(result.error) {
      console.log("failure", result.error);
    } else if(result.requestSuccessful) {
      route.push('/dashboard');
    }
  }

  //   content for dialog --- show either form for declining or approval CTA
  const content =
    status !== "DECLINED" ? (
      <Box
        style={{ width: isMobile ? "100%" : "30rem", padding: "0 0.4rem 1rem" }}
      >
        <DialogContent>
          <DialogContentText tabIndex={-1}>
            <Typography variant="body2">
              {status === "APPROVED"
                ? "Are you sure you want to submit loan for Approval?"
                : "Are you sure this loan has been disbursed?"}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <Box marginTop={2}>
          <DialogActions>
            <Typography
              style={{ marginRight: "1rem", cursor: "pointer" }}
              color="primary"
              onClick={handleClose}
            >
              Cancel
            </Typography>

            <Button
              variant="contained"
              onClick={() => approveOrDisburseLoan(loanRef, status)}
              disabled={isSubmitting}
              loading={isSubmitting}
              size="medium"
              type="submit"
              color="primary"
            >
              Yes, {status === "APPROVED" ? "Submit" : "It has"}
            </Button>
          </DialogActions>
        </Box>
      </Box>
    ) : (
      <Box style={{ width: isMobile ? "100%" : "30rem" }}>
        <DialogContent>
          <DialogContentText
          //   tabIndex={-1}
          >
            <Formik
              initialValues={declineValues}
              validationSchema={declineValidation}
              onSubmit={submitForDecline}
            >
              {({ getFieldProps }) => (
                <Form noValidate autoComplete="off">
                  <Box>
                    <FormControl className={classes.formControl}>
                      <Typography gutterBottom variant="body2">
                        Reason for declining
                      </Typography>
                      <TextField
                        name="message"
                        id="message"
                        fullWidth
                        multiline
                        // placeholder="Enter remark here"
                        rows={7}
                        {...getFieldProps("message")}
                        variant="outlined"
                        size="small"
                      />
                      <ErrorMessage name="message" component={FormErrors} />
                    </FormControl>
                  </Box>

                  <Box marginTop={2}>
                    <DialogActions>
                      <Typography
                        style={{ marginRight: "1rem", cursor: "pointer" }}
                        color="primary"
                        onClick={handleClose}
                      >
                        Cancel
                      </Typography>

                      <Button
                        variant="contained"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        size={"medium"}
                        type="submit"
                        color="primary"
                      >
                        Decline
                      </Button>
                    </DialogActions>
                  </Box>
                </Form>
              )}
            </Formik>
          </DialogContentText>
        </DialogContent>
      </Box>
    );

  //ACTUAL RENDER
  return gettingLoan || !currentLoan ? (
    <Spinner />
  ) : (
    <Container className={classes.root}>
      <Box className={classes.header}>
        <Link to="/dashboard" className={classes.goBack}>
          <KeyboardArrowLeftIcon />
        </Link>

        <Typography variant="h2">User Details</Typography>

        {allowReview && (
          <Box style={{ marginLeft: "auto" }}>
            <Button
              variant="outlined"
              size="medium"
              color="primary"
              linkPage={`/loan/edit?loanRef=${loanRef}&userId=${userId}`}
            >
              Edit Application
            </Button>
          </Box>
        )}
      </Box>

      <Paper style={{ padding: "2rem 1.5rem 3rem" }}>
        <Typography variant="h4">Loan Details</Typography>
        <Box className={classes.details}>
          <Box>
            <Typography gutterBottom variant="subtitle2">
              Loan Amount
            </Typography>
            <Typography variant="body1">
              NGN {moneyFormat(loanRequest?.loanAmount)}
            </Typography>
          </Box>

          <Box>
            <Typography gutterBottom variant="subtitle2">
              Loan Tenure
            </Typography>
            <Typography variant="body1">{loanRequest?.tenure}</Typography>
          </Box>

          <Box>
            <Typography gutterBottom variant="subtitle2">
              Sponsor Type
            </Typography>
            <Typography variant="body1">
              {loanRequest?.isSelfSponsored ? "Self-Sponsored" : "Guardian"}
            </Typography>
          </Box>

          <Box>
            <Typography gutterBottom variant="subtitle2">
              Loan Reference
            </Typography>
            <Typography variant="body1">{loanRef}</Typography>
          </Box>

          {bankName === "Advancly" && (
            <Box>
              <Typography gutterBottom variant="subtitle2">
                Advancly's Loan Reference
              </Typography>
              <Typography variant="body1">{advanclyLoanRef}</Typography>
            </Box>
          )}
          <Box>
            <Typography gutterBottom variant="subtitle2">
              Status
            </Typography>
            <Typography variant="body1">
              {loanRequest.status === "APPROVED"
                ? "PENDING APPROVAL"
                : loanRequest?.status}
            </Typography>
          </Box>

          {
            dateCreated &&
            <Box>
              <Typography gutterBottom variant="subtitle2">
                Application Date
              </Typography>
              <Typography variant="body1">
                {new Date(dateCreated).toDateString()}
              </Typography>
            </Box>
          }

          {
            (loanRequest?.status === "DISBURSED" && disburseDate) &&
            <Box>
              <Typography gutterBottom variant="subtitle2">
                Disburse Date
              </Typography>
              <Typography variant="body1">
                {new Date(disburseDate).toDateString()}
              </Typography>
            </Box>
          }

          <Box>
              <Typography gutterBottom variant="subtitle2">
                Provider Name
              </Typography>
              <Typography variant="body1">{providerName ? providerName : "N/A"}</Typography>
            </Box>
          {/* <Box>
            <Typography gutterBottom variant="subtitle2">
              Agreement Status
            </Typography>
            <Typography variant="body1">
              {loanRequest?.isAcceptedAgreement ? "Accepted" : "Not Accepted"}
            </Typography>
          </Box> */}
        </Box>

        <Box className={classes.other}>
          <Typography variant="h4">Student Details</Typography>
          <Box className={classes.details}>
            <Box>
              <Typography gutterBottom variant="subtitle2">
                First Name
              </Typography>
              <Typography variant="body1">{firstName}</Typography>
            </Box>
            <Box>
              <Typography gutterBottom variant="subtitle2">
                Last Name
              </Typography>
              <Typography variant="body1">{lastName}</Typography>
            </Box>

            <Box>
              <Typography gutterBottom variant="subtitle2">
                Gender
              </Typography>
              <Typography variant="body1">
                {gender === "M" ? "Male" : gender === "F" ? "Female" : ""}
              </Typography>
            </Box>

            <Box>
              <Typography gutterBottom variant="subtitle2">
                Date Of Birth
              </Typography>
              <Typography variant="body1">
                {moment(dateOfBirth).format("MMM Do, YYYY")}
              </Typography>
            </Box>

            <Box>
              <Typography gutterBottom variant="subtitle2">
                Email
              </Typography>
              <Typography variant="body1">{email}</Typography>
            </Box>

            <Box>
              <Typography gutterBottom variant="subtitle2">
                Phone Number
              </Typography>
              <Typography variant="body1">{phoneNumber}</Typography>
            </Box>

            <Box>
              <Typography gutterBottom variant="subtitle2">
                Residential address
              </Typography>
              <Typography variant="body1">{address}</Typography>
            </Box>

            <Box>
              <Typography gutterBottom variant="subtitle2">
                State
              </Typography>
              <Typography variant="body1">{state}</Typography>
            </Box>

            <Box>
              <Typography gutterBottom variant="subtitle2">
                City
              </Typography>
              <Typography variant="body1">{city}</Typography>
            </Box>

            <Box>
              <Typography gutterBottom variant="subtitle2">
                Registration Number
              </Typography>
              <Typography variant="body1">{regNo}</Typography>
            </Box>
          </Box>
        </Box>

        {sponsorInfo && (
          <Box className={classes.other}>
            <Typography variant="h4">Sponsor Details</Typography>
            <Box className={classes.details}>
              <Box>
                <Typography gutterBottom variant="subtitle2">
                  First Name
                </Typography>
                <Typography variant="body1">
                  {sponsorInfo?.firstName}
                </Typography>
              </Box>

              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Last Name
                </Typography>
                <Typography variant="body1">{sponsorInfo?.lastName}</Typography>
              </Box>

              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Gender
                </Typography>
                <Typography variant="body1">
                  {sponsorInfo?.gender === "M"
                    ? "Male"
                    : gender === "F"
                    ? "Female"
                    : ""}
                </Typography>
              </Box>

              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Date Of Birth
                </Typography>
                <Typography variant="body1">
                  {moment(sponsorInfo?.dateOfBirth).format("MMM Do, YYYY")}
                </Typography>
              </Box>

              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Phone Number
                </Typography>
                <Typography variant="body1">
                  {sponsorInfo?.phoneNumber}
                </Typography>
              </Box>

              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Email
                </Typography>
                <Typography variant="body1">{sponsorInfo?.email}</Typography>
              </Box>
              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Relationship
                </Typography>
                <Typography variant="body1">
                  {sponsorInfo?.relationship}
                </Typography>
              </Box>
              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Residential address
                </Typography>
                <Typography variant="body1">{sponsorInfo?.address}</Typography>
              </Box>

              <Box>
                <Typography gutterBottom variant="subtitle2">
                  State
                </Typography>
                <Typography variant="body1">{sponsorInfo?.state}</Typography>
              </Box>

              <Box>
                <Typography gutterBottom variant="subtitle2">
                  City
                </Typography>
                <Typography variant="body1">{sponsorInfo?.city}</Typography>
              </Box>
              <Box>
                <Typography gutterBottom variant="subtitle2">
                  NIN
                </Typography>
                <Typography variant="body1">{sponsorInfo?.nin}</Typography>
              </Box>
              {requestDocument && (
                <>
                  <Box>
                    <Typography gutterBottom variant="subtitle2">
                      ID Type
                    </Typography>
                    <Typography variant="body1">
                      {identificationType}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography gutterBottom variant="subtitle2">
                      ID Number
                    </Typography>
                    <Typography variant="body1">{idNumber}</Typography>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        )}

        {nextOfKin && (
          <Box className={classes.other}>
            <Typography variant="h4">Next of Kin Details</Typography>
            <Box className={classes.details}>
              <Box>
                <Typography gutterBottom variant="subtitle2">
                  First Name
                </Typography>
                <Typography variant="body1">
                  {nextOfKin.firstName}
                </Typography>
              </Box>

              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Last Name
                </Typography>
                <Typography variant="body1">{nextOfKin.lastName}</Typography>
              </Box>

              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Address
                </Typography>
                <Typography variant="body1">
                  {nextOfKin.address}
                </Typography>
              </Box>

              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Phone Number
                </Typography>
                <Typography variant="body1">
                  {nextOfKin?.phoneNumber}
                </Typography>
              </Box>

              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Relationship
                </Typography>
                <Typography variant="body1">
                  {nextOfKin?.relationship}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        {bankAccount && (
          <Box className={classes.other}>
            <Typography variant="h4">Bank Details</Typography>
            <Box className={classes.details}>
              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Bank Name
                </Typography>
                <Typography variant="body1">
                  {
                    banks.find((bank) => bank.id === bankAccount.bankId)
                      .bankName
                  }
                </Typography>
              </Box>
              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Account Number
                </Typography>
                <Typography variant="body1">
                  {bankAccount?.accountNumber}
                </Typography>
              </Box>
              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Account Name
                </Typography>
                <Typography variant="body1">
                  {bankAccount?.accountName}
                </Typography>
              </Box>
              <Box>
                <Typography gutterBottom variant="subtitle2">
                  BVN
                </Typography>
                <Typography variant="body1">{bankAccount?.bvn}</Typography>
              </Box>
            </Box>
          </Box>
        )}

        {guarantors?.length && (
          <Box className={classes.other}>
            <Typography variant="h4" style={{ marginBottom: "1.5rem" }}>
              Guarantor(s) Details
            </Typography>
            <Box>
              {guarantors.map(
                ({ firstName, lastName, phoneNumber, relationship }, i) => {
                  return (
                    <Box key={i}>
                      <Typography variant="body2">Guarantor {i + 1}</Typography>
                      <Box className={classes.details}>
                        <Box>
                          <Typography gutterBottom variant="subtitle2">
                            First Name
                          </Typography>
                          <Typography variant="body1">{firstName}</Typography>
                        </Box>
                        <Box>
                          <Typography gutterBottom variant="subtitle2">
                            Last Name
                          </Typography>
                          <Typography variant="body1">{lastName}</Typography>
                        </Box>
                        <Box>
                          <Typography gutterBottom variant="subtitle2">
                            Phone Number
                          </Typography>
                          <Typography variant="body1">{phoneNumber}</Typography>
                        </Box>
                        <Box>
                          <Typography gutterBottom variant="subtitle2">
                            Relationship
                          </Typography>
                          <Typography variant="body1">
                            {relationship}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                }
              )}
            </Box>
          </Box>
        )}

        {employment && (
          <Box className={classes.other}>
            <Typography variant="h4">Employment Details</Typography>
            <Box className={classes.details}>
              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Employer Name
                </Typography>
                <Typography variant="body1">
                  {employment.employerName}
                </Typography>
              </Box>

              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Employment Status
                </Typography>
                <Typography variant="body1">
                  {employment.employmentStatus}
                </Typography>
              </Box>
              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Employer's Address
                </Typography>
                <Typography variant="body1">
                  {employment.employerAddress}
                </Typography>
              </Box>

              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Work Place Email
                </Typography>
                <Typography variant="body1">
                  {employment.workPlaceEmail}
                </Typography>
              </Box>
              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Salary Amount
                </Typography>
                <Typography variant="body1">
                  {" "}
                  NGN {moneyFormat(employment.salaryAmount)}
                </Typography>
              </Box>
              <Box>
                <Typography gutterBottom variant="subtitle2">
                  Salary Due Date
                </Typography>
                <Typography variant="body1">
                  {moment(employment.salaryDueDate).format("MMM Do, YYYY")}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        {institutionInfo && (
          <Box className={classes.other}>
            <Typography variant="h4">Institution Details</Typography>
            <Box className={classes.details}>
              { institutionInfo.name &&
                <Box>
                  <Typography gutterBottom variant="subtitle2">
                    Institution Name
                  </Typography>
                  <Typography variant="body1">{institutionInfo.name}</Typography>
                </Box>
              }

              { institutionInfo.bankName &&
                <Box>
                  <Typography gutterBottom variant="subtitle2">
                    Bank Name
                  </Typography>
                  <Typography variant="body1">{institutionInfo.bankName}</Typography>
                </Box>
              }
              
              { institutionInfo.bankAccountNo &&
                <Box>
                  <Typography gutterBottom variant="subtitle2">
                    Bank Account Number
                  </Typography>
                  <Typography variant="body1">{institutionInfo.bankAccountNo}</Typography>
                </Box>
              }

              { institutionInfo.bankAccountName &&
                <Box>
                  <Typography gutterBottom variant="subtitle2">
                    Institution Bank Name
                  </Typography>
                  <Typography variant="body1">{institutionInfo.bankAccountName}</Typography>
                </Box>
              }
            </Box>
          </Box>
        )}

        {requestDocument && (
          <Box className={classes.other}>
            <Typography variant="h4">Uploaded Documents</Typography>
            {documents?.length ? (
              <Box className={classes.documents}>
                {documents.map(({ uploadUrl, key, documentType }) => (
                  <Box className={classes.fileContainer} key={key}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "0.6rem",
                      }}
                    >
                      <Typography>DOCUMENT NAME</Typography>
                      <a
                        href={uploadUrl}
                        target="_blank"
                        alt=""
                        rel="noopener noreferrer"
                      >
                        View
                      </a>
                    </Box>

                    <Box style={{ display: "flex", gap: "1rem" }}>
                      <Box className={classes.imageContainer}>
                        {/* <img
                        src={FileImage}
                        style={{ width: "80%", height: "80%" }}
                      /> */}
                        <InsertDriveFileIcon
                          fontSize="large"
                          style={{ fill: "#758590" }}
                        />
                      </Box>
                      <Typography display="inline">
                        {documentType.replace("_", " ")}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography style={{ marginTop: "2rem" }}>
                This Applicant has not uploaded any document yet
              </Typography>
            )}
          </Box>
        )}

        {
          providerCode &&
          <div >
            <Box className={classes.other}>
              <FormControl variant="outlined" size="small" fullWidth>
                <Typography gutterBottom variant="h5" style={{marginBottom : "20px"}}>
                  Update Loan Provider
                </Typography>
                <Select
                  style={{width : "300px"}}
                  defaultValue=""
                  labelId="provider"
                  id="provider"
                  name="provider"
                  value={providerValue || providerCode}
                  onChange={(e) => {
                    const {value} = e.target;
                    setProviderValue(value)
                  }}
                >
                  {
                    providers.filter((provider) => provider.status === "active").map((provider) => (
                      <MenuItem key={provider.id} value={provider.sortCode}>{provider.title}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Box>
            <Box
              marginTop={"2rem"}
              align="right"
              className={classes.statusCTA}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleProviderUpdate}
              >
                Update
              </Button>
            </Box>
          </div>
        }

        {loanRequest?.status && loanRequest?.status !== "INITIATED" && (
          <Box className={classes.other}>
            <Typography variant="h4">Set Status</Typography>
            <Formik
              initialValues={{
                status: loanRequest?.status ? loanRequest.status : "",
              }}
              validationSchema={Yup.object({
                status: Yup.string().required("Select a status"),
              })}
              onSubmit={(values) => handleOpen(values.status)}
              enableReinitialize
            >
              {({ values, getFieldProps }) => (
                <Form noValidate autoComplete="off">
                  <Box style={{ margin: "1rem 0 -1rem 0" }}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="status"
                        name="status"
                        value={values.status}
                        {...getFieldProps("status")}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <FormControlLabel
                          value="DECLINED"
                          control={<Radio />}
                          disabled={
                            loanRequest?.status === "DECLINED" || loanRequest?.status === "DISBURSED"
                          }
                          label="Declined"
                        />
                        <FormControlLabel
                          value="APPROVED"
                          control={<Radio />}
                          label="Approved"
                          disabled={
                            loanRequest?.status === "DISBURSED" || loanRequest?.status === "APPROVED"
                          }
                        />
                        <FormControlLabel
                          value="DISBURSED"
                          control={<Radio />}
                          disabled={loanRequest?.status !== "APPROVED" || loanRequest?.status === "DISBURSED"}
                          label="Disbursed"
                        />
                      </RadioGroup>
                      <ErrorMessage name="status" component={FormErrors} />
                    </FormControl>
                  </Box>
                  <Box
                    marginTop={"2rem"}
                    align="right"
                    className={classes.statusCTA}
                  >
                    <Link
                      to="/dashboard"
                      style={{ cursor: "pointer", textDecoration: "none" }}
                    >
                      <Typography variant="body2" color="primary">
                        Cancel
                      </Typography>
                    </Link>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={
                        ["DISBURSED"].includes(
                          loanRequest?.status
                        ) ||
                        !["DECLINED", "APPROVED", "DISBURSED"].includes(
                          values.status
                        )
                      }
                    >
                      Submit
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        )}
        <Dialog handleClose={handleClose} open={open} content={content} />
      </Paper>
    </Container>
  );
};

export default CredPalView;
