//all reusable functions would be declared here`

import moment from "moment";
import { EnvVariables } from "../env/Env";

export const capitalizeFirstLetter = (text) =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const formatDateTime = (date) =>
  moment(date).format("MMM Do, YYYY | h:mm a");

  export const formatDataId = (id) =>
  id.substr(id.length - 10).toUpperCase();

export const validateDates = (startDate, endDate) => {
  if (
    (startDate !== "" && endDate !== "" && startDate > endDate) ||
    (startDate === "" && endDate !== "") ||
    (startDate !== "" && endDate === "")
  ) {
    return false;
  } else {
    return true;
  }
};

export const envURL = window.location.hostname.includes("localhost")
? EnvVariables.ENV_TEST_URL
: window.location.hostname.includes("educollect") 
? "http://educollectwebsitetest.azurewebsites.net"
: "https://bursery.com"


// export const envURL = process.env.envURL;

  
export const compareArrays = (array1, array2) => {
  console.log(array1);
  console.log(array2);
  if (!Array.isArray(array1) || !Array.isArray(array2)) {
    return false;
  }

  if (array1.length !== array2.length) {
    return false;
  }

  for (var i = 0, l = array1.length; i < l; i++) {
    if (array1[i] instanceof Array && array2[i] instanceof Array) {
      if (!array1[i].equals(array2[i])) return false;
    } else if (array1[i] !== array2[i]) {
      return false;
    }
  }
  return true;
};


export const moneyFormat = (num) => {
  if (!num) return;
  num = num.toString();
  let kobo = num.split(".")[1];
  if (kobo ) {
    if (kobo.length === 1) {
      kobo = `${kobo[0]}`;
    } else {
      kobo = `${kobo[0]}${kobo[1]}`;
    }

    if(!(/^[0-9]*$/i).test(kobo)) {
      kobo = ""
    }

    num = num.split(".")[0].replace(/,/g, "");
    return `${num.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${kobo}`;
  } else {
    num = num.replace(/,/g, "");

    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const currencyToNumber = (amount, stringFormat) => {
  if (!amount) return "";
  //ensure all commas are striped from the amount
  amount = String(amount).replace(/,/g, "");
  // return Number(amount);
  return !stringFormat ? Number(amount) : amount;
};

// export const moneyFormat = number => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// Array.prototype.equals = function (array) {
//   // if the other array is a falsy value, return
//   if (!array)
//       return false;

//   // compare lengths - can save a lot of time
//   if (this.length != array.length)
//       return false;

//   for (var i = 0, l=this.length; i < l; i++) {
//       // Check if we have nested arrays
//       if (this[i] instanceof Array && array[i] instanceof Array) {
//           // recurse into the nested arrays
//           if (!this[i].equals(array[i]))
//               return false;
//       }
//       else if (this[i] != array[i]) {
//           // Warning - two different object instances will never be equal: {x:20} != {x:20}
//           return false;
//       }
//   }
//   return true;
// }
