import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Box,
  FormControl,
  Grid,
  TextField,
  useMediaQuery,
  FormControlLabel,
  RadioGroup,
  Radio,
  Switch,
  Paper,
  Select,
  MenuItem
} from "@material-ui/core";
import { useParams } from "react-router";
import { Formik, Form, ErrorMessage, Field, FieldArray } from "formik";
import { DropzoneArea } from "material-ui-dropzone";
import { useTheme } from "@material-ui/styles";
import FormErrors from "../../../FormErrors";
import * as Yup from "yup";
import Button from "../../../Button";
import { useStyles } from "./style";
import alertContext from "../../../../Contexts/alert/alertContext";
import Spinner from "../../../Reusables/Spinner";
import StatusIndicator from "../../../Reusables/StatusIndicator";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ProviderConfigLayout from "./ProviderConfigLayout";
import ProviderSettingsLayout from "./ProviderSettingsLayout";

import LoanContext from "../../../../Contexts/loan/loanContext";

const ProviderLayout = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [providerConfig, setProviderConfig] = useState(null)

  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const { id } = useParams();

  const { setAlert } = useContext(alertContext);

  const {
    isSubmitting,
    createProvider,
    clearProvider,
    provider,
    getProvider,
    isLoading,
    getProvidersSettings,
    getProvidersConfig,
    pSettings,
    availableLoanTypes,
    getLoanTypes
  } = useContext(LoanContext);

  const providerId = id ? id : null;

  const getData = async () => {
    getProvidersSettings();
    getProvider(id);
    const configurations = await getProvidersConfig();
    setProviderConfig(configurations.find(config => config.providerId === providerId))
  }

  useEffect(() => {
    getLoanTypes();
    if (id) {
      getData();
    }

    //component will unmount, clear the current provider state
    return () => {
      clearProvider();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    bankName: provider ? provider.bankName : "",
    sortCode: provider ? provider.sortCode : "",
    baseApiUrl: provider ? provider.baseApiUrl : "",
    title: provider ? provider.title : "",
    maximumLoan: provider ? provider.maximumLoan : "",
    minimumLoan: provider ? provider.minimumLoan : "",
    tenor: provider ? provider.tenor : "",
    status: provider ? provider.status : "",
    isAutoIntegration: provider ? provider.isAutoIntegration : false,
    requestDocument: provider?.requestDocument
      ? provider.requestDocument
      : false,
    borrowerTypes: provider?.borrowerTypes ? provider.borrowerTypes : [],
    allowReview: provider ? provider.allowReview : false,
    logo: undefined,
    availableLoanTypeId : provider?.availableLoanTypeId ? provider.availableLoanTypeId : null,
    loanTypeName : null,
  };

  const validationSchema = Yup.object({
    bankName: Yup.string()
      .min(3, "Minimum of 3 characters")
      .required("Provider Name is empty"),
    sortCode: Yup.string().required("sort code is empty"),
    baseApiUrl: Yup.string()
      .url("Invalid url format e.g (https://www.abc.com)")
      .required("Url is empty"),
    title: Yup.string().required("Title is empty"),
    maximumLoan: Yup.string()
      .matches(/^[0-9]*$/, "Only numbers allowed")
      .required("maximum Amount is empty"),
    minimumLoan: Yup.string()
      .matches(/^[0-9]*$/, "Only numbers allowed")
      .required("minimum Amount is empty"),
    tenor: Yup.number().required("Loan tenor is empty"),
    status: Yup.string().required("Select a staus"),
    availableLoanTypeId: Yup.string().required("Select a loan type"),
    requestDocument: Yup.bool().required("Please select one"),
    // isAutoIntegration: Yup.bool().oneOf([true], "Automate?"),
    // allowReview: Yup.bool().oneOf([true], "Allow Review?"),
  });

  const onSubmit = (values) => {
    let passed = true;
    values.borrowerTypes.forEach((borrowerType) => {
      const { typeId, name } = borrowerType;
      if (typeId === "" || name === "") {
        passed = false;
        setAlert("Please fill all fields", "error");
      }
    });
    if (passed) createProvider(values, providerId);
  };

  let pSetting = null;
  if (id && !isLoading) {
    pSetting = pSettings.find((loanProvider) => loanProvider.providerId === id);
    pSetting = pSetting ? pSetting : null;
  }

  const render = () => (
    <Paper className={classes.root}>
      {/* PROVIDER INFORMATION */}
      <Box>
        <Typography variant="h5" gutterBottom>
          Provider Information
        </Typography>
        <Box marginTop={1}>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize
            validationSchema={validationSchema}
          >
            {({ values, getFieldProps, setFieldValue }) => (
              <Form noValidate autoComplete="off">
                <Grid container spacing={isMobile ? 1 : 3}>
                  <Grid item xs={12} sm={4}>
                    <FormControl className={classes.formControl}>
                      <Typography>Provider Name</Typography>
                      <TextField
                        name="bankName"
                        id="bankName"
                        {...getFieldProps("bankName")}
                        variant="outlined"
                        size="small"
                      />
                      <ErrorMessage name="bankName" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControl className={classes.formControl}>
                      <Typography>Sort code</Typography>
                      <TextField
                        name="sortCode"
                        id="sortCode"
                        {...getFieldProps("sortCode")}
                        variant="outlined"
                        size="small"
                      />
                      <ErrorMessage name="sortCode" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControl className={classes.formControl}>
                      <Typography>Url</Typography>
                      <TextField
                        name="baseApiUrl"
                        id="baseApiUrl"
                        {...getFieldProps("baseApiUrl")}
                        variant="outlined"
                        size="small"
                      />
                      <ErrorMessage name="baseApiUrl" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControl className={classes.formControl}>
                      <Typography>Title</Typography>
                      <TextField
                        name="title"
                        id="title"
                        {...getFieldProps("title")}
                        variant="outlined"
                        size="small"
                      />
                      <ErrorMessage name="title" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControl className={classes.formControl}>
                      <Typography>Max. Loan Amount (NGN)</Typography>
                      <TextField
                        name="maximumLoan"
                        id="maximumLoan"
                        {...getFieldProps("maximumLoan")}
                        variant="outlined"
                        size="small"
                      />
                      <ErrorMessage name="maximumLoan" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControl className={classes.formControl}>
                      <Typography>Min. Loan Amount (NGN)</Typography>
                      <TextField
                        name="minimumLoan"
                        id="minimumLoan"
                        {...getFieldProps("minimumLoan")}
                        variant="outlined"
                        size="small"
                      />
                      <ErrorMessage name="minimumLoan" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControl className={classes.formControl}>
                      <Typography>Loan tenor</Typography>
                      <TextField
                        name="tenor"
                        id="tenor"
                        type="number"
                        className={classes.numberInput}
                        {...getFieldProps("tenor")}
                        variant="outlined"
                        size="small"
                      />
                      <ErrorMessage name="tenor" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControl className={classes.formControl}>
                      <Typography>Application Type</Typography>
                      <Select
                        className={classes.select}
                        defaultValue=""
                        name="applicationType"
                        id="applicationType"
                        value={values.availableLoanTypeId}
                        onChange={(e) => {
                          const {name, value} = e.target;
                          setFieldValue("availableLoanTypeId", value);
                          setFieldValue("loanTypeName", name);
                        }}
                        variant="outlined"
                        size="small"
                      >
                        {availableLoanTypes?.map((loanType, key) => (
                          <MenuItem key={key} value={loanType.id} name={loanType.name}>
                            {loanType.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <ErrorMessage name="availableLoanTypeId" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControl className={classes.formControl}>
                      <Typography>Status</Typography>
                      <RadioGroup
                        className={classes.radioGroup}
                        row
                        aria-label="status"
                        name="status"
                        {...getFieldProps("status")}
                      >
                        <FormControlLabel
                          value="active"
                          control={<Radio color="primary" />}
                          label={
                            <Typography variant="body2">Active</Typography>
                          }
                        />
                        <FormControlLabel
                          value="inactive"
                          control={<Radio color="primary" />}
                          label={
                            <Typography variant="body2">Inactive</Typography>
                          }
                        />
                      </RadioGroup>
                      <ErrorMessage name="status" component={FormErrors} />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Typography>Automate?</Typography>
                        <FormControlLabel
                          control={
                            <Field
                              component={Switch}
                              id="isAutoIntegration"
                              type="checkbox"
                              checked={values.isAutoIntegration}
                              // disabled={values.allowReview}
                              name="isAutoIntegration"
                              {...getFieldProps("isAutoIntegration")}
                              color="primary"
                            />
                          }
                          // label="Automate?"
                        />
                        <ErrorMessage
                          name="isAutoIntegration"
                          component={FormErrors}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Typography>Allow Reviews</Typography>
                        <FormControlLabel
                          control={
                            <Field
                              component={Switch}
                              type="checkbox"
                              id="allowReview"
                              checked={values.allowReview}
                              // disabled={values.isAutoIntegration}
                              name="allowReview"
                              {...getFieldProps("allowReview")}
                              color="primary"
                            />
                          }
                          // label="Automate?"
                        />
                        <ErrorMessage
                          name="allowReview"
                          component={FormErrors}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={4} mt={4}>
                    <FormControl className={classes.formControl}>
                      <Typography>
                        Does provider require loan documents?{" "}
                      </Typography>
                      <RadioGroup
                        value={values.requestDocument}
                        onChange={(e) => {
                          const val = e.target.value;
                          setFieldValue(
                            "requestDocument",
                            val === "true" ? true : false
                          ); //this was important, because internally, the boolean values were being converted to strings
                        }}
                        className={classes.radioGroup}
                        row
                        aria-label="requestDoucment"
                        name="requestDocument"
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio color="primary" />}
                          label={<Typography variant="body2">Yes</Typography>}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio color="primary" />}
                          label={<Typography variant="body2">No</Typography>}
                        />
                      </RadioGroup>
                      <ErrorMessage
                        name="requestDocument"
                        component={FormErrors}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Box marginTop={2}>
                  <Grid container spacing={isMobile ? 1 : 3}>
                    <Grid item xs={12} sm={4}>
                      <FormControl
                        component="fieldset"
                        style={{ width: "100%", marginTop: "2rem" }}
                      >
                        <DropzoneArea
                          acceptedFiles={["image/*"]}
                          dropzoneText={"Upload Provider Logo here"}
                          maxFileSize={500000}
                          showPreviewsInDropzone
                          useChipsForPreview
                          showFileNames
                          showFileNamesInPreview
                          getDropRejectMessage={() =>
                            "File should be the required format and not more than 0.5MB"
                          }
                          filesLimit={1}
                          onChange={(file) => setFieldValue("logo", file[0])}
                        />
                        <div className={classes.uploadInfo}>
                          <Typography variant="caption">
                            Files Supported: PNG, Jpeg
                          </Typography>
                          <Typography variant="caption">
                            Maximum Size: 0.5MB
                          </Typography>
                        </div>
                        <ErrorMessage name="logo" component={FormErrors} />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      {provider && provider.logoUrl && (
                        <Box
                          className={classes.logo}
                          style={{
                            marginTop: !isMobile ? "1.5rem" : "",
                            height: "5rem",
                          }}
                        >
                          <img
                            src={provider.logoUrl}
                            alt=""
                            width="80%"
                            height="100%"
                          />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Box>

                <Box marginTop={8}>
                  <Typography variant="h5">Borrower Types</Typography>
                  <Box marginTop={3}>
                    <FieldArray
                      name="borrowerTypes"
                      render={(arrayHelpers) => (
                        <>
                          <Grid container spacing={isMobile ? 1 : 3}>
                            {values.borrowerTypes.map((borrowerType, index) => (
                              <Grid
                                container
                                spacing={isMobile ? 1 : 2}
                                item
                                xs={12}
                                key={index}
                                style={{ alignItems: "flex-end" }}
                              >
                                <Grid item xs={12} sm={2}>
                                  <FormControl className={classes.formControl}>
                                    <Typography>Type ID</Typography>
                                    <TextField
                                      variant="outlined"
                                      type="number"
                                      name={`borrowerTypes[${index}].typeId`}
                                      {...getFieldProps(
                                        `borrowerTypes[${index}].typeId`
                                      )}
                                      size="small"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <FormControl className={classes.formControl}>
                                    <Typography>Name</Typography>
                                    <TextField
                                      variant="outlined"
                                      name={`borrowerTypes.${index}.name`}
                                      {...getFieldProps(
                                        `borrowerTypes.${index}.name`
                                      )}
                                      size="small"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item>
                                  <StatusIndicator
                                    text={"Delete"}
                                    onClick={() => arrayHelpers.remove(index)}
                                  />
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                          <Box marginTop={3}>
                            <Typography
                              color="primary"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                arrayHelpers.push({ typeId: "", name: "" })
                              }
                            >
                              <AddCircleOutlineIcon
                                style={{ verticalAlign: "bottom" }}
                              />{" "}
                              Add borrower type
                            </Typography>
                          </Box>
                        </>
                      )}
                    />
                  </Box>
                </Box>

                <Box marginTop={7} align="right">
                  <Button
                    padding="1"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    Submit
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>

      {/* //This section would only show if the provider had been created (edit) */}
      {id && (
        <>
          <Box marginTop={14}>
            <ProviderConfigLayout providerConfig={providerConfig} provider={provider} />
          </Box>
          <Box marginTop={24} paddingBottom={4}>
            <Typography variant="h4">OTHER DETAILS</Typography>
            <ProviderSettingsLayout
              providerId={providerId}
              pSetting={pSetting}
            />
          </Box>
        </>
      )}
    </Paper>
  );

  return isLoading ? <Spinner /> : render();
};

export default ProviderLayout;
