import React from 'react'
import ReportLayout from '../../Components/Layout/AdminLayouts/ReportLayout'

const AdminReport = () => {
    return (
        <ReportLayout/>
    )
}

export default AdminReport
