import React from 'react'
import LoanApplicationLayout from '../../Components/Layout/UsersLayouts/LoanApplicationLayout'
// import DownTIme from '../../Components/Layout/UsersLayouts/DownTIme'

const LoanApplication = () => {
    return (
        <div>
            {/* <Box marginBottom="2rem">
                <Typography variant="h3">Users</Typography>
            </Box> */}
            {/* <DownTIme /> */}
            <LoanApplicationLayout/> 
        </div>
    )
}

export default LoanApplication
