import React from "react";
import ProfileUserLayout from "../../Components/Layout/UsersLayouts/ProfileUserLayout";
import AuthLayout from "../../Components/Layout/AdminLayouts/AuthLayout";

const ProfileUser = () => {
  return (
    <>
      <AuthLayout custom={true}>
        <ProfileUserLayout />
      </AuthLayout>
    </>
  );
};

export default ProfileUser;
