import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Select,
} from "@material-ui/core";
import Table from "../../../Table";
import { Link } from "react-router-dom";
import api from "../../../../Services/axiosAdmin";
import moment from "moment";

// Filters

// Define a default UI for filtering
export function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {

  return (
    <TextField
      label="filter"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
    />
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Select
      native
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      style={{ width: "100%", marginTop: "16px" }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function DateRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "baseline",
      }}
    >
      <TextField
        label="Start"
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <TextField
        label="End"
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

// const getStatus = color => (

// )

const ReportLayout = (props) => {
  // Columns
  const columns = React.useMemo(
    () => [
      {
        Header: "Loan Requests",
        columns: [
          {
            Header: "Name",
            accessor: "beneficiary",
          },
          {
            Header: "Amount",
            accessor: "loanAmount",
          },
          {
            Header: "Institution",
            accessor: "institution",
            Filter: SelectColumnFilter,
            filter: "includes",
          },
          {
            Header: "Sponsor Type",
            accessor: "isSelfSponsored",
            Cell: (props) => {
              return props.value ? "Self" : "Guardian";
            },
          },
          {
            Header: "Request Date",
            accessor: "dateCreated",
            Filter: DateRangeColumnFilter,
            filter: "between",
            Cell: (props) => {
              return moment(props.value).format("DD-MM-YYYY");
            },
          },
          {
            Header: "Loan Ref",
            accessor: "loanRef",
          },
          {
            Header: "Tenure",
            accessor: "tenor",
          },
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: "Status",
            accessor: "status",
            // Cell: props => {
            //   let color;

            //   switch (props.value) {
            //     case "COMPLETED":
            //       color = "blue"
            //       break;
                
            //     case "INITIATED":
            //      color = "green"

            //     default:
            //       color = "green"
            //       break;
            //   }
            //   return getStatus(color)
            // }
          },
          // {
          //   Header: 'Status',
          //   accessor: 'status',
          //   Filter: SelectColumnFilter,
          //   filter: 'includes',
          //   Cell: props => {
          //     const [ disabled, setDisabled ] = React.useState(props.value === "COMPLETED" ? true : false)
          //     return (
          //     <Button
          //       color="secondary"
          //       variant="contained"
          //       // disabled={disabled}
          //       onClick={ async () => {

          //         try {
          //             // const res = await api.post(`/LoanTransaction/approve/${props.row.allCells[7]?.value}`)
          //             // const res = await api.get('/LoanTransaction/loan/OTH7122017828')
          //             console.log(props.row.original.userId)

          //             // console.log('loans', res.data);
          //             // setDisabled(true)
          //             // tableRef.current && tableRef.current.onQueryChange()
          //         } catch (error) {

          //         }
          //     }}
          //     >
          //       {disabled ? 'Approved' : 'Approve'}
          //     </Button>
          //     )
          //   }
          // },
          {
            Header: "Action",
            Cell: (props) => {
              return (
                <Link
                style={{ textDecoration: "none" }}
                  to={`/loan?loanRef=${props.row.allCells[5]?.value}&userId=${props.row.original.userId}`}
                >
                  <Button
                    color="secondary"
                    variant="contained"
                    
                  >
                    View
                  </Button>
                </Link>
              );
            },
          },
        ],
      },
    ],
    []
  );

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    async ({ startDate, endDate, pageSize, pageIndex }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);

      if (fetchId === fetchIdRef.current) {
        const res = await api.get(
          `/LoanTransaction/loans?startDate=${startDate}&endDate=${endDate}&pageSize=${pageSize}&page=${pageIndex}`
        );
        console.log("loans pagination", res.data.responseData);
        const loanRes = res.data.responseData;
        setData(loanRes.items);
        setPageCount(loanRes.totalSize);
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    // getLoans()
  }, []);

  return (
    <div>
      {/* <ReportTable /> */}
      <Table
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        pageName="report"
      />
    </div>
  );
};

ReportLayout.propTypes = {};

export default ReportLayout;
