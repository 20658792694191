import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  profileForm: {
      width: "80%",
      margin: "0 auto",
      marginTop: "1rem"
  },
  formControl: {
      width: "100%",
  }
}));

export { useStyles }
