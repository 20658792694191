import React from 'react'
import { Box, Typography } from '@material-ui/core'
import BanksLayout from '../../Components/Layout/AdminLayouts/BanksLayout'

const AdminBanks = () => {
    return (
        <>
        <Box marginBottom="2rem">
            <Typography variant="h3">Banks</Typography>
        </Box>
        <BanksLayout/>
        </>
    )
}

export default AdminBanks
